import { useQuery } from '@apollo/client';
import * as React from 'react';

import { ModelDocument } from '../generated/graphql';
import { addFieldsetToFields, getFieldsetIds, normalizeFieldsets } from '../utils';

export function useModelFields() {
  const { data } = useQuery(ModelDocument);
  const result = React.useMemo(() => {
    if (!data?.model) {
      return {
        modelFields: [],
        modelFieldsetIds: []
      };
    }
    const fields = normalizeFieldsets(data.model.fieldsets.map(addFieldsetToFields));
    return {
      modelFields: fields,
      modelFieldsetIds: getFieldsetIds(fields)
    };
  }, [data?.model]);
  return result;
}
