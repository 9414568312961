import * as React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Button,
  DisabledSelect,
  EditPermission,
  Label,
  MiddleEllipsis,
  MyCombobox,
  MyInput,
  Section,
  SideBySide
} from '../../../components';
import {
  useApplyFieldsetUpdate,
  useApplyFieldsetUpdateLoading,
  useFieldsetState
} from '../../../hooks';
import { FieldsetFormValues, flatToSelectable, hasItems } from '../../../utils';
import { AdditionalConfig, ApiParam, FieldsTable, FieldsTableWrapper } from '../model-components';

const delimOptions = ['Comma', 'Tab', 'Semi-colon'];

export function CsvFieldsetConfig() {
  const fieldset = useFieldsetState();
  const { control, register, getValues } = useFormContext<FieldsetFormValues>();
  const { state: loading } = useApplyFieldsetUpdateLoading();
  const { applyUpdate } = useApplyFieldsetUpdate();
  const hasPath = useWatch({ control, name: 'configuration.path' });

  function handleRefresh() {
    applyUpdate(
      { ...getValues('configuration') },
      { refresh: !!hasPath && hasItems(fieldset?.fields) }
    );
  }

  return (
    <>
      <div className="border-b border-gray-300">
        <Section className="space-y-6">
          <SideBySide heading="Build model using" styles="max-w-4xl">
            <div>
              <Label htmlFor="path">Path to CSV file</Label>
              <div className="flex items-start space-x-2">
                <label htmlFor="path" className="mt-1.5 max-w-xl truncate">
                  <MiddleEllipsis
                    text={fieldset?.connection.configuration.url as string | undefined}
                  />
                </label>
                <div>
                  <EditPermission>
                    <MyInput {...register('configuration.path')} className="min-w-[15rem]" />
                  </EditPermission>
                  <p className="mt-0.5 overflow-hidden whitespace-nowrap text-xs text-gray-500">
                    e.g. /static/table.csv
                  </p>
                </div>
              </div>
            </div>
            <Label>Delimiter</Label>
            <Controller
              control={control}
              name="configuration.delim"
              render={({ field }) => (
                <EditPermission
                  fallback={<DisabledSelect className="max-w-xs" valueLabel={field.value} />}
                >
                  <MyCombobox
                    className="max-w-xs"
                    value={flatToSelectable(field.value)}
                    options={delimOptions.map(flatToSelectable)}
                    onChange={option => field.onChange(option?.value)}
                  />
                </EditPermission>
              )}
            />
            <ApiParam className="mt-4" label="Headers" type="headers" />
            <ApiParam className="mt-4" label="Query string parameters" type="parameters" />

            {fieldset?.fields.length === 0 && (
              <Button className="mt-4" onClick={handleRefresh} loading={loading}>
                Continue
              </Button>
            )}
          </SideBySide>
          <FieldsTableWrapper
            hasFields={hasItems(fieldset?.fields)}
            loading={loading}
            refresh={handleRefresh}
            hasWriteinFields={fieldset?.properties.writeinFields}
          >
            <FieldsTable
              fields={fieldset?.fields}
              hasWriteinFields={fieldset?.properties.writeinFields}
            />
          </FieldsTableWrapper>
        </Section>
      </div>
      <AdditionalConfig />
    </>
  );
}
