import { useLazyQuery } from '@apollo/client';
import { RadioGroup } from '@headlessui/react';
import cx from 'clsx';
import * as React from 'react';
import { useAmplitude } from '~/lib/Amplitude';
import { v4 as uuid } from 'uuid';

import { Icon } from '~/components';
import { Button, SideBySide } from '../../components';
import { Dialog } from '~/components/v3';
import {
  ExportQueryAsyncDocument,
  ExportQueryDocument,
  ExportQueryQuery,
  ExportQueryQueryVariables,
  ExportSqlResult
} from '../../generated/graphql';
import { useBannerDispatch, useLazyContinuationQuery } from '../../hooks';
import { Selectable } from '../../utils';

const options = [
  { value: 'visible', label: 'Visible results only' },
  { value: 'all', label: 'All rows' }
];

interface Props {
  query: string;
  connectionId: string;
  toggle: () => void;
}

export function QueryExportDialog(props: Props & { toggle: () => void }) {
  const { logEvent } = useAmplitude();
  const dispatchBanner = useBannerDispatch();
  const [exportEmail, setExportEmail] = React.useState<string | null>(null);
  const [selected, setSelected] = React.useState<Selectable>(options[0]);

  const [exportQueryAsync, { loading: exportAsyncLoading }] = useLazyQuery(
    ExportQueryAsyncDocument,
    {
      onCompleted: data => {
        if (!data || !data.exportQueryAsync) {
          return;
        }
        setExportEmail(data.exportQueryAsync);
      },
      onError: error => {
        dispatchBanner({ type: 'show', payload: { message: error } });
        toggleDialog();
      },
      fetchPolicy: 'no-cache'
    }
  );

  const [exportQuery, { loading: exportLoading }] = useLazyContinuationQuery<
    ExportQueryQuery,
    ExportQueryQueryVariables
  >(ExportQueryDocument, {
    onCompleted: data => {
      if (!data || !data.exportQuery) {
        return;
      }
      toggleDialog();
      const link = (data.exportQuery as ExportSqlResult).downloadLink;
      if (link) {
        window.location.href = link;
      }
    },
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error } });
      toggleDialog();
    }
  });

  function toggleDialog() {
    props.toggle();
    setExportEmail(null);
    setSelected(options[0]);
  }

  function handleExportVisible() {
    logEvent('sqlrunner.export');
    void exportQuery({
      variables: {
        connectionID: props.connectionId,
        query: props.query,
        continuation: uuid(),
        visibleOnly: true
      }
    });
  }

  function handleExportAll() {
    logEvent('sqlrunner.export');
    void exportQueryAsync({
      variables: {
        connectionID: props.connectionId,
        query: props.query
      }
    });
  }

  function handleExport() {
    if (selected.value === 'all') {
      handleExportAll();
    } else {
      handleExportVisible();
    }
  }
  return (
    <Dialog
      show={true}
      heading="Export query"
      actions={
        <>
          <Button onClick={toggleDialog} disabled={exportLoading || exportAsyncLoading}>
            {exportEmail ? 'Close' : 'Cancel'}
          </Button>

          <Button
            theme="primary"
            disabled={!!exportEmail}
            loading={exportLoading || exportAsyncLoading}
            onClick={handleExport}
          >
            Export CSV
          </Button>
        </>
      }
      slots={{ body: 'p-0' }}
      size="xs"
    >
      {exportEmail ? (
        <div className="space-y-4 p-6 text-sm text-gray-800">
          <h3 className="font-medium">Export in progress...</h3>
          <p className="font-normal">
            When the completed CSV file is ready, a download link will be sent to:{' '}
            <strong className="font-medium">{exportEmail}</strong>
          </p>
        </div>
      ) : (
        <SideBySide hasSectionWrap heading="Data to include" styles="grid gap-2">
          <RadioGroup
            value={selected}
            onChange={setSelected}
            disabled={exportLoading || exportAsyncLoading}
          >
            <RadioGroup.Label className="sr-only">Data to include</RadioGroup.Label>
            <div className="space-y-4">
              {options.map(option => (
                <RadioGroup.Option
                  key={option.value}
                  value={option}
                  className="group max-w-fit focus:outline-none"
                >
                  {({ checked }) => (
                    <span className="flex items-center space-x-1.5 group-hover:cursor-pointer">
                      <Icon
                        name={checked ? 'ActiveCircle' : 'EmptyCircle'}
                        className={cx(
                          'h-4 w-4 rounded-full group-hover:cursor-pointer group-focus-visible:ring-2 group-focus-visible:ring-indigo-500 group-focus-visible:ring-offset-2',
                          checked ? 'text-green-500' : 'text-gray-500'
                        )}
                      />
                      <RadioGroup.Label className="mt-px leading-none group-hover:cursor-pointer">
                        {option.label}
                      </RadioGroup.Label>
                    </span>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </SideBySide>
      )}
    </Dialog>
  );
}
