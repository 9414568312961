import clsx from 'clsx';

import { COLOR } from '~/components/v2/configs/SX';

// Todo - add comments
export const LoadingDotsColor = {
  [COLOR.DEFAULT]: 'bg-gray-800',
  [COLOR.PRIMARY]: 'bg-indigo-600',
  [COLOR.SECONDARY]: 'bg-gray-400',
  [COLOR.SUCCESS]: 'bg-green-500',
  [COLOR.INFO]: 'bg-blue-500',
  [COLOR.WARNING]: 'bg-orange-500',
  [COLOR.ERROR]: 'bg-red-500'
};

// Todo - add comments
export type LoadingDotsProps = {
  // Enum
  color?: keyof typeof LoadingDotsColor;
  // Boolean
  dense?: boolean;
  buttonLoader?: boolean;
};

// Todo - add comments
const LoadingDots = ({
  color = COLOR.DEFAULT,
  dense = false,
  buttonLoader = false
}: LoadingDotsProps) => {
  const ROOT_SX = clsx(
    'inline-flex',
    dense && 'h-1 w-3',
    buttonLoader && 'absolute left-1/2 mt-px -translate-x-1/2 transform'
  );
  const DOTS_SX = clsx(
    'w-2.5 h-2.5 rounded-full inline-block opacity-70 animate-sk-bounce',
    LoadingDotsColor[color],
    buttonLoader && 'bg-current'
  );

  return (
    <span className={ROOT_SX}>
      <span style={{ animationDelay: '-0.32s' }} className={DOTS_SX} />
      <span style={{ animationDelay: '-0.16s' }} className={DOTS_SX} />
      <span className={DOTS_SX} />
    </span>
  );
};

export default LoadingDots;
