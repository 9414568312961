import { useQuery } from '@apollo/client';
import { generatePath, Link, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { Icon } from '~/components';
import { FieldDetail, FieldPill, SideBySide } from '../../../components';
import {
  SyncForFieldFragment,
  SyncsForFieldDocument,
  TargetField
} from '../../../generated/graphql';
import { routes } from '../../../utils';

export function SyncsUsingField() {
  const { fieldId } = useParams<{ fieldId: string }>();
  const { data, loading } = useQuery(SyncsForFieldDocument, {
    fetchPolicy: 'network-only',
    skip: !fieldId,
    variables: { fieldId }
  });
  const syncs = data?.syncsForField;

  if (loading || !syncs || syncs.length == 0) {
    return null;
  }

  const targets = syncs.map(sync => targetForField(fieldId, sync));
  return (
    <SideBySide hasSectionWrap heading="Syncs">
      <ul className={clsx('space-y-2', syncs.length > 1 && 'list-disc')}>
        {syncs.map((sync, idx) => (
          <li key={sync.id} className="grid grid-cols-[1fr,auto,1fr] items-center space-x-1">
            <Link
              to={generatePath(routes.syncStatus, { id: sync?.id })}
              target="_blank"
              rel="noopener noreferrer"
              className="link text-sm"
            >
              {sync.name}
            </Link>
            <Icon name="ArrowNarrowRight" className="h-5 w-5 text-gray-500" />
            <FieldPill
              type="logo"
              img={sync?.targetConnection?.type.id}
              text={targets[idx]?.name}
              tooltipContent={
                <FieldDetail
                  theme="dark"
                  logoId={sync?.targetConnection?.type.id}
                  columnName={targets[idx]?.id}
                  type={targets[idx]?.type}
                />
              }
            />
          </li>
        ))}
      </ul>
    </SideBySide>
  );

  function targetForField(fieldId: string, sync: SyncForFieldFragment): TargetField {
    if (sync.identity?.model?.id === fieldId) {
      return sync.identity.target;
    }
    return sync.fields.find(f => f.model.id === fieldId)?.target;
  }
}
