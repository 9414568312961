import { SideBySide } from '~/components';

/**
 * ConnectionIPWhitelistProps
 * if hidden is true, the component will not render
 */
type ConnectionIPWhitelistProps = {
  hidden?: boolean;
};

/**
 * PolytmicIPWhitelist
 * Whitelist of IP addresses that are allowed to connect to the Polytomic API.
 * The list of IP addresses can be found on this docs page: https://docs.polytomic.com/docs/whitelist-ips
 */
const PolytmicIPWhitelist: string[] = [
  '54.190.82.25',
  '44.232.40.21',
  '35.155.106.54',
  '54.200.67.134',
  '44.224.213.129',
  '54.149.95.139'
];

const ConnectionIPWhitelist = ({ hidden }: ConnectionIPWhitelistProps) => {
  const heading = 'IP address whitelist';
  const subheading = '(Optional)';
  const description = 'Polytomic offers these public IP addresses for whitelisting:';

  return hidden ? null : (
    <SideBySide
      hasSectionWrap
      heading={
        <div className="flex flex-col">
          <h3 className="sbs-heading">{heading}</h3>
          <p className="text-gray-500 text-sm font-normal leading-5">{subheading}</p>
        </div>
      }
      children={
        <div className="flex flex-col space-y-2">
          <p className="text-gray-800 text-sm font-normal leading-5">{description}</p>
          <div className="flex flex-col">
            {PolytmicIPWhitelist.map(ip => (
              <p
                key={ip}
                className="text-gray-600 text-sm font-mono font-normal leading-6 tracking-wider"
                children={ip}
              />
            ))}
          </div>
        </div>
      }
    />
  );
};

export default ConnectionIPWhitelist;
