import clsx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as React from 'react';
import ReactDatePicker from 'react-date-picker/dist/entry.nostyle';
import { v4 as uuid } from 'uuid';

import { isValidDate } from '../../utils';
import { Icon } from '~/components';
import { isArray } from 'lodash';

dayjs.extend(customParseFormat);

interface Props {
  className?: string;
  value?: string;
  onChange: (value: string, idx: number) => void;
  index: number;
  // Todo -> turn into enum type for all inputs
  variant?: 'outlined' | 'flat';
}

export const MyDatePicker = ({ index, className, variant = 'outlined', ...props }: Props) => {
  const [value, setValue] = React.useState<Date | [Date | null, Date | null] | null | undefined>(
    () => {
      // Check to see if string is null or an invalid date
      if (!props.value || !dayjs(props.value).isValid()) {
        return null;
      } else {
        const onlyDate = props.value.split('T');
        if (onlyDate.length === 0) {
          return null;
        } else {
          const date = dayjs(onlyDate[0], 'YYYY-MM-DD', true);
          return new Date(date.toDate());
        }
      }
    }
  );

  const onChange = (val: Date | [Date | null, Date | null] | null | undefined) => {
    if (val && !isArray(val)) {
      val.setHours((-1 * val.getTimezoneOffset()) / 60);
    }
    if (val && isArray(val)) {
      val.forEach(v => v.setHours((-1 * v.getTimezoneOffset()) / 60));
    }
    setValue(val);
  };

  const handleDateConversion = () => {
    if (!isValidDate(value) || Array.isArray(value)) {
      return;
    }
    const [date] = value.toISOString().split('T');
    const utcDate = date.concat('T00:00:00.000Z');
    props.onChange(utcDate, index);
  };

  return (
    <ReactDatePicker
      className={clsx(variant === 'outlined' ? 'react-date-picker' : 'react-date-picker-flat')}
      name={uuid()}
      // Makes week start on Sunday vs default of Monday
      calendarType="US"
      returnValue="start"
      value={value}
      onChange={onChange}
      onCalendarClose={handleDateConversion}
      minDetail="year"
      calendarIcon={
        <Icon name="TypeDate" className="h-5 w-5 shrink-0 text-gray-500 hover:text-gray-800" />
      }
      clearIcon={
        <Icon name="CloseXSmall" className="h-5 w-5 shrink-0 text-gray-500 hover:text-gray-800" />
      }
    />
  );
};
