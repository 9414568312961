import * as React from 'react';
import cx from 'clsx';

import { useFormContext } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  MyCombobox,
  MyInput,
  SideBySide
} from '../../../components';
import { ConnectionTypeFragment } from '../../../generated/graphql';
import { getSchemaNormalized, isRequiredMsg, Selectable } from '../../../utils';
import { ConnectionFormValues } from '../connection-config';

const S3_AUTH_METHODS = {
  ACCESS_KEY_AND_SECRET: { label: 'Access Key and Secret', value: 'access_key_and_secret' },
  IAM_ROLE: { label: 'IAM Role', value: 'iam_role' }
};

export type S3ConnectionConfigProps = {
  connectionType: ConnectionTypeFragment;
  organizationID: string;
};

export function S3ConnectionConfig({ connectionType, organizationID }: S3ConnectionConfigProps) {
  const { setValue, register, getValues, formState, watch } =
    useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  const [authMethod, setAuthMode] = React.useState<Selectable>(() => {
    const auth = getValues('configuration.auth_mode');
    return auth === 'iam_role' ? S3_AUTH_METHODS.IAM_ROLE : S3_AUTH_METHODS.ACCESS_KEY_AND_SECRET;
  });

  const fields = getSchemaNormalized(connectionType.configurationSchema);

  const handleAuthModeChange = (option: Selectable | null) => {
    if (option) {
      setValue('configuration.auth_mode', option.value, {
        shouldValidate: true,
        shouldDirty: true
      });
      setAuthMode(option);
    }
  };

  const awsUser = watch('configuration.aws_user');

  return (
    <>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Authentication method">
        <EditPermission
          fallback={<DisabledSelect valueLabel={authMethod?.label} className="max-w-xs" />}
        >
          <MyCombobox
            className="max-w-xs"
            value={authMethod}
            options={Object.values(S3_AUTH_METHODS)}
            onChange={handleAuthModeChange}
          />
        </EditPermission>
      </SideBySide>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Options">
        {authMethod?.value === S3_AUTH_METHODS.ACCESS_KEY_AND_SECRET.value && (
          <div className="max-w-xs">
            <EditPermission>
              <MyInput
                {...register('configuration.aws_access_key_id', {
                  required: isRequiredMsg(fields?.['aws_access_key_id']?.title)
                })}
                label={fields?.['aws_access_key_id']?.title}
                description={fields?.['aws_access_key_id']?.description}
                errors={errors}
              />
            </EditPermission>
          </div>
        )}
        {authMethod?.value === S3_AUTH_METHODS.ACCESS_KEY_AND_SECRET.value && (
          <div className="max-w-xs">
            <EditPermission>
              <MyInput
                {...register('configuration.aws_secret_access_key', {
                  required: isRequiredMsg(fields?.['aws_secret_access_key']?.title)
                })}
                label={fields?.['aws_secret_access_key']?.title}
                description={fields?.['aws_secret_access_key']?.description}
                errors={errors}
              />
            </EditPermission>
          </div>
        )}
        {authMethod?.value === S3_AUTH_METHODS.ACCESS_KEY_AND_SECRET.value && (
          <div className={cx(!awsUser && 'hidden', 'max-w-xs')}>
            <EditPermission>
              <MyInput
                {...register('configuration.aws_user', {})}
                label={fields?.['aws_user']?.title}
                description={fields?.['aws_user']?.description}
                readOnly={true}
                errors={errors}
              />
            </EditPermission>
          </div>
        )}
        {authMethod?.value === S3_AUTH_METHODS.IAM_ROLE.value && (
          <div className="max-w-xs">
            <EditPermission>
              <MyInput
                {...register('configuration.iam_role_arn', {
                  required: isRequiredMsg(fields?.['iam_role_arn']?.title)
                })}
                label={fields?.['iam_role_arn']?.title}
                description={fields?.['iam_role_arn']?.description}
                errors={errors}
              />
            </EditPermission>
          </div>
        )}
        <div className="max-w-xs">
          <EditPermission>
            <MyInput
              {...register('configuration.s3_bucket_region', {
                required: isRequiredMsg(fields?.['s3_bucket_region']?.title)
              })}
              label={fields?.['s3_bucket_region']?.title}
              description={fields?.['s3_bucket_region']?.description}
              errors={errors}
            />
          </EditPermission>
        </div>
        <div className="max-w-xs">
          <EditPermission>
            <MyInput
              {...register('configuration.s3_bucket_name', {
                required: isRequiredMsg(fields?.['s3_bucket_name']?.title)
              })}
              label={fields?.['s3_bucket_name']?.title}
              description={fields?.['s3_bucket_name']?.description}
              errors={errors}
            />
          </EditPermission>
        </div>

        {authMethod?.value === S3_AUTH_METHODS.IAM_ROLE.value && (
          <div className="max-w-xs">
            <EditPermission>
              <MyInput
                label="External ID"
                description="External ID for the IAM role"
                readOnly={true}
                value={organizationID}
              />
            </EditPermission>
          </div>
        )}
      </SideBySide>
    </>
  );
}
