import * as FullStory from '@fullstory/browser';
import { Completion } from '@codemirror/autocomplete';
import { useMutation, useQuery } from '@apollo/client';
import { EditorState } from '@codemirror/state';
import { sql } from '@codemirror/lang-sql';
import { debounce } from 'lodash';
import * as React from 'react';
import { useAmplitude } from '~/lib/Amplitude';
import { v4 as uuid } from 'uuid';

import { Editor, OneWayCancel, RefreshButton, TableTopper, TableWrap } from '../../components';
import {
  ConnectionSchemasDocument,
  GetBufferDocument,
  RunQueryDocument,
  RunQueryQuery,
  RunQueryQueryVariables,
  SaveBufferDocument,
  SqlRunnerResult
} from '../../generated/graphql';
import {
  useAbortQuery,
  useBannerDispatch,
  useEditorViewState,
  useLazyContinuationQuery
} from '../../hooks';
import { handleReplaceQuery, handleWorksheet } from '../../utils';
import { QueryHistory } from './query-history';
import _ from 'lodash';
import { ResizeHandleH } from '~/components/v3/Icons';
import { NumberSize, Resizable } from 're-resizable';

const storageKey = 'query-runner-editor';
const MIN_HEIGHT = 150;
const DEFAULT_HEIGHT = 300;

interface Props {
  connectionId: string;
  setQueryResult: React.Dispatch<React.SetStateAction<SqlRunnerResult | undefined>>;
  lastQuery: string;
  setLastQuery: React.Dispatch<React.SetStateAction<string>>;
  setQueryLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function QueryRunnerEditor({
  connectionId,
  lastQuery,
  setLastQuery,
  setQueryResult,
  setQueryLoading
}: Props) {
  const { logEvent } = useAmplitude();
  const dispatchBanner = useBannerDispatch();

  const [enableRun, setEnableRun] = React.useState(false);
  const [runCount, setRunCount] = React.useState(0);

  const view = useEditorViewState();
  const { signal, abortQuery, resetAbortQuery } = useAbortQuery();

  const [height, setHeight] = React.useState(() => {
    const stored = localStorage.getItem(storageKey);
    return stored ? Math.max(parseInt(stored), MIN_HEIGHT) : DEFAULT_HEIGHT;
  });

  const onResizeStop = (_e, _direction, _ref, d: NumberSize) => {
    const newHeight = height + d.height;
    setHeight(newHeight);
    if (newHeight >= MIN_HEIGHT) {
      localStorage.setItem(storageKey, newHeight.toString());
    }
  };

  // Saves a successful query buffer to the server
  const [saveQuery] = useMutation(SaveBufferDocument);
  // Fetches the last query buffer from the server
  useQuery(GetBufferDocument, {
    variables: { connectionId: connectionId },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data && data.getBuffer) {
        handleReplaceQuery(view, String(data.getBuffer));
      } else {
        handleReplaceQuery(view, '');
      }
    }
  });
  const [completions, setCompletions] = React.useState<Record<string, Completion[]>>({});
  useQuery(ConnectionSchemasDocument, {
    variables: { connectionId: connectionId },
    onCompleted: data => {
      if (data && data.schemas && data.schemas.schemas) {
        const completions = data.schemas.schemas.reduce((acc, schema) => {
          acc[schema.name] = schema.columns.map(column => {
            return {
              label: column.columnName,
              type: column.type,
              detail: column.sourceType
            };
          });
          acc[schema.label] = schema.columns.map(column => {
            return {
              label: column.columnName,
              type: column.type,
              detail: column.sourceType
            };
          });
          return acc;
        }, {});
        setCompletions(completions);
      }
    }
  });
  const [runQuery, { loading: runQueryLoading }] = useLazyContinuationQuery<
    RunQueryQuery,
    RunQueryQueryVariables
  >(RunQueryDocument, {
    context: { fetchOptions: { signal } },
    onCompleted: data => {
      setQueryLoading(false);
      if (!data || !data.runQuery) {
        return;
      }
      setQueryResult(data.runQuery as SqlRunnerResult);
      setRunCount(prev => prev + 1);
    },
    onError: error => {
      setQueryLoading(false);
      if (signal.aborted && error?.message.includes('aborted')) {
        return;
      }
      dispatchBanner({ type: 'show', payload: { message: error } });
    }
  });
  const isValidRef = React.useRef<boolean>(false);
  const debouncedUpdate = debounce((state: EditorState) => {
    const hasQuery = handleWorksheet(state);

    const isChanged = isValidRef.current !== !!hasQuery;
    if (!isChanged) {
      return;
    }
    isValidRef.current = !!hasQuery;
    if (hasQuery && !enableRun) {
      setEnableRun(true);
      return;
    }
    if (!enableRun) {
      setEnableRun(false);
    }
  }, 300);

  const onAnyUpdate = React.useCallback(debouncedUpdate, [debouncedUpdate]);

  const handleRunQuery = React.useCallback(() => {
    logEvent('sqlrunner.run');
    if (!enableRun) {
      return;
    }
    if (!connectionId) {
      dispatchBanner({
        type: 'show',
        payload: {
          message: 'Select a connection to query against'
        }
      });
      return;
    }
    const query = view ? handleWorksheet(view.state) : null;
    if (!query) {
      dispatchBanner({ type: 'show', payload: { message: 'Invalid statement.' } });
      return;
    }
    dispatchBanner({ type: 'hide' });
    resetAbortQuery();
    setQueryResult(undefined);
    setLastQuery(query);
    setQueryLoading(true);
    void runQuery({
      variables: {
        connectionID: connectionId,
        query,
        continuation: uuid()
      }
    });
    import.meta.env.MODE === 'production' &&
      FullStory.isInitialized() &&
      FullStory.event('query_runner.run', { query });
    void saveQuery({
      variables: {
        connectionId: connectionId,
        buffer: view?.state?.doc?.toString() || ''
      }
    });
  }, [
    logEvent,
    enableRun,
    connectionId,
    view,
    dispatchBanner,
    resetAbortQuery,
    setQueryResult,
    setLastQuery,
    setQueryLoading,
    runQuery,
    saveQuery
  ]);

  return (
    <Resizable
      handleClasses={{
        right: 'pointer-events-none',
        top: 'pointer-events-none',
        left: 'pointer-events-none',
        topRight: 'pointer-events-none',
        bottomRight: 'pointer-events-none',
        bottomLeft: 'pointer-events-none',
        topLeft: 'pointer-events-none',
        bottom: `
                !w-[initial] flex flex-row items-center justify-center
                bg-transparent hover:bg-gray-200 active:bg-gray-300 transition
                absolute !-bottom-[0.9rem] !right-4 !left-4 text-gray-400 hover:text-gray-500 active:text-gray-600
                rounded !h-[5px]
              `
      }}
      handleComponent={{ bottom: <ResizeHandleH className="h-6 w-6" /> }}
      maxHeight="60vh"
      minWidth="100%"
      minHeight={MIN_HEIGHT}
      size={{ height, width: '100%' }}
      onResizeStop={onResizeStop}
    >
      <TableWrap className="flex h-full flex-col">
        <TableTopper className="h-[4rem] min-h-[4rem] justify-between px-4">
          Query editor
          <div className="flex items-center space-x-2">
            <OneWayCancel
              canceled={signal.aborted}
              loading={runQueryLoading}
              onClick={abortQuery}
            />
            <QueryHistory
              view={view}
              refetch={`${connectionId}-${runCount}`}
              connectionId={connectionId}
            />
            <RefreshButton
              disabled={!enableRun}
              onExecution={handleRunQuery}
              executionText="Run query"
              loading={runQueryLoading}
            />
          </div>
        </TableTopper>

        <div className="flex-1 overflow-auto">
          <Editor
            language={sql({
              upperCaseKeywords: true,
              schema: completions
            })}
            defaultDoc={lastQuery || ''}
            completions={completions}
            onUpdate={null}
            onAnyUpdate={onAnyUpdate}
            height="100%"
            resizable={false}
            className="h-full"
          />
        </div>
      </TableWrap>
    </Resizable>
  );
}
