import * as React from 'react';

import { useAuth } from '../hooks';

interface Props {
  feature: string;
  children?: React.ReactNode;
  fallback?: React.ReactNode;
}

export function FeatureFlag({ feature, children, fallback }: Props) {
  const { user } = useAuth();

  if (!children) {
    return null;
  }
  if (!React.isValidElement(children)) {
    throw new Error('must be a single element');
  }
  if (!user?.organization.features.includes(feature)) {
    if (fallback) {
      if (!React.isValidElement(fallback)) {
        throw new Error('must be a single element');
      }
      return fallback;
    }
    return null;
  }
  return children;
}
