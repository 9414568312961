import { Reference, useMutation } from '@apollo/client';

import { Icon } from '~/components';
import { Button } from '../../components';
import { DeleteUserDocument, UserFragment } from '../../generated/graphql';
import { useBannerDispatch } from '../../hooks';
import { Dialog } from '~/components/v3';

const wrapperStyles = 'mb-3';

interface Props {
  user: UserFragment;
  toggleDialog: () => void;
}

export function DeleteUserDialog({ user, toggleDialog }: Props) {
  const dispatchBanner = useBannerDispatch();
  const [deleteUser, { loading: deleteUserLoading }] = useMutation(DeleteUserDocument, {
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
      toggleDialog();
    },
    update: (cache, { data }) => {
      if (!data || !data.deleteUser) {
        return;
      }
      const id = data.deleteUser;
      cache.modify({
        fields: {
          users: (existing: Reference[], { readField }) => {
            const filtered = existing.filter(ref => readField('id', ref) !== id);
            return filtered;
          }
        }
      });
      cache.evict({ id: cache.identify({ __typename: 'User', id }) });
      cache.gc();
      toggleDialog();
    }
  });

  const handleDelete = () => {
    dispatchBanner({ type: 'hide' });
    void deleteUser({ variables: { id: user.id } });
  };
  return (
    <Dialog
      show
      size="lg"
      onDismiss={toggleDialog}
      heading="Remove user?"
      actions={
        <>
          <Button onClick={toggleDialog}>Cancel</Button>
          <Button theme="danger" onClick={handleDelete} disabled={deleteUserLoading}>
            Remove user
          </Button>
        </>
      }
    >
      <div className="mb-4 flex items-start space-x-2">
        <Icon name="UserCircle" className="h-5 w-5 text-gray-500" />
        <p className="text-sm font-medium">{user.email}</p>
      </div>
      <p className="text-sm text-gray-600 [max-width:65ch]">
        This team member will lose access Polytomic. You will need to re-add them if you would like
        them to access Polytomic again.
      </p>
    </Dialog>
  );
}
