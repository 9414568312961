import cx from 'clsx';
import * as React from 'react';
import LoadingDots from '../v2/feedback/LoadingDots';
import { Icon } from '../Icon';
import { IconName } from '~/assets';

const baseStyles = 'inline-flex font-medium items-center border rounded group focus:outline-none';
const baseDisabledStyles = 'cursor-not-allowed';

const themes = {
  default: 'text-gray-700 bg-gray-50 border-gray-400 shadow-button',
  white: 'text-gray-700 bg-white border-gray-400 shadow-button',
  placeholder: 'text-gray-400 bg-gray-50 border-gray-400 shadow-button',
  primary: 'text-white bg-indigo-500 border-indigo-700 shadow-button',
  outline: 'text-white bg-transparent border-white border-opacity-50',
  ghost: 'text-gray-500 bg-gray-800 bg-opacity-0 border-transparent',
  'ghost-ko': 'text-white bg-transparent border-transparent text-opacity-50',
  // success: "text-green-700 bg-green-200 border-green-400 shadow-button",
  warning: 'text-orange-700 bg-orange-200 border-orange-400 shadow-button',
  danger: 'text-red-700 bg-red-200 border-red-400 shadow-button',
  disabled: 'text-gray-400 bg-gray-200 border-gray-200',
  'disabled-outline': 'text-white text-opacity-60 border-white border-opacity-30'
};

const themesInteractive = {
  default:
    'hover:bg-gray-100 hover:border-gray-500 active:bg-gray-200 active:border-gray-600 focus-visible:ring-1 focus-visible:ring-indigo-500 focus-visible:border-indigo-500',
  white:
    'hover:border-gray-500 active:text-gray-500 active:border-gray-600 focus-visible:ring-1 focus-visible:ring-indigo-500 focus-visible:border-indigo-500',
  placeholder: 'hover:bg-gray-100 hover:border-gray-500 active:bg-gray-200 active:border-gray-600',
  primary:
    'hover:bg-indigo-600 active:bg-indigo-700 focus-visible:ring-4 focus-visible:ring-indigo-300 focus-visible:border-gray-800',
  outline:
    'hover:border-opacity-100 active:border-opacity-25 focus-visible:ring-1 focus-visible:ring-white focus-visible:border-white',
  ghost:
    'hover:text-gray-800 hover:bg-opacity-5 active:bg-opacity-10 focus-visible:ring-1 focus-visible:ring-indigo-400 focus-visible:border-indigo-400',
  'ghost-ko':
    'hover:text-opacity-100 hover:bg-white hover:bg-opacity-30 active:text-opacity-25 active:bg-white active:bg-opacity-20 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-20',
  // success:
  //     "hover:bg-green-300 active:bg-green-400 focus-visible:ring-1 focus-visible:ring-green-500 focus-visible:border-green-500",
  warning:
    'hover:bg-orange-300 active:bg-orange-400 focus-visible:ring-1 focus-visible:ring-orange-500 focus-visible:border-orange-500',
  danger:
    'hover:bg-red-300 active:bg-red-400 focus-visible:ring-1 focus-visible:ring-red-500 focus-visible:border-red-500',
  disabled: '',
  'disabled-outline': ''
};

const sizes = {
  default: 'text-sm h-8 px-3',
  mini: 'text-xs h-6 px-2',
  icon: 'p-2'
};

type ButtonProps = {
  theme?: keyof typeof themes;
  size?: keyof typeof sizes;
  disabled?: boolean;
  readOnly?: boolean;
  loading?: boolean;
  iconStart?: IconName;
  iconEnd?: IconName;
} & React.ComponentPropsWithoutRef<'button'>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      theme = 'default',
      size = 'default',
      disabled = false,
      loading = false,
      onClick,
      className,
      children,
      iconStart,
      iconEnd,
      ...props
    },
    ref
  ) => {
    const iconStyles = cx(
      'shrink-0 text-current opacity-70',
      !disabled && !props.readOnly && 'group-hover:opacity-100',
      size === 'default' && 'w-5 h-5',
      size === 'mini' && 'w-4 h-4'
    );

    return (
      <button
        {...props}
        ref={ref}
        className={cx(
          'relative',
          baseStyles,
          disabled || props.readOnly
            ? theme === 'outline'
              ? [baseDisabledStyles, themes['disabled-outline']]
              : [baseDisabledStyles, themes.disabled]
            : loading
            ? [baseDisabledStyles, themes[theme]]
            : [themes[theme], themesInteractive[theme]],
          sizes[size],
          className
        )}
        disabled={loading || disabled || theme === 'disabled'}
        onClick={disabled || props.readOnly ? undefined : onClick}
      >
        {loading && <LoadingDots buttonLoader />}
        <span
          className={cx(
            'inline-flex w-full items-center justify-between',
            size === 'default' && 'space-x-2',
            size === 'mini' && 'space-x-1',
            loading ? 'invisible' : 'visible'
          )}
        >
          {iconStart && <Icon name={iconStart} className={cx(iconStyles, 'justify-self-start')} />}
          {typeof children === 'string' ? <span>{children}</span> : children}
          {iconEnd && <Icon name={iconEnd} className={cx(iconStyles, 'justify-self-end')} />}
        </span>
      </button>
    );
  }
);
