import * as React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Select, { components, createFilter, SingleValueProps } from 'react-select';
import { Icon } from '~/components';
import { IconName } from '~/assets';
import {
  Checkbox,
  DisabledSelect,
  EditPermission,
  MyInput,
  MyPopover,
  PopoverAction,
  PopoverButtonMinimal,
  TableDataType,
  TableTruncatedCell
} from '../../../components';
import { FieldType, ModelFieldFragment } from '../../../generated/graphql';
import { useFieldsetDispatch, useFieldsetState, useToggle } from '../../../hooks';
import {
  DropdownIndicator,
  FieldsetFormValues,
  fieldTypeIconName,
  hasItems,
  selectStyles,
  updateFieldsetForm
} from '../../../utils';
import { ColumnDef, DataTable } from '~/components/v3';

const SelectAllCheckbox = React.memo(() => {
  const [checked, setChecked] = React.useState(false);
  const { control, setValue } = useFormContext<FieldsetFormValues>();
  const fields = useWatch({ control, name: 'fields' });

  React.useEffect(() => {
    if (hasItems(fields) && fields.every(field => field.published)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [fields]);

  function handleChange(e: React.FormEvent<HTMLInputElement>) {
    setChecked(e.currentTarget.checked);
    for (let i = 0; i < fields.length; i++) {
      setValue(`fields.${i}.published`, e.currentTarget.checked, { shouldDirty: true });
    }
  }

  return (
    <div className="inline-flex items-center">
      <EditPermission>
        <Checkbox name="include-all" checked={checked} onChange={handleChange} />
      </EditPermission>
    </div>
  );
});

function formatOptionLabel({ value, label }: { value: FieldType; label: IconName }) {
  return (
    <span className="flex space-x-2">
      <Icon name={label} className="h-5 w-5 text-gray-500" />
      <span>{value}</span>
    </span>
  );
}

function SingleValue(props: SingleValueProps<{ value: FieldType; label: IconName }>) {
  return (
    <components.SingleValue {...props}>
      <span className="flex space-x-2">
        <Icon name={props.data.label} className="h-5 w-5 text-gray-500" />
        <span>{props.data.value || 'Not specified'}</span>
      </span>
    </components.SingleValue>
  );
}

const FieldTypeSelect = React.memo<{ index: number }>(({ index }) => {
  const { control } = useFormContext<FieldsetFormValues>();

  return (
    <Controller
      control={control}
      name={`fields.${index}.type`}
      render={({ field }) => (
        <EditPermission
          fallback={
            <DisabledSelect
              valueLabel={
                <>
                  <Icon name={fieldTypeIconName(field?.value)} className="h-5 w-5 text-gray-500" />
                  <div className="ml-1.5 text-sm text-gray-800">{field?.value}</div>
                </>
              }
              className="w-full min-w-[130px]"
            />
          }
        >
          <Select
            autoFocus={false}
            filterOption={createFilter({ ignoreAccents: false })}
            components={{
              SingleValue,
              DropdownIndicator,
              ClearIndicator: null,
              IndicatorSeparator: null
            }}
            formatOptionLabel={formatOptionLabel}
            value={
              field.value ? { value: field.value, label: fieldTypeIconName(field.value) } : null
            }
            options={Object.values(FieldType)
              .map(type => ({
                value: type,
                label: fieldTypeIconName(type)
              }))
              .filter(({ value }) => value !== FieldType.Unknown)}
            onChange={(option: { label: IconName; value: FieldType } | null) => {
              if (!option) {
                return;
              }
              field.onChange(option.value);
            }}
            maxMenuHeight={256}
            className="w-full min-w-[130px]"
            styles={selectStyles}
            menuShouldBlockScroll
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="bottom"
          />
        </EditPermission>
      )}
    />
  );
});

const ActionsPopover = React.memo<{ index: number }>(({ index }) => {
  const [show, toggle] = useToggle();
  const fieldset = useFieldsetState();
  const dispatch = useFieldsetDispatch();
  const methods = useFormContext<FieldsetFormValues>();

  function handleDelete() {
    toggle();
    dispatch({ type: 'delete-user-field', index });
    const oldFields = fieldset.fields.map((f, idx) => ({
      ...f,
      published: methods.getValues(`fields.${idx}.published`) || f.published,
      label: methods.getValues(`fields.${idx}.label`) || f.label
    }));
    updateFieldsetForm(methods.reset, methods.getValues, {
      ...fieldset,
      ...methods.getValues(),
      fields: oldFields.filter((_, idx) => idx !== index)
    });
  }

  return (
    <div className="pt-1.5">
      <MyPopover
        visible={show}
        onClickOutside={toggle}
        placement="top-end"
        offset={[0, 6]}
        className="z-10 w-52"
        content={
          <EditPermission>
            <PopoverAction onClick={handleDelete}>Delete field</PopoverAction>
          </EditPermission>
        }
      >
        <PopoverButtonMinimal onClick={toggle} isShowingPopover={show} />
      </MyPopover>
    </div>
  );
});

const LabelInput = React.memo<{ index: number }>(({ index }) => {
  const { register } = useFormContext<FieldsetFormValues>();

  return (
    <EditPermission>
      <MyInput theme="table" className="w-64" {...register(`fields.${index}.label`)} />
    </EditPermission>
  );
});

const PublishedCheckbox = React.memo<{ index: number }>(({ index }) => {
  const { register } = useFormContext<FieldsetFormValues>();

  return (
    <div className="py-1.25">
      <EditPermission>
        <Checkbox {...register(`fields.${index}.published`)} />
      </EditPermission>
    </div>
  );
});

export const FieldsTable = React.memo(
  ({
    fields = [],
    hasWriteinFields,
    userTypeSelection
  }: {
    fields?: ModelFieldFragment[];
    hasWriteinFields?: boolean;
    userTypeSelection?: boolean;
  }) => {
    const columns = React.useMemo<ColumnDef<ModelFieldFragment>[]>(() => {
      const cols: ColumnDef<ModelFieldFragment>[] = [
        {
          header: () => <SelectAllCheckbox />,
          accessorKey: 'published',
          cell: ({ row }) => <PublishedCheckbox index={row.index} />,
          size: 50
        },
        {
          header: 'Label',
          accessorKey: 'label',
          cell: ({ row }) => <LabelInput index={row.index} />,
          size: 275
        },

        {
          header: 'Example',
          accessorKey: 'example',
          cell: ({ row }) => <TableTruncatedCell value={row.original.example} />,
          size: 200
        },
        {
          header: 'Type',
          accessorKey: 'type',
          cell: ({ row: { index, original } }) =>
            userTypeSelection ? (
              <FieldTypeSelect index={index} />
            ) : (
              <TableDataType value={original.type} sourceType={original.sourceType} />
            ),
          size: 150
        },
        {
          header: 'Source',
          accessorKey: 'sourceName',
          cell: ({ row }) => <TableTruncatedCell value={row.original.sourceName} />,
          size: 150
        }
      ];
      if (hasWriteinFields) {
        cols.push({
          header: '',
          accessorKey: 'actions',
          cell: ({ row: { index, original } }) =>
            original.userAdded ? <ActionsPopover index={index} /> : null,
          size: 50
        });
      }
      return cols;
    }, [hasWriteinFields]);

    return (
      <DataTable
        columns={columns}
        data={fields}
        slots={{ wrapper: 'max-h-[37.5rem] border-none' }}
        disableVirtualization={true}
      />
    );
  }
);
