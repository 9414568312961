import * as React from 'react';
import { SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { Button, EditPermission, FieldTypeSelect, Label, MyInput } from '../../../components';
import { FieldType, ModelFieldFragment } from '../../../generated/graphql';
import { useFieldsetDispatch, useFieldsetState, useToggle } from '../../../hooks';
import { FieldsetFormValues, humanizeText, updateFieldsetForm } from '../../../utils';
import { Dialog } from '~/components/v3';

interface AddFieldFormValues {
  label: string;
  sourceName: string;
  type: FieldType | null;
  example: string;
}

export const AddField = React.memo(() => {
  const [show, toggle] = useToggle();
  const fieldset = useFieldsetState();
  const dispatch = useFieldsetDispatch();
  const methods = useFormContext<FieldsetFormValues>();
  const { handleSubmit, register, reset, formState, control, setValue } =
    useForm<AddFieldFormValues>({
      criteriaMode: 'all',
      defaultValues: {
        label: '',
        sourceName: '',
        type: null,
        example: ''
      }
    });
  const { errors } = formState;

  function dismiss() {
    reset();
    toggle();
  }

  function handleSourceName(e: React.FocusEvent<HTMLInputElement>) {
    setValue('label', humanizeText(e.target.value));
  }

  const save: SubmitHandler<AddFieldFormValues> = form => {
    dispatch({
      type: 'add-user-field',
      field: {
        ...form,
        id: uuid(),
        sourceType: '',
        description: '',
        published: true,
        userAdded: true
      } as ModelFieldFragment
    });
    const oldFields = fieldset.fields.map((f, idx) => ({
      ...f,
      published: methods.getValues(`fields.${idx}.published`) || f.published,
      label: methods.getValues(`fields.${idx}.label`) || f.label
    }));
    updateFieldsetForm(methods.reset, methods.getValues, {
      ...fieldset,
      ...methods.getValues(),
      fields: [
        ...oldFields,
        {
          ...form,
          id: uuid(),
          sourceType: '',
          description: '',
          published: true,
          userAdded: true
        } as ModelFieldFragment
      ]
    });
    dismiss();
  };

  return (
    <>
      <EditPermission>
        <Button onClick={toggle} iconEnd="PlusCircle">
          Add field
        </Button>
      </EditPermission>
      <Dialog
        heading="Add field"
        show={show}
        actions={
          <>
            <Button onClick={dismiss}>Cancel</Button>
            <Button theme="primary" onClick={handleSubmit(save)}>
              Add field
            </Button>
          </>
        }
        slots={{ body: 'p-6 max-w-xs space-y-3' }}
      >
        <div>
          <Label htmlFor="sourceName">Source name</Label>
          <MyInput
            {...register('sourceName', { required: 'Source name is required' })}
            onBlur={handleSourceName}
            errors={errors}
          />
        </div>
        <div>
          <Label htmlFor="label">Label</Label>
          <MyInput
            {...register('label', {
              required: 'Label is required',
              maxLength: { value: 50, message: 'Too many characters' }
            })}
            errors={errors}
          />
        </div>
        <div>
          <Label>Type</Label>
          <FieldTypeSelect<AddFieldFormValues> name="type" control={control} />
        </div>
        <div>
          <Label htmlFor="example">
            Example value <span className="text-gray-500">(optional)</span>
          </Label>
          <MyInput
            {...register('example', {
              maxLength: { value: 200, message: 'Too many characters' }
            })}
            errors={errors}
          />
        </div>
      </Dialog>
    </>
  );
});
