import amplitude from 'amplitude-js';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Amplitude, AmplitudeProvider } from '~/lib/Amplitude';
import BulkSync from '~/pages/bulk-syncs/bulk-sync';
import BulkSyncConfig from '~/pages/bulk-syncs/bulk-sync-config';
import BulkSyncs from '~/pages/bulk-syncs/bulk-syncs';
import Connections from '~/pages/connections/connections';
import Explore from '~/pages/explore/Explore';
import Models from '~/pages/models/models';
import QueryRunner from '~/pages/query-runner/query-runner';
import SyncConfig from '~/pages/syncs/sync-config/sync-config';
import Sync from '~/pages/syncs/sync/sync';
import Syncs from '~/pages/syncs/syncs';
import Team from '~/pages/team/team';
import User from '~/pages/user/user';
import { ErrBoundary, LoadConfig, Permission } from './components';
import Wrapper from './components/v2/layout/Wrapper';
import XRoute from './components/v2/router/XRoute';
import EmptyPage from './components/v2/templates/EmptyPage';
import Route404 from './components/v2/templates/Route404';
import { ResourceType } from './generated/graphql';
import { BannerProvider, useAuth } from './hooks';
import ConnectPage from './pages/connect';
import { IdentityPicker, Login, SSOError } from './pages/login';
import SSRedirectLink from './pages/utility/SSRedirectLink';
import { routes } from './utils';
import PageLoader from './components/v2/layout/PageLoader';
import { isAdmin } from './utils';

function AmplitudeWrapper(props: { children: ReactNode }) {
  const { user } = useAuth();

  if (import.meta.env.MODE === 'development') {
    return <>{props.children}</>;
  }
  // use legacy roles. admin if admin role, user otherwise
  const role = isAdmin(user) ? 'admin' : 'user';
  return (
    <AmplitudeProvider
      amplitudeInstance={amplitude.getInstance()}
      apiKey={import.meta.env.VITE_AMPLITUDE_KEY || ''}
      userId={user.id}
    >
      <Amplitude
        userProperties={{
          email: user.email,
          status: user.status,
          role: role
        }}
      >
        {props.children}
      </Amplitude>
    </AmplitudeProvider>
  );
}

function Routes() {
  const { user, called, loading } = useAuth();

  if (!called || loading) {
    return <PageLoader />;
  }

  if (user === undefined) {
    return (
      <Switch>
        <Route exact path={routes.login}>
          <Login />
        </Route>
        <Route exact path={routes.identities}>
          <IdentityPicker />
        </Route>
        <Route exact path={routes.loginError}>
          <SSOError />
        </Route>
        <Route
          path={routes.root}
          render={({ location }) => {
            if (
              location.pathname !== routes.identities &&
              location.pathname !== routes.login &&
              location.pathname !== routes.root
            ) {
              window.localStorage.setItem(
                'continueUrl',
                JSON.stringify({
                  search: location.search,
                  referrer: location.pathname
                })
              );
            }
            return <Redirect to={routes.login} />;
          }}
        />
      </Switch>
    );
  }

  return (
    <AmplitudeWrapper>
      <Route path={routes.root}>
        <BannerProvider>
          <Wrapper>
            <ErrBoundary>
              <LoadConfig user={user} />
            </ErrBoundary>

            <Switch>
              {/* MODEL */}
              <XRoute path={routes.models}>
                <Helmet title="Models | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Data models" />}>
                  <Models />
                </ErrBoundary>
              </XRoute>

              {/* Explore */}
              <XRoute featureFlag="explore" path={routes.explore}>
                <Helmet title="Explore | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Explore" />}>
                  <Explore />
                </ErrBoundary>
              </XRoute>

              {/* SYNCS */}
              <XRoute exact={true} path={routes.syncs}>
                <Helmet title="Syncs | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Syncs" />}>
                  <Syncs />
                </ErrBoundary>
              </XRoute>
              <XRoute path={routes.createSync}>
                <Helmet title="Create sync | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Create sync" />}>
                  <SyncConfig />
                </ErrBoundary>
              </XRoute>
              <XRoute path={routes.sync}>
                <Helmet title="Syncs | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Syncs" />}>
                  <Sync />
                </ErrBoundary>
              </XRoute>

              {/* BULK SYNCS */}
              <XRoute exact={true} path={routes.bulkSyncsRoot}>
                <Helmet title="Bulk syncs | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Bulk syncs" />}>
                  <BulkSyncs />
                </ErrBoundary>
              </XRoute>
              <XRoute path={routes.createBulkSync}>
                <Helmet title="Create bulk sync | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Create bulk sync" />}>
                  <BulkSyncConfig />
                </ErrBoundary>
              </XRoute>
              <XRoute path={routes.bulkSync}>
                <Helmet title="Bulk sync | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Bulk sync" />}>
                  <BulkSync />
                </ErrBoundary>
              </XRoute>

              {/* CONNECTIONS */}
              <XRoute exact={true} path={routes.connections}>
                <Helmet title="Connections | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Connections" />}>
                  <Connections />
                </ErrBoundary>
              </XRoute>
              <XRoute exact={true} path={routes.addConnection}>
                <Helmet title="Connections | Polytomic" />
                <Permission
                  hideTooltip={true}
                  type={ResourceType.Connection}
                  fallback={<Redirect to={routes.connections} />}
                >
                  <ErrBoundary fallback={<PageErrFallback heading="Connections" />}>
                    <Connections />
                  </ErrBoundary>
                </Permission>
              </XRoute>
              <XRoute exact={true} path={routes.editConnection}>
                <Helmet title="Connections | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Connections" />}>
                  <Connections />
                </ErrBoundary>
              </XRoute>

              {/* QUERY RUNNER */}
              <XRoute exact={true} path={routes.queryRunner}>
                <Helmet title="Query runner | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Connections" />}>
                  <QueryRunner />
                </ErrBoundary>
              </XRoute>

              {/* TEAM */}
              <XRoute path={routes.team}>
                <Helmet title="Team | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Team" />}>
                  <Team />
                </ErrBoundary>
              </XRoute>

              {/* SETTINGS */}
              <XRoute exact={true} path={routes.user}>
                <Helmet title="Settings | Polytomic" />
                <ErrBoundary fallback={<PageErrFallback heading="Settings" />}>
                  <User />
                </ErrBoundary>
              </XRoute>

              {/* REDIRECTS*/}
              <Redirect exact={true} from={routes.root} to={routes.models} />
              <Redirect exact={true} from={routes.login} to={routes.models} />
              <Redirect exact={true} from={routes.identities} to={routes.models} />
              <Redirect exact={true} from="/sql-runner" to={routes.queryRunner} />
              <Redirect
                exact={true}
                from={`${routes.models}/fieldset/:fieldsetId`}
                to={routes.editModel}
              />
              <Redirect
                exact={true}
                from={`${routes.models}/fieldset/:fieldsetId/field/:fieldId`}
                to={routes.editField}
              />
              {/* UTILITY */}
              <XRoute exact path={routes.serverRedirect}>
                <SSRedirectLink />
              </XRoute>
              <XRoute exact path={routes.legacyServerRedirect}>
                <SSRedirectLink />
              </XRoute>
              <XRoute path="*">
                <Route404 />
              </XRoute>
            </Switch>
          </Wrapper>
        </BannerProvider>
      </Route>
    </AmplitudeWrapper>
  );
}

export function App() {
  // NOTE @kyle: We should rewrite how the routing is working and at the same time upgrade to React Router 6+,
  // improvements have been made to stucturing of routes as well as handling Auth in the latest version.

  // Connect page needs to be outside regular routing to skip any auth steps and to prevent 404.
  const isConnect = useRouteMatch({ exact: true, path: routes.connect });
  if (isConnect) {
    return (
      <Route exact path={routes.connect}>
        <ConnectPage />
      </Route>
    );
  }

  return <Routes />;
}

function PageErrFallback({ heading }: { heading: string }) {
  return (
    <EmptyPage
      error
      topNavHeading={heading}
      message={
        <span className="text-xs font-medium uppercase tracking-wider">Application error</span>
      }
    >
      <p className="text-sm">
        Try refreshing the page, or if the error persists, contact Polytomic.
      </p>
    </EmptyPage>
  );
}
