import { LinkButton } from '../../../components';
import { ExecutionStatus, SyncStatusFragment } from '../../../generated/graphql';
import { useSyncState, useToggle } from '../../../hooks';
import { emptyCell, recordDialogHeading } from '../../../utils';
import { SyncExecutionRecordsPreview } from '../sync-execution-records-preview';
import { WebhookSyncRequests } from '../sync-history/webhook-sync-requests';

export function SyncRecordCount(props: { syncStatus: SyncStatusFragment }) {
  const [showDialog, toggleDialog] = useToggle();
  const sync = useSyncState();
  if (
    !props.syncStatus?.execution ||
    props.syncStatus.execution.recordCount == null ||
    props.syncStatus.execution.status === ExecutionStatus.Created ||
    props.syncStatus.execution.status === ExecutionStatus.Queued ||
    props.syncStatus.execution.status === ExecutionStatus.Scheduled ||
    (props.syncStatus.execution.status === ExecutionStatus.Running &&
      props.syncStatus.execution?.recordCount === 0)
  ) {
    return <p>{emptyCell}</p>;
  }

  if (
    sync.targetConnection?.type.id !== 'webhook' &&
    props.syncStatus.execution?.records?.hasData &&
    props.syncStatus.execution?.recordCount > 0
  ) {
    return (
      <>
        <p>
          <LinkButton onClick={toggleDialog}>
            {props.syncStatus.execution.recordCount.toLocaleString()}
          </LinkButton>
        </p>
        {showDialog && (
          <SyncExecutionRecordsPreview
            heading={recordDialogHeading('records')}
            executionId={props.syncStatus.execution?.id}
            recordType="records"
            targetConnectionTypeId={sync.targetConnection?.type.id}
            targetConnectionName={sync.targetConnection?.name}
            targetObjectName={sync.targetObject?.name}
            handleDismiss={toggleDialog}
          />
        )}
      </>
    );
  }
  if (
    sync.targetConnection?.type.id === 'webhook' &&
    props.syncStatus.execution?.completedAt &&
    props.syncStatus.execution?.recordCount > 0
  ) {
    return (
      <>
        <p>
          <LinkButton onClick={toggleDialog}>
            {props.syncStatus.execution.recordCount.toLocaleString()}
          </LinkButton>
        </p>
        {showDialog && (
          <WebhookSyncRequests
            referenceId={props.syncStatus.execution?.id}
            handleClose={toggleDialog}
          />
        )}
      </>
    );
  }
  return <span>{props.syncStatus.execution?.recordCount.toLocaleString()}</span>;
}
