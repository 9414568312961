import { JSONSchema4 } from 'json-schema';
import * as React from 'react';
import { useController } from 'react-hook-form';

import { Selectable } from '../../utils';
import { EditPermission } from '../edit-permission';
import { MyCombobox } from './combobox';
import { DisabledSelect } from './disabled-select';
import { Label } from './label';

interface Props {
  item: JSONSchema4;
  className?: string;
}

export function FormEnumSelect({ item, className }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { field } = useController({ name: item.name });

  function handleChange(option: Selectable | null) {
    if (option == null) {
      return;
    }
    field.onChange(option.value);
  }

  React.useEffect(() => {
    if (field.value) {
      return;
    }
    if (item.default) {
      field.onChange(item.default);
    } else if (item.enum?.length === 1) {
      field.onChange(item.enum[0]);
    }
  }, [field, item.default, item.enum]);

  if (!item.enum || item.enum.length === 0) {
    return null;
  }

  const options =
    (item.enum as (string | Selectable)[])?.map(option => {
      if (typeof option === 'object') {
        return {
          label: option.label || option.value,
          value: option.value
        };
      }
      return { label: option, value: option };
    }) || [];

  const value = options.find(option => option.value === field.value) || null;

  return (
    <div className={className}>
      <Label>{item.title}</Label>
      <EditPermission fallback={<DisabledSelect valueLabel={value?.label} />}>
        <MyCombobox value={value} options={options} onChange={handleChange} />
      </EditPermission>
    </div>
  );
}
