import { useLazyQuery, useQuery } from '@apollo/client';
import * as FullStory from '@fullstory/browser';
import * as React from 'react';

import {
  AllRolesDocument,
  AllTagsDocument,
  BulkSyncsDocument,
  ConnectionsDocument,
  GetConfigurationDocument,
  ModelDocument,
  OrgStatsDocument,
  PeekSyncsDocument,
  SyncsDocument,
  UserFragment
} from '../generated/graphql';
import { useResetStore, useScript } from '../hooks';
import { BackendConfiguration, useConfiguration } from '~/hooks/configuration';
import clsx from 'clsx';

export function LoadConfig({ user }: { user: UserFragment }) {
  /* HOOKS */
  useResetStore();
  const { update: setConfiguration } = useConfiguration();
  /* QUERIES */
  const [loadOrgStats] = useLazyQuery(OrgStatsDocument, { fetchPolicy: 'no-cache' });
  const { data } = useQuery(GetConfigurationDocument, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.getConfiguration?.values) {
        const conf = data.getConfiguration.values as BackendConfiguration;
        setConfiguration(conf);
        initializeFullStory(conf);
      }
    }
  });
  useQuery(ConnectionsDocument);
  useQuery(ModelDocument);
  useQuery(SyncsDocument);
  useQuery(PeekSyncsDocument);
  useQuery(BulkSyncsDocument);
  useQuery(AllRolesDocument);
  useQuery(AllTagsDocument);

  /* VARS */
  const googAnalyticsId = data?.getConfiguration?.values?.ga_measurement_id as string | undefined;
  /* FX */
  const initializeFullStory = (conf: BackendConfiguration) => {
    const isSessionCaptureEnabled =
      import.meta.env.MODE === 'production' && conf?.disable_session_capture === false;
    const devUrls = ['release.polytomic.net', 'polytomic-staging.com'];
    if (isSessionCaptureEnabled) {
      if (FullStory.isInitialized() === false) {
        FullStory.init(
          {
            devMode: devUrls.some(url => window.location.hostname.includes(url)),
            orgId: 'o-1E0BY1-na1'
          },
          postInitializeFullStory
        );
      }
    }
  };

  const postInitializeFullStory = async () => {
    if (FullStory.isInitialized() === true) {
      /* Identity User in FullStory */
      FullStory.identify(user.id, {
        displayName: clsx(user?.firstName, user?.lastName),
        email: user.email,
        org_id_str: user.organization.id,
        org_name_str: user.organization.name
      });
      /* Get OrgStats and send them as an Event */
      const orgStats = await loadOrgStats();
      if (orgStats.data?.orgStats) {
        const { __typename, ...stats } = orgStats.data.orgStats;
        FullStory.event('OrgStats', stats);
      }
    }
  };

  return (
    <React.Fragment>
      {googAnalyticsId && (
        <GoogleAnalytics
          id={googAnalyticsId}
          email={user.email}
          orgName={user.organization.name}
          orgId={user.organization.id}
        />
      )}
    </React.Fragment>
  );
}

function GoogleAnalytics({
  id,
  email,
  orgName,
  orgId
}: {
  id: string;
  email: string;
  orgName: string;
  orgId: string;
}) {
  useScript(`https://www.googletagmanager.com/gtag/js?id=${id}`);

  if (typeof window !== 'undefined') {
    // @ts-expect-error TS doesn't know about gtag
    if (typeof window.gtag === 'undefined') {
      // @ts-expect-error TS doesn't know about gtag
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      window.dataLayer = window.dataLayer || [];
      // @ts-expect-error TS doesn't know about gtag
      window.gtag = function gtag() {
        // @ts-expect-error TS doesn't know about gtag
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, prefer-rest-params, @typescript-eslint/no-unsafe-call
        window.dataLayer.push(arguments);
      };
      // @ts-expect-error TS doesn't know about gtag
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.gtag('js', new Date());

      // @ts-expect-error TS doesn't know about gtag
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.gtag('config', id, {
        user_id: email,
        org_id: orgId,
        org_name: orgName
      });
    }
  }

  return null;
}
