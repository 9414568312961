import cx from 'clsx';
import _ from 'lodash';
import * as React from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  ModelFieldSelect,
  ParamButton,
  SideBySide
} from '../../../components';
import TooltipIcon from '../../../components/tooltip-icon';
import { useFieldsetState } from '../../../hooks';
import {
  FieldsetFormValues,
  hasItems,
  modelFieldToSelectable,
  TooltipMessaging,
  WINDOW_THRESHOLD
} from '../../../utils';

const newCol = { id: undefined, name: undefined, label: undefined };

export function TrackingColumns() {
  const fieldset = useFieldsetState();
  const { control } = useFormContext<FieldsetFormValues>();

  const trackingCols = useWatch({ control, name: 'configuration.trackingColumns' });

  const {
    fields: columns,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'configuration.trackingColumns',
    keyName: 'colId'
  });

  if (!fieldset || fieldset.fields.length === 0) {
    return null;
  }

  return (
    <SideBySide
      hasSectionWrap
      heading={
        <div>
          <div className="flex items-center space-x-1">
            <h3 className="sbs-heading">
              Tracking field
              {cx(columns && columns.length > 1 && 's')}
            </h3>
            <TooltipIcon message={TooltipMessaging.TRACKING_FIELD} />
          </div>
          <p className="text-sm font-normal text-gray-500">(Optional)</p>
        </div>
      }
      styles="space-y-3"
    >
      {hasItems(columns) ? (
        columns.map((col, index) => {
          const { colId } = col;
          return (
            <div className="flex items-center" key={colId}>
              <Controller
                control={control}
                name={`configuration.trackingColumns.${index}` as const}
                render={({ field }) => {
                  // Don't include existing tracking columns
                  const options = _.differenceBy(fieldset?.trackableFields, trackingCols, 'id');
                  return (
                    <EditPermission
                      fallback={
                        <DisabledSelect
                          className="mr-3 w-full max-w-xs"
                          valueLabel={field.value.label}
                        />
                      }
                    >
                      <ModelFieldSelect
                        className="mr-3 w-full max-w-xs"
                        options={options}
                        placeholder="Select model tracking field..."
                        value={
                          field.value && !!field.value.id
                            ? modelFieldToSelectable(field.value)
                            : null
                        }
                        hideGroups={true}
                        hideConnections={true}
                        isClearable={false}
                        onChange={field.onChange}
                        isWindowed={options.length > WINDOW_THRESHOLD}
                      />
                    </EditPermission>
                  );
                }}
              />
              <EditPermission>
                <ParamButton
                  className="mt-px focus-visible:ring-offset-gray-100"
                  action="delete"
                  onClick={() => remove(index)}
                />
              </EditPermission>
              <EditPermission>
                <ParamButton
                  action="add"
                  className={cx(
                    'ml-2 focus-visible:ring-offset-gray-100',
                    index === columns?.length - 1 &&
                      fieldset &&
                      columns?.length < fieldset.trackableFields?.length
                      ? 'visible mt-px'
                      : 'invisible'
                  )}
                  onClick={() => append(newCol)}
                />
              </EditPermission>
            </div>
          );
        })
      ) : (
        <EditPermission>
          <ParamButton
            action="add"
            className="focus-visible:ring-offset-gray-100"
            onClick={() => append(newCol)}
          />
        </EditPermission>
      )}
    </SideBySide>
  );
}
