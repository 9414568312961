import { useState } from 'react';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';

import { LinkButton, TableWrap } from '~/components';
import { COLOR } from '~/components/v2/configs/SX';
import Chip from '~/components/v2/display/Chip';
import { Icon } from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import { AllRolesDocument, AllTagsDocument } from '~/generated/graphql';
import { AclProvider, useBannerDispatch } from '~/hooks';
import { AddTag } from '~/pages/team/add-tag';
import { hasItems } from '~/utils';
import { TagActions } from './tag-actions';

const TeamTag = () => {
  // hooks
  const [expanded, setExpanded] = useState([]);
  const dispatchBanner = useBannerDispatch();

  // queries
  /* GetTags (access control) */
  const { data: tagsData, loading } = useQuery(AllTagsDocument, {
    onError: error => dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'mb-3' } })
  });
  /* GetRoles */
  const { data: rolesData } = useQuery(AllRolesDocument);

  // f(x)
  /**
   * Check if a tag is expanded
   */
  const isItemExpanded = (tagId: string) => expanded?.includes(tagId);

  /**
   * Check if all tags are expanded
   */
  const isAllExpanded = expanded?.length === tagsData?.allTags?.length;

  /**
   * Expand or collapse a tag
   */
  const handleExpand = (tagId: string) => {
    if (expanded?.includes(tagId)) {
      setExpanded(expanded.filter(id => id !== tagId));
    } else {
      setExpanded([...expanded, tagId]);
    }
  };
  /**
   * Expand or collapse all tags
   */
  const handleExpandAll = () => {
    if (!isAllExpanded) {
      setExpanded(tagsData?.allTags?.map(t => t.id));
    } else {
      setExpanded([]);
    }
  };

  // TODO -> remove
  const roles = rolesData?.allRoles?.map(r => ({ ...r, label: r.name, value: r.id })) || [];

  /**
   * Get the populated policy actions for a tag
   */
  const getPopulatedPolicyActions = (tagId: string) =>
    tagsData?.allTags
      // Populate the roleIDs with the actual role objects
      .find(t => t.id === tagId)
      .policy.policyActions.map(pa => ({
        ...pa,
        roleIDs: pa.roleIDs.map(roleId => roles.find(r => r.id === roleId))
      }))
      // Filter out the actions that don't have any roles
      .filter(action => action.roleIDs.length > 0)
      // Sort the actions alphabetically
      .sort((a, b) => a.action.localeCompare(b.action)) || [];

  return (
    <PageLayout
      loading={loading}
      topNavHeading="Access control"
      topNavActions={<AddTag roles={roles} setToggle={handleExpand} />}
    >
      <div className="mx-auto w-full max-w-4xl justify-items-end pl-1">
        <LinkButton onClick={handleExpandAll}>
          {isAllExpanded ? 'Collapse all' : 'Expand all'}
        </LinkButton>
      </div>
      {!loading &&
        tagsData &&
        tagsData?.allTags &&
        [...tagsData.allTags]
          // Sort the tags first by whether they are system tags, then alphabetically
          .sort((a, b) => +b?.system - +a?.system || a?.name?.localeCompare(b?.name))
          .map(tag => (
            <AclProvider key={tag.id} value={tag.acl}>
              <TableWrap className="mx-auto w-full max-w-4xl overflow-x-auto">
                <table className="min-w-full text-left">
                  <thead>
                    <tr>
                      <th colSpan={2} className="py-2.75 pl-2 pr-4">
                        <span className="flex items-center">
                          <button
                            className="inline-flex items-center space-x-1 rounded px-2 py-1 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-300 active:bg-gray-200"
                            onClick={() => handleExpand(tag.id)}
                          >
                            <span className="text-base font-medium text-gray-800">{tag.name}</span>
                            <Icon
                              name="SelectSingle"
                              className={clsx(
                                'h-5 w-5 text-gray-500',
                                isItemExpanded(tag.id) && 'rotate-180'
                              )}
                            />
                          </button>
                          <span className="ml-auto inline-flex items-center pt-0.5">
                            {tag.system && (
                              <span className="text-right text-xs font-normal text-amber-600">
                                Built-in policy
                              </span>
                            )}
                            <TagActions roles={roles} tag={tag} />
                          </span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  {isItemExpanded(tag?.id) && (
                    <tbody className="divide-y divide-gray-200 border-t border-gray-200 bg-gray-50">
                      {hasItems(getPopulatedPolicyActions(tag.id)) ? (
                        getPopulatedPolicyActions(tag.id).map(action => (
                          <tr key={action.action}>
                            <td className="flex min-h-[2.25rem] items-start px-4 py-2">
                              <span className="flex h-5 w-40 items-center pt-px text-xs font-medium text-gray-600">
                                {action.action.toLocaleUpperCase()}
                              </span>
                              <span className="flex flex-wrap gap-2">
                                {[...action.roleIDs]
                                  // Filter out any roles that don't exist
                                  .filter(roleID => !!roleID && !!roleID?.name)
                                  // Sort roles by name (A-Z )
                                  .sort((a, b) => a?.name?.localeCompare(b?.name))
                                  .map((roleID, idx) => (
                                    <Chip key={idx} color={COLOR.DEFAULT}>
                                      {roleID?.name}
                                    </Chip>
                                  ))}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="align-top">
                          <td className="p-4 text-center text-gray-400">No actions assigned.</td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </TableWrap>
            </AclProvider>
          ))}
    </PageLayout>
  );
};

export default TeamTag;
