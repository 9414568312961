import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { StageCard } from '.';
import { FrequencyOptions, Label, RunAfterSync, SchedulePicker } from '../../../components';
import { Frequency, Schedule } from '../../../generated/graphql';
import { SyncConfigFormValues } from '../../../utils';

export function StageSchedule({
  syncId,
  setIsDirty
}: {
  syncId: string;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { control, setValue } = useFormContext<SyncConfigFormValues>();

  const schedule = useWatch({ control, name: 'schedule' });
  const runAfterSyncs = useWatch({ control, name: 'runAfterSyncs' }) || [];

  const handleScheduleChange = (schedule: Schedule) => {
    setValue('schedule', schedule);
    setIsDirty(true);
  };

  const handleRunAfterSyncsChange = (syncs: RunAfterSync[]) => {
    setValue('runAfterSyncs', syncs);
    setIsDirty(true);
  };

  const options = [
    FrequencyOptions.manual,
    FrequencyOptions.continuous,
    FrequencyOptions.hourly,
    FrequencyOptions.daily,
    FrequencyOptions.weekly,
    FrequencyOptions.builder,
    FrequencyOptions.custom,
    FrequencyOptions.runafter
  ];

  return (
    <StageCard
      hasStickyHeader={
        schedule?.frequency !== Frequency.Manual && schedule?.frequency !== Frequency.Continuous
      }
      step={4}
      header="Set sync schedule"
      className="px-6"
    >
      <Label>Frequency</Label>
      <SchedulePicker
        options={options}
        syncId={syncId}
        schedule={schedule}
        onScheduleChange={handleScheduleChange}
        runAfterSyncs={runAfterSyncs}
        onRunAfterSyncsChange={handleRunAfterSyncsChange}
      />
    </StageCard>
  );
}
