import { fieldTypeIconName } from '../utils';
import { Tooltip } from '.';
import { Icon } from '~/components';

export function TableDataType({ value, sourceType }: { value: string; sourceType?: string }) {
  return (
    <Tooltip offset={[0, 2]} disabled={!sourceType} content={sourceType}>
      <div className="flex items-center space-x-1.5 py-1.5">
        <Icon name={fieldTypeIconName(value)} className="h-5 w-5 text-gray-500" />
        <span className="text-gray-800">{value || 'unknown'}</span>
      </div>
    </Tooltip>
  );
}
