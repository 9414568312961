import { useLazyQuery, useQuery } from '@apollo/client';
import { Float } from '@headlessui-float/react';
import { Popover } from '@headlessui/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { isEmpty } from 'lodash';
import QueryString from 'qs';
import { Resizable } from 're-resizable';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Button, Icon, Truncator } from '~/components';
import PageInfoBox from '~/components/v2/display/PageInfoBox';
import FieldMappingBottomNav from '~/components/v2/experimental/FieldMappingBottomNav';
import { FIELD_MAPPING_TYPE } from '~/components/v2/experimental/StageMappings';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import PageLayout from '~/components/v2/layout/PageLayout';
import { DevToolsStateAtom } from '~/components/v2/util/DevTools';
import {
  ExploreCountDocument,
  ExploreModelDocument,
  ExplorePagedDocument,
  ModelFieldFragment,
  OrderByDirection,
  QueryOrderBy,
  QueryResultConnectionFragment,
  ReachableFieldsDocument
} from '~/generated/graphql';
import { plural } from '~/utils';
import { useBannerDispatch, useLazyContinuationQuery } from '~/hooks';
import { ExploreFilters, type ExploreFilter } from './ExploreFilters';
import { ExploreModelList } from './ExploreModelList';
import { ExportToSyncDialog } from './ExploreToSyncDialog';

const Explore = () => {
  const search = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });

  const defaultFields = search?.fields as string[];
  const defaultFilters = search?.filters as ExploreFilter[];
  const defaultLogic = search?.logic as string;

  const history = useHistory();

  const [devToolsState] = useAtom(DevToolsStateAtom);
  const noSelectionMsg = 'No fields added yet. Click fields on the left to add them.';

  const [selected, setSelected] = useState<string[]>(defaultFields ?? []);
  const [selectedSort, setSelectedSort] = useState(defaultFields ?? []);
  const [selectedWidth, setSelectedWidth] = useState(
    defaultFields?.map?.(id => ({ id, width: 150 })) ?? []
  );
  const [sorts, setSorts] = useState<Array<QueryOrderBy>>([]);
  const [cursor, setCursor] = useState<string>(null);
  const [exploreData, setExploreData] = useState([]);
  const [filters, setFilters] = useState<ExploreFilter[]>(defaultFilters ?? []);
  const [showBottomNav, setShowBottomNav] = useState(false);
  const [bottomNavConfig, setBottomNavConfig] = useState({
    config: FIELD_MAPPING_TYPE.NONE,
    mappingIndex: NaN
  });
  const [autoSelectedModelId, setAutoSelectedModelId] = useState<string>('');
  const [selectedFieldIds, setSelectedFieldIds] = useState<string[]>([]);
  const [reachableFieldsets, setReachableFieldsets] = useState<ModelFieldFragment[]>([]);
  const [shouldOverwrite, setShouldOverwrite] = useState<boolean>(false);
  const [hoveredFieldCol, setHoveredFieldCol] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [conditionLogic, setConditionLogic] = useState<string>(defaultLogic ?? '');
  const [count, setCount] = useState<number | undefined>();
  const [showFilters, setShowFilters] = useState(!isEmpty(defaultFilters));
  const [letterIdx, setLetterIdx] = useState<number>(0);
  const [showLogic, setShowLogic] = useState<boolean>(!isEmpty(defaultLogic));
  const [showExportDialog, setShowExportDialog] = useState<boolean>(false);

  const dispatchBanner = useBannerDispatch();

  const { data, loading, error, refetch } = useQuery(ExploreModelDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only'
  });

  const allModels = useMemo(() => {
    return data?.model?.fieldsets ?? [];
  }, [data]);

  const [executeCountQuery] = useLazyContinuationQuery<
    { exploreCount: { count: number } },
    unknown
  >(ExploreCountDocument, {
    fetchPolicy: devToolsState.explore.apolloCaching ? 'cache-first' : 'network-only',
    onCompleted: data => {
      if (data.exploreCount?.count) {
        setCount(data.exploreCount.count);
      } else {
        setCount(undefined);
      }
    }
  });

  const [executeExploreQuery, { loading: exploreLoading, error: exploreError }] =
    useLazyContinuationQuery<{ explorePaged: QueryResultConnectionFragment }, unknown>(
      ExplorePagedDocument,
      {
        fetchPolicy: devToolsState.explore.apolloCaching ? 'cache-first' : 'network-only',
        onCompleted: data => {
          if (data.explorePaged) {
            const currentLength = shouldOverwrite ? 1 : exploreData?.length;
            if (cursor === null || shouldOverwrite === true) {
              setExploreData(data?.explorePaged?.edges?.map(e => e.node));
              // Set state in search
              history.push({
                search: QueryString.stringify({
                  filters,
                  fields: selected,
                  models: [...new Set(selectedFields.map(field => field.fieldset?.id)).values()]
                })
              });
            } else {
              setExploreData(e => [...e, ...data.explorePaged.edges.map(e => e.node)]);
            }
            if (data.explorePaged.edges) {
              setCursor(data.explorePaged.edges.at(data.explorePaged.edges.length - 1).cursor);
            }

            setShouldOverwrite(false);
            setHasNextPage(data.explorePaged.pageInfo.hasNextPage);
            setTimeout(() => {
              scrollToIndex(currentLength - 1, {
                align: 'start'
              });
            });
          }
        },
        onError: error => {
          dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
        }
      }
    );

  const [getReachableFields] = useLazyQuery(ReachableFieldsDocument, {
    fetchPolicy: devToolsState.explore.apolloCaching ? 'cache-first' : 'network-only',
    onCompleted(data) {
      setReachableFieldsets(data.reachableFieldsets);
    },
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
    }
  });

  const reachableFields = useMemo(() => {
    return !data?.model?.fieldsets
      ? []
      : //@ts-expect-error its okay
        (allModels
          .map(fs =>
            fs.fields.map(f => ({
              ...f,
              filterFunctions: f.filterFunctions,
              fieldset: { id: fs.id, name: fs.name, connection: fs.connection }
            }))
          )
          .flat() as ModelFieldFragment[]);
  }, [data]);

  /**
   * HandleSelect
   *  - if selected:
   *      - remove from selected
   *      - remove cursor
   *  - if not selected, add to selected
   *
   */
  const handleSelect = (id: string) => {
    if (selected.includes(id)) {
      if (selected.length === 1) {
        setReachableFieldsets([]);
      }
      setSelected(e => e.filter(i => i !== id));
      setSorts(s => s.filter(s => s.field !== id));
      setSelectedSort(e => e.filter(i => i !== id));
      setSelectedWidth(e => e.filter(i => i.id !== id));
    } else {
      if (selected.length === 0) {
        getReachableFields({ variables: { field: id } });
      }
      setSelected(e => [...e, id]);
      setSelectedSort(e => [...e, id]);
      setSelectedWidth(e => [...e, { id: id, width: 150 }]);
    }
    setCursor(null);
  };

  const handleExecute = isRefresh => {
    if (exploreLoading) {
      return;
    }
    isRefresh === true && setShouldOverwrite(true);
    executeExploreQuery({
      variables: {
        continuation: uuid(),
        fields: selected,
        orderBy: sorts,
        after: isRefresh === true ? null : cursor,
        conditions: filters,
        conditionLogic: conditionLogic,
        first: devToolsState.explore.queryLimit
      }
    });
    executeCountQuery({
      variables: {
        continuation: uuid(),
        fields: selected,
        conditions: filters,
        conditionLogic: conditionLogic
      }
    });
  };

  const selectedFields = useMemo(() => {
    if (!data) return [];
    const selectedFields = [];
    data.model.fieldsets.forEach(fs => {
      fs.fields.forEach(f => {
        if (selected.includes(f.id)) selectedFields.push({ ...f, fieldset: fs });
      });
    });
    return selectedFields;
  }, [data, selected]);

  const handleSort = (field: string, direction: OrderByDirection) => {
    setSorts(s => {
      setCursor(null);
      const existingSort = s.find(s => s.field === field);
      if (existingSort) {
        if (existingSort.direction === direction) return s.filter(s => s.field !== field);
        else return s.map(s => (s.field === field ? { field, direction } : s));
      } else {
        return [...s, { field, direction }];
      }
    });
  };

  const handleExport = () => {
    setShowExportDialog(true);
  };

  const reset = () => {
    setFilters([]);
    setSelectedFieldIds([]);
    setConditionLogic('');
    setShowLogic(false);
    setLetterIdx(0);
    setSorts([]);
    setCursor(null);
    setExploreData(null);
    setCount(null);
    setSelected([]);
    setReachableFieldsets([]);
  };

  /**
   * This effect runs whenever the user changes input on the page
   * It will toggle the setShouldOverwrite flag to true if the following conditions are met:
   * - The user has ADDED fields to the selected list
   * - The user has ADDED filters to the filter list
   * - The user has UPDATED the filters
   * - The user has ADDED sorts to the sort list
   * - The user has CHANGED the query limit
   */
  useEffect(() => {
    setShouldOverwrite(true);
  }, [selected, filters, sorts]);

  // If there are values in the query string, execute on page load
  useEffect(() => {
    if (!isEmpty(defaultFields)) {
      handleExecute(true);
      getReachableFields({ variables: { field: defaultFields[0] } });
    }
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);

  const { getVirtualItems, getTotalSize, scrollToIndex, measureElement } = useVirtualizer({
    count: exploreData?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 30,
    overscan: 10
  });

  return (
    <>
      <PageLayout
        sideNavInnerHeading="Explore"
        topNavActions={[
          <Button
            key="reset"
            theme="outline"
            iconEnd="Sparkle"
            disabled={selected.length === 0}
            onClick={reset}
          >
            Start over
          </Button>,
          <Button
            key="refresh"
            theme="outline"
            iconEnd="Refresh"
            disabled={selected.length === 0}
            onClick={() => handleExecute(true)}
          >
            Refresh
          </Button>
        ]}
        bottomNavShow={showBottomNav}
        bottomNavContent={
          <FieldMappingBottomNav
            autoSelectedModelId={autoSelectedModelId}
            setAutoSelectedModelId={setAutoSelectedModelId}
            mappingIndex={bottomNavConfig.mappingIndex}
            targetConnection={null}
            setShowBottomNav={setShowBottomNav}
            fieldType={FIELD_MAPPING_TYPE.FILTER_TARGET}
            title={'Select field...'}
            fields={reachableFields}
            selectedFieldIds={selectedFieldIds}
            setSelectedFieldIds={setSelectedFieldIds}
            disabledFieldIds={[]}
            multiSelect={false}
            handleAddFields={(
              fieldType: FIELD_MAPPING_TYPE,
              mappingIndex: number,
              fieldIds: string[]
            ) => {
              setFilters(f => {
                const newFilters = [...f];
                newFilters[mappingIndex].fieldID = fieldIds[0];
                newFilters[mappingIndex].fieldType = 'Model';
                return newFilters;
              });
              setShowBottomNav(false);
            }}
            linkButton={undefined}
          />
        }
        sideNavInnerContent={
          <ExploreModelList
            //@ts-expect-error its okay
            allModels={allModels}
            error={error}
            loading={loading}
            filters={filters}
            selected={selected}
            handleSelect={handleSelect}
            refetch={refetch}
            reachableFieldsets={reachableFieldsets}
            setFilters={setFilters}
            setShowFilters={setShowFilters}
          />
        }
      >
        <div className="flex h-full flex-col">
          <ExploreFilters
            filters={filters}
            conditionLogic={conditionLogic}
            reachableFields={reachableFields}
            showFilters={showFilters}
            letterIdx={letterIdx}
            showLogic={showLogic}
            setShowLogic={setShowLogic}
            setLetterIdx={setLetterIdx}
            setShowFilters={setShowFilters}
            setFilters={setFilters}
            setConditionLogic={setConditionLogic}
            setShowBottomNav={setShowBottomNav}
            setBottomNavConfig={setBottomNavConfig}
          />
          {selected.length === 0 && (
            <div className="mt-12">
              <PageInfoBox title={noSelectionMsg} />{' '}
            </div>
          )}
          <div className="grid h-full w-full grid-rows-[auto,1fr] overflow-x-scroll ">
            {selected.length > 0 && (
              <div className="w-full overflow-x-clip">
                {count > 0 && (
                  <div className="flex w-full flex-row items-center justify-between border-b border-gray-300 py-1 px-2">
                    <span>
                      {count} {plural('result', count !== 1)}
                    </span>
                    <Button onClick={handleExport} iconEnd="Export">
                      Export
                    </Button>
                  </div>
                )}
                {/* HEADER */}
                <div className={clsx(`grid w-full auto-cols-max grid-flow-col`)}>
                  <div className="flex w-10 border border-t-0 border-gray-300 p-1" />
                  {selectedFields
                    .sort((a, b) => selectedSort.indexOf(a.id) - selectedSort.indexOf(b.id))
                    .map((field, fieldIndex) => (
                      <Popover
                        key={`head_col${fieldIndex}`}
                        onMouseEnter={() => setHoveredFieldCol(field.id)}
                        onMouseLeave={() => setHoveredFieldCol(null)}
                      >
                        <Float
                          placement="bottom-start"
                          flip={10}
                          arrow
                          portal
                          enter="transition duration-200 ease-out"
                          enterFrom="opacity-0 -translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition duration-150 ease-in"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 -translate-y-1"
                        >
                          <Popover.Button>
                            <Resizable
                              className="flex w-full flex-row justify-between border border-t-0 border-gray-300 p-1"
                              style={{
                                width:
                                  selectedWidth.find(f => f.id === field.id)?.width || field.width
                              }}
                              onResizeStop={(e, direction, ref, d) => {
                                setSelectedWidth(w => [
                                  ...w.filter(f => f.id !== field.id),
                                  {
                                    id: field.id,
                                    width: (w.find(f => f.id === field.id)?.width || 0) + d.width
                                  }
                                ]);
                              }}
                              size={{
                                height: 'auto',
                                width:
                                  selectedWidth.find(f => f.id === field.id)?.width || field.width
                              }}
                              handleClasses={{
                                top: 'pointer-events-none',
                                bottom: 'pointer-events-none',
                                left: 'pointer-events-none',
                                topRight: 'pointer-events-none',
                                bottomRight: 'pointer-events-none',
                                bottomLeft: 'pointer-events-none',
                                topLeft: 'pointer-events-none'
                              }}
                            >
                              <Truncator content={field.label}>
                                <div className="w-full overflow-clip text-ellipsis whitespace-nowrap text-left text-sm font-medium uppercase text-gray-800">
                                  {field.label}
                                </div>
                              </Truncator>
                              <Icon
                                name="DotsH"
                                size="sm"
                                className={clsx(
                                  'p-[0.5] text-gray-500 hover:text-gray-800 active:text-gray-900',
                                  hoveredFieldCol === field.id ||
                                    sorts.find(f => f.field === field.id)
                                    ? 'opacity-100'
                                    : 'opacity-0'
                                )}
                              />
                            </Resizable>
                          </Popover.Button>
                          <Popover.Panel className="mt-2 w-60">
                            <div className="flex flex-col rounded border border-gray-300 bg-white shadow-flyout">
                              <button
                                className="flex flex-row justify-between py-2  px-3.5 hover:bg-indigo-50 active:bg-indigo-100"
                                onClick={() => handleSort(field.id, OrderByDirection.Asc)}
                              >
                                <div>Sort ascending</div>

                                {sorts.find(f => f.field === field.id)?.direction ===
                                  OrderByDirection.Asc && (
                                  <div>
                                    <Icon name="Check" />
                                  </div>
                                )}
                              </button>
                              <button
                                className="flex flex-row justify-between py-2 px-3.5 hover:bg-indigo-50 active:bg-indigo-100"
                                onClick={() => handleSort(field.id, OrderByDirection.Desc)}
                              >
                                <div>Sort descending</div>
                                {sorts.find(f => f.field === field.id)?.direction ===
                                  OrderByDirection.Desc && (
                                  <div>
                                    <Icon name="Check" />
                                  </div>
                                )}
                              </button>
                              <button
                                className="py-2 px-3.5 text-left hover:bg-indigo-50 active:bg-indigo-100"
                                onClick={() => handleSelect(field.id)}
                              >
                                Remove
                              </button>
                            </div>
                          </Popover.Panel>
                        </Float>
                      </Popover>
                    ))}
                </div>
              </div>
            )}
            {/* ROWS */}
            {exploreLoading && (
              <div className="flex h-full items-center justify-center">
                <LoadingDots />
              </div>
            )}
            {!exploreLoading &&
              exploreData &&
              exploreData?.length === 0 &&
              selected?.length !== 0 &&
              cursor !== null && (
                <div className="p-10 text-left text-lg font-medium text-gray-700">
                  No data found
                </div>
              )}
            {!exploreLoading &&
              !exploreError &&
              exploreData &&
              exploreData?.length !== 0 &&
              selected?.length !== 0 && (
                <div
                  ref={parentRef}
                  className="w-full overflow-x-clip overflow-y-scroll"
                  style={{ contain: 'strict' }}
                >
                  <div className="relative" style={{ height: `${getTotalSize()}px` }}>
                    {getVirtualItems().map(item => (
                      <div
                        key={item.key}
                        ref={measureElement}
                        className="absolute left-0 top-0 w-full"
                        style={{ transform: `translateY(${item.start}px)` }}
                        data-index={item.index}
                      >
                        <div className="grid w-full auto-cols-max grid-flow-col">
                          <div className="flex w-10 justify-end border border-gray-300 p-1">
                            <p className="text-end text-gray-400">{item.index + 1}</p>
                          </div>
                          {selectedFields
                            .sort((a, b) => selectedSort.indexOf(a.id) - selectedSort.indexOf(b.id))
                            .map((field, fieldIndex) => (
                              <div
                                key={`cell_row${item.index}_col${fieldIndex}`}
                                className="h-full"
                                style={{
                                  width: selectedWidth.find(f => f.id === field.id)?.width || 150
                                }}
                              >
                                <div className="flex h-full w-full border border-gray-300 bg-white p-1">
                                  <Truncator content={exploreData[item.index][field.id]}>
                                    <p className="hide-native-tooltip cursor-default truncate text-left text-gray-800">
                                      {exploreData[item.index][field.id]}
                                    </p>
                                  </Truncator>
                                </div>
                              </div>
                            ))}
                        </div>
                        {item.index === exploreData?.length - 1 && (
                          <div className="flex p-2 ">
                            {hasNextPage ? (
                              <Button
                                onClick={() => handleExecute(false)}
                                size="mini"
                                disabled={!hasNextPage || exploreLoading}
                              >
                                Load more
                              </Button>
                            ) : (
                              <div className="p-2 text-base text-gray-500">No more results...</div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        </div>
        <ExportToSyncDialog
          show={showExportDialog}
          filters={filters}
          conditionLogic={conditionLogic}
          setShow={setShowExportDialog}
          selectedFields={selectedFields}
        />
      </PageLayout>
    </>
  );
};

export default Explore;
