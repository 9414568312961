import { useLazyQuery } from '@apollo/client';

import { Button, Permission } from '../../components';
import { GetChargebeePortalJsonDocument, Action } from '../../generated/graphql';
import { useBannerDispatch, useAuth, AclProvider } from '../../hooks';

interface Props {
  text: string;
}

export function ChargebeeBilling(props: Props) {
  const { user } = useAuth();
  const dispatchBanner = useBannerDispatch();
  const [getBilling, { loading }] = useLazyQuery(GetChargebeePortalJsonDocument, {
    onCompleted: data => {
      if (!data || !data.getChargebeePortalJson) {
        return;
      }
      window.location.href = data.getChargebeePortalJson.accessUrl;
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } })
  });

  function handleBillingRedirect() {
    void getBilling({ variables: { returnUri: window.location.href } });
  }

  return (
    <AclProvider value={user.organization.chargebee?.acl}>
      <Permission type={Action.Edit}>
        <Button loading={loading} iconEnd="Export" onClick={handleBillingRedirect}>
          {props.text}
        </Button>
      </Permission>
    </AclProvider>
  );
}
