import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { Icon } from '../../Icon';
import clsx from 'clsx';
import { Float } from '@headlessui-float/react';
import { Truncator } from '~/components/truncator';

export type SelectOption = {
  /* - Required - */
  label: string;
  value: string;
  /* - Optional - */
  id?: string | number;
  // icon?: ReactNode | string
  iconId?: string;
  disabled?: boolean;
  // mods
  isTopOption?: boolean | undefined | null;
};

export type SelectProps = {
  variant?: 'outlined' | 'filled';
  label?: string;
  options?: SelectOption[] | [] | null;
  value?: SelectOption | null;
  defaultValue?: SelectOption | null;
  disabled?: boolean;
  placeholder?: string;
  inputWidth?: string;
  optionsWidth?: string;
  portal?: boolean;
  onChange?: (option: SelectOption | null) => void;
};

const Select = ({
  variant = 'outlined',
  label,
  options,
  value,
  defaultValue,
  placeholder = 'Select...',
  disabled,
  inputWidth = 'w-24',
  optionsWidth = inputWidth,
  portal = true,
  onChange
}: SelectProps) => {
  const [selected, setSelected] = useState<SelectOption | null>(value || defaultValue || null);

  const handleChange = (option: SelectOption) => {
    setSelected(option);
    onChange && onChange(option);
  };

  const isFilled = variant === 'filled';

  const SELECT_ICON_WRAPPER_SX = clsx(
    'absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'
  );
  const SELECT_ICON_SX = clsx('h-5 w-5', isFilled ? 'text-gray-500' : 'text-gray-400');
  const SELECT_OPTIONS_SX = clsx(
    'mt-1 max-h-60 overflow-y-auto overflow-x-clip flex flex-col rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
    optionsWidth
  );
  const SELECT_FLOAT_SX = clsx('relative bg-white rounded-md', inputWidth);
  const SELECT_INPUT_LABEL_SX = clsx(
    isFilled ? 'bg-gray-200' : 'bg-white',
    selected?.iconId ? 'pl-8' : 'pl-2',
    'text-left py-1 pr-8 rounded-md',
    'w-full border-none text-sm leading-5 text-gray-800 focus:ring-0'
  );
  const SELECT_OPTION_SX = option =>
    clsx(
      'relative cursor-default flex select-none py-2 px-4 text-gray-700',
      option?.value === selected?.value ? 'bg-gray-100' : 'bg-white',
      optionsWidth,
      option.isTopOption && 'border-b border-gray-300',
      'hover:bg-indigo-100'
    );
  return (
    <Listbox<any, SelectOption> value={selected} onChange={handleChange} disabled={disabled}>
      <div className="relative">
        <Listbox.Label>{label}</Listbox.Label>
        <Float
          floatingAs={Fragment}
          className={SELECT_FLOAT_SX}
          placement="bottom-start"
          arrow
          portal={portal}
          offset={1}
        >
          <Listbox.Button className={SELECT_INPUT_LABEL_SX}>
            {selected?.iconId && (
              <Icon
                match={selected?.iconId}
                className="h-5 w-5 absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-400"
              />
            )}
            {selected ? (
              <Truncator content={selected?.label}>
                <p className={`truncate overflow-clip w-full`}>{selected?.label}</p>
              </Truncator>
            ) : (
              <p className={`truncate text-gray-500 overflow-clip w-full`}>{placeholder}</p>
            )}
            <span className={SELECT_ICON_WRAPPER_SX}>
              <Icon name="SelectSingle" className={SELECT_ICON_SX} />
            </span>
          </Listbox.Button>

          <Listbox.Options className={SELECT_OPTIONS_SX}>
            {options
              .sort(o => (o.isTopOption ? 1 : 0))
              .map(option => (
                <Listbox.Option
                  key={option?.id || option?.value}
                  className={SELECT_OPTION_SX(option)}
                  value={option}
                  disabled={option?.disabled}
                >
                  {option?.iconId && <Icon match={option?.iconId} className="mr-2" />}
                  <Truncator content={option?.label}>
                    <span className={`block truncate`}>{option?.label}</span>
                  </Truncator>
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </Float>
      </div>
    </Listbox>
  );
};

export default Select;
