import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Icon } from '~/components';
import { COLOR } from '~/components/v2/configs/SX';
import Chip from '~/components/v2/display/Chip';
import { ConnectionFragment, HealthStatus } from '~/generated/graphql';
import { capsFirst, isNum } from '~/utils';

interface Props {
  connection: ConnectionFragment;
  isEditing?: boolean;
  pathname: string; // Set URL path to this in edit mode
}

export function ConnectionRow({ connection, pathname, isEditing = false }: Props) {
  const dailyCalls = connection.configuration.daily_api_calls;
  const enforceLimits = connection.configuration.enforce_api_limits;
  const callCount = connection.stats.callsLast24h;

  const sharedStyles = clsx(
    'flex justify-between px-4 py-1.5 rounded hover:bg-indigo-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-400',
    isEditing ? 'items-center bg-indigo-100' : 'items-start'
  );

  return (
    <Link to={{ pathname }} className={sharedStyles}>
      <div>
        <h3 className="mb-0.5 text-base font-medium leading-5">{connection.name}</h3>

        {connection.health.status === HealthStatus.Error ? (
          <Chip
            color={COLOR.ERROR}
            children={capsFirst(connection.health.status)}
            enablePointer
            bold
          />
        ) : (
          <h4 className="text-sm text-green-500">{capsFirst(connection.health.status)}</h4>
        )}

        {enforceLimits ? (
          <p className="mt-0.5 text-xs font-medium text-gray-500">
            <span
              className={clsx(
                isNum(callCount) && isNum(dailyCalls) && callCount >= dailyCalls && 'text-red-500'
              )}
            >
              {isNum(callCount) ? callCount : 0}
            </span>
            {` / ${String(dailyCalls)} API calls (past 24 hrs)`}
          </p>
        ) : isNum(callCount) && callCount > 0 ? (
          <p className="mt-0.5 text-xs font-medium text-gray-500">
            {`${callCount} API call${callCount > 1 ? 's' : ''} (past 24 hrs)`}
          </p>
        ) : null}
      </div>
      {isEditing ? (
        <Icon name="FastForward" className="h-5 w-5 animate-fadeIn text-indigo-500" />
      ) : (
        <Icon match={connection.type.id} className="mt-1 h-6 w-6" />
      )}
    </Link>
  );
}
