import { Route, RouteProps } from 'react-router-dom';
import { useAuth } from '~/hooks/auth-context';

export type XRouteProps = {
  featureFlag?: string;
} & RouteProps;

const XRoute = ({ featureFlag, ...rest }: XRouteProps) => {
  const { user } = useAuth();

  const hasFeature = !featureFlag ? true : user?.organization.features.includes(featureFlag);

  return <Route {...rest}>{hasFeature ? rest.children : null}</Route>;
};

export default XRoute;
