import { useMutation } from '@apollo/client';
import cx from 'clsx';
import { useSetAtom } from 'jotai';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { Button, EditPermission, Tooltip } from '../../../components';
import {
  AllLabelsDocument,
  ClientFieldsetFragment,
  CosmosDbConfiguration,
  DataliteConfiguration,
  EditableFieldsetFragment,
  FieldsetConfiguration,
  FieldsetType,
  FilterFunction,
  ModelDocument,
  MongoDbConfiguration,
  UpdateFieldsetDocument
} from '../../../generated/graphql';
import { useApplyFieldsetUpdateLoading, useBannerDispatch } from '../../../hooks';
import {
  FieldsetFormValues,
  fieldsetToUpdate,
  hasItems,
  mapFieldsToFieldUpdates,
  routes
} from '../../../utils';
import { ModelsStateAtom } from '../models';

export type FieldsetConfigActionsProps = {
  deleteLoading: boolean;
  fieldset: EditableFieldsetFragment | undefined;
  handleSubmit: UseFormReturn<FieldsetFormValues>['handleSubmit'];
  reset: UseFormReturn<FieldsetFormValues>['reset'];
  preventSave: boolean;
};

const FieldsetConfigActions = React.memo<FieldsetConfigActionsProps>(
  ({ deleteLoading, fieldset, handleSubmit, preventSave, reset }) => {
    // state
    const setModelsState = useSetAtom(ModelsStateAtom);
    // hooks
    const { fieldsetId } = useParams<{ fieldsetId: string }>();
    const history = useHistory();
    const dispatchBanner = useBannerDispatch();
    const { state: updateLoading } = useApplyFieldsetUpdateLoading();
    // queries
    const [saveFieldset, { loading: saveLoading }] = useMutation(UpdateFieldsetDocument, {
      fetchPolicy: 'no-cache',
      refetchQueries: [AllLabelsDocument],
      onError: error => {
        dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
      },
      update: (cache, { data }) => {
        if (!data || !data.updateFieldset) {
          return;
        }
        if (!fieldsetId) {
          setModelsState(s => ({
            ...s,
            expandedFieldsetIds: [...s.expandedFieldsetIds, data.updateFieldset.fieldset.id]
          }));
        }
        // This is to guard against the response sending down unpublished fields.
        // We don't want those in the Model. Ideally, this wouldn't be needed.
        const fieldsets = data.updateFieldset.model.fieldsets.reduce(
          (acc: ClientFieldsetFragment[], fs) => {
            acc.push({ ...fs, fields: fs.fields.filter(f => f.published) });
            return acc;
          },
          []
        );
        cache.writeQuery({
          query: ModelDocument,
          data: { model: { fieldsets, __typename: 'Model' } }
        });
        // force syncs to refetch if updating
        // as creation flow won't have a fieldsetId
        if (fieldsetId) {
          cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'syncs'
          });
        }
        reset();
        history.push(routes.models);
      }
    });
    // f(x)
    const handleCancel = () => history.push(routes.models);
    const onSave = handleSubmit((form: FieldsetFormValues) => {
      if (!(fieldset && form)) {
        return;
      }
      const localErrors: string[] = [];

      if (fieldset.fieldsetType !== FieldsetType.Datalite) {
        if (!form.fields.some(field => field.published)) {
          localErrors.push('Must add at least one field to model');
        }
      }

      if (fieldset.fieldsetType === FieldsetType.Datalite) {
        if (!hasItems((form.configuration as DataliteConfiguration).schemas)) {
          localErrors.push('At least one object must be selected');
        }
        if (!(form.configuration as DataliteConfiguration).query) {
          localErrors.push('Must add a valid query');
        }
      }
      if (fieldset.fieldsetType === FieldsetType.MongoDb) {
        if (!(form.configuration as MongoDbConfiguration).database) {
          localErrors.push('Must specify database');
        }
        if (!(form.configuration as MongoDbConfiguration).collection) {
          localErrors.push('Must specify collection');
        }
      }
      if (fieldset.fieldsetType === FieldsetType.CosmosDb) {
        if (hasItems((form.configuration as CosmosDbConfiguration).filters)) {
          (form.configuration as CosmosDbConfiguration).filters.forEach((filter, idx) => {
            if (
              (filter.function === FilterFunction.Equality ||
                filter.function === FilterFunction.Inequality) &&
              !filter.value
            ) {
              const field = fieldset.fields.find(f => f.sourceName === filter.field);
              localErrors.push(
                `Filter: "${field?.label || '#' + idx.toString()}" has missing or invalid values`
              );
            }
          });
        }
      }
      if (hasItems(localErrors)) {
        dispatchBanner({
          type: 'show',
          payload: { message: localErrors, wrapper: 'px-3 pt-3' }
        });
        return;
      }
      const variables = {
        id: fieldset.id,
        name: form.realName,
        fields: mapFieldsToFieldUpdates(form.fields),
        primaryKey: form.primaryKey?.id || null,
        relatedTo:
          form.relatedTo?.[0]?.from && form.relatedTo?.[0]?.to
            ? [{ from: form.relatedTo[0].from.id, to: form.relatedTo[0].to.id }]
            : [],
        update: fieldsetToUpdate(
          fieldset.fieldsetType,
          form.configuration as FieldsetConfiguration
        ),
        tags: form?.tags?.map(tag => tag.id) || [],
        labels: form?.labels?.map(label => label.id) ?? []
      };
      void saveFieldset({ variables });
    });

    return (
      <>
        <Button
          theme="outline"
          iconEnd="CloseX"
          onClick={handleCancel}
          disabled={saveLoading || updateLoading}
        >
          Cancel
        </Button>
        <Tooltip
          offset={[0, 12]}
          placement="bottom-end"
          disabled={!preventSave}
          content={cx(
            'Model cannot be saved until',
            fieldset?.fieldsetType !== FieldsetType.Api && 'query is',
            'refreshed'
          )}
        >
          <span>
            <EditPermission>
              <Button
                theme="outline"
                onClick={onSave}
                loading={saveLoading}
                disabled={updateLoading || deleteLoading || preventSave}
                iconEnd="Check"
              >
                Save
              </Button>
            </EditPermission>
          </span>
        </Tooltip>
      </>
    );
  }
);

export default FieldsetConfigActions;
