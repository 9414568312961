import * as React from 'react';

import { useFieldsetState } from '../../../hooks';
import { hasItems } from '../../../utils';
import { InboundJoins, JoinToModel, PrimaryKey, SyncsUsingFieldset } from '.';

export function AdditionalConfig() {
  const fieldset = useFieldsetState();

  if (!hasItems(fieldset?.fields)) {
    return null;
  }
  return (
    <>
      <PrimaryKey />
      <JoinToModel />
      <InboundJoins />
      <SyncsUsingFieldset />
    </>
  );
}
