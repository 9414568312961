import clsx from 'clsx';
import { useMemo } from 'react';
import { JsonView } from 'react-json-view-lite';
import { useBannerDispatch } from '~/hooks';

interface Props {
  data?: string | Record<string, unknown> | null;
  className?: string;
}

export function JsonViewer({ data, className = 'text-sm' }: Props) {
  // Hooks
  const dispatchBanner = useBannerDispatch();

  /**
   * parsedData
   * - The parsed data that is usable for the JSON Viewer element
   * - If the data is a string, it will attempt to parse it as JSON
   * - If the data is not a string, it will return the data as-is
   * - If the data is a string and cannot be parsed, it will return an empty object
   * - When the data cannot be parsed, an error banner will be displayed with the parsing error
   */
  const parsedData = useMemo(() => {
    if (typeof data === 'string') {
      try {
        return JSON.parse(data || '{}');
      } catch (e) {
        const parsingError = `Error parsing JSON: ${data}`;
        dispatchBanner({
          type: 'show',
          payload: {
            message: parsingError,
            wrapper: 'px-3 pt-3'
          }
        });
        return {};
      }
    }
    return data;
  }, [data]);

  // SX
  const CONTAINER_SX = clsx(
    'px-3 whitespace-pre text-gray-800 font-mono break-words overflow-auto',
    className
  );

  return (
    <JsonView
      data={parsedData}
      style={{
        container: CONTAINER_SX,
        basicChildStyle: 'mx-3 p-0',
        pointer: 'cursor-pointer select-none',
        expander: '-ml-4 mr-0.75 text-gray-400 text-xl leading-5 cursor-pointer select-none',
        label: 'mr-1.25',
        nullValue: 'text-fuchsia-600',
        undefinedValue: 'text-fuchsia-600',
        booleanValue: 'text-fuchsia-600',
        numberValue: 'text-blue-600',
        stringValue: 'text-amber-700',
        otherValue: 'text-gray-800',
        punctuation: 'text-gray-800'
      }}
    />
  );
}
