import clsx from 'clsx';
import qs from 'query-string';
import * as React from 'react';
import { useMemo } from 'react';
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom';
import { FrequencyOptions, Icon } from '~/components';
import { IconName } from '~/assets';
import { Tooltip, Truncator } from '../../components';
import { COLOR, TextColor } from '../../components/v2/configs/SX';
import { ExecutionStatus, Frequency, SyncPeekFragment } from '../../generated/graphql';
import { useMountEffect } from '../../hooks';
import { addLineBreaks, capsFirst, getShortLocalTime, hasItems, routes } from '../../utils';

const sharedStyles =
  'min-h-[3.75rem] flex px-4 border-t border-gray-300 group-hover:bg-indigo-50 h-full';

const logoStyles = 'w-5 h-5';
const iconStyles = 'w-5 h-5 text-gray-600';

type SyncStatusProps = {
  sync: SyncPeekFragment;
  handleClick: () => void;
};

enum SYNC_STATUS {
  COMPLETED = 'completed',
  COMPLETED_WITH_ERRORS = 'completedWithErrors',
  STARTING = 'starting',
  FAILED = 'failed',
  RUNNING = 'running',
  NEVER_RUN = 'neverRun',
  QUEUED = 'queued',
  DISABLED = 'disabled',
  DEFAULT = 'default'
}

type SyncStatusConfig = {
  label: string | null;
  labelColor: COLOR | null;
  icon: IconName | null;
  iconColor: COLOR | null;
  iconPlacement: 'start' | 'end' | null;
  tooltipContent: string | null;
  tooltipDisabled: boolean;
  link: string;
};

const getSyncStatusConfig = ({ sync }: Pick<SyncStatusProps, 'sync'>): SyncStatusConfig => {
  const status = () => {
    if (sync.Status === ExecutionStatus.Queued) {
      return SYNC_STATUS.QUEUED;
    }
    if (sync.Status === ExecutionStatus.Created) {
      return SYNC_STATUS.STARTING;
    }
    if (sync.Status === ExecutionStatus.Running) {
      return SYNC_STATUS.RUNNING;
    }
    if (sync.Status === ExecutionStatus.Processing) {
      return SYNC_STATUS.RUNNING;
    }
    if (!sync.Active) {
      return SYNC_STATUS.DISABLED;
    }
    if (!sync.Status && sync.LastRunTimestamp === null) {
      return SYNC_STATUS.NEVER_RUN;
    }
    if (sync.Status === ExecutionStatus.Completed) {
      if (sync.LastRunErrorCount > 0) {
        return SYNC_STATUS.COMPLETED_WITH_ERRORS;
      }
      return SYNC_STATUS.COMPLETED;
    }
    if (sync.Status === ExecutionStatus.Failed) {
      return SYNC_STATUS.FAILED;
    }

    return SYNC_STATUS.DEFAULT;
  };

  switch (status()) {
    case SYNC_STATUS.COMPLETED:
      return {
        label: null,
        labelColor: null,
        icon: 'CheckFilled',
        iconColor: COLOR.SUCCESS,
        iconPlacement: null,
        tooltipContent: null,
        tooltipDisabled: true,
        link: generatePath(routes.syncStatus, { id: sync.id })
      };
    case SYNC_STATUS.COMPLETED_WITH_ERRORS:
      return {
        label: 'History',
        labelColor: COLOR.INFO,
        icon: 'WarningFilled',
        iconColor: COLOR.WARNING,
        iconPlacement: 'start',
        tooltipContent: `${sync.LastRunErrorCount} error${sync.LastRunErrorCount > 1 ? 's' : ''}`,
        tooltipDisabled: sync.LastRunErrorCount <= 0,
        link: generatePath(routes.syncHistory, { id: sync.id })
      };
    case SYNC_STATUS.FAILED:
      return {
        label: 'History',
        labelColor: COLOR.INFO,
        icon: 'DangerFilled',
        iconColor: COLOR.ERROR,
        iconPlacement: 'start',
        tooltipContent: sync.LastRunError,
        tooltipDisabled: !sync.LastRunError,
        link: generatePath(routes.syncHistory, { id: sync.id })
      };
    case SYNC_STATUS.RUNNING:
      return {
        label: 'Running...',
        labelColor: COLOR.SUCCESS,
        icon: null,
        iconColor: null,
        iconPlacement: null,
        tooltipContent: null,
        tooltipDisabled: true,
        link: generatePath(routes.syncStatus, { id: sync.id })
      };
    case SYNC_STATUS.NEVER_RUN:
      return {
        label: 'Never Run',
        labelColor: COLOR.PRIMARY,
        icon: null,
        iconColor: null,
        iconPlacement: null,
        tooltipContent: null,
        tooltipDisabled: true,
        link: generatePath(routes.syncStatus, { id: sync.id })
      };
    case SYNC_STATUS.QUEUED:
      return {
        label: 'Queued',
        labelColor: COLOR.SECONDARY,
        icon: 'InfoFilled',
        iconColor: COLOR.INFO,
        iconPlacement: 'end',
        tooltipContent: 'Only one sync can run against a destination object at a time',
        tooltipDisabled: false,
        link: generatePath(routes.syncStatus, { id: sync.id })
      };

    case SYNC_STATUS.DISABLED:
      return {
        label: 'Disabled',
        labelColor: COLOR.DISABLED,
        icon: null,
        iconColor: null,
        iconPlacement: null,
        tooltipContent: null,
        tooltipDisabled: true,
        link: generatePath(routes.syncStatus, { id: sync.id })
      };
    case SYNC_STATUS.STARTING:
      return {
        label: 'Starting...',
        labelColor: COLOR.PRIMARY,
        icon: null,
        iconColor: null,
        iconPlacement: null,
        tooltipContent: null,
        tooltipDisabled: true,
        link: generatePath(routes.syncStatus, { id: sync.id })
      };
    case SYNC_STATUS.DEFAULT:
    default:
      return {
        label: capsFirst(sync.Status),
        labelColor: COLOR.PRIMARY,
        icon: null,
        iconColor: null,
        iconPlacement: null,
        tooltipContent: null,
        tooltipDisabled: true,
        link: generatePath(routes.syncStatus, { id: sync.id })
      };
  }
};

const SyncStatus = ({ sync, handleClick }: SyncStatusProps) => {
  const config = useMemo(() => getSyncStatusConfig({ sync }), [sync]);
  const ICON_SX = clsx('h-4 w-4', config.iconColor && TextColor[config.iconColor]);
  const LABEL_SX = clsx('text-sm', config.labelColor && TextColor[config.labelColor]);
  const INNER_SX = clsx(
    'flex items-center gap-2',
    config.iconPlacement === 'start' && 'flex-row-reverse',
    config.iconPlacement === 'end' && 'flex-row gap-1'
  );
  const TOOLTIP_SX = 'max-w-3xl';
  return (
    <Link to={config.link} onClick={handleClick}>
      <div className={clsx('items-center', sharedStyles)}>
        <Tooltip
          placement="left"
          className={TOOLTIP_SX}
          disabled={config.tooltipDisabled}
          content={config.tooltipContent}
        >
          <span className={INNER_SX}>
            {config.label && <p className={LABEL_SX}>{config.label}</p>}
            {config.icon && <Icon name={config.icon} className={ICON_SX} />}
          </span>
        </Tooltip>
      </div>
    </Link>
  );
};

const RowSyncIconsWrapper = ({
  sourceConnectionIds,
  hasOverrideFields,
  children
}: {
  hasOverrideFields: boolean;
  sourceConnectionIds: Array<string | undefined>;
  children: React.ReactNode;
}) => {
  return (
    <div className={clsx('items-center truncate', sharedStyles)}>
      {hasItems(sourceConnectionIds) ? (
        <>
          <Icon match={sourceConnectionIds[0]} className={logoStyles} />
          {sourceConnectionIds.slice(1).map(connId => (
            <React.Fragment key={connId}>
              <Icon name="PlusSmall" className={iconStyles} />
              <Icon match={connId} className={logoStyles} />
            </React.Fragment>
          ))}
        </>
      ) : (
        hasOverrideFields && (
          <Icon name="TypeString" className={clsx(logoStyles, 'text-gray-600')} />
        )
      )}
      {children}
    </div>
  );
};

export const SyncRow = React.memo<{
  sync: SyncPeekFragment;
}>(({ sync }) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { search } = useLocation();

  const sourceConnectionIds =
    // @ts-ignore
    sync.SourceConnections?.map(connection => connection.ConnectionType) || [];
  const health = sync.Status === 'healthy' || true;

  const handleClick = React.useCallback(() => {
    history.replace({ search: `id=${sync.id}` });
  }, [history, sync.id]);

  useMountEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    const parsed = qs.parse(search);
    if (parsed.id === sync.id) {
      history.replace({ search: undefined });
      scrollRef.current.scrollIntoView({ block: 'center' });
    }
  });

  return (
    <div className="group contents">
      <Link to={generatePath(routes.syncStatus, { id: sync.id })} onClick={handleClick}>
        <div ref={scrollRef} className={clsx('flex-col justify-center', sharedStyles)}>
          <span className="text-sm font-medium text-gray-800">{addLineBreaks(sync.name)}</span>
          <span className="mt-0.5 text-xs text-gray-500">
            Last run: {getShortLocalTime(sync.LastRunTimestamp) || 'Never'}
          </span>
        </div>
      </Link>
      {!health ? (
        sync.TargetConnectionID && (
          <RowSyncIconsWrapper
            hasOverrideFields={hasItems(sync.SourceConnections)}
            sourceConnectionIds={sourceConnectionIds}
          >
            <Link
              to={generatePath(routes.editConnection, { id: sync.TargetConnectionID })}
              onClick={handleClick}
              className="mx-2 break-words text-red-500 underline hover:no-underline"
            >
              Unhealthy connection
            </Link>
          </RowSyncIconsWrapper>
        )
      ) : (
        <Link to={generatePath(routes.syncStatus, { id: sync.id })} onClick={handleClick}>
          <RowSyncIconsWrapper
            hasOverrideFields={hasItems(sync.SourceConnections)}
            sourceConnectionIds={sourceConnectionIds}
          >
            <Icon name="Syncs" className={clsx('mx-2', iconStyles)} />
            {sync.TargetConnectionType && (
              <Icon match={sync.TargetConnectionType} className={clsx('mr-1.5', logoStyles)} />
            )}
            <Truncator content={sync.TargetObjectName || 'Missing target object'}>
              <p
                className={clsx(
                  'truncate whitespace-nowrap text-sm',
                  sync.TargetObjectName ? 'text-gray-800' : 'text-red-500'
                )}
              >
                {sync.TargetObjectName || 'Missing target object'}
              </p>
            </Truncator>
          </RowSyncIconsWrapper>
        </Link>
      )}
      <Link to={generatePath(routes.syncStatus, { id: sync.id })} onClick={handleClick}>
        <div className={clsx('items-center text-sm text-gray-800', sharedStyles)}>
          {FrequencyOptions[sync.Frequency as Frequency]?.label}
          {sync.Frequency === Frequency.Runafter && (
            <Tooltip
              className="max-w-sm"
              interactive={true}
              trigger="mouseenter"
              offset={[0, 12]}
              content={
                <p>
                  Following completion of:{' '}
                  {sync.RunAfterSyncs.map(sync => (
                    <Link
                      key={sync.id}
                      to={generatePath(
                        sync.Type === 'model' ? routes.syncStatus : routes.bulkSyncStatus,
                        { id: sync.id }
                      )}
                      onClick={e => {
                        e.stopPropagation();
                        handleClick();
                      }}
                      className="link table"
                      target="_blank"
                    >
                      {sync.name}
                    </Link>
                  ))}
                </p>
              }
            >
              <div className="pl-2">
                <Icon name="InfoFilled" className="h-4 w-4 text-blue-500 hover:text-blue-400" />
              </div>
            </Tooltip>
          )}
        </div>
      </Link>
      <SyncStatus sync={sync} handleClick={handleClick} />
    </div>
  );
});
