import { useLazyQuery } from '@apollo/client';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import {
  ApplyFieldsetUpdateDocument,
  EditableFieldsetFragment,
  FieldsetConfiguration
} from '../generated/graphql';
import {
  FieldsetFormValues,
  isFieldset,
  mapFieldsToFieldUpdates,
  updateFieldsetForm
} from '../utils';
import { useBannerDispatch } from './banner-context';
import { useApplyFieldsetUpdateLoading, usePreventSave } from './boolean-context';
import { useFieldsetDispatch, useFieldsetState } from './fieldset-context';
import { useUpdatedConfig } from './use-model-query-ref';
import { useAbortQuery } from './use-abort-query';

export function useApplyFieldsetUpdate() {
  const { getValues, reset } = useFormContext<FieldsetFormValues>();
  const dispatchBanner = useBannerDispatch();
  const fieldset = useFieldsetState();
  const dispatch = useFieldsetDispatch();
  const { update: setPreventSave } = usePreventSave();
  const { update: setUpdateLoading } = useApplyFieldsetUpdateLoading();
  const { update: setUpdatedConfig } = useUpdatedConfig();
  const { signal, abortQuery, resetAbortQuery } = useAbortQuery();

  const [applyFieldsetUpdate, { startPolling, stopPolling }] = useLazyQuery(
    ApplyFieldsetUpdateDocument,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      context: { fetchOptions: { signal } },
      onCompleted: data => {
        if (!data || !data.applyFieldsetUpdate) {
          setUpdateLoading(false);
          setPreventSave(false);
          return;
        }
        if (!isFieldset(data.applyFieldsetUpdate)) {
          startPolling(750);
          return;
        }
        stopPolling();
        setUpdateLoading(false);
        setPreventSave(false);
        const fieldset = data.applyFieldsetUpdate as EditableFieldsetFragment;
        fieldset.realName = getValues()?.realName ?? fieldset.realName;
        dispatch({ type: 'replace', fieldset });
        updateFieldsetForm(reset, getValues, fieldset);
        setUpdatedConfig(fieldset.configuration as FieldsetConfiguration);
      },
      onError: error => {
        stopPolling();
        setUpdateLoading(false);
        if (signal.aborted && error?.message.includes('aborted')) {
          return;
        }
        setPreventSave(false);
        if (
          error.extraInfo &&
          (error.extraInfo as Record<string, unknown>)['isComplete'] === false
        ) {
          return;
        }
        dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
      }
    }
  );

  const applyUpdate = React.useCallback(
    (
      update: Record<string, unknown>,
      options?: { refresh?: boolean; resetFields?: boolean; refreshConfiguration?: boolean }
    ) => {
      if (!fieldset?.id) {
        return;
      }
      dispatchBanner({ type: 'hide' });
      resetAbortQuery();
      setUpdateLoading(true);
      const form = getValues();
      void applyFieldsetUpdate({
        variables: {
          id: fieldset.id,
          continuation: uuid(),
          name: form.realName,
          fields: options?.resetFields ? [] : mapFieldsToFieldUpdates(form.fields),
          primaryKey: form.primaryKey?.id,
          relatedTo: [],
          refresh: !!options?.refresh,
          refreshConfiguration: !!options?.refreshConfiguration,
          update
        }
      });
    },
    [
      fieldset?.id,
      dispatchBanner,
      setUpdateLoading,
      getValues,
      resetAbortQuery,
      applyFieldsetUpdate
    ]
  );

  return { applyUpdate, abortQuery, abortSignal: signal };
}
