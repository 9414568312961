import Form from '@rjsf/core';
import {
  FieldTemplateProps,
  ObjectFieldTemplateProps,
  RJSFSchema,
  RegistryWidgetsType,
  TemplatesType,
  UiSchema,
  WidgetProps
} from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import Ajv2020 from 'ajv/dist/2020';
import { Selectable } from '~/utils';
import { EditPermission } from './edit-permission';
import { Checkbox, DisabledSelect, Label, MyCombobox, MyInput } from './form-components';
import { useMemo } from 'react';
import { defaultTo, mapValues } from 'lodash';

const FieldTemplate = (props: FieldTemplateProps) => props.children;

const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => (
  <fieldset className="space-y-3">{props.properties.map(element => element.content)}</fieldset>
);

export const DefaultTemplates: Partial<TemplatesType> = {
  FieldTemplate,
  ObjectFieldTemplate
};

const CheckboxWidget = (props: WidgetProps) => (
  <div>
    <EditPermission>
      <Checkbox
        id={props.id}
        defaultChecked={props.value}
        label={props.label}
        required={props.required}
        onChange={e => props.onChange(e.target.checked)}
      />
    </EditPermission>
  </div>
);

const SelectWidget = (props: WidgetProps) => {
  const options =
    (props.schema.enum as (string | Selectable)[])?.map(option => {
      if (typeof option === 'object') {
        return {
          label: option.label || option.value,
          value: option.value
        };
      }
      return { label: option, value: option };
    }) || [];

  const value = options.find(option => option.value === props.value) || null;

  return (
    <div className={props.className}>
      <Label htmlFor={props.id}>{props.label}</Label>
      <EditPermission fallback={<DisabledSelect valueLabel={value?.label} />}>
        <MyCombobox
          id={props.id}
          value={value}
          options={options}
          onChange={e => props.onChange(e?.value || e)}
          isClearable={!props.required}
        />
      </EditPermission>
    </div>
  );
};

const TextWidget = function (props: WidgetProps) {
  if (props.readonly && !props.value) {
    return null;
  }
  return (
    <div>
      <EditPermission>
        <MyInput
          name={props.name}
          className={props.className}
          label={props.label}
          required={props.required}
          defaultValue={(props.value || props.default) as string}
          errors={props.rawErrors}
          description={props.description}
          placeholder={props.placeholder}
          onChange={e => props.onChange(e.target.value)}
          readOnly={props.readonly}
        />
      </EditPermission>
    </div>
  );
};

export const DefaultWidgets: RegistryWidgetsType = {
  CheckboxWidget,
  SelectWidget,
  TextWidget
};

export interface JSONSchemaFormProps<T extends object> {
  formData?: T;
  schema: RJSFSchema;
  templates?: Partial<TemplatesType>;
  uiSchema: UiSchema;
  widgets?: RegistryWidgetsType;

  onChange?: (value: T) => unknown;
}

export function JSONSchemaForm<T extends object>(props: JSONSchemaFormProps<T>) {
  const {
    formData,
    schema,
    templates = DefaultTemplates,
    uiSchema,
    widgets = DefaultWidgets,
    onChange
  } = props;

  const newSchema = {
    ...schema,
    dependencies: { ...(schema.dependentSchemas || schema.dependencies) }
  } as RJSFSchema;

  return (
    <Form
      tagName="div"
      formData={formData}
      schema={newSchema}
      templates={templates}
      uiSchema={uiSchema}
      validator={customizeValidator({ AjvClass: Ajv2020 })}
      widgets={widgets}
      onChange={e => {
        onChange && onChange(e.formData as T);
      }}
    />
  );
}
