import { Header } from '@tanstack/react-table';
import clsx from 'clsx';

export type TableColResizerProps<TData, TValue> = {
  header: Header<TData, TValue>;
};

const TableColResizer = <TData, TValue>({ header }: TableColResizerProps<TData, TValue>) => {
  const isResizing = header.column.getIsResizing();
  const thResizerClassname = clsx(
    'absolute rounded right-0 top-0 bottom-0 w-0.75 h-6 my-auto',
    'translate-x-0.5 z-10', // Allows resizer to overlap border
    'cursor-col-resize select-none touch-none', // Interactivity
    !isResizing && 'hover:bg-indigo-200', // Hover
    isResizing && 'bg-indigo-500'
  );

  const handleResetSize = () => header.getContext().column.resetSize();

  return (
    <div
      draggable //Necessary for showing cursor as col-resize
      onDragStart={e => e.preventDefault()}
      onDragEnd={e => e.preventDefault()}
      onMouseDown={header.getResizeHandler()}
      onTouchStart={header.getResizeHandler()}
      onDoubleClick={handleResetSize}
      className={thResizerClassname}
    />
  );
};

export default TableColResizer;
