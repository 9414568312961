import { useFormContext, useWatch } from 'react-hook-form';

import { Icon } from '~/components';
import { LinkButton } from '../../../components';
import { SyncMode } from '../../../generated/graphql';
import { SyncConfigFormValues } from '../../../utils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItemCircle,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';

interface Props {
  index: number;
}

export function FieldSyncMode({ index }: Props) {
  const { control, setValue } = useFormContext<SyncConfigFormValues>();

  const mode = useWatch({ control, name: `mappings.${index}.syncMode` });

  function handleFieldSyncModePopoverAction(value: SyncMode) {
    setValue(`mappings.${index}.syncMode`, value === SyncMode.Create ? value : null);
  }

  return (
    <>
      <Icon name="RefreshSmall" className="h-4 w-4 text-gray-500" />
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="-top-0.5 text-xs">
          <LinkButton>
            {mode === SyncMode.Create ? 'Do not overwrite existing' : 'Always sync'}
          </LinkButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuLabel>Field sync mode</DropdownMenuLabel>
          <DropdownMenuRadioGroup
            value={mode || SyncMode.UpdateOrCreate}
            onValueChange={value => {
              handleFieldSyncModePopoverAction(value as SyncMode);
            }}
          >
            <DropdownMenuRadioItemCircle
              value={SyncMode.UpdateOrCreate}
              checked={!mode || mode === SyncMode.UpdateOrCreate}
            >
              Always sync
            </DropdownMenuRadioItemCircle>
            <DropdownMenuRadioItemCircle value={SyncMode.Create} checked={mode === SyncMode.Create}>
              Do not overwrite existing
            </DropdownMenuRadioItemCircle>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}

if (import.meta.env.MODE === 'development') {
  FieldSyncMode.displayName = 'FieldSyncMode';
}
