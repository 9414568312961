import {
  acceptCompletion,
  autocompletion,
  completionKeymap,
  Completion
} from '@codemirror/autocomplete';
import {
  copyLineDown,
  copyLineUp,
  cursorMatchingBracket,
  cursorSyntaxLeft,
  cursorSyntaxRight,
  deleteLine,
  history,
  indentLess,
  indentMore,
  indentSelection,
  moveLineDown,
  moveLineUp,
  redo,
  selectLine,
  selectParentSyntax,
  selectSyntaxLeft,
  selectSyntaxRight,
  simplifySelection,
  standardKeymap,
  toggleBlockComment,
  toggleComment,
  undo
} from '@codemirror/commands';
import { sql } from '@codemirror/lang-sql';
import { bracketMatching, indentOnInput, indentUnit, LanguageSupport } from '@codemirror/language';
import { search, searchKeymap } from '@codemirror/search';
import { Compartment, EditorState, Extension } from '@codemirror/state';
import {
  EditorView,
  highlightActiveLine,
  highlightActiveLineGutter,
  keymap,
  lineNumbers,
  placeholder as placeholderExt
} from '@codemirror/view';
import * as React from 'react';
import { useSetEditorView } from '~/hooks/editor-context';

import { editorTheme } from '../../utils';
import { completeFromSchema } from './completion';

export interface EditorProps {
  onUpdate: ((value: string) => void) | null;
  onAnyUpdate?: (state: EditorState) => void;
  defaultDoc?: string | undefined | null;
  height?: string;
  maxHeight?: string;
  resizable?: boolean;
  placeholder?: string;
  language: LanguageSupport;
  completions?: Record<string, Completion[]>;
  readOnly?: boolean;
  className?: string;
}

export const Editor = React.memo<EditorProps>(
  ({
    language,
    defaultDoc,
    onUpdate,
    onAnyUpdate,
    height,
    maxHeight,
    resizable = true,
    placeholder = 'Enter query...',
    completions,
    readOnly = false,
    className
  }) => {
    const setView = useSetEditorView();
    const ref = React.useRef<HTMLDivElement | null>(null);
    const view = React.useRef<EditorView | null>(null);

    const compartments = React.useRef<{ c: Compartment; a: Compartment }>({
      c: new Compartment(),
      a: new Compartment()
    });
    React.useEffect(() => {
      if (view.current) {
        const effects = [compartments.current.c.reconfigure(language)];
        if (completions) {
          effects.push(
            compartments.current.a.reconfigure(
              autocompletion({
                override: [completeFromSchema(completions, undefined, undefined, 'public')]
              })
            )
          );
        }
        view.current.dispatch({ effects });
      }
    }, [language, view.current]);

    const state = React.useRef<EditorState>(
      EditorState.create({
        doc: defaultDoc || '',
        extensions: [
          editorTheme({ height, maxHeight, resizable, readOnly }),
          indentUnit.of('    '), // default is 2 spaces
          lineNumbers(),
          highlightActiveLineGutter(),
          highlightActiveLine(),
          history(),
          indentOnInput(),
          placeholderExt(placeholder),
          bracketMatching(),
          compartments.current.a.of(
            autocompletion({
              override: [completeFromSchema(completions, undefined, undefined, 'public')]
            })
          ),
          search({ top: true }),
          EditorView.updateListener.of(update => {
            onAnyUpdate?.(update.state);
            if (update.docChanged) {
              onUpdate?.(update.state.doc.toString());
            }
          }),
          EditorView.lineWrapping,
          EditorView.editable.of(!readOnly),
          EditorState.readOnly.of(readOnly),
          keymap.of([
            ...standardKeymap,
            ...searchKeymap,
            ...completionKeymap,
            { key: 'Tab', run: acceptCompletion },
            // indent hotkeys
            { key: 'Mod-[', run: indentLess },
            { key: 'Mod-]', run: indentMore },
            // ---
            {
              key: 'Alt-ArrowLeft',
              mac: 'Ctrl-ArrowLeft',
              run: cursorSyntaxLeft,
              shift: selectSyntaxLeft
            },
            {
              key: 'Alt-ArrowRight',
              mac: 'Ctrl-ArrowRight',
              run: cursorSyntaxRight,
              shift: selectSyntaxRight
            },
            { key: 'Alt-ArrowUp', run: moveLineUp },
            { key: 'Shift-Alt-ArrowUp', run: copyLineUp },
            { key: 'Alt-ArrowDown', run: moveLineDown },
            { key: 'Shift-Alt-ArrowDown', run: copyLineDown },
            { key: 'Escape', run: simplifySelection },
            { key: 'Alt-l', mac: 'Ctrl-l', run: selectLine },
            { key: 'Mod-i', run: selectParentSyntax, preventDefault: true },
            { key: 'Mod-Alt-\\', run: indentSelection },
            { key: 'Shift-Mod-k', run: deleteLine },
            { key: 'Shift-Mod-\\', run: cursorMatchingBracket },
            { key: 'Mod-/', run: toggleComment },
            { key: 'Alt-A', run: toggleBlockComment },
            { key: 'Mod-z', run: undo, preventDefault: true },
            { key: 'Mod-Shift-z', run: redo, preventDefault: true }
          ]),
          compartments.current.c.of(language)
        ]
      })
    );

    React.useLayoutEffect(() => {
      if (!ref.current) {
        return;
      }
      if (!view.current) {
        const initView = new EditorView({
          state: state.current,
          parent: ref.current
        });
        view.current = initView;
        setView?.(initView);
      }
      return () => {
        view.current?.destroy();
        view.current = null;
        setView?.(null);
      };
    }, [setView]);

    return <div className={className} ref={ref} />;
  }
);

if (import.meta.env.MODE === 'development') {
  Editor.displayName = 'Editor';
}

export const ReadOnlyEditor = React.memo<Partial<Omit<EditorProps, 'resizable' | 'onUpdate'>>>(
  ({ defaultDoc, height = null, maxHeight, language }) => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const view = React.useRef<EditorView | null>(null);
    const compartments = React.useRef<{ c: Compartment }>({ c: new Compartment() });

    const state = React.useRef<EditorState>(
      EditorState.create({
        doc: defaultDoc || '',
        extensions: [
          editorTheme({ height, maxHeight, resizable: false, readOnly: true }),
          indentUnit.of('    '),
          EditorState.readOnly.of(true),
          EditorView.editable.of(false),
          compartments.current.c.of(language)
        ]
      })
    );

    React.useLayoutEffect(() => {
      if (!ref.current) {
        return;
      }
      if (!view.current) {
        const initView = new EditorView({
          state: state.current,
          parent: ref.current
        });
        view.current = initView;
      }
      return () => {
        view.current?.destroy();
        view.current = null;
      };
    }, []);

    return <div ref={ref} />;
  }
);

if (import.meta.env.MODE === 'development') {
  ReadOnlyEditor.displayName = 'ReadOnlyEditor';
}
