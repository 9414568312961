import { v4 as uuid } from 'uuid';

import {
  CompletionValue,
  FieldType,
  FilterFragment,
  FilterFunction,
  OverrideFragment
} from '../../generated/graphql';
import { hasItems, isTargetField, Selectable } from '../../utils';
import { MyCombobox } from '..';
import { BetweenNumbersInput, MultiInput, MyDatePicker, MyInput } from '.';

interface Props {
  obj: FilterFragment | OverrideFragment | undefined | null;
  index: number;
  handleValue: (value: CompletionValue | string | string[], index: number) => void;
  // Todo -> turn into enum type for all inputs
  variant?: 'outlined' | 'flat';
}

export function FilterValueElements({ obj, index, handleValue, variant = 'outlined' }: Props) {
  if (!obj) {
    return null;
  }
  const options =
    obj.field && (obj.field.filterFunctions || []).find(func => func.id === obj.function);
  if (!(obj.function && obj.field && options?.requiresValue)) {
    return null;
  }
  if (obj.field?.type === FieldType.Datetime) {
    // One-off for handling FilterFunction.InTheLast
    if (options?.id === FilterFunction.InTheLast) {
      return (
        <MyCombobox
          variant={variant}
          className="col-start-3 col-end-4"
          options={options.values as Selectable[]}
          value={(options.values.find(v => v.value === obj.value) as Selectable) || null}
          placeholder="Value..."
          onChange={newValue => {
            if (!newValue) {
              return;
            }
            handleValue(newValue.value, index);
          }}
        />
      );
    }
    return (
      <MyDatePicker
        variant={variant}
        index={index}
        value={(obj.value as string) ?? undefined}
        onChange={handleValue}
      />
    );
  }
  if (isTargetField(obj.field) && hasItems(obj.field.values)) {
    return (
      <MyCombobox
        variant={variant}
        className="col-start-3 col-end-4"
        options={obj.field && isTargetField(obj.field) ? (obj.field.values as Selectable[]) : []}
        value={obj.field ? (obj.value as Selectable) : null}
        placeholder="Value..."
        onChange={newValue => {
          if (!newValue) {
            return;
          }
          handleValue(newValue, index);
        }}
      />
    );
  }
  if (obj.field?.type === FieldType.Number) {
    if (options?.id === FilterFunction.Between) {
      return (
        <BetweenNumbersInput
          variant={variant}
          values={obj.value as string[]}
          onUpdate={values => handleValue(values, index)}
        />
      );
    }
    return (
      <MyInput
        variant={variant}
        name={uuid()}
        defaultValue={(obj.value as string) ?? undefined}
        placeholder="Number..."
        onBlur={e => handleValue(e.target.value, index)}
      />
    );
  }
  if (options?.multiValue) {
    return (
      <MultiInput
        variant={variant}
        values={obj?.value}
        onUpdate={values => handleValue(values, index)}
      />
    );
  }
  return (
    <MyInput
      variant={variant}
      name={uuid()}
      defaultValue={(obj.value as string) ?? undefined}
      placeholder="Value..."
      onBlur={e => handleValue(e.target.value, index)}
    />
  );
}
