import { useFormContext } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';

import { EditPermission, SideBySide } from '../../../components';
import { FieldsetFormValues, selectStyles } from '../../../utils';
import { useQuery, useMutation } from '@apollo/client';
import { AllLabelsDocument, CreateLabelDocument, LabelFragment } from '~/generated/graphql';
import { useState } from 'react';

interface Props {
  disabled: boolean;
}

interface OptionType {
  value: string;
  label: string;
}

export function FieldsetLabels(props: Props) {
  const { setValue, getValues } = useFormContext<FieldsetFormValues>();
  const [allLabels, setAllLabels] = useState<LabelFragment[]>([]);

  // TODO @poindexd hack until we find a better way to manage state
  const [refresh, refreshState] = useState<boolean>();

  useQuery(AllLabelsDocument, {
    fetchPolicy: 'no-cache',
    variables: {
      entityType: ''
    },
    onCompleted: data => {
      setAllLabels(data?.allLabels ?? []);
    }
  });

  const [createLabel] = useMutation(CreateLabelDocument);

  const onCreateOption = async (input: string) => {
    const {
      data: { createLabel: newLabel }
    } = await createLabel({
      variables: {
        label: {
          name: input
        }
      }
    });

    const newValue = [...(getValues().labels ?? []), newLabel];

    setValue('labels', newValue);
    refreshState(!refresh);
  };

  const onChange = v => {
    const newLabels = v as [OptionType];
    const newValue = newLabels.map(n => ({
      id: n.value,
      name: n.label
    }));
    setValue('labels', newValue);
    refreshState(!refresh);
  };

  return (
    <SideBySide
      hasSectionWrap
      heading={
        <div>
          <div className="flex items-center space-x-1">
            <h3 className="sbs-heading">Labels</h3>
          </div>
          <p className="text-sm font-normal text-gray-500">(Optional)</p>
        </div>
      }
    >
      <EditPermission>
        <CreatableSelect<OptionType, true>
          placeholder="Enter labels..."
          value={getValues()?.labels?.map(l => ({ label: l.name, value: l.id })) ?? []}
          options={allLabels?.map(l => ({ label: l.name, value: l.id })) ?? []}
          className="max-w-xs"
          styles={selectStyles}
          isDisabled={props.disabled}
          onCreateOption={onCreateOption}
          getNewOptionLabel={option => {
            return option.name;
          }}
          getOptionValue={option => option.value}
          onChange={onChange}
          isClearable={false}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          isMulti
        />
      </EditPermission>
    </SideBySide>
  );
}
