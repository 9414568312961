import cx from 'clsx';
import * as React from 'react';
import { Tooltip, TooltipProps } from '.';
import { Icon } from './Icon';
import { IconName } from '~/assets';

interface BaseProps extends Omit<TooltipProps, 'content' | 'children'> {
  tooltipContent?: React.ReactNode;
  text: string | undefined | null;
}

type GraphicTypes = {
  type?: 'logo';
  img: string | undefined | IconName;
};

type Props = BaseProps & GraphicTypes;

export function FieldPill({ tooltipContent, text, type, img, className, ...props }: Props) {
  const pill = (
    <div
      className={cx(
        'flex w-max items-center space-x-1.5 rounded-full border border-gray-200 bg-white py-1.75 px-3',
        className
      )}
    >
      {type === 'logo' ? (
        <Icon match={img} className="h-5 w-5" />
      ) : (
        <Icon match={img} className="h-5 w-5 text-gray-500" />
      )}
      <p className="text-xs font-medium text-gray-800">{text}</p>
    </div>
  );
  if (tooltipContent) {
    return (
      <Tooltip placement="top-start" offset={[0, 6]} {...props} content={tooltipContent}>
        {pill}
      </Tooltip>
    );
  }
  return pill;
}
