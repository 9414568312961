/*

Most of this is cribbed from @codemirror/sql; we vendor it here in order to
support > 3 levels of auto-completion for systems like Athena that require a
catalog reference.

*/
import { Completion, CompletionContext, CompletionSource } from '@codemirror/autocomplete';
import { EditorState, Text } from '@codemirror/state';
import { syntaxTree } from '@codemirror/language';
import { SyntaxNode } from '@lezer/common';
import _ from 'lodash';

function tokenBefore(tree: SyntaxNode) {
  const cursor = tree.cursor().moveTo(tree.from, -1);
  while (/Comment/.test(cursor.name)) cursor.moveTo(cursor.from, -1);
  return cursor.node;
}

function idName(doc: Text, node: SyntaxNode): string {
  const text = doc.sliceString(node.from, node.to);
  const quoted = /^([`'"])(.*)\1$/.exec(text);
  return quoted ? quoted[2] : text;
}

function plainID(node: SyntaxNode | null) {
  return node && (node.name == 'Identifier' || node.name == 'QuotedIdentifier');
}

function pathFor(doc: Text, id: SyntaxNode) {
  if (id.name == 'CompositeIdentifier') {
    const path = [];
    for (let ch = id.firstChild; ch; ch = ch.nextSibling)
      if (plainID(ch)) path.push(idName(doc, ch));
    return path;
  }
  return [idName(doc, id)];
}

function parentsFor(doc: Text, node: SyntaxNode | null) {
  for (let path = []; ; ) {
    if (!node || (node.name != '.' && node.name != ':')) return path;
    const name = tokenBefore(node);
    if (!plainID(name)) return path;
    path.unshift(idName(doc, name));
    node = tokenBefore(name);
  }
}

function sourceContext(state: EditorState, startPos: number) {
  const pos = syntaxTree(state).resolveInner(startPos, -1);
  const aliases = getAliases(state.doc, pos);
  if (pos.name == 'Identifier' || pos.name == 'QuotedIdentifier' || pos.name == 'Keyword') {
    return {
      from: pos.from,
      quoted: pos.name == 'QuotedIdentifier' ? state.doc.sliceString(pos.from, pos.from + 1) : null,
      parents: parentsFor(state.doc, tokenBefore(pos)),
      aliases
    };
  }
  if (pos.name == '.') {
    return { from: startPos, quoted: null, parents: parentsFor(state.doc, pos), aliases };
  } else {
    return { from: startPos, quoted: null, parents: [], empty: true, aliases };
  }
}

const EndFrom = new Set(
  'where group having order union intersect except all distinct limit offset fetch for'.split(' ')
);

function getAliases(doc: Text, at: SyntaxNode) {
  let statement;
  for (let parent: SyntaxNode | null = at; !statement; parent = parent.parent) {
    if (!parent) return null;
    if (parent.name == 'Statement') statement = parent;
  }
  let aliases = null;
  for (
    let scan = statement.firstChild, sawFrom = false, prevID: SyntaxNode | null = null;
    scan;
    scan = scan.nextSibling
  ) {
    const kw = scan.name == 'Keyword' ? doc.sliceString(scan.from, scan.to).toLowerCase() : null;
    let alias = null;
    if (!sawFrom) {
      sawFrom = kw == 'from';
    } else if (kw == 'as' && prevID && plainID(scan.nextSibling)) {
      alias = idName(doc, scan.nextSibling!);
    } else if (kw && EndFrom.has(kw)) {
      break;
    } else if (prevID && plainID(scan)) {
      alias = idName(doc, scan);
    }
    if (alias) {
      if (!aliases) aliases = Object.create(null);
      aliases[alias] = pathFor(doc, prevID!);
    }
    prevID = /Identifier$/.test(scan.name) ? scan : null;
  }
  return aliases;
}

function maybeQuoteCompletions(quote: string | null, completions: readonly Completion[]) {
  if (!quote) return completions;
  return completions.map(c => ({ ...c, label: quote + c.label + quote, apply: undefined }));
}

const Span = /^\w*$/,
  QuotedSpan = /^[`'"]?\w*[`'"]?$/;

class CompletionLevel {
  list: readonly Completion[] = [];
  children: { [name: string]: CompletionLevel } | undefined = undefined;

  child(name: string) {
    const children = this.children || (this.children = Object.create(null));
    return children[name] || (children[name] = new CompletionLevel());
  }

  childCompletions(type: string) {
    return this.children
      ? Object.keys(this.children)
          .filter(x => x)
          .map(name => ({ label: name, type } as Completion))
      : [];
  }
}

export type completions = {
  [table: string]: {
    children?: completions;
    list?: readonly (string | Completion)[];
  };
};

function destructureCompletions(input: Record<string, Completion[]>): completions {
  let output: completions = {};

  for (const key in input) {
    const levels = key.split('.').reverse();
    if (levels.length < 1) {
      continue;
    }

    let current: completions = {};
    current[levels[0]] = {
      list: input[key]
    };

    for (const level of levels.slice(1)) {
      current = {
        [level]: {
          children: current
        }
      };
    }
    output = _.merge(output, current);
  }

  return output;
}

function makeCompletions(top: CompletionLevel, schema: completions) {
  for (const table in schema) {
    if (schema[table].list) {
      const leaf = top.child(table);
      leaf.list = schema[table].list.map(val =>
        typeof val == 'string' ? { label: val, type: 'property' } : val
      );
      continue;
    }
    if (schema[table].children) {
      makeCompletions(top.child(table), schema[table].children);
      continue;
    }
  }

  for (const sName in top.children) {
    const schema = top.child(sName);
    if (!schema.list.length) schema.list = schema.childCompletions('type');
  }
}

export function completeFromSchema(
  schema: Record<string, Completion[]>,
  tables?: readonly Completion[],
  defaultTableName?: string,
  defaultSchemaName?: string
): CompletionSource {
  const top = new CompletionLevel();
  const defaultSchema = top.child(defaultSchemaName || '');
  makeCompletions(top, destructureCompletions(schema));

  defaultSchema.list = (tables || defaultSchema.childCompletions('type')).concat(
    defaultTableName ? defaultSchema.child(defaultTableName).list : []
  );
  top.list = defaultSchema.list.concat(top.childCompletions('type'));

  return (context: CompletionContext) => {
    const { parents: p, from, quoted, empty, aliases } = sourceContext(context.state, context.pos);
    let parents = p;
    if (empty && !context.explicit) return null;
    if (aliases && parents.length == 1) parents = aliases[parents[0]] || parents;
    let level = top;
    for (const name of parents) {
      while (!level.children || !level.children[name]) {
        if (level == top) level = defaultSchema;
        else if (level == defaultSchema && defaultTableName) level = level.child(defaultTableName);
        else return null;
      }
      level = level.child(name);
    }
    const quoteAfter = quoted && context.state.sliceDoc(context.pos, context.pos + 1) == quoted;
    let options = level.list;
    if (level == top && aliases)
      options = options.concat(
        Object.keys(aliases).map(name => ({ label: name, type: 'constant' }))
      );
    return {
      from,
      to: quoteAfter ? context.pos + 1 : undefined,
      options: maybeQuoteCompletions(quoted, options),
      validFor: quoted ? QuotedSpan : Span
    };
  };
}
