export enum COLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEFAULT = 'default',
  DISABLED = 'disabled'
}

export enum VARIANT {
  DEFAULT = 'default',
  OUTLINED = 'outlined',
  FILLED = 'filled'
}

export enum SIZE {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge'
}

export enum MOD {
  HIGHLIGHTED = 'highlighted',
  HOVER = 'hover'
}

export const TextColor = {
  [COLOR.DEFAULT]: 'text-gray-800',
  [COLOR.PRIMARY]: 'text-gray-800',
  [COLOR.SECONDARY]: 'text-gray-500',
  [COLOR.SUCCESS]: 'text-green-500',
  [COLOR.INFO]: 'text-blue-500',
  [COLOR.WARNING]: 'text-amber-500',
  [COLOR.ERROR]: 'text-red-500',
  [COLOR.DISABLED]: 'text-gray-400'
};

export const TextSize = {
  [SIZE.XSMALL]: 'text-xs',
  [SIZE.SMALL]: 'text-sm',
  [SIZE.MEDIUM]: 'text-base',
  [SIZE.LARGE]: 'text-lg',
  [SIZE.XLARGE]: 'text-xl'
};
