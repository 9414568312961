import { ColumnDef } from '@tanstack/react-table';
import React, { Ref, useCallback } from 'react';

import { FIELD_MAPPING_TYPE } from '~/components/v2/experimental/StageMappings';
import { Icon } from '~/components';
import Table from '~/components/v2/table/Table';
import TableBodyRow from '~/components/v2/table/TableBodyRow';
import { ModelFieldFragment, TargetFieldFragment } from '~/generated/graphql';
import { fieldTypeIconName, getValueAsFormattedString, isModelField } from '~/utils';

export type FieldMappingTableProps = {
  fieldType: FIELD_MAPPING_TYPE;
  fields: Array<ModelFieldFragment | TargetFieldFragment>;
  setSelectedFieldIds?: React.Dispatch<React.SetStateAction<string[]>>;
  disabledFieldIds?: Array<string | undefined>; //example: ["7f5baae1-ddca-5f11-ab9b-d74e1d73f619"]
  multiSelect?: boolean;
  // Todo -> possibly rename to something more explanatory
  handleAdd: (s?: string[]) => void;
  triggerPopoverChip: ({ x, y }: { x: number; y: number }) => void;
  focusedRowRef?: Ref<HTMLTableRowElement>;
  focusedRowIndex?: number;
  fieldScrollIndex?: number;
};

export const FieldMappingTable = ({
  fieldType,
  fields = [],
  setSelectedFieldIds = () => null,
  disabledFieldIds = [],
  multiSelect = false,
  handleAdd,
  triggerPopoverChip,
  focusedRowRef,
  focusedRowIndex,
  fieldScrollIndex
}: FieldMappingTableProps) => {
  const mode = [
    FIELD_MAPPING_TYPE.MAPPING_SOURCE,
    FIELD_MAPPING_TYPE.IDENTITY_SOURCE,
    FIELD_MAPPING_TYPE.FILTER_MODEL,
    FIELD_MAPPING_TYPE.OVERRIDE
  ].includes(fieldType)
    ? 'model'
    : 'target';
  // MSG
  const disabledToolTipMessage = `Already mapped as a ${mode === 'model' ? 'source' : 'target'}`;

  // Toggles One Field
  const addRemSelected = useCallback(
    ({ id }: ModelFieldFragment | TargetFieldFragment) => {
      if (multiSelect) {
        setSelectedFieldIds(s => (s.includes(id) ? s.filter(v => v !== id) : [...s, id]));
        handleAdd([id]);
      } else {
        setSelectedFieldIds(s => (s.includes(id) ? [] : [id]));
        handleAdd([id]);
      }
    },
    [multiSelect, setSelectedFieldIds, handleAdd]
  );

  // Todo -> Add comments
  const handleMouseClick = (event: React.MouseEvent) => {
    const y = document.body.offsetHeight - event.pageY;
    const x = document.body.offsetWidth - event.pageX;
    multiSelect && triggerPopoverChip({ x, y });
  };

  const columns: ColumnDef<ModelFieldFragment | TargetFieldFragment>[] = [
    {
      id: 'check',
      header: () => 'Label',
      accessorFn: row => (isModelField(row) ? String(row?.label) : String(row?.name)),
      cell: ({ row }) =>
        isModelField(row.original) ? String(row.original.label) : String(row.original.name)
    },
    {
      accessorKey: 'example',
      header: 'Example',
      accessorFn: row => (isModelField(row) ? JSON.stringify(row?.example) : ''),
      cell: ({ row }) =>
        isModelField(row.original) ? getValueAsFormattedString(row?.original?.example) : ''
    },
    {
      accessorKey: 'type',
      header: 'Type',
      cell: ({ row }) => (
        <div className="flex items-center space-x-1.5 py-1.5">
          <Icon name={fieldTypeIconName(row?.original?.type)} className="h-5 w-5 text-gray-500" />
          <span className="text-gray-800">{row?.original?.type || 'unknown'}</span>
        </div>
      )
    },
    {
      accessorKey: 'sourceName',
      header: 'Source Field',
      cell: ({ row }) => (isModelField(row.original) ? String(row?.original?.sourceName) : '')
    },
    {
      accessorKey: 'id',
      header: 'System Name',
      cell: ({ row }) => (!isModelField(row.original) ? String(row?.original?.id) : '')
    }
  ];

  return (
    <div className="flex h-full w-full flex-col overflow-x-clip">
      <Table
        focusedRowIndex={focusedRowIndex}
        focusedRowRef={focusedRowRef}
        enableRowHover
        scrollIndex={fieldScrollIndex}
        state={{
          columnVisibility: {
            example: mode === 'model',
            sourceName: mode === 'model',
            id: mode !== 'model'
          }
        }}
        data={fields}
        isRowDisabled={({ original }) => disabledFieldIds.includes(original.id)}
        noDataMessage={
          <TableBodyRow className="min-w-full odd:bg-gray-50">
            <td className="px-7 py-4" colSpan={columns.length}>
              <p className="text-sm text-gray-800">No objects found</p>
            </td>
          </TableBodyRow>
        }
        columns={columns}
        TableWrapperProps={{
          className: 'rounded border border-gray-300 h-auto overflow-y-scroll shadow-card'
        }}
        TableProps={{
          className: 'table-fixed text-left w-full border-separate border-spacing-0 bg-gray-50'
        }}
        TableHeaderColProps={{
          className: 'thead-label border-b border-gray-300 first:rounded-tl last:rounded-tr'
        }}
        TableHeaderRowProps={{
          className: 'shadow-card'
        }}
        TableBodyRowProps={{
          disabledToolTipMessage: disabledToolTipMessage,
          onRowClick: ({ original }, e) => {
            handleMouseClick(e);
            addRemSelected(original);
          }
        }}
        TableBodyColProps={{
          className: 'truncate border-b border-gray-300'
        }}
      />
    </div>
  );
};

export default FieldMappingTable;
