import { HTMLAttributes } from 'react';

import LoadingDots from '~/components/v2/feedback/LoadingDots';

type PageLoaderProps = HTMLAttributes<HTMLDivElement>;

const PageLoader = (props: PageLoaderProps) => {
  const baseClassName = `flex grow items-center justify-center`;
  return (
    <div className={baseClassName}>
      <LoadingDots />
    </div>
  );
};

export default PageLoader;
