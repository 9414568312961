import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef } from 'react';
import { SqlRunnerSchemaV2MetaFragment } from '../../generated/graphql';
import { SchemaFlatTypes } from '../../utils';
import { RunnerRow } from './runner-row';

interface Props {
  connectionId: string;
  items: SchemaFlatTypes[];
  infoMeta: SqlRunnerSchemaV2MetaFragment | undefined;
  expanded: Record<string, boolean>;
  expandedSearched: Record<string, boolean>;
  search: string | undefined;
}

export function RunnerList({
  connectionId,
  items,
  infoMeta,
  expanded,
  expandedSearched,
  search
}: Props) {
  const parentRef = useRef<HTMLDivElement>(null);
  const { getTotalSize, getVirtualItems, measureElement } = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 52
  });

  return (
    <div
      ref={parentRef}
      className="h-full w-full overflow-y-scroll py-3"
      style={{ contain: 'strict' }}
    >
      <div className="relative" style={{ height: `${getTotalSize()}px` }}>
        {getVirtualItems().map(item => (
          <div
            key={item.key}
            ref={measureElement}
            className="absolute left-0 top-0 w-full"
            style={{ transform: `translateY(${item.start}px)` }}
            data-index={item.index}
          >
            <RunnerRow
              item={items[item.index]}
              index={item.index}
              connectionId={connectionId}
              infoMeta={infoMeta}
              expanded={expanded}
              expandedSearched={expandedSearched}
              search={search}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
