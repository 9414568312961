import { Switch } from '@headlessui/react';
import { atom, useAtom } from 'jotai';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, MyInput } from '~/components/form-components';
import { Dialog } from '~/components/v3';
import Divider from '~/components/v2/display/Divider';
import { Icon } from '~/components';
import { useBannerDispatch } from '~/hooks';

export type DevToolsStateAtomType = {
  fieldMappingDrawer: {
    placeholder: string;
  };
  explore: {
    apolloCaching: boolean;
    queryLimit: number;
  };
};

export const DevToolsStateAtom = atom<DevToolsStateAtomType>({
  fieldMappingDrawer: {
    placeholder: 'placeholder'
  },
  explore: {
    apolloCaching: true,
    queryLimit: 100
  }
});

export const DevToolsToggle = (props: {
  checked: boolean;
  onChange: (v: boolean) => void;
  label: string;
}) => {
  return (
    <div className="flex gap-2">
      <Switch
        checked={props.checked}
        onChange={props.onChange}
        className={`${
          props.checked ? 'bg-indigo-600' : 'bg-gray-200'
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span
          className={`${
            props.checked ? 'translate-x-6' : 'translate-x-1'
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
      <p>{props.label}</p>
    </div>
  );
};

export const DevToolsInput = (props: {
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}) => {
  return (
    <div className="flex w-full flex-col gap-3">
      <p>{props.label}</p>
      <MyInput className="w-full" value={props.value} onChange={props.onChange} />
    </div>
  );
};

const DevTools = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [devToolsState, setDevToolsState] = useAtom(DevToolsStateAtom);
  const [errorBannerMessage, setErrorBannerMessage] = useState<string>('');

  const banner = useBannerDispatch();

  if (process.env.NODE_ENV !== 'development') {
    return null;
  }
  return (
    <>
      {/* Toggle Button */}
      <Button onClick={() => setOpen(s => !s)} theme="primary" className="mx-2.5">
        <div className="flex items-center justify-center space-x-2.5 py-3 xl:justify-start">
          <Icon name={'Gear'} className="h-5 w-5" />
          <span className="hidden text-sm font-semibold text-white xl:block">DEV TOOLS</span>
        </div>
      </Button>
      {/* Modal / Config Menu */}
      <Dialog show={open} onDismiss={() => setOpen(false)} heading={'DEV Settings'}>
        <div className="flex flex-col gap-6">
          <Divider children="Error Banner" textAlign="left" />
          <div className="flex w-full flex-row items-end space-x-2">
            <DevToolsInput
              value={errorBannerMessage}
              onChange={e => setErrorBannerMessage(e.target.value)}
              label="Error Message"
            />
            <Button
              theme="primary"
              children="Show"
              onClick={() => banner({ type: 'show', payload: { message: errorBannerMessage } })}
            />
            <Button theme="warning" children="Hide" onClick={() => banner({ type: 'hide' })} />
          </div>
          <Divider children="Explore" textAlign="left" />
          <div className="space-y1-2 flex w-full flex-col">
            <DevToolsToggle
              label={'Explore apollo caching'}
              checked={devToolsState.explore.apolloCaching}
              onChange={() =>
                setDevToolsState(state => ({
                  ...state,
                  explore: { ...state.explore, apolloCaching: !state.explore.apolloCaching }
                }))
              }
            />
            <DevToolsInput
              label={'Explore query limit'}
              value={devToolsState.explore.queryLimit}
              onChange={e => {
                const val = Number(e.target.value);
                if (!isNaN(val)) {
                  setDevToolsState(state => ({
                    ...state,
                    explore: { ...state.explore, queryLimit: Number(e.target.value) }
                  }));
                }
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DevTools;
