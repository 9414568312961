import TooltipIcon from '~/components/tooltip-icon';
import { Icon } from '~/components';
import { IBulkField, SchemaOrFieldKey } from './BulkNamespaceUtil';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import { Row } from '@tanstack/react-table';

interface FieldRowMenuProps {
  row: Row<IBulkField>;
  setNamespaceValue: (path: string, key: SchemaOrFieldKey, value: boolean) => void;
}

export function FieldRowMenu({ row, setNamespaceValue }: FieldRowMenuProps) {
  const field = row.original as IBulkField;

  return (
    <div className="flex flex-row space-x-2 items-start justify-end text-gray-500">
      {!!field.slowMode && (
        <TooltipIcon
          message={field.slowReason || 'Field will be synced non-incrementally'}
          icon={<Icon name="Turtle" size="sm" />}
        />
      )}
      {!!field.obfuscate && (
        <TooltipIcon
          message={`Values will be obfuscated`}
          icon={<Icon name="Astrisk" size="sm" className={'align-top'} />}
        />
      )}

      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className="invisible group-hover/row:visible hover:bg-gray-300 p-[2px] rounded">
            <Icon name="DotsH" size="sm" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" portal={false}>
          <DropdownMenuRadioGroup
            onValueChange={v =>
              setNamespaceValue(row.original.path, 'obfuscate', v === 'obfuscate')
            }
          >
            <DropdownMenuRadioItem value="plaintext" checked={!field.obfuscate}>
              Plain text
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="obfuscate" checked={!!field.obfuscate}>
              Obfuscate
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
