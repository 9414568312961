import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { useMemo } from 'react';

import { TableWrap, Truncator } from '~/components';
import { COLOR } from '~/components/v2/configs/SX';
import Chip from '~/components/v2/display/Chip';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import PageLayout from '~/components/v2/layout/PageLayout';
import { Icon } from '~/components';
import { AllRolesDocument, UsersDocument } from '~/generated/graphql';
import { AclProvider, useAuth, useBannerDispatch } from '~/hooks';
import { ADMIN_ROLE_ID } from '~/utils';
import { AddUser } from '~/pages/team/add-user';
import { UserActions } from '~/pages/team/user-actions';

const wrapperStyles = 'mb-3';

const TeamUsers = () => {
  // hooks
  const { user: currentUser } = useAuth();
  const dispatchBanner = useBannerDispatch();
  // queries
  const { data: rolesData } = useQuery(AllRolesDocument);
  const { data, loading } = useQuery(UsersDocument, {
    onError: error =>
      dispatchBanner({
        type: 'show',
        payload: {
          message: error,
          wrapper: wrapperStyles
        }
      })
  });
  // memoized data
  const roles = useMemo(() => {
    if (!rolesData || !rolesData.allRoles) {
      return [];
    }
    return [...rolesData.allRoles].sort(
      (a, b) => +b.system - +a.system || a.name.localeCompare(b.name)
    );
  }, [rolesData]);
  const users = useMemo(() => {
    if (!data || !data.users) {
      return [];
    }
    return [...data.users].sort((a, b) => a.email.localeCompare(b.email));
  }, [data]);

  return !currentUser ? (
    <LoadingDots />
  ) : (
    <PageLayout topNavHeading="Users" loading={loading} topNavActions={<AddUser />}>
      <TableWrap className="mx-auto w-full max-w-4xl overflow-x-auto">
        <table className="min-w-full table-fixed text-left">
          <thead className="h-11 bg-gray-50">
            <tr>
              <th className="thead-label w-[calc(60%-2.5rem)] border-b border-gray-300 first:rounded-tl">
                Email address
              </th>
              <th className="thead-label w-2/5 border-b border-gray-300 first:rounded-tl">Roles</th>
              <th className="w-10 rounded-tr border-b border-gray-300">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id} className="align-top even:bg-gray-50">
                <td className="inline-flex items-center py-2 px-4">
                  <Icon name="UserCircle" className="mr-2 h-4 w-4 text-gray-500" />
                  <Truncator
                    content={clsx(user.email, currentUser && user.id === currentUser.id && '(You)')}
                  >
                    <p className="truncate">
                      {user.email}
                      <span className="text-gray-500">
                        {currentUser && user.id === currentUser.id ? ' (You)' : ''}
                      </span>
                    </p>
                  </Truncator>
                </td>
                <td className="py-2 px-4">
                  <span className="inline-flex flex-wrap gap-2">
                    {[...user.roles]
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(role => (
                        <Chip
                          key={role.id}
                          color={role.id === ADMIN_ROLE_ID ? COLOR.PRIMARY : COLOR.DEFAULT}
                        >
                          {role.name}
                        </Chip>
                      ))}
                  </span>
                </td>
                <AclProvider value={user.acl}>
                  <td className="py-2 pr-2">
                    <UserActions currentUser={currentUser} user={user} roles={roles} />
                  </td>
                </AclProvider>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrap>
    </PageLayout>
  );
};

export default TeamUsers;
