import { uuid4 } from '@sentry/utils';
import clsx from 'clsx';

import { COLOR, SIZE, TextSize } from '../configs/SX';
import InputLabel from './InputLabel';

export const CheckboxColor = {
  [COLOR.DEFAULT]:
    'text-green-500 bg-white border-gray-500 hover:text-green-400 hover:border-gray-600',
  [COLOR.PRIMARY]:
    'text-indigo-500 bg-white border-gray-500 hover: text-indigo-400 hover:border-gray-600',
  [COLOR.SECONDARY]:
    'text-gray-400 bg-white border-gray-500 hover:text-gray-300 hover:border-gray-600',
  [COLOR.DISABLED]: 'text-gray-300 bg-gray-100 border-gray-300'
};

// Todo - add comments
export type CheckboxProps = {
  variant?: 'indeterminate' | 'determinate';
  // String
  id?: string;
  // Boolean
  indeterminate?: boolean;
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  readonly?: boolean;
  // Socket
  label?: React.ReactNode;
  // F(x)
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  // SX
  color?: keyof typeof CheckboxColor;
  size?: keyof typeof TextSize;
};

const Checkbox = ({
  id,
  variant = 'determinate',
  indeterminate,
  checked,
  disabled,
  readonly = false,
  error,
  label,
  onChange,
  onClick,
  color = COLOR.DEFAULT,
  size = SIZE.SMALL
}: CheckboxProps) => {
  const checkboxColor = disabled ? COLOR.DISABLED : color;
  const ROOT_SX = 'inline-flex flex-row space-x-[0.35rem] items-start';
  const INPUT_SX = clsx(
    'h-4 w-4 border-2 rounded indeterminate-checkbox',
    'focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0',
    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2',
    CheckboxColor[checkboxColor],
    !disabled && 'cursor-pointer',
    variant === 'indeterminate' && 'indeterminate-checkbox'
  );
  const inputId = uuid4();

  const styles = {
    ...(variant === 'indeterminate' &&
      indeterminate && {
        background:
          "url(\"data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='12' height='3' x='2' y='6.5' rx='1'/%3E%3C/svg%3E\")",
        borderColor: 'transparent',
        backgroundColor: 'currentColor',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      })
  };

  return (
    <div className={ROOT_SX} id={id}>
      <input
        type="checkbox"
        id={inputId}
        className={INPUT_SX}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        style={{ ...styles }}
        readOnly={readonly}
      />
      <InputLabel inputId={inputId} size={size} color={checkboxColor} children={label} />
    </div>
  );
};

export default Checkbox;
