import qs from 'query-string';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  BulkSyncPageInfo,
  ExecutionsCondition,
  PageInfo,
  SyncExecutionsQueryVariables
} from '../../../generated/graphql';
import { isSyncHistoryFilter, SyncHistoryFilter, transformFiltersForWhere } from '../../../utils';

interface Props {
  hasNodes: boolean;
  pageInfo: PageInfo | BulkSyncPageInfo | undefined;
  getHistory: (variables: SyncExecutionsQueryVariables, paginate?: boolean) => void;
}

export function useSyncHistory({ hasNodes, pageInfo, getHistory }: Props) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { search } = useLocation();

  const methods = useForm<{ filters: SyncHistoryFilter[] }>();
  const { reset, getValues, watch } = methods;

  const filters = watch('filters');
  const filtersRef = React.useRef<SyncHistoryFilter[]>([]);

  const [where, setWhere] = React.useState<ExecutionsCondition>({});
  const [hasNew, setHasNew] = React.useState<boolean>(false);

  // effect to sync url search params
  React.useEffect(() => {
    const query = qs.parse(search);
    if (!query.filters) {
      reset({ filters: [] });
      setWhere({});
      filtersRef.current = [];
      void getHistory({ syncId: id });
      return;
    }

    const queryFilters: SyncHistoryFilter[] = [];

    if (Array.isArray(query.filters)) {
      query.filters.forEach(item => {
        const filter: unknown = JSON.parse(item || '');
        if (isSyncHistoryFilter(filter)) {
          queryFilters.push(filter);
        }
      });
    } else {
      const filter: unknown = JSON.parse(query.filters);
      if (isSyncHistoryFilter(filter)) {
        queryFilters.push(filter);
      }
    }

    reset({ filters: queryFilters });
    filtersRef.current = queryFilters;
    const parsedWhere = transformFiltersForWhere(queryFilters);
    setWhere(parsedWhere);
    void getHistory({
      syncId: id,
      where: parsedWhere
    });
  }, [getHistory, reset, search, id]);

  // effect to get history if filters are cleared
  // and the filters were a change from before
  React.useEffect(() => {
    if (
      filters &&
      filters.length === 0 &&
      filtersRef.current &&
      JSON.stringify(filtersRef.current) !== JSON.stringify(filters)
    ) {
      history.push({ search: undefined });
      setWhere({});
      filtersRef.current = [];
      void getHistory({ syncId: id });
    }
  }, [filters, getHistory, history, id]);

  React.useLayoutEffect(() => {
    if (!hasNodes && hasNew) {
      setHasNew(false);
      void getHistory({
        syncId: id,
        where: where
      });
    }
  }, [hasNodes, hasNew, getHistory, id, where]);

  const refreshHistory = React.useCallback(() => {
    const filters = getValues('filters');

    if (filters.length === 0) {
      history.push({ search: undefined });
      setWhere({});
      filtersRef.current = [];
      void getHistory({ syncId: id });
      return;
    }

    const query = qs.parse(search);
    const validFilters = filters.filter(
      item =>
        item.column !== undefined &&
        item.column !== '' &&
        item.comparator !== undefined &&
        item.param !== undefined &&
        item.param !== ''
    );
    filtersRef.current = validFilters;
    query.filters = validFilters.map(item => JSON.stringify(item));

    history.push({ search: qs.stringify(query) });

    const updatedWhere = transformFiltersForWhere(filters);
    setWhere(updatedWhere);
    void getHistory({
      syncId: id,
      where: updatedWhere
    });
  }, [getValues, history, search, getHistory, id]);

  const clearFilters = React.useCallback(() => {
    history.push({ search: undefined });
  }, [history]);

  const getNewerHistory = React.useCallback(() => {
    if (!pageInfo) {
      return;
    }
    setHasNew(false);
    void getHistory(
      {
        syncId: id,
        where: where,
        after: null,
        before: pageInfo.startCursor
      },
      true
    );
  }, [getHistory, pageInfo, id, where]);

  return {
    methods,
    filters,
    refreshHistory,
    clearFilters,
    getNewerHistory,
    where,
    hasNew,
    setHasNew,
    isSameFilters:
      filtersRef.current && JSON.stringify(filtersRef.current) === JSON.stringify(filters)
  };
}
