import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Select from '~/components/v2/inputs/Select';
import { SearchWithAction, Tooltip, Truncator } from '../../../components';
import {
  BulkNamespaceOptionFragment,
  BulkSelectedNamespace,
  Term
} from '../../../generated/graphql';
import { BulkSyncForm, fieldTypeIconName, searchByNameAndId } from '../../../utils';
import { StageCard } from '../../syncs/sync-config';
import {
  EBulkEntityType,
  IBulkField,
  IBulkNamespace,
  IBulkSchema,
  SchemaOrFieldKey,
  getEntitiesFromPath,
  getNamespaceState,
  getNamespaceUpdateFromState,
  getNewFields,
  getServerSelection,
  namespacesToSelection
} from './BulkNamespaceUtil';
import { capitalize, flatMap, set, sumBy } from 'lodash';
import { Icon } from '~/components';
import TooltipIcon from '~/components/tooltip-icon';
import { ExpandedState, Row, RowSelectionState, functionalUpdate } from '@tanstack/react-table';
import clsx from 'clsx';
import { DataTable, ColumnDef } from '~/components/v3';
import { SchemaRowMenu } from './SchemaRowMenu';
import { FieldRowMenu } from './FieldRowMenu';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem
} from '~/components/v3/DropdownMenu';
import { useToggle } from '~/hooks';
import { cn } from '~/lib/utils';

type StageBulkObjectProps = {
  namespaceOptions: BulkNamespaceOptionFragment[];
  selectedNamespaces: BulkSelectedNamespace[];
  setSelectedNamespaces: (namespaces: BulkSelectedNamespace[]) => void;
  newNamespaces: BulkSelectedNamespace[];
  step: number;
  bulkSyncSchemaLabel?: Term;
  bulkSyncSchemaHeader?: Term;
  refreshBtn: React.ReactNode;
  loading: boolean;
};
interface FilterOption {
  label: string;
  value: string;
  fn?: (row: Row<IBulkField | IBulkSchema | IBulkNamespace>, search?: string) => boolean;
  placeholder?: string;
}

export function StageBulkObjects({
  namespaceOptions,
  selectedNamespaces,
  setSelectedNamespaces,
  newNamespaces,
  step,
  bulkSyncSchemaLabel = { singular: 'object', plural: 'objects' },
  bulkSyncSchemaHeader,
  refreshBtn,
  loading = false
}: StageBulkObjectProps) {
  const { control } = useFormContext<BulkSyncForm>();
  const source = useWatch({ control, name: 'source' });
  const destination = useWatch({ control, name: 'destination' });

  const [namespaces, setNamespaces] = useState<IBulkNamespace[]>(
    getNamespaceState(namespaceOptions, selectedNamespaces)
  );
  const isNamespaceRoot = useMemo(
    () => namespaces?.length === 1 && !namespaces[0].id,
    [namespaces]
  );

  const [search, setSearch] = useState('');
  const [isSearchDropdownOpen, toggleSearchDropdownOpen] = useToggle();
  const handleResetSearch = useCallback(() => setSearch(''), [setSearch]);
  const searchFilterOptions = useMemo<FilterOption[]>(
    () => [
      {
        label: capitalize(bulkSyncSchemaLabel.plural),
        value: 'objects',
        fn: (row, search) =>
          searchByNameAndId(
            row.original.bulkEntityType === EBulkEntityType.FIELD
              ? row.getParentRow().original
              : row.original,
            search
          ),
        placeholder: `Search all ${bulkSyncSchemaLabel.plural}...`
      },
      {
        label: 'Fields',
        value: 'fields',
        fn: (row, search) =>
          row.original.bulkEntityType === 'FIELD' ? searchByNameAndId(row.original, search) : false,
        placeholder: 'Seach all fields...'
      }
    ],
    [search, bulkSyncSchemaLabel]
  );
  const [searchFilter, setSearchFilter] = useState<FilterOption>(searchFilterOptions[0]);

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    namespacesToSelection(selectedNamespaces)
  );

  const visibilityFilterOptions = useMemo<FilterOption[]>(() => {
    const filters = [
      { label: 'All objects', value: 'all', fn: () => true },
      {
        label: 'Selected objects',
        value: 'selected',
        fn: row => {
          const { namespace, schema } = getEntitiesFromPath(namespaces, row.original.path);
          if (schema) {
            return schema.fields.some(field => rowSelection[field.path]);
          } else if (namespace) {
            return namespace.schemas.some(schema =>
              schema.fields.some(field => rowSelection[field.path])
            );
          }
          return true;
        }
      }
    ];
    if (destination?.properties?.supportsPartitions) {
      filters.push({
        label: 'Partition keys',
        value: 'partitionKey',
        fn: row => !!(row.original as IBulkSchema).partitionKey
      });
    }
    if (source?.properties?.supportsIncremental) {
      filters.push(
        ...[
          {
            label: 'Incremental sync fields',
            value: 'incremental',
            fn: row => !(row.original as IBulkField).slowMode
          },
          {
            label: 'Non-incremental sync fields',
            value: 'nonIncremental',
            fn: row => !!(row.original as IBulkField).slowMode
          }
        ]
      );
    }
    if (source.connection.type.id === 'salesforce') {
      filters.push({
        label: 'Formula fields',
        value: 'formulaFields',
        fn: row => !!(row.original as IBulkField).isCalculated
      });
    }
    filters.push({
      label: 'Obfuscated columns',
      value: 'obfuscate',
      fn: row => !!(row.original as IBulkField).obfuscate
    });

    return filters;
  }, [namespaces, rowSelection, source, destination]);

  const [visibilityFilter, setVisibilityFilter] = useState<FilterOption>(
    visibilityFilterOptions[0]
  );

  const counts = useMemo(() => {
    const allSchemas = flatMap(namespaces, ({ schemas }) => schemas);
    const allFields = flatMap(allSchemas, ({ fields }) => fields);
    const totalPartitionKeys =
      sumBy(
        allSchemas,
        schema => schema.partitionKey && schema.fields.some(field => rowSelection[field.path]) && 1
      ) || 0;
    const totalNonIncremental =
      sumBy(
        allSchemas,
        schema => schema.fields?.some(field => rowSelection[field.path] && field.slowMode) && 1
      ) || 0;
    const totalObfuscated =
      sumBy(allFields, field => rowSelection[field.path] && field.obfuscate && 1) || 0;
    const totalOptions = flatMap(namespaceOptions, namespace => namespace.schemas).length;
    const totalSelections = flatMap(selectedNamespaces, namespace => namespace.schemas).length;

    return {
      allFields,
      totalPartitionKeys,
      totalNonIncremental,
      totalObfuscated,
      totalOptions,
      totalSelections
    };
  }, [namespaces, namespaceOptions, selectedNamespaces, rowSelection]);

  // When refreshing the schema, detect any new fields and update local state
  // New fields will be consumed once the server selection has updated
  const [newServerFields, setNewServerFields] = useState<string[]>();
  useEffect(() => {
    const newFields = getNewFields(namespaces, namespaceOptions);
    setNewServerFields(newFields);
    setNamespaces(getNamespaceState(namespaceOptions, selectedNamespaces, namespaces));
  }, [namespaceOptions]);

  // When the server selection is updated, add new selected fields to local state,
  // then update the parent form state with the updated selection
  useEffect(() => {
    const serverSelection = getServerSelection(newNamespaces, newServerFields);
    const newRowSelection = { ...rowSelection, ...serverSelection };
    setRowSelection(newRowSelection);
    updateSelectedNamespaces(
      getNamespaceState(namespaceOptions, newNamespaces, namespaces),
      newRowSelection
    );
  }, [newNamespaces]);

  const updateSelectedNamespaces = (namespaces: IBulkNamespace[], selection: RowSelectionState) => {
    const newNamespaces = getNamespaceUpdateFromState(namespaces, selection);
    setSelectedNamespaces(newNamespaces);
  };

  const setNamespaceValue = (path: string, key: SchemaOrFieldKey, value: unknown) => {
    const newNamespaces = [...namespaces];
    const { schema, field } = getEntitiesFromPath(newNamespaces, path);
    if (field) {
      set(field, key, value);
    } else {
      set(schema, key, value);
    }
    setNamespaces(newNamespaces);
    updateSelectedNamespaces(newNamespaces, rowSelection);
  };

  const handleRowSelectionChange = (selection: RowSelectionState) => {
    setRowSelection(selection);
    updateSelectedNamespaces(namespaces, selection);
  };

  const columns = useMemo<ColumnDef<IBulkField | IBulkSchema | IBulkNamespace>[]>(
    () => [
      {
        id: 'schema',
        accessorKey: 'schema',
        header: () => (
          <span>{`${bulkSyncSchemaHeader?.plural || bulkSyncSchemaLabel?.plural || 'name'}`}</span>
        ),
        accessorFn: row => row.id,
        cell: ({ row }) => (
          <Truncator content={row.original.name}>
            <p
              className={clsx(
                'hide-native-tooltip cursor-default truncate text-gray-800',
                row.original.bulkEntityType !== 'FIELD' && 'font-medium'
              )}
            >
              {row.original.name}
            </p>
          </Truncator>
        ),
        size: 125,
        sortDescFirst: false,
        enableGlobalFilter: true
      },
      {
        id: 'type',
        accessorKey: 'type',
        header: () => null,
        cell: ({ row }) => {
          if (row.original.bulkEntityType === 'SCHEMA') {
            const schema = row.original as IBulkSchema;
            const trackingField = schema.fields?.find(field => field.id === schema.trackingField);
            return (
              !!trackingField && (
                <span className="text-gray-500">Tracking field: {trackingField.name}</span>
              )
            );
          }

          if (row.original.bulkEntityType === 'FIELD') {
            const field = row.original as IBulkField;
            return (
              <span className="flex flex-row content-center items-center space-x-1 text-gray-500">
                <Icon name={fieldTypeIconName(field.type)} />
                <Tooltip disabled={!field.isCalculated} content="Formula field">
                  <p className="hide-native-tooltip cursor-default truncate text-left text-gray-500">
                    {field.type}
                  </p>
                </Tooltip>
              </span>
            );
          }

          return null;
        },
        enableGlobalFilter: false,
        size: 75
      },
      {
        id: 'systemName',
        accessorKey: 'id',
        header: () => <span>System name</span>,
        cell: ({ row }) => (
          <Truncator content={row.original?.id}>
            <p className="hide-native-tooltip cursor-default truncate text-left text-gray-800">
              {row.original?.id}
            </p>
          </Truncator>
        ),
        isVisible: !source?.properties?.hideSystemName,
        size: 100
      },
      {
        id: 'actions',
        accessorKey: 'actions',
        header: () => (
          <div className="flex flex-row content-center items-center justify-end space-x-2 text-gray-500 opacity-50">
            {source?.properties?.supportsIncremental && (
              <TooltipIcon
                message={`${counts.totalNonIncremental} ${
                  counts.totalNonIncremental === 1 ? 'object' : 'objects'
                } will be synced non-incrementally`}
                icon={<Icon name="Turtle" size="sm" />}
              />
            )}
            {destination?.properties?.supportsPartitions && (
              <TooltipIcon
                message={`${counts.totalPartitionKeys} tables with partition keys`}
                icon={<Icon name="PartitionKey" size="sm" />}
              />
            )}
            <TooltipIcon
              message={`${counts.totalObfuscated} ${
                (bulkSyncSchemaLabel?.singular || 'object') === 'table' ? 'column' : 'field'
              }${counts.totalObfuscated === 1 ? '' : 's'} obfuscated`}
              icon={<Icon name="Astrisk" size="sm" />}
            />
            <div className="h-5 w-5"></div>
          </div>
        ),
        cell: ({ row }) => {
          if (row.original.bulkEntityType === 'SCHEMA') {
            return (
              <SchemaRowMenu
                row={row as Row<IBulkSchema>}
                setNamespaceValue={setNamespaceValue}
                supportsPartitionKeys={destination?.properties?.supportsPartitions}
                supportsTrackingFields={source?.properties?.supportsTrackingFields}
              />
            );
          }
          if (row.original.bulkEntityType === 'FIELD') {
            return (
              <FieldRowMenu row={row as Row<IBulkField>} setNamespaceValue={setNamespaceValue} />
            );
          }
          return null;
        },
        size: 75
      }
    ],
    [counts, source, destination, bulkSyncSchemaHeader, bulkSyncSchemaLabel]
  );

  return (
    <StageCard
      hasStickyHeader={true}
      header={`Choose ${source?.connection?.name || 'source'} ${
        bulkSyncSchemaLabel?.plural || 'objects'
      }`}
      step={step}
    >
      <div className="space-y-6 px-6">
        <div className="flex items-center justify-between">
          <SearchWithAction
            debounce
            onChange={setSearch}
            onReset={handleResetSearch}
            stopEscHotKey={false}
            wrapperStyles="w-1/2 overflow-hidden"
            placeholder={searchFilter.placeholder}
            endAction={
              <DropdownMenu open={isSearchDropdownOpen} onOpenChange={toggleSearchDropdownOpen}>
                <DropdownMenuTrigger asChild>
                  <button
                    className={cn(
                      'flex h-8 max-w-[6.625rem] items-center justify-between rounded-l-full border-r border-gray-300 p-1 focus:outline-none',
                      isSearchDropdownOpen
                        ? 'bg-gray-200'
                        : 'bg-gray-100 hover:bg-gray-200 active:bg-gray-200'
                    )}
                  >
                    <p className="ml-2 max-w-[6rem] overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium">
                      {searchFilter.label}
                    </p>
                    <span className="ml-1 mr-0.5">
                      <Icon
                        name="SelectSingle"
                        className={cn(
                          'h-5 w-5 transform text-gray-500',
                          isSearchDropdownOpen && 'rotate-180'
                        )}
                      />
                    </span>
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="end"
                  portal={false}
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {searchFilterOptions.map(option => (
                    <DropdownMenuItem
                      key={option.value}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        toggleSearchDropdownOpen();
                        setSearchFilter(searchFilterOptions.find(o => option.value === o.value));
                      }}
                    >
                      {option.label}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            }
          />
          {refreshBtn}
        </div>
        <div className="h-[400px] overflow-hidden">
          <DataTable
            data={isNamespaceRoot ? namespaces[0].schemas : namespaces}
            columns={columns}
            loading={loading}
            // Expansion
            expanded={expanded}
            onExpandedChange={setExpanded}
            getRowId={row => row.path}
            getSubRows={row => (row as IBulkNamespace).schemas ?? (row as IBulkSchema).fields}
            // Filtering
            globalFilter={row => visibilityFilter.fn(row) && searchFilter.fn(row, search)}
            onGlobalFilterChange={() => {}}
            // Selection
            rowSelection={rowSelection}
            onRowSelectionChange={updaterFunction =>
              handleRowSelectionChange(functionalUpdate(updaterFunction, rowSelection))
            }
            enableSorting={false}
            // Options
            emptyMessage={`No ${bulkSyncSchemaLabel?.plural || 'objects'} ${
              visibilityFilter.value === 'selected' ? 'selected' : 'detected'
            }`}
            slots={{ wrapper: 'h-full' }}
            maxLeafRowFilterDepth={2}
            showLoadingWhenRowsExist={true}
          />
        </div>

        <div className="flex items-center justify-between">
          {!loading && (
            <div className="flex flex-row items-center justify-start space-x-3">
              <Select
                variant="filled"
                options={visibilityFilterOptions}
                onChange={v => setVisibilityFilter(v)}
                value={visibilityFilter}
                inputWidth="w-[26ch]"
                portal={false}
              />
              <p className="text-gray-600">{`${counts.totalSelections} out of ${
                counts.totalOptions
              } ${bulkSyncSchemaLabel?.plural || 'objects'} selected`}</p>
            </div>
          )}
        </div>
      </div>
    </StageCard>
  );
}
