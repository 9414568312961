import { useQuery } from '@apollo/client';
import { ColumnDef } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Button,
  Checkbox,
  Icon,
  Search,
  Section,
  SideBySide,
  Truncator
} from '../../../components';
import { Tooltip } from '../../../components';
import Card from '../../../components/v2/display/Card';
import Table from '../../../components/v2/table/Table';
import {
  DataliteConfiguration,
  DataliteSchemaConfiguration,
  DataliteSchemaMetadataFragment,
  DataliteSchemasMetadataDocument
} from '../../../generated/graphql';
import { useFieldsetState, useModelQueryRef, usePreventSave } from '../../../hooks';
import { emptyCell, FieldsetFormValues, getShortLocalTime, hasItems } from '../../../utils';
import {
  AdditionalConfig,
  FieldsTable,
  FieldsTableWrapper,
  ModelQueryEditor
} from '../model-components';

export const DataliteModelConfig = () => {
  const fieldset = useFieldsetState();
  const { control, setValue } = useFormContext<FieldsetFormValues>();
  const { update: setPreventSave } = usePreventSave();

  // Schemas Table
  const [filter, setFilter] = useState('');
  const schemas = useWatch({ control, name: 'configuration.schemas' });
  const [refreshLoading, setRefreshLoading] = useState(false);
  const { refetch, data, loading } = useQuery(DataliteSchemasMetadataDocument, {
    fetchPolicy: 'network-only'
  });

  // Sql Query
  const defaultDoc = (fieldset?.configuration as DataliteConfiguration)?.query;
  const queryRef = useModelQueryRef('query', defaultDoc);
  const queryUpdateObj = (
    conf: DataliteConfiguration | undefined
  ): { configuration: DataliteConfiguration } => ({
    configuration: {
      query: conf?.query || '',
      schemas: conf?.schemas || []
    }
  });

  // vars
  const schemaCount = useMemo(() => schemas?.length || 0, [schemas]);
  const handleRefresh = async () => {
    setRefreshLoading(true);
    await refetch();
    setRefreshLoading(false);
  };
  const handleSelectItem = (schema: DataliteSchemaMetadataFragment | null) => {
    const found =
      schemas.findIndex(
        v => v.schemaID === schema?.schemaID && v.connectionID === schema?.connectionID
      ) !== -1;
    let alias = schema?.schemaID;
    if (!found) {
      let candidateAlias = schema?.schemaID;
      let counter = 2;
      while (
        schemas.findIndex(v => v.alias.toLowerCase() === candidateAlias.toLowerCase()) !== -1
      ) {
        candidateAlias = schema.schemaID + '_' + counter;
        counter += 1;
      }
      alias = candidateAlias;
    }
    const updatedSchemas = found
      ? schemas?.filter(
          s => !(s.schemaID === schema?.schemaID && s.connectionID === schema?.connectionID)
        )
      : schemas?.concat([
          {
            schemaID: schema?.schemaID,
            alias: alias,
            connectionID: schema?.connectionID,
            connectionName: schema?.connectionName,
            connectionType: schema?.connectionType
          } as DataliteSchemaConfiguration
        ]);
    setPreventSave(true);
    setValue('configuration.schemas', updatedSchemas, { shouldValidate: true, shouldDirty: true });
  };
  const handleSearchInput = useCallback(
    (value: string | undefined) => setFilter(value?.trim() || ''),
    [setFilter]
  );
  const handleResetSearchInput = useCallback(() => setFilter(''), [setFilter]);

  const filteredData = useMemo(() => {
    if (!filter) return data?.dataliteMetadata || [];
    return data?.dataliteMetadata?.filter(v =>
      v?.connectionName.toLowerCase().includes(filter.toLowerCase())
    );
  }, [data, filter]);

  const cols: ColumnDef<DataliteSchemaMetadataFragment | null>[] = [
    {
      accessorKey: 'connectionID',
      header: 'Connection',
      cell: ({ row }) => (
        <div className="flex items-center gap-2 overflow-clip">
          <Checkbox
            checked={
              schemas?.findIndex(
                v =>
                  v.connectionID === row?.original?.connectionID &&
                  v.schemaID === row?.original?.schemaID
              ) !== -1
            }
            onChange={e => handleSelectItem(row?.original)}
          />
          <div className="flex items-center gap-1 overflow-clip">
            <Icon match={row?.original?.connectionType} />
            <Truncator content={row?.original?.connectionName}>
              <p className="hide-native-tooltip cursor-default truncate text-gray-800">
                {row?.original?.connectionName}
              </p>
            </Truncator>
          </div>
        </div>
      )
    },
    { accessorKey: 'schemaID', header: 'Object name' },
    {
      accessorKey: 'alias',
      header: 'SQL Table Name',
      cell: ({ row }) => (
        <p>
          {
            schemas?.find(
              v =>
                v.connectionID === row?.original?.connectionID &&
                v.schemaID === row?.original?.schemaID
            )?.alias
          }
        </p>
      )
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => {
        const itemIndex = data?.dataliteMetadata?.findIndex(
          v =>
            v.connectionID === row?.original?.connectionID && v.schemaID === row?.original?.schemaID
        );
        if (itemIndex !== -1) {
          const item = data?.dataliteMetadata[itemIndex];
          if (item?.initialized) {
            return (
              <Tooltip
                placement="left"
                content={`Last updated: ${getShortLocalTime(item?.lastUpdated)}`}
              >
                <div>
                  <Icon name="CheckFilled" className="h-4 w-4 text-green-500" />
                </div>
              </Tooltip>
            );
          } else {
            return <span className="pointer-events-none text-gray-400">Fetching...</span>;
          }
        } else {
          return emptyCell;
        }
      }
    }
  ];

  useEffect(() => {
    if (schemaCount == 0) {
      setPreventSave(true);
    }
  }, [schemaCount, setPreventSave]);

  return (
    <>
      <Section className="space-y-6">
        {/* Heading */}
        <SideBySide heading="Build model using" />
        {/* Objects Table */}
        <Card
          headerTitle="Select objects"
          headerSubtitle="At least one object must be selected to use in query"
          footerTitle={`${schemas?.length} of ${
            data?.dataliteMetadata?.length || 0
          } objects selected`}
        >
          <div className="mt-2 mb-4 flex items-center justify-between">
            <Search
              debounce
              onChange={handleSearchInput}
              onReset={handleResetSearchInput}
              stopEscHotKey={false}
              placeholder={'Search objects...'}
              className="h-6"
            />
            <Button
              size="mini"
              iconEnd="Refresh"
              onClick={handleRefresh}
              loading={refreshLoading}
              children="Refresh"
            />
          </div>
          <Table
            data={filteredData}
            columns={cols}
            loading={loading || refreshLoading}
            alternatingRowColors={true}
            TableWrapperProps={{
              className:
                'max-h-[26.5rem] flex w-full rounded border border-gray-300 overflow-y-auto overflow-x-clip shadow-card'
            }}
            TableProps={{
              className: 'table-fixed text-left w-full border-separate border-spacing-0'
            }}
            TableHeaderColProps={{
              className: 'thead-label border-b border-gray-300 first:rounded-tl last:rounded-tr'
            }}
            TableHeaderRowProps={{
              className: 'shadow-card w-full'
            }}
            TableBodyColProps={{
              className: 'truncate divide-y divide-gray-300'
            }}
          />
        </Card>
        {/* Sql Query */}
        <ModelQueryEditor
          key={fieldset.connection.id}
          heading="SQL query"
          path="configuration.query"
          updateObj={queryUpdateObj}
          defaultDoc={defaultDoc}
          queryRef={queryRef}
          hidden={!schemas || !schemas.length}
        />
        <FieldsTableWrapper
          hasFields={hasItems(fieldset?.fields)}
          loading={loading}
          refresh={undefined}
          hasWriteinFields={fieldset?.properties.writeinFields}
        >
          <FieldsTable
            fields={fieldset?.fields}
            hasWriteinFields={fieldset?.properties.writeinFields}
          />
        </FieldsTableWrapper>
      </Section>
      <AdditionalConfig />
    </>
  );
};
