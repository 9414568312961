import { useLazyQuery } from '@apollo/client';

import { Button, Permission } from '../../components';
import { IdentityManagementPortalLinkDocument, Action } from '../../generated/graphql';
import { useBannerDispatch, useAuth, AclProvider } from '../../hooks';

export function SamlSsoPortal() {
  const { user } = useAuth();

  const dispatchBanner = useBannerDispatch();
  const [getIdentityManagement, { loading }] = useLazyQuery(IdentityManagementPortalLinkDocument, {
    onCompleted: data => {
      if (!data || !data.identityManagementPortalLink) {
        return;
      }
      window.location.href = data.identityManagementPortalLink;
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } })
  });

  function getLink() {
    void getIdentityManagement();
  }

  return (
    <AclProvider value={user.organization.workos?.acl}>
      <Permission type={Action.Edit}>
        <Button loading={loading} iconEnd="Export" onClick={getLink}>
          View configuration
        </Button>
      </Permission>
    </AclProvider>
  );
}
