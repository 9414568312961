import cx from 'clsx';
import * as React from 'react';

import styles from './EditableField.module.css';

export interface EditableFieldProps {
  type: 'default' | 'expandableHeight' | 'tableCell';
  value?: string | null;
  optional?: boolean;
  placeholder?: string;
  onSet?: (newName: string) => void;
  className?: string;
  readOnly?: boolean;
}

export const EditableField = (props: EditableFieldProps) => {
  const [text, setText] = React.useState(props.value);

  React.useEffect(() => {
    setText(props.value);
  }, [props.value]);

  let prevText = props.value;
  let isEscapedBlur = false;
  const [validationError, setIsValidationError] = React.useState(false);
  const inputRef = React.createRef<HTMLDivElement>();

  function validate() {
    const inputLength = inputRef.current!.innerText.trim().length;
    let isValid = true;
    if (!props.optional) {
      isValid = inputLength > 0;
    }

    return isValid;
  }

  function handleBlur() {
    if (isEscapedBlur) {
      setIsValidationError(false);
      setText(prevText);
      inputRef.current!.innerText = prevText ? prevText : '';
      isEscapedBlur = false;
    } else {
      if (!validate()) {
        setIsValidationError(true);
        inputRef.current!.focus();
      } else {
        setIsValidationError(false);
        const enteredText = inputRef.current!.innerText.trim();
        setText(enteredText);
        if (props.onSet) {
          props.onSet(enteredText);
        }
      }
    }
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key.length === 1) {
      setIsValidationError(false);
    }
    if (e.key === 'Escape') {
      isEscapedBlur = true;
      inputRef.current!.blur();
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (!validate()) {
        setIsValidationError(true);
      } else {
        inputRef.current!.blur();
      }
    }
  }

  function handleFocus() {
    prevText = text;
  }

  const sharedStyles = 'min-h-[2rem] text-sm py-1.25 border';
  const inputStyles = 'px-3 bg-white focus:shadow-none';
  const readOnlyStyles = props.readOnly ? 'border-gray-300' : 'border-gray-400 shadow-input';

  let typeClassName;
  switch (props.type) {
    case 'default':
      typeClassName = cx(
        'whitespace-nowrap overflow-auto',
        inputStyles,
        sharedStyles,
        readOnlyStyles
      );
      break;
    case 'expandableHeight':
      typeClassName = cx('whitespace-normal', inputStyles, sharedStyles, readOnlyStyles);
      break;
    case 'tableCell':
      typeClassName = cx('px-1 border-gray-200 whitespace-normal overflow-auto', sharedStyles);
      break;
  }

  return (
    <div
      contentEditable={props.readOnly ? false : true}
      suppressContentEditableWarning={true}
      ref={inputRef}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      spellCheck={false}
      placeholder={props.placeholder}
      style={styles}
      className={cx(
        'border-box cursor-text rounded focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500',

        typeClassName,
        props.className,
        validationError && 'border-red-500 outline-none ring-1 ring-red-500'
      )}
    >
      {text}
    </div>
  );
};
