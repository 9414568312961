import _ from 'lodash';
import * as React from 'react';

import { Button, Search } from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { Icon } from '~/components';
import { useAuth } from '~/hooks';
import {
  handleContinueUrl,
  hasItems,
  selectIdentity,
  SelectableUser,
  UserAccountsRepsonse
} from '~/utils';
import { LoginWrapper } from '~/pages/login/login-wrapper';
import { useQueryPersistance } from '~/hooks/use-query-persistance';
import { useHistory } from 'react-router-dom';

const wrapperStyles = 'grid justify-center max-h-52 gap-4 -mt-0.5 py-1 pb-2 overflow-y-auto';

export function IdentityPicker() {
  const { refresh } = useAuth();
  const history = useHistory();

  const [accounts, setAccounts] = React.useState<SelectableUser[]>([]);
  const [supportable, setSupportable] = React.useState<SelectableUser[]>([]);
  const [query, setQuery] = React.useState<string>();

  const [error, setError] = React.useState('');
  const [noIdentity, setNoIdentity] = React.useState(false);
  const [identityLoading, setIdentityLoading] = React.useState(false);

  const { clearAllItemsFromStorage } = useQueryPersistance();

  const handleIdentity = React.useCallback(async () => {
    if (!refresh) {
      return;
    }
    const query = new URLSearchParams(window.location.search);
    const isLogin = !!query.get('login');
    const continueUrl = handleContinueUrl();

    await refresh();

    if (isLogin && continueUrl?.referrer) {
      history.replace({
        pathname: continueUrl.referrer,
        search: continueUrl.search
      });
    }
  }, [refresh]);

  function handleSelection(identity: string) {
    clearAllItemsFromStorage();
    setIdentityLoading(true);
    selectIdentity(identity, handleIdentity).catch(error => setError(error as string));
  }

  React.useEffect(() => {
    const controller = new AbortController();

    if (!controller.signal.aborted) {
      fetch(`${import.meta.env.VITE_API_URL || ''}/auth/identity`, {
        signal: controller.signal,
        method: 'GET',
        cache: 'no-cache',
        redirect: 'manual',
        credentials: 'include',
        referrerPolicy: 'no-referrer'
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          }
          setNoIdentity(true);
        })
        .then(data => {
          if (!data) {
            setNoIdentity(true);
            return;
          }
          const identities = data as UserAccountsRepsonse;
          const { memberOf, supportOrgs = [] } = identities;
          if (identities.memberOf.length === 0) {
            setNoIdentity(true);
          } else if (memberOf.length === 1 && supportOrgs.length === 0) {
            selectIdentity(memberOf[0].qualifiedUser, handleIdentity).catch(error => {
              if (typeof error === 'string') {
                setError(error);
              }
              setError(JSON.stringify(error, null, 2));
            });
          } else {
            setAccounts(_.sortBy(memberOf, 'orgName'));
            setSupportable(_.sortBy(supportOrgs, 'orgName'));
          }
        })
        .catch(error => {
          if (!controller.signal.aborted) {
            if (typeof error === 'string') {
              setError(error);
            }
            setError(JSON.stringify(error, null, 2));
          }
        });
    }

    return () => {
      controller.abort();
    };
  }, [handleIdentity]);

  if (noIdentity) {
    return (
      <LoginWrapper error={error}>
        <div className={wrapperStyles}>
          <div className="z-20 flex flex-col items-center rounded-md border border-gray-300 bg-gray-100 px-4 pt-3 pb-4">
            <Icon name="DangerFilled" className="mb-1 h-8 w-8 text-orange-500" />
            <p>Your email does not belong to an organization.</p>
            <p>Please ask an admin to add you.</p>
            <p className="mt-1">
              <a href={`${import.meta.env.VITE_API_URL || ''}/logout`} className="link">
                ← Back to sign in
              </a>
            </p>
          </div>
        </div>
      </LoginWrapper>
    );
  }

  const filteredSupportable = supportable.filter(item =>
    query ? (item.orgName.toLocaleLowerCase().indexOf(query) > -1 || item.qualifiedUser.toLocaleLowerCase().startsWith(query, 0)) : item
  );

  return (
    <LoginWrapper error={error}>
      {hasItems(accounts) && (
        <p>
          <a
            href={`${import.meta.env.VITE_API_URL || ''}/logout`}
            className="link absolute left-2 top-1.5"
          >
            ← Back to sign in
          </a>
        </p>
      )}
      <div className={wrapperStyles}>
        {accounts.length === 0 && (
          <div className="flex justify-center">
            <LoadingDots />
          </div>
        )}
        {accounts.map(({ qualifiedUser, orgName }) => (
          <Button
            className="justify-self-center"
            key={qualifiedUser}
            disabled={identityLoading}
            onClick={() => handleSelection(qualifiedUser)}
          >
            {orgName}
          </Button>
        ))}
        {supportable.length > 0 && (
          <>
            <h2>Support-Enabled Organizations</h2>
            <Search
              placeholder="Search organizations"
              wrapperStyles="px-1 w-56 justify-self-center"
              onChange={e => setQuery(e.toLocaleLowerCase())}
              onReset={() => setQuery(undefined)}
            />
            {filteredSupportable.length === 0 && <p>No organizations match your search</p>}
            {filteredSupportable.map(({ qualifiedUser, orgName }) => (
              <Button
                className="justify-self-center"
                key={qualifiedUser}
                disabled={identityLoading}
                onClick={() => handleSelection(qualifiedUser)}
              >
                {orgName}
              </Button>
            ))}
          </>
        )}
      </div>
    </LoginWrapper>
  );
}
