import { Controller, UseFormReturn, useWatch } from 'react-hook-form';

import { MyCombobox, MyInput } from '../../../components';
import {
  capsFirst,
  flatToSelectable,
  objToSelectables,
  Selectable,
  SyncHistoryFilter
} from '../../../utils';
import { DateRangePicker } from '~/components/v3';

const timeParams: Record<string, string> = {
  '86400': '24 hours',
  '172800': '48 hours',
  '604800': '7 days',
  '1209600': '14 days'
};

const statusParams: string[] = ['running', 'completed', 'completed with errors', 'failed'];
const triggerParams: string[] = ['manual', 'scheduled'];

interface Props {
  methods: UseFormReturn<{ filters: SyncHistoryFilter[] }>;
  filter: SyncHistoryFilter;
  index: number;
  params: Record<string, string[]>;
}

export function HistoryFilterParams(props: Props) {
  const { control } = props.methods;
  const column = useWatch({
    control,
    name: `filters.${props.index}.column`
  });

  const comparator = useWatch({
    control,
    name: `filters.${props.index}.comparator`
  });

  return (
    <Controller
      name={`filters.${props.index}.param`}
      control={control}
      defaultValue={props.filter.param}
      render={({ field }) => {
        switch (column) {
          case 'time': {
            switch (comparator) {
              case 'eq': {
                const value = field.value
                  ? ({
                      label: timeParams[field.value],
                      value: field.value
                    } as Selectable)
                  : null;
                const options = objToSelectables(timeParams, true);
                return (
                  <MyCombobox
                    isDisabled={!column || !comparator}
                    placeholder="Select value..."
                    value={value}
                    options={options}
                    onChange={option => {
                      field.onChange(option);
                      props.methods.setValue(`filters.${props.index}`, {
                        column,
                        comparator,
                        param: option?.value
                      });
                    }}
                  />
                );
              }
              case 'between': {
                const getRange = (value?: string) => {
                  if (value) {
                    const [from, to] = value.split('@').map(item => new Date(item));
                    return { from, to };
                  }
                };

                return (
                  <DateRangePicker
                    placeholder="Select a date range..."
                    range={getRange(field.value)}
                    onSelect={range => {
                      if (range && range.to) {
                        const value = `${range.from.toISOString()}@${range.to.toISOString()}`;
                        field.onChange(value);
                        props.methods.setValue(`filters.${props.index}`, {
                          column,
                          comparator,
                          param: value
                        });
                      }
                    }}
                  />
                );
              }
            }
          }
          case 'trigger':
          case 'status':
            return (
              <MyCombobox
                isDisabled={!column || !comparator}
                placeholder="Select value..."
                value={
                  field.value
                    ? ({
                        label: capsFirst(field.value),
                        value: field.value
                      } as Selectable)
                    : null
                }
                options={(column === 'trigger' ? triggerParams : statusParams)
                  .map(flatToSelectable)
                  .map(item => ({
                    ...item,
                    label: capsFirst(item.label)
                  }))}
                onChange={option => {
                  field.onChange(option);
                  props.methods.setValue(`filters.${props.index}`, {
                    column,
                    comparator,
                    param: option?.value
                  });
                }}
              />
            );
          case 'schema':
            return (
              <MyCombobox
                isDisabled={!column || !comparator}
                placeholder="Select value..."
                value={
                  field.value
                    ? ({
                        label: field.value,
                        value: field.value
                      } as Selectable)
                    : null
                }
                options={(props.params[column] || []).map(value => ({
                  label: value,
                  value
                }))}
                onChange={option => {
                  field.onChange(option);
                  props.methods.setValue(`filters.${props.index}`, {
                    column,
                    comparator,
                    param: option?.value
                  });
                }}
              />
            );
          default:
            return (
              <MyInput
                type={comparator === 'neq' ? 'hidden' : 'text'}
                name={field.name}
                value={field.value || ''}
                onChange={field.onChange}
                placeholder={!column || !comparator ? '' : 'Enter number...'}
                disabled={!column || !comparator}
                readOnly={comparator === 'neq'}
              />
            );
        }
      }}
    />
  );
}
