import cx from 'clsx';
import qs from 'query-string';
import * as React from 'react';
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom';
import { FrequencyOptions, Icon } from '~/components';

import { Tooltip, Truncator } from '../../../components';
import { BulkExecutionStatus, BulkSyncIndexFragment } from '../../../generated/graphql';
import { useMountEffect } from '../../../hooks';
import { addLineBreaks, capsFirst, getShortLocalTime, plural, routes } from '../../../utils';

const sharedStyles =
  'min-h-[3.75rem] flex px-4 border-t border-gray-300 group-hover:bg-indigo-50 h-full';

const logoStyles = 'w-5 h-5';
const iconStyles = 'w-5 h-5 text-gray-600';

export const BulkSyncRow = React.memo<{
  bulkSync: BulkSyncIndexFragment;
}>(({ bulkSync }) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { search } = useLocation();

  const health = bulkSync.destination?.connection?.health.status === 'healthy' || true;

  const amendHistory = React.useCallback(() => {
    history.replace({ search: `id=${bulkSync.id}` });
  }, [bulkSync.id, history]);

  useMountEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    const parsed = qs.parse(search);
    if (parsed.id === bulkSync.id) {
      history.replace({ search: undefined });
      scrollRef.current.scrollIntoView({ block: 'center' });
    }
  });

  return (
    <div className="group contents">
      <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
        <div ref={scrollRef} className={cx('flex-col justify-center', sharedStyles)}>
          <span className="text-sm font-medium text-gray-800">{addLineBreaks(bulkSync.name)}</span>
          <span className="mt-0.5 text-xs text-gray-500">
            Last run: {getShortLocalTime(bulkSync.execution?.startedAt) || 'Never'}
          </span>
        </div>
      </BulkSyncLink>
      {!health ? (
        bulkSync.destination?.connection?.id && (
          <RowSyncIconsWrapper id={bulkSync.source?.connection?.type?.id}>
            <Link
              to={generatePath(routes.editConnection, {
                id: bulkSync.destination.connection.id
              })}
              onClick={amendHistory}
              className="mx-2 break-words text-red-500 underline hover:no-underline"
            >
              Unhealthy connection
            </Link>
          </RowSyncIconsWrapper>
        )
      ) : (
        <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
          <RowSyncIconsWrapper id={bulkSync.source?.connection?.type?.id}>
            <Icon name="Syncs" className={cx('mx-2', iconStyles)} />
            {bulkSync.destination?.connection?.type.id && (
              <Icon
                match={bulkSync.destination?.connection.type.id}
                className={cx('mr-1.5', logoStyles)}
              />
            )}
            <Truncator
              content={
                bulkSync.destination?.connection
                  ? bulkSync.destination?.connection?.name
                  : 'Missing target object'
              }
            >
              <p
                className={cx(
                  'truncate whitespace-nowrap text-sm',
                  bulkSync.destination?.connection ? 'text-gray-800' : 'text-red-500'
                )}
              >
                {bulkSync.destination?.connection
                  ? bulkSync.destination?.connection?.name
                  : 'Missing target object'}
              </p>
            </Truncator>
          </RowSyncIconsWrapper>
        </BulkSyncLink>
      )}
      <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
        <div className={cx('items-center text-sm text-gray-800', sharedStyles)}>
          {FrequencyOptions[bulkSync.schedule?.frequency]?.label}
        </div>
      </BulkSyncLink>
      <BulkSyncStatus bulkSync={bulkSync} amendHistory={amendHistory} />
    </div>
  );
});

const BulkSyncLink = (props: { id: string; children: React.ReactNode; onClick: () => void }) => {
  return (
    <Link to={generatePath(routes.bulkSyncStatus, { id: props.id })} onClick={props.onClick}>
      {props.children}
    </Link>
  );
};

const RowSyncIconsWrapper = ({
  children,
  id
}: {
  id: string | undefined;
  children: React.ReactNode;
}) => {
  return (
    <div className={cx('items-center truncate', sharedStyles)}>
      <Icon match={id} />
      {children}
    </div>
  );
};

// Todo -> Aggregate into one component with switch statement on child text
const BulkSyncStatus = ({
  bulkSync,
  amendHistory
}: {
  bulkSync: BulkSyncIndexFragment;
  amendHistory: () => void;
}) => {
  if (!bulkSync.active && (!bulkSync.activeExecutions || bulkSync.activeExecutions?.length === 0)) {
    return (
      <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
        <div className={cx('items-center', sharedStyles)}>
          <p className="text-sm text-gray-400">Disabled</p>
        </div>
      </BulkSyncLink>
    );
  }
  if (!bulkSync.execution) {
    return (
      <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
        <div className={cx('items-center', sharedStyles)}>
          <p className="text-sm text-gray-800">Never run</p>
        </div>
      </BulkSyncLink>
    );
  }
  if (bulkSync.activeExecutions && bulkSync.activeExecutions?.length > 0) {
    switch (bulkSync.activeExecutions[0].status) {
      case BulkExecutionStatus.Running:
      case BulkExecutionStatus.Processing:
        return (
          <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
            <div className={cx('items-center', sharedStyles)}>
              <p className="text-sm text-green-500">Running...</p>
            </div>
          </BulkSyncLink>
        );
      case BulkExecutionStatus.Exporting:
        return (
          <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
            <div className={cx('items-center', sharedStyles)}>
              <p className="text-sm text-green-500">Exporting...</p>
            </div>
          </BulkSyncLink>
        );
      default:
        return (
          <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
            <div className={cx('items-center', sharedStyles)}>
              <p className="text-sm text-gray-800">{capsFirst(bulkSync.execution.status)}</p>
            </div>
          </BulkSyncLink>
        );
    }
  }
  switch (bulkSync.execution.status) {
    case BulkExecutionStatus.Errors:
      return (
        <div className={cx('items-center', sharedStyles)}>
          <Tooltip
            placement="left"
            content={cx(
              bulkSync.execution.errorCount?.toLocaleString(),
              plural('error', (bulkSync.execution.errorCount || 0) > 1)
            )}
          >
            <span>
              <Icon name="WarningFilled" className="h-4 w-4 text-amber-500" />
            </span>
          </Tooltip>
          <Link
            className="link ml-2 mt-px text-xs"
            to={generatePath(routes.bulkSyncHistory, { id: bulkSync.id })}
            onClick={amendHistory}
          >
            History
          </Link>
        </div>
      );
    case BulkExecutionStatus.Completed:
      return (
        <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
          <div className={cx('items-center', sharedStyles)}>
            <Icon name="CheckFilled" className="h-4 w-4 text-green-500" />
          </div>
        </BulkSyncLink>
      );
    case BulkExecutionStatus.Failed:
      return (
        <div className={cx('items-center', sharedStyles)}>
          <Tooltip
            placement="left"
            content={bulkSync.execution.statusMessage || 'Bulk sync failed'}
            className="max-w-3xl"
          >
            <span>
              <Icon name="DangerFilled" className="h-4 w-4 text-red-500" />
            </span>
          </Tooltip>
          <Link
            className="link ml-2 mt-px text-xs"
            to={generatePath(routes.bulkSyncHistory, { id: bulkSync.id })}
            onClick={amendHistory}
          >
            History
          </Link>
        </div>
      );
    default:
      return (
        <BulkSyncLink id={bulkSync.id} onClick={amendHistory}>
          <div className={cx('items-center', sharedStyles)}>
            <p className="text-sm text-gray-800">{capsFirst(bulkSync.execution.status)}</p>
          </div>
        </BulkSyncLink>
      );
  }
};
