import cx from 'clsx';
import _ from 'lodash';

import {
  DailySchedule,
  FieldMappingFragment,
  FieldReferenceType,
  FilterFragment,
  Frequency,
  HourlySchedule,
  MappedFieldUpdate,
  MappedOverrideFieldUpdate,
  Metadata,
  ModelFieldFragment,
  NewFilter,
  NewOverride,
  Operation,
  OverrideFieldMappingFragment,
  OverrideFragment,
  SyncFragment,
  SyncMode,
  SyncUpdate,
  TargetFieldFragment,
  TargetObjectConfiguration,
  TargetObjectFragment,
  TargetObjectWithFieldsFragment,
  WeeklySchedule
} from '../generated/graphql';
import { CREATE_TARGET_TABLE } from './constants.util';
import { GetReachableFields, SyncConfigFormValues } from './custom-types.util';
import { capsFirst, filterToIds } from './helpers.util';
import {
  hasItems,
  isModelField,
  isModelMapping,
  isOverrideMapping,
  isTargetField
} from './predicates.util';
import { isUnreachableMapping } from './sync-config-mappings.util';
import { handleScheduleUpdate } from './sync-schedule.util';
import { Mapping } from './union-types.util';

export function syncToUpdate(sync: SyncFragment): SyncUpdate {
  const requiresIdentity = sync.targetObject?.modes.find(
    m => m.mode === sync.mode
  )?.requiresIdentity;

  const mappingIds: MappedFieldUpdate[] = sync.fields
    .filter(mapping => mapping.model?.id)
    .map((mapping: FieldMappingFragment) => ({
      modelFieldID: mapping.model!.id,
      targetFieldID: mapping.target?.id || '',
      syncMode: mapping.syncMode,
      newField: mapping.newField || false,
      remoteFieldTypeId: mapping.target?.remoteFieldTypeId || undefined
    }));
  const overrideIds: NewOverride[] = sync.overrides
    .filter(override => override.field?.id)
    .map((override: OverrideFragment) => ({
      fieldID: override.field!.id,
      function: override.function!,
      value: override.value,
      overrideValue: override.overrideValue
    }));
  const overrideFieldIds: MappedOverrideFieldUpdate[] = sync.overrideFields.map(
    (mapping: OverrideFieldMappingFragment) => ({
      overrideValue: mapping.overrideValue || '',
      targetFieldID: mapping.target?.id || '',
      syncMode: mapping.syncMode
    })
  );

  const filters: NewFilter[] = sync.filters
    .filter(filter => filter.field?.id)
    .map(filter => ({
      fieldID: filter.field!.id,
      fieldType: isTargetField(filter.field!)
        ? FieldReferenceType.Target
        : FieldReferenceType.Model,
      function: filter.function!,
      value: filter.value,
      label: filter.label
    }));

  const targetConfiguration = sanitizeTargetConfig(
    sync.targetObjectConfiguration as TargetObjectConfiguration
  );
  const schedule = handleScheduleUpdate(sync.schedule);

  const runAfterSyncIDs = hasItems(sync.runAfterSyncs)
    ? filterToIds(sync.runAfterSyncs)
    : undefined;

  const runAfterBulkSyncIDs = hasItems(sync.runAfterBulkSyncs)
    ? filterToIds(sync.runAfterBulkSyncs)
    : undefined;

  const syncUpdate: SyncUpdate = {
    id: sync.id,
    name: sync.name,
    notes: sync.notes,
    targetObject: sync.targetObject!.id,
    targetConnection: sync.targetConnection!.id,
    mode: sync.mode,
    syncAllRecords: sync.syncAllRecords,
    fields: mappingIds,
    overrideFields: overrideFieldIds,
    filters,
    overrides: overrideIds,
    filterLogic: sync.filterLogic,
    targetFilterLogic: sync.targetFilterLogic,
    targetSearchValues: sync.targetSearchValues,
    targetConfiguration,
    schedule,
    runAfterSyncIDs,
    runAfterBulkSyncIDs
  };
  if (requiresIdentity) {
    syncUpdate.identity = {
      newField: sync.identity?.newField || false,
      modelFieldID: sync.identity?.model?.id || '',
      targetFieldID: sync.identity?.target?.id || '',
      function: sync.identity?.function,
      remoteFieldTypeId: sync.identity?.target?.remoteFieldTypeId || undefined
    };
  }
  return syncUpdate;
}

export function sanitizeTargetConfig(targetObjectConfiguration: TargetObjectConfiguration) {
  const { __typename, ...rest } = targetObjectConfiguration;

  if ('metadata' in rest && hasItems(rest.metadata)) {
    rest.metadata = rest.metadata
      .filter((m: Metadata) => m.key && m.value)
      .map((m: Metadata) => ({
        key: m.key,
        value: m.value
      }));
  }
  return _.omit(
    // false is a perfectly valid value, especially for checkboxes
    _.pickBy(rest, v => v != null)
  );
}

export function supportsSyncMode(
  field: TargetFieldFragment,
  mode: SyncMode | undefined | null
): boolean {
  if (!mode) {
    return false;
  }
  switch (mode) {
    case SyncMode.Create:
      return field.createable;
    case SyncMode.Update:
      return field.updateable;
    case SyncMode.UpdateOrCreate:
      return field.createable || field.updateable;
    case SyncMode.Replace:
      return field.createable || field.updateable;
    case SyncMode.Append:
      return field.createable || field.updateable;
    case SyncMode.Remove:
      return field.createable || field.updateable;
    default:
      return false;
  }
}

export function getMissingRequiredFields({
  identityTarget,
  mappings,
  targetFields
}: {
  mappings: Mapping[];
  targetFields: TargetFieldFragment[];
  identityTarget: TargetFieldFragment | null | undefined;
}): TargetFieldFragment[] {
  const requiredTargetFields = targetFields.filter(field => field.required);
  const mappingTargetFields = mappings.map(mapping => mapping?.target);
  if (identityTarget) {
    mappingTargetFields.push(identityTarget);
  }
  const missingRequiredFields: TargetFieldFragment[] = [];
  if (hasItems(mappingTargetFields)) {
    for (const requiredField of requiredTargetFields) {
      if (
        !mappingTargetFields.find(
          mappingField =>
            mappingField?.id === requiredField.id ||
            // swap __r for __c to accommodate SFDC relationship fields
            mappingField?.id?.replace('__r', '__c').split('.')[0] === requiredField.id ||
            // see if we match the required field without the Id prefix
            mappingField?.id?.replace('__r', '__c').split('.')[0] ===
              requiredField.id.replace(/Id$/, '')
        )
      ) {
        missingRequiredFields.push(requiredField);
      }
    }
  }
  return missingRequiredFields;
}

export function setLetterIndex(filters: FilterFragment[]) {
  let count = 0;
  for (const filter of filters) {
    if (filter.label !== '') {
      count++;
    }
  }
  return count;
}

export function sortSyncFilters(filters: FilterFragment[]) {
  if (filters.length === 0) {
    return [];
  }
  const sorted = [...filters];
  return sorted.sort((a, b) => {
    // order unlabeled below labeled
    if (a.label === '' && b.label) {
      return 1;
    }
    if (b.label === '' && a.label) {
      return -1;
    }
    // order model fields with letters alphabetically
    if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) {
      return 1;
    }
    if (b.label.toLocaleLowerCase() > a.label.toLocaleLowerCase()) {
      return -1;
    }
    // order new fields at the bottom
    if (a.field == null && !!b.field) {
      return 1;
    }
    if (b.field == null && !!a.field) {
      return -1;
    }
    return 0; // leave as is
  });
}

export function getTargetConnectionTypeLabel(id?: string) {
  switch (id) {
    case 'gsheets':
      return 'sheet';
    case 'awsathena':
    case 'airtable':
    case 'azuresql':
    case 'bigquery':
    case 'databricks':
    case 'mysql':
    case 'postgresql':
    case 'redshift':
    case 'smartsheet':
    case 'snowflake':
      return 'table';
    default:
      return 'object';
  }
}

export function getTargetConnectionObjectLabel(id?: string) {
  switch (id) {
    case 'awsathena':
    case 'azuresql':
    case 'bigquery':
    case 'databricks':
    case 'gsheets':
    case 'mysql':
    case 'postgresql':
    case 'redshift':
    case 'smartsheet':
    case 'snowflake':
      return 'column';
    default:
      return 'field';
  }
}

export function getTargetConnectionEmptyMessage(id?: string) {
  switch (id) {
    case 'fbaudience':
      return 'No audiences defined';
    default:
      return 'No options';
  }
}

export function sortTargetObjects(
  objects?: Array<TargetObjectFragment | TargetObjectWithFieldsFragment>
) {
  if (!objects || objects.length === 0) {
    return [];
  }
  const sorted = [...objects];
  return sorted.sort((a, b) => {
    // put the "Create new table..." option at the top
    if (a.id === CREATE_TARGET_TABLE) {
      return -1;
    }
    if (b.id === CREATE_TARGET_TABLE) {
      return 1;
    }
    // order name alphabetically
    if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
      return 1;
    }
    if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
      return -1;
    }
    return 0;
  });
}
export function hasMissingSource(target: FilterFragment | OverrideFragment, mappings?: Mapping[]) {
  if (!mappings || (target.field as TargetFieldFragment)?.filterable) {
    return false;
  }

  const hasSource = !!mappings.find(
    mapping => isModelMapping(mapping) && mapping?.model?.id === target.field?.id
  );
  return !hasSource;
}

export function syncModeToDisplay(mode: SyncMode): string {
  if (mode === SyncMode.UpdateOrCreate) {
    return 'Update or create';
  }
  return capsFirst(mode);
}
export function splitMappings(mappings?: Mapping[]) {
  const modelMappings = mappings?.filter(isModelMapping) || [];
  const overrideMappings = mappings?.filter(isOverrideMapping) || [];
  return {
    fields: modelMappings,
    overrideFields: overrideMappings
  };
}

export function handleTargetSearchValues(
  sync: Pick<SyncFragment, 'targetObject' | 'targetSearchValues'>
) {
  if (sync.targetObject?.id == null) {
    return {};
  }
  if (
    Object.keys(sync.targetSearchValues).length === 1 &&
    'targetObject' in sync.targetSearchValues &&
    sync.targetSearchValues.targetObject === null
  ) {
    return { targetSearchValues: { targetObject: sync.targetObject.id } };
  }
  // to assign proper target for mysql
  const entry = Object.entries(sync.targetSearchValues).find(e => e[1] === CREATE_TARGET_TABLE);
  if (entry) {
    return { targetSearchValues: { [entry[0]]: sync.targetObject.id } };
  }
  return {};
}

export function validateMappings(
  data: SyncConfigFormValues,
  getReachableFields: GetReachableFields,
  modelFields: ModelFieldFragment[],
  targetFields: TargetFieldFragment[]
): string[] {
  const localErrors: string[] = [];

  const mappings = data.mappings;
  const identity = data.identity;
  const mode = data.mode;
  const targetConnection = data.targetConnection;
  const targetObject = data.targetObject;
  const overrides = data.overrides.filter(o => o.field?.id);

  const requiresIdentity = targetObject?.modes.find(m => m.mode === mode)?.requiresIdentity;

  const { fields, overrideFields } = splitMappings(mappings);
  const firstMapping = fields?.[0]?.model;

  if (fields.length === 0 && overrideFields.length === 0 && mode !== SyncMode.Remove) {
    localErrors.push('Field mappings cannot be empty');
  } else {
    if (requiresIdentity && (!identity?.model || !identity?.target)) {
      localErrors.push('Must specify identity field mapping');
    }
    if (mode === SyncMode.UpdateOrCreate || mode === SyncMode.Create) {
      getMissingRequiredFields({
        mappings,
        identityTarget: identity?.target,
        targetFields
      }).forEach(missingField =>
        localErrors.push(
          `Required ${targetConnection?.type.name || 'target connection'} ${
            targetObject?.name || 'target object'
          } field mapping missing: ${missingField.name}`
        )
      );
    }
    const { reachableFields, reachableFieldsetIds } = getReachableFields(
      requiresIdentity ? identity.model?.fieldset.id : firstMapping?.fieldset.id
    );
    fields.forEach(mapping => {
      // validate completeness
      // if (
      //     ((isModelMapping(mapping) && mapping.model) ||
      //         (isOverrideMapping(mapping) && mapping.overrideValue)) &&
      //     !mapping.target
      // ) {
      //     localErrors.push(
      //         `"${
      //             mapping.model?.label || `Field mapping ${i}`
      //         }" is missing destination mapping`
      //     )
      // }
      // validate reachability
      if (isUnreachableMapping(mapping, reachableFieldsetIds)) {
        localErrors.push(
          `"${mapping.model?.label || 'Model field'}" is not reachable: model "${
            mapping.model?.fieldset.name || ''
          }" not joined to model "${
            requiresIdentity
              ? identity?.model?.fieldset.name || ''
              : firstMapping?.fieldset.name || ''
          }"`
        );
      }
    });

    // validate filters
    let filters = data.modelFilters.filters;
    if (
      targetObject?.properties?.supportsTargetFilters &&
      targetObject?.modes.find(m => m.mode === mode)?.supportsTargetFilters
    ) {
      filters = [...filters, ...data.targetFilters.filters];
    }
    filters = filters.filter(f => f.field?.id);
    filters.forEach(filter => {
      const validFilter = (hasItems(reachableFields) ? reachableFields : modelFields).find(
        field => field.id === filter.field?.id
      );
      const filterField = modelFields.find(field => field.id === filter.field?.id);
      const filterOptions =
        filter.field &&
        (filter.field.filterFunctions || []).find(
          filterFunctionOption => filterFunctionOption.id === filter.function
        );

      const labelFallback = 'Filter';

      if (!validFilter && isModelField(filter.field)) {
        localErrors.push(
          `"${
            filterField?.label || labelFallback
          }" is not reachable: cannot filter on unreachable fields. Model "${
            filterField?.fieldset.name || ''
          }" not joined to model "${
            requiresIdentity
              ? identity?.model?.fieldset.name || ''
              : firstMapping?.fieldset.name || ''
          }"`
        );
      }

      if (!filter.function) {
        localErrors.push(
          `"${filterField?.label || labelFallback}" comparison function cannot be empty`
        );
      }

      if (isTargetField(filter.field) && mode !== SyncMode.Update) {
        localErrors.push(
          `Filter: "${filter.field.name || labelFallback}" can only be used in Update mode`
        );
      }

      if (filterOptions?.requiresValue) {
        if (
          filter.value === undefined ||
          filter.value === null ||
          filter.value === '' ||
          (Array.isArray(filter.value) && filter.value?.length === 0)
        ) {
          localErrors.push(
            `Filter: "${filterField?.label || labelFallback} ${
              filterOptions.label
            }" cannot be empty`
          );
        } else if (
          Array.isArray(filter.value) &&
          filter.value.some(val => val === '' || val === undefined || val === null)
        ) {
          localErrors.push(
            `Filter: "${filterField?.label || labelFallback} ${
              filterOptions.label
            }" has missing or invalid values`
          );
        }
      }
    });
  }

  if (hasItems(overrideFields)) {
    overrideFields.forEach(mapping => {
      if (!mapping.target) {
        localErrors.push(
          `Text string: "${mapping.overrideValue || ''}" is missing ${
            targetConnection?.type.name || 'target connection'
          } ${targetObject?.name || 'target object'} field mapping`
        );
      }
    });
  }

  // validate overrides
  if (hasItems(overrides)) {
    const labelFallback = 'Field';

    overrides.forEach(override => {
      const options = (override.field?.filterFunctions || []).find(
        func => func.id === override.function
      );
      if (!override.function) {
        localErrors.push(
          `Override: "${
            override.field?.label || labelFallback
          }" comparison function cannot be empty`
        );
      }
      if (options?.requiresValue) {
        if (
          override.value == null ||
          override.value === '' ||
          (Array.isArray(override.value) && override.value?.length === 0)
        ) {
          localErrors.push(
            `Override: "${override.field?.label || labelFallback} ${
              options.label
            }" comparison value cannot be empty`
          );
        } else if (
          Array.isArray(override.value) &&
          override.value.some(val => val === '' || val === undefined)
        ) {
          localErrors.push(
            `Override: "${cx(
              override.field?.label || labelFallback,
              options?.label
            )}" has missing or invalid values`
          );
        }
      }
      // if (override.function && !override.overrideValue) {
      //     localErrors.push(
      //         `Override: "${cx(
      //             override.field?.label || labelFallback,
      //             options?.label
      //         )}" override value cannot be empty`
      //     )
      // }
    });
  }
  return localErrors;
}

export function validateNames(
  isTargetCreator: boolean,
  newParentName: string | null,
  newTargetName: string,
  name: string,
  parentKey: string | undefined | null,
  dependentKey: string | undefined | null
): string[] {
  const localErrors: string[] = [];
  if (isTargetCreator && newParentName === '') {
    localErrors.push(`${parentKey || 'Schema'} name cannot be empty`);
  }
  if (isTargetCreator && newTargetName === '') {
    localErrors.push(`${dependentKey || 'Table'} name cannot be empty`);
  }
  if (name === '') {
    localErrors.push('Sync name cannot be empty');
  }
  return localErrors;
}

export function validateSchedule(data: SyncConfigFormValues): string[] {
  const localErrors: string[] = [];
  const schedule = data.schedule;
  const runAfterSyncs = data.runAfterSyncs || [];
  const frequency = schedule?.frequency;
  if (!frequency) {
    localErrors.push('Schedule frequency cannot be empty');
  } else {
    switch (frequency) {
      case Frequency.Hourly:
        if ((schedule as HourlySchedule)?.minute == null) {
          localErrors.push('Hourly schedule: must specify minutes');
        }
        break;
      case Frequency.Daily: {
        const s = schedule as DailySchedule;
        if (s?.hour == null) {
          localErrors.push('Daily schedule: must specify hour');
        }
        if (s?.minute == null) {
          localErrors.push('Daily schedule: must specify minute');
        }
        break;
      }
      case Frequency.Weekly: {
        const s = schedule as WeeklySchedule;
        if (s.dayOfWeek == null) {
          localErrors.push('Weekly schedule: must specify day');
        }
        if (s.hour == null) {
          localErrors.push('Weekly schedule: must specify hour');
        }
        if (s.minute == null) {
          localErrors.push('Weekly schedule: must specify minute');
        }
        break;
      }
      case Frequency.Runafter:
        if (runAfterSyncs.length === 0) {
          localErrors.push('Sync after schedule: must specify at least one sync');
        }
        break;
      default:
        break;
    }
  }
  return localErrors;
}

export function prepareSyncUpdate(data: SyncConfigFormValues, active: boolean) {
  const supportsTargetFilters = !!(
    data.targetObject?.properties?.supportsTargetFilters &&
    data.targetObject?.modes.find(m => m.mode === data.mode)?.supportsTargetFilters
  );

  let filters = data.modelFilters.filters;
  if (supportsTargetFilters) {
    filters = [...filters, ...data.targetFilters.filters];
  }
  // hasInlineConfig which is S3
  if (
    !!data.targetConnection?.type.operations.includes(Operation.DestinationRequireConfiguration) &&
    data.targetObject?.id &&
    data.targetObjectIdDraft
  ) {
    data.targetObject.id = data.targetObjectIdDraft;
  }

  const runAfterSyncs = data.runAfterSyncs
    ? (data.runAfterSyncs.filter(
        item => item.__typename === 'Sync'
      ) as SyncFragment['runAfterSyncs'])
    : [];

  const runAfterBulkSyncs = data.runAfterSyncs
    ? (data.runAfterSyncs.filter(
        item => item.__typename === 'BulkSync'
      ) as SyncFragment['runAfterBulkSyncs'])
    : [];

  return syncToUpdate({
    ...data,
    ...splitMappings(data.mappings),
    targetObjectConfiguration:
      data.targetObjectConfiguration == null
        ? { __typename: 'NoConfiguration' }
        : data.targetObjectConfiguration,
    filters,
    filterLogic: data.modelFilters.logic,
    targetSearchValues: data.targetSearchValues ? data.targetSearchValues : {},
    targetFilterLogic: supportsTargetFilters ? data.targetFilters.logic : '',
    willTruncateOnResync: false,
    active,
    tags: [],
    acl: [],
    runAfterSyncs,
    runAfterBulkSyncs
  });
}
