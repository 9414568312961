import { useMutation, useQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Icon } from '~/components';
import { ConnectionSelect, SideBySide } from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';

import {
  Action,
  ConnectionFragment,
  ConnectionsDocument,
  CreateFieldsetDocument,
  EditableFieldsetFragment,
  Operation
} from '~/generated/graphql';
import { useBannerDispatch, useMountEffect } from '~/hooks';
import {
  CreateModelLocalStorage,
  FieldsetFormValues,
  FieldsetReducerActions,
  filterConnections,
  getTempLocal,
  removeTempLocal,
  routes,
  updateFieldsetForm
} from '~/utils';

interface Props {
  fieldset: EditableFieldsetFragment | undefined;
  dispatch: React.Dispatch<FieldsetReducerActions>;
}

export function FieldsetConnection({ fieldset, dispatch }: Props) {
  const { fieldsetId } = useParams<{ fieldsetId: string }>();
  const isAdding = useRouteMatch({ path: routes.createModel, exact: true });
  const dispatchBanner = useBannerDispatch();

  const { reset, getValues } = useFormContext<FieldsetFormValues>();

  const { data: connectionsData, loading: connectionsLoading } = useQuery(ConnectionsDocument, {
    skip: !!fieldsetId
  });

  const initModel = getTempLocal<CreateModelLocalStorage>('createModel');

  const [createFieldset, { loading: createFieldsetLoading }] = useMutation(CreateFieldsetDocument, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (!data || !data.createFieldset) {
        return;
      }
      const _fieldset = data.createFieldset.fieldset;
      if (initModel?.query && _fieldset.configuration && 'query' in _fieldset.configuration) {
        _fieldset.configuration.query = initModel.query;
      }

      dispatch({ type: 'replace', fieldset: { ...fieldset, ..._fieldset } });
      updateFieldsetForm(reset, getValues, { ...fieldset, ..._fieldset });
      removeTempLocal('createModel');
    },
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
    }
  });

  function handleConnection(connection: ConnectionFragment) {
    void createFieldset({
      variables: { connection: connection.id }
    });
  }

  useMountEffect(() => {
    if (isAdding && initModel?.connectionId) {
      void createFieldset({
        variables: { connection: initModel.connectionId }
      });
    }
  });
  const options = filterConnections(connectionsData, Operation.Fieldsetquery, Action.Query);
  const value = initModel ? options.find(opt => opt.id === initModel?.connectionId) || null : null;

  if (isAdding) {
    return (
      <>
        <SideBySide hasSectionWrap heading="Connection" styles="flex items-center space-x-2">
          <ConnectionSelect
            autoFocus={!value}
            value={value}
            options={options}
            onChange={handleConnection}
            isLoading={connectionsLoading}
            isDisabled={createFieldsetLoading}
            className="w-full max-w-xs"
          />
          {createFieldsetLoading && (
            <span className="mt-0.5 flex items-center justify-center">
              <LoadingDots />
            </span>
          )}
        </SideBySide>
      </>
    );
  }

  return (
    <SideBySide hasSectionWrap heading="Connection">
      <p className="flex items-center pt-1 text-sm font-medium text-gray-800">
        <Icon match={fieldset?.connection.type.id} className="mr-2 h-5" />
        {fieldset?.connection.name}
      </p>
    </SideBySide>
  );
}
