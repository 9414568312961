import { Row } from '@tanstack/react-table';
import clsx from 'clsx';
import React, { HTMLAttributes, ReactNode } from 'react';
import { followCursor } from 'tippy.js/headless';

import { Tooltip } from '~/components/tooltip';
import TableBodyCol, { TableBodyColProps } from '~/components/v2/table/TableBodyCol';

export type TableBodyRowProps<TData, TValue = unknown> = {
  row?: Row<TData>;
  children?: ReactNode;
  enableRowHover?: boolean;
  disabled?: (row: Row<TData>) => boolean;
  startingTabIndex?: number;
  disabledToolTipMessage?: string;
  onRowClick?: (row: Row<TData>, e: React.MouseEvent) => void;
  renderSubComponent?: (props: { row: Row<TData> }) => React.ReactElement;
  enableColumnResizing?: boolean;
  TableBodyColProps?: Partial<TableBodyColProps<TData, TValue>>;
  focused?: boolean;
  focusRef?: React.Ref<HTMLTableRowElement>;
} & HTMLAttributes<HTMLTableRowElement>;

const TableBodyRow = <TData,>({
  row,
  children,
  enableRowHover = false,
  disabled,
  disabledToolTipMessage,
  onRowClick,
  renderSubComponent,
  startingTabIndex,
  enableColumnResizing,
  TableBodyColProps,
  className,
  focused = false,
  focusRef,
  ...rest
}: TableBodyRowProps<TData>) => {
  const isDisabled = disabled && row ? disabled(row) : false;
  // SX
  const TABLE_BODY_ROW_SX = clsx(
    'outline-none focus:outline-none',
    className,
    enableRowHover && !isDisabled && 'hover:bg-indigo-50 focus:bg-indigo-50',
    enableColumnResizing && 'flex',
    !isDisabled && onRowClick && 'hover:cursor-pointer',
    isDisabled && 'focus:bg-indigo-50 [&_*]:text-gray-400',
    focused && 'bg-indigo-50 focus:bg-indigo-50 active:bg-indigo-200 active:text-indigo-500'
  );
  // F(x)
  const handleRowClick = (e: React.MouseEvent) => !isDisabled && row && onRowClick?.(row, e);

  return (
    <>
      {/* TODO -> cleanup */}
      {disabledToolTipMessage && isDisabled ? (
        <Tooltip
          content={disabledToolTipMessage}
          disabled={!isDisabled}
          followCursor
          delay={[900, 0]}
          plugins={[followCursor]}
        >
          <tr
            className={TABLE_BODY_ROW_SX}
            {...rest}
            onClick={handleRowClick}
            ref={focusRef}
            tabIndex={focusRef ? 0 : -1}
          >
            {row
              ? row
                  .getVisibleCells()
                  .map(cell => (
                    <TableBodyCol<TData> key={cell.id} cell={cell} {...TableBodyColProps} />
                  ))
              : children}
          </tr>
        </Tooltip>
      ) : (
        <tr
          className={TABLE_BODY_ROW_SX}
          {...rest}
          onClick={handleRowClick}
          ref={focusRef}
          tabIndex={focusRef ? 0 : -1}
        >
          {row
            ? row
                .getVisibleCells()
                .map(cell => (
                  <TableBodyCol<TData> key={cell.id} cell={cell} {...TableBodyColProps} />
                ))
            : children}
        </tr>
      )}
      {row && row.getIsExpanded() && renderSubComponent && (
        <tr>
          <td colSpan={row.getVisibleCells().length}>{renderSubComponent({ row })}</td>
        </tr>
      )}
    </>
  );
};

export default TableBodyRow;
