import { JSONSchema4 } from 'json-schema';
import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { Label, LinkButton, MyCombobox } from '../../../components';
import {
  DataArchitectureDocument,
  DataArchitectureQuery,
  DataArchitectureQueryVariables,
  DataArchitectureResponse
} from '../../../generated/graphql';
import { useBannerDispatch, useLazyContinuationQuery } from '../../../hooks';
import { getSchemaNormalized, Selectable, SyncConfigFormValues } from '../../../utils';

interface Props {
  field: JSONSchema4;
  name?: string;
  connectionId: string;
  path?: string | null;
  callback: (option: Selectable) => void;
}

export function DestDataArchitectureSelect({ field, callback, path, connectionId, name }: Props) {
  const { control, setValue } = useFormContext<SyncConfigFormValues>();

  const { field: f } = useController({ control, name: `targetSearchValues.${name}` });
  const [continuation, setContinuation] = React.useState<string>(uuid());
  const dispatchBanner = useBannerDispatch();
  const [options, setOptions] = React.useState<Selectable[]>([]);

  const [queryDataArchitecture, { loading: isLoading }] = useLazyContinuationQuery<
    DataArchitectureQuery,
    DataArchitectureQueryVariables
  >(DataArchitectureDocument, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      // Reset the continuation ID so the next query doesn't return the cached
      // result; I am a bad person and I feel bad about this.
      setContinuation(uuid());
      setOptions(
        (getSchemaNormalized((data.dataArchitectureQuery as DataArchitectureResponse).schema)?.[
          name
        ]?.enum as unknown as Selectable[]) || []
      );
    },
    onError: error => {
      dispatchBanner({
        type: 'show',
        payload: { message: error, wrapper: 'px-3 pt-3 max-w-5xl mx-auto' }
      });
    }
  });

  useEffect(() => {
    queryDataArchitecture({
      variables: {
        connectionId,
        objectType: name,
        searchPath: path || '',
        continuation
      }
    });
  }, [queryDataArchitecture, connectionId, name, path]);

  const value =
    options.find(option => option.value === f.value || option.label === f.value) || null;

  const handleChange = React.useCallback(
    (option: Selectable | null) => {
      if (option == null) {
        return;
      }
      f.onChange(option.value);
      callback(option);
    },
    [callback, f]
  );

  const refresh = React.useCallback(() => {
    const continuation = uuid();
    setContinuation(continuation);
    void queryDataArchitecture({
      variables: {
        connectionId,
        objectType: name,
        searchPath: path || '',
        continuation,
        refresh: true
      }
    });
  }, [queryDataArchitecture]);

  React.useEffect(() => {
    if (value) {
      return;
    }
    if (options.length === 1) {
      setValue(`targetSearchValues.${name}`, options[0].value);
      callback(options[0]);
    }
  }, [callback, value, name, options, setValue]);

  React.useEffect(() => {
    if (value) {
      return;
    }
    if (field.default && options.length > 0) {
      const newVal = options.find(opt => opt.value === field.default);
      if (newVal) {
        setValue(`targetSearchValues.${name}`, newVal.value);
      }
    }
  }, [value, field.default, setValue, options, name]);

  return (
    <div>
      <div className="flex justify-between">
        <Label>{field.title}</Label>
        <LinkButton
          tabIndex={-1}
          disabled={isLoading}
          onClick={refresh}
          className="mb-1 self-end text-xs"
        >
          Refresh
        </LinkButton>
      </div>
      <MyCombobox
        isLoading={isLoading}
        placeholder={isLoading ? ' ' : undefined}
        value={value}
        options={options}
        onChange={handleChange}
      />
    </div>
  );
}
