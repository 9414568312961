import { useParams } from 'react-router-dom';

import { AddSubscriber } from '../syncs/sync-notifications/add-subscriber';

const BulkSyncNotifications = () => {
  // hooks
  const { id } = useParams<{ id: string }>();
  // SX
  const ROOT_SX = 'animate-fadeIn divide-y divide-gray-300 pb-32';

  return (
    <div className={ROOT_SX}>
      <AddSubscriber id={id} notificationEvent="bulksync.failed" />
    </div>
  );
};

export default BulkSyncNotifications;
