import { useFormContext } from 'react-hook-form';

import { EditPermission, MyInput, SideBySide } from '../../../components';
import { FieldsetFormValues } from '../../../utils';

interface Props {
  disabled: boolean;
  autoFocus?: boolean;
  onInputCapture?: (e: React.FormEvent<HTMLInputElement>) => void;
}

export function FieldsetName(props: Props) {
  const { register, formState } = useFormContext<FieldsetFormValues>();
  const { errors } = formState;

  return (
    <SideBySide hasSectionWrap heading="Model name">
      <EditPermission>
        <MyInput
          autoFocus={props.autoFocus}
          className="max-w-xs"
          {...register('realName', {
            required: 'Model name cannot be empty'
          })}
          onInputCapture={props?.onInputCapture}
          errors={errors}
          disabled={props.disabled}
        />
      </EditPermission>
    </SideBySide>
  );
}
