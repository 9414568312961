import { Icon } from '~/components';
interface ConnectUsingProps {
  whitelisted: {
    id: string;
    name: string;
  }[];
  onSelected(id: string): unknown;
}

export function ConnectUsing(props: ConnectUsingProps) {
  return (
    <>
      <p className="text-gray-800 text-xl pb-3">Connect using...</p>
      <ul className="space-y-1">
        {props.whitelisted.map(item => (
          <li key={item.id}>
            <button
              className="
                p-2 flex items-center gap-2 w-full rounded-[3px]
                hover:bg-indigo-100 hover:border-indigo-500
              "
              onClick={() => props.onSelected(item.id)}
            >
              <Icon match={item.id} className="w-8 h-8" />
              <span>{item.name}</span>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
}
