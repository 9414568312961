import cx from 'clsx';
import * as React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { AccessControlInForm, AccessControlWrap, Textarea } from '../../../components';
import { HealthStatus, ResourceType, SyncFragment, SyncMode } from '../../../generated/graphql';
import {
  addLineBreaks,
  getTargetConnectionTypeLabel,
  hasItems,
  routes,
  SyncConfigFormValues
} from '../../../utils';
import { FieldMappingsSummary, ScheduleToDisplay, SummarySectionWrapper } from '.';
import { Icon } from '~/components';
import { useFormContext, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';

const labelStyles = 'text-sm font-semibold text-gray-500';

interface Props {
  sync: SyncFragment;
  hideSections?: string[];
  className?: string;
  nameControls?: React.ReactNode;
  readonly?: boolean;
  newTargetName?: string;
}

export function SyncSummary({ hideSections, ...props }: Props) {
  const supportsFieldTypeSelection =
    props.sync?.targetObject?.properties?.supportsFieldTypeSelection;
  const hasOptionalTargetMappings = props.sync?.targetObject?.properties?.optionalTargetMappings;
  const isNonIdentity =
    props.sync.targetObject?.modes &&
    !props.sync.targetObject.modes.find(m => m.mode === props.sync.mode)?.requiresIdentity;

  const summarySections: Record<string, React.ReactElement> = {
    Name: (
      <>
        {props.nameControls || (
          <>
            <p className={labelStyles}>Name</p>
            <h3 className="-mt-0.5 text-base font-semibold text-gray-800">{props.sync.name}</h3>
          </>
        )}
      </>
    ),
    Destination: (
      <>
        <p className={labelStyles}>Destination</p>
        <div className="flex items-start space-x-1.5">
          <Icon match={props.sync.targetConnection?.type.id} />
          {props.sync.targetObject ? (
            <p className="text-sm text-gray-800">
              {addLineBreaks(
                cx(
                  props.sync.targetConnection?.name,
                  props.sync.targetObject?.id === '__pt_new_target' && isEmpty(props.newTargetName)
                    ? ''
                    : props.sync.targetObject?.name
                )
              )}
            </p>
          ) : (
            <p className="text-sm text-red-500">
              Missing {props.sync.targetConnection?.name || 'destination'}{' '}
              {getTargetConnectionTypeLabel(props.sync.targetConnection?.type.id)}
            </p>
          )}
        </div>
      </>
    ),
    Schedule: (
      <>
        <p className={labelStyles}>Schedule</p>
        <div className="text-sm text-gray-800">
          <ScheduleToDisplay
            schedule={props.sync.schedule!}
            runAfterSyncs={[...props.sync.runAfterSyncs, ...props.sync.runAfterBulkSyncs]}
          />
        </div>
      </>
    ),
    'Sync mode': (
      <>
        <p className={labelStyles}>Sync mode</p>
        <p className="text-sm text-gray-800">
          {props.sync.mode != null &&
            props.sync.targetObject?.modes.find(m => m.mode === props.sync.mode)?.label}
        </p>
      </>
    ),
    Identity: (
      <>
        <p className={labelStyles}>Identity</p>
        <FieldMappingsSummary
          hasOptionalTargetMappings={hasOptionalTargetMappings && !supportsFieldTypeSelection}
          syncMode={null}
          targetConnection={props.sync.targetConnection}
          mappings={props.sync.identity ? [props.sync.identity] : []}
        />
      </>
    ),
    'Field mappings': (
      <>
        <p className={labelStyles}>
          {hasOptionalTargetMappings
            ? props.sync.targetConnection?.type.id === 'webhook'
              ? 'Webhook payload'
              : 'Model fields'
            : 'Field mappings'}
        </p>
        <div className="space-y-2">
          {hasItems(props.sync.fields) || hasItems(props.sync.overrideFields) ? (
            <FieldMappingsSummary
              hasOptionalTargetMappings={hasOptionalTargetMappings && !supportsFieldTypeSelection}
              syncMode={props.sync.mode || null}
              targetConnection={props.sync.targetConnection}
              mappings={[...props.sync.fields, ...props.sync.overrideFields]}
            />
          ) : (
            <div>
              {props.sync.targetConnection?.health?.status === HealthStatus.Healthy ? (
                <span className="text-red-500">
                  Model fields deleted. Add new ones to your sync mapping.
                </span>
              ) : (
                props.sync.targetConnection?.id && (
                  <Link
                    className="text-red-500 underline hover:no-underline"
                    to={generatePath(routes.editConnection, {
                      id: props.sync.targetConnection.id
                    })}
                  >
                    Check unhealthy connection
                  </Link>
                )
              )}
            </div>
          )}
        </div>
      </>
    ),
    Notes: (
      <>
        <p className={labelStyles}>Notes</p>
        {props.readonly ? (
          <div className="text-sm text-gray-800">{props.sync.notes}</div>
        ) : (
          <NotesTextArea />
        )}
      </>
    ),
    'Access control': (
      <>
        <p className={labelStyles}>Access control</p>
        <AccessControlWrap hasSideBySide={false}>
          <AccessControlInForm resourceType={ResourceType.Sync} />
        </AccessControlWrap>
      </>
    )
  };

  if (isNonIdentity) {
    hideSections = (hideSections || []).concat(['Identity']);
  }

  if (props.readonly && !props.sync.notes?.length) {
    hideSections = (hideSections || []).concat(['Notes']);
  }

  if (props.sync.mode === SyncMode.Remove) {
    hideSections = (hideSections || []).concat(['Field mappings']);
  }

  return (
    <>
      {Object.keys(summarySections)
        .filter(item => !hideSections?.includes(item))
        .map(label => (
          <SummarySectionWrapper key={label} className={props.className}>
            {summarySections[label]}
          </SummarySectionWrapper>
        ))}
    </>
  );
}

function NotesTextArea() {
  const { control, setValue } = useFormContext<SyncConfigFormValues>();
  const notes = useWatch({ control, name: 'notes' });

  return (
    <Textarea
      className="min-h-[1rem]"
      value={notes}
      onChange={e => setValue('notes', e.target.value)}
      placeholder="A note about purpose or use"
      rows={2}
    />
  );
}
