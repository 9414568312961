import { FieldsetType } from '../../../generated/graphql';
import { ApiFieldsetConfig } from '../backends/api-model';
import { AthenaFieldsetConfig } from '../backends/athena-model';
import { BigQueryFieldsetConfig } from '../backends/bigquery-model';
import { CosmosDbFieldsetConfig } from '../backends/cosmosdb-model';
import { CsvFieldsetConfig } from '../backends/csv-model';
import { DefaultFieldsetConfig } from '../backends/default-model';
import { DynamoDBFieldsetConfig } from '../backends/dynamodb-model';
import { GsheetsFieldsetConfig } from '../backends/gsheets-model';
import { HarmonicFieldsetConfig } from '../backends/harmonic-model';
import { MongoFieldsetConfig } from '../backends/mongo-model';
import { PipedriveFieldsetConfig } from '../backends/pipedrive-model';
import { SalesforceFieldsetConfig } from '../backends/salesforce-model';
import { SeparateSqlConfig } from '../backends/sql-warehouse-model';
import { SqlFieldsetConfig } from '../backends/sql-model';
import { DataliteModelConfig } from '../backends/DataliteModelConfig';
import { useApplyFieldsetUpdate } from '~/hooks/use-apply-fieldset-update';
import { useFieldsetState, useMountEffect } from '~/hooks';
import { DatabricksModelConfig } from '../backends/DatabricksModelConfig';
import { ZoomInfoFieldsetConfig } from '../backends/zoominfo-model';
import { AffinityFieldsetConfig } from '../backends/affinity-model';

export type FieldsetConfigSwitcherProps = {
  adding: boolean;
  type: FieldsetType;
  connectionId?: string;
};

const FieldsetConfigSwitcher = ({ adding, type, connectionId }: FieldsetConfigSwitcherProps) => {
  const { applyUpdate } = useApplyFieldsetUpdate();
  const fieldset = useFieldsetState();
  useMountEffect(() => {
    if (adding) {
      applyUpdate(
        {
          ...fieldset.configuration
        },
        { refresh: true }
      );
    }
  });

  switch (type) {
    case FieldsetType.Affinity:
      return <AffinityFieldsetConfig />;
    case FieldsetType.Api:
      return <ApiFieldsetConfig />;
    case FieldsetType.CosmosDb:
      return <CosmosDbFieldsetConfig />;
    case FieldsetType.Csv:
      return <CsvFieldsetConfig />;
    case FieldsetType.Datalite:
      return <DataliteModelConfig />;
    case FieldsetType.GSheets:
      return <GsheetsFieldsetConfig />;
    case FieldsetType.Harmonic:
      return <HarmonicFieldsetConfig />;
    case FieldsetType.MongoDb:
      return <MongoFieldsetConfig />;
    case FieldsetType.Pipedrive:
      return <PipedriveFieldsetConfig />;
    case FieldsetType.Salesforce:
      return <SalesforceFieldsetConfig />;
    case FieldsetType.Sql:
      switch (connectionId) {
        case 'bigquery':
          return <BigQueryFieldsetConfig />;
        case 'awsathena':
          return <AthenaFieldsetConfig />;
        case 'dynamodb':
          return <DynamoDBFieldsetConfig />;
        case 'databricks':
          return <DatabricksModelConfig />;
        case 'redshift':
        case 'redshiftserverless':
        case 'snowflake':
          return <SeparateSqlConfig />;
        default:
          return <SqlFieldsetConfig />;
      }
    case FieldsetType.ZoomInfo:
      return <ZoomInfoFieldsetConfig />;
    default:
      return <DefaultFieldsetConfig />;
  }
};

export default FieldsetConfigSwitcher;
