import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import ListItem, { ListItemProps } from './ListItem';
import scrollIntoView from 'scroll-into-view-if-needed';

export type ListProps = {
  dense?: boolean;
  variant?: 'outlined' | 'flat';
  items?: ListItemProps[];
};

export const List = ({ dense = false, variant = 'flat', items }: ListProps) => {
  // SX
  const ROOT_SX = clsx(
    'flex flex-col items-start h-full overflow-y-scroll overflow-x-clip snap-y scroll-p-1 scroll-smooth',
    dense ? 'space-y-2' : 'space-y-4',
    variant === 'outlined' && 'rounded border border-gray-300 bg-gray-50 p-1 '
  );

  return (
    <ul className={ROOT_SX}>
      {items?.map((item, index) => (
        <ListItem key={index} scrollSnap={true} {...item} dense={dense} />
      ))}
    </ul>
  );
};

export default List;
