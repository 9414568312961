import * as React from 'react';
import Select, {
  components,
  createFilter,
  GroupProps,
  Props as SelectProps,
  SingleValueProps
} from 'react-select';

import { ModelFieldFragment } from '../../generated/graphql';
import {
  DropdownIndicator,
  GroupedHeading,
  hasItems,
  Selectable,
  selectStyles,
  toGroupedModelOptions,
  toSelectable,
  toSelectables,
  WindowedMenuList
} from '../../utils';
import { Icon } from '../Icon';

function formatOptionLabelWithSourceName(field: ModelFieldFragment) {
  return (
    <div className="flex items-start">
      <Icon match={field.fieldset.connection.type.id} className="mt-1 mr-2 h-4 w-4" />
      <div className="flex flex-col">
        <p className="text-sm font-medium">{field.label}</p>
        <p className="text-sm text-gray-500">{field.sourceName}</p>
        <p className="text-sm text-gray-500">{field.fieldset.connection.name}</p>
      </div>
    </div>
  );
}

function formatOptionLabel(field: ModelFieldFragment) {
  return (
    <div className="flex items-start">
      <Icon match={field.fieldset.connection.type.id} className="mt-1 mr-2 h-4 w-4" />
      <div className="flex flex-col">
        <p className="text-sm font-medium">{field.label}</p>
        <p className="text-sm text-gray-500">{field.fieldset.connection.name}</p>
      </div>
    </div>
  );
}

function formatWindowedOptionWithSourceName(field: ModelFieldFragment) {
  return (
    <div className="flex items-start">
      <Icon match={field.fieldset.connection.type.id} className="mt-1 mr-2 h-4 w-4" />
      <div className="flex flex-col">
        <p className="text-sm font-medium">{field.label}</p>
        <p className="text-sm text-gray-500">{field.sourceName}</p>
        <p className="text-sm text-gray-500">{field.fieldset.name}</p>
      </div>
    </div>
  );
}

function formatWindowedOption(field: ModelFieldFragment) {
  return (
    <div className="flex items-start">
      <Icon match={field.fieldset.connection.type.id} className="mt-1 mr-2 h-4 w-4" />
      <div className="flex flex-col">
        <p className="text-sm font-medium">{field.label}</p>
        <p className="text-sm text-gray-500">{field.fieldset.name}</p>
      </div>
    </div>
  );
}

function formatMinimalOption(modelField: ModelFieldFragment) {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-medium">{modelField.label}</p>
      <p className="text-sm text-gray-500">{modelField.sourceName}</p>
    </div>
  );
}

function GroupHeading(
  props: GroupProps<ModelFieldFragment, false> & {
    data: { label: string; options: ModelFieldFragment[] };
  }
) {
  return <GroupedHeading label={props.data.label} optionsCount={props.data.options.length} />;
}

/* Don't show connection name for selected values outside of dropdown */
function SingleValue(props: SingleValueProps<ModelFieldFragment>) {
  return (
    <components.SingleValue {...props}>
      <div className="flex items-center space-x-2">
        <Icon match={props.data.fieldset.connection.type.id} className="h-4 w-4" />
        <p className="text-sm">{props.data.label}</p>
      </div>
    </components.SingleValue>
  );
}

function SingleValueMinimal(props: SingleValueProps<ModelFieldFragment>) {
  return (
    <components.SingleValue {...props}>
      <p className="text-sm">{props.data.label}</p>
    </components.SingleValue>
  );
}

function stringify(option: Selectable & { data: ModelFieldFragment }) {
  return `${option.label}${option.data?.sourceName || ''}${option.data?.fieldset?.name || ''}`;
}

type Props = Pick<
  SelectProps,
  'placeholder' | 'isLoading' | 'isDisabled' | 'isClearable' | 'isSearchable'
> & {
  value?: ModelFieldFragment | null;
  hideConnections?: boolean;
  hideGroups?: boolean;
  options: ModelFieldFragment[];
  onChange: (option: ModelFieldFragment) => void;
  className?: string;
  isWindowed?: boolean;
  autoFocus?: boolean;
  showSourceName?: boolean;
};

export function ModelFieldSelect({ onChange: propsOnChange, ...props }: Props) {
  const [width, setWidth] = React.useState(320);
  const ref = React.useRef<Select<ModelFieldFragment, false>>(null);

  const onChange = React.useCallback(
    (option: ModelFieldFragment | null) => {
      if (option == null) {
        return;
      }
      propsOnChange(option);
    },
    [propsOnChange]
  );

  const MenuList = props.isWindowed ? { MenuList: WindowedMenuList } : {};

  React.useLayoutEffect(() => {
    if (ref.current) {
      // @ts-expect-error Not sure of the types for this one
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setWidth(ref.current.select?.controlRef?.clientWidth || 320);
    }
  }, []);

  return (
    <Select
      {...props}
      ref={ref}
      width={width}
      formatOptionLabel={
        props.hideConnections
          ? formatMinimalOption
          : props.isWindowed
          ? props.showSourceName
            ? formatWindowedOptionWithSourceName
            : formatWindowedOption
          : props.showSourceName
          ? formatOptionLabelWithSourceName
          : formatOptionLabel
      }
      filterOption={createFilter({
        ignoreAccents: false,
        ignoreCase: true,
        matchFrom: 'any',
        stringify: stringify,
        trim: true
      })}
      components={{
        ClearIndicator: null,
        IndicatorSeparator: null,
        SingleValue: props.hideConnections ? SingleValueMinimal : SingleValue,
        DropdownIndicator,
        GroupHeading: props.hideGroups ? undefined : GroupHeading,
        ...MenuList
      }}
      autoFocus={props.autoFocus || false}
      isLoading={props.isLoading}
      isClearable={props.isClearable != null ? props.isClearable : true}
      isDisabled={props.isLoading || props.isDisabled}
      options={
        hasItems(props.options)
          ? props.hideGroups
            ? toSelectables(props.options)
            : toGroupedModelOptions(props.options)
          : []
      }
      onChange={onChange}
      value={props.value ? toSelectable(props.value) : null}
      placeholder={props.placeholder ? props.placeholder : 'Select field...'}
      styles={selectStyles}
      className={props.className}
    />
  );
}
