import { FilterFunction } from '../generated/graphql';
import { hasItems } from './predicates.util';
import { SyncFilterField } from './union-types.util';

export function getFieldsWithFilters(
  fields: SyncFilterField[] | undefined | null
): SyncFilterField[] {
  if (!fields) {
    return [];
  }
  return fields.filter(field => hasItems(field.filterFunctions));
}

export function getFilterLabel(filterId: FilterFunction, field?: SyncFilterField | null) {
  if (!field) {
    return;
  }
  return field.filterFunctions.find(f => f.id === filterId)?.label;
}
