import * as React from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';

import {
  Button,
  Editor,
  EditorProps,
  EditPermission,
  ReadOnlyEditor,
  TopperWrapper
} from '../../../components';
import { FieldsetConfiguration, FieldsetUpdate } from '../../../generated/graphql';
import { FieldsetFormValues } from '../../../utils';
import { ModelQueryButton } from '.';
import { sql } from '@codemirror/lang-sql';

type Props<ConfType extends Exclude<FieldsetConfiguration, 'ApiConfiguration'>> =
  Partial<EditorProps> & {
    heading: string;
    path: FieldPath<FieldsetFormValues>;
    updateObj: (conf: ConfType | undefined) => { configuration: FieldsetUpdate };
    queryRef: string | undefined | null;
    showRefreshHelper?: boolean;
    hidden?: boolean;
  };

export function ModelQueryEditor<
  ConfType extends Exclude<FieldsetConfiguration, 'ApiConfiguration'>
>({
  placeholder,
  defaultDoc,
  heading,
  path,
  updateObj,
  queryRef,
  showRefreshHelper = true,
  hidden = false
}: Props<ConfType>) {
  const { register, setValue } = useFormContext<FieldsetFormValues>();

  register(path);

  const onUpdate = React.useCallback(
    (value: string) => {
      void setValue(path, value);
    },
    [setValue, path]
  );

  return (
    <TopperWrapper
      hidden={hidden}
      heading={heading}
      rightSlot={
        <EditPermission
          fallback={
            <Button disabled={true} iconEnd="Refresh">
              Refresh
            </Button>
          }
        >
          <ModelQueryButton
            path={path}
            updateObj={updateObj}
            queryRef={queryRef}
            showRefreshHelper={showRefreshHelper}
          />
        </EditPermission>
      }
    >
      <EditPermission
        fallback={
          <ReadOnlyEditor height="15rem" defaultDoc={defaultDoc || queryRef} language={sql()} />
        }
      >
        <Editor
          language={sql()}
          onUpdate={onUpdate}
          defaultDoc={defaultDoc || queryRef}
          height="15rem"
          placeholder={placeholder}
        />
      </EditPermission>
    </TopperWrapper>
  );
}
