import { LinkButton } from '../../../components';
import { SyncStatusFragment } from '../../../generated/graphql';
import { useSyncState, useToggle } from '../../../hooks';
import { recordDialogHeading } from '../../../utils';
import { SyncExecutionRecordsPreview } from '../sync-execution-records-preview';
import { StatusLabel } from '.';

export function SyncErrorCount(props: { syncStatus: SyncStatusFragment }) {
  const [showDialog, toggleDialog] = useToggle();
  const sync = useSyncState();
  if (
    !props.syncStatus?.execution ||
    typeof props.syncStatus.execution.errorCount !== 'number' ||
    !props.syncStatus.execution.errors?.hasData
  ) {
    return null;
  }
  return (
    <StatusLabel text="Errors">
      <p>
        <LinkButton onClick={toggleDialog}>
          {props.syncStatus.execution.errorCount?.toLocaleString()}
        </LinkButton>
      </p>
      {showDialog && (
        <SyncExecutionRecordsPreview
          heading={recordDialogHeading('errors')}
          executionId={props.syncStatus.execution?.id}
          recordType="errors"
          targetConnectionTypeId={sync.targetConnection?.type.id}
          targetConnectionName={sync.targetConnection?.name}
          targetObjectName={sync.targetObject?.name}
          handleDismiss={toggleDialog}
        />
      )}
    </StatusLabel>
  );
}
