import clsx from 'clsx';
import { useState, useCallback, useMemo, SetStateAction, Dispatch, useEffect, useRef } from 'react';
import { Button, SearchWithAction, Tooltip, Truncator } from '~/components';
import Divider from '~/components/v2/display/Divider';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { Icon } from '~/components';
import {
  AllLabelsDocument,
  ExploreFieldsetFragment,
  ExploreStatusOrPollerFragment,
  FieldsetFragment,
  LabelFragment,
  ModelFieldFragment,
  RefreshCachedModelDocument
} from '~/generated/graphql';
import {
  useAuth,
  useBannerDispatch,
  useLazyContinuationQuery,
  useLocalStorage,
  useMountEffect,
  useToggle
} from '~/hooks';
import { v4 as uuid } from 'uuid';
import { ExploreFilter } from './ExploreFilters';
import { ApolloError, useQuery } from '@apollo/client';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItemCircle,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import cx from 'clsx';
import { find, isEmpty } from 'lodash';
import { hasItems } from '~/utils';
import QueryString from 'qs';

type ModelListItemProps = {
  model?: Partial<ExploreFieldsetFragment>;
  isHovered?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  setHoveredModel: (modelId?: string) => void;
  refetchModels?: () => void;
};

const ModelListItem = ({
  model,
  isHovered = false,
  isLoading = false,
  isDisabled = false,
  setHoveredModel,
  refetchModels
}: ModelListItemProps) => {
  const { models } = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });

  const scrollRef = useRef<HTMLDivElement>(null);

  const [refreshing, setRefreshing] = useState(false);
  const dispatchBanner = useBannerDispatch();

  const [refreshModel] = useLazyContinuationQuery<
    { refreshCachedModel: ExploreStatusOrPollerFragment },
    unknown
  >(RefreshCachedModelDocument, {
    onCompleted: data => {
      if (data.refreshCachedModel.__typename === 'ExploreStatus') {
        refetchModels();
        setRefreshing(false);
      }
    },
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } });
      setRefreshing(false);
    }
  });

  const handleRefreshModel = useCallback(async (modelId: string) => {
    try {
      setRefreshing(true);
      refreshModel({ variables: { modelId, continuation: uuid() } });
    } catch (e) {
      setRefreshing(false);
      dispatchBanner({
        type: 'show',
        payload: { message: 'Unable to refresh model', wrapper: 'px-3 pt-3' }
      });
    }
  }, []);

  useMountEffect(() => {
    if (scrollRef.current && models?.[0] === model.id) {
      scrollRef.current.scrollIntoView({ block: 'center' });
    }
  });

  const getTooltipMessage = () => {
    if (isDisabled) {
      return 'Unreachable model: no joins specified';
    }
    if (!model.exploreStatus.available) {
      return 'Model unavailable';
    }
    return `Last refreshed: ${model.exploreStatus.formattedLastUpdated}`;
  };

  return (
    <Tooltip
      content={
        <div className="flex flex-row items-center space-x-2">
          <div
            className={clsx(
              'min-w-2 h-2 rounded-full',
              model.exploreStatus?.available ? 'bg-green-500' : 'bg-red-500'
            )}
          />
          <span>{getTooltipMessage()}</span>
        </div>
      }
    >
      <div
        className="w-full grid grid-cols-[1fr,auto] px-4 py-1.5 gap-x-1 justify-between "
        onMouseEnter={() => setHoveredModel(model.id)}
        onMouseLeave={() => setHoveredModel(null)}
        ref={scrollRef}
      >
        {/* MODEL NAME(s) */}
        <div className="flex flex-col w-full ">
          <div className="flex flex-row space-x-2 items-center">
            {!model.exploreStatus.available && (
              <div className={clsx('w-2 h-2 rounded-full bg-red-500')} />
            )}
            <div
              className={clsx([
                'text-base font-medium text-gray-800',
                isDisabled && 'text-gray-500'
              ])}
            >
              {model.name}
            </div>
          </div>
          <div className="text-sm text-gray-500 ">{model.connection.name}</div>
        </div>
        {/* CONNECTION ICON */}
        {!isHovered || isDisabled ? (
          <div className="self-start p-2">
            <Icon match={model.connection.type.id} size="lg" />
          </div>
        ) : (
          <button
            className={clsx(
              'self-start p-2 text-gray-600',
              isLoading || (refreshing && 'cursor-not-allowed animate-spin')
            )}
            onClick={() => handleRefreshModel(model.id)}
            disabled={isLoading || refreshing || isDisabled}
          >
            <Icon name="Refresh" size="lg" />
          </button>
        )}
      </div>
    </Tooltip>
  );
};

interface ExploreModelListProps {
  allModels: Partial<FieldsetFragment>[];
  filters: ExploreFilter[];
  selected: string[];
  error: ApolloError;
  loading: boolean;
  reachableFieldsets: ModelFieldFragment[];
  handleSelect: (id: string) => void;
  setFilters: Dispatch<SetStateAction<ExploreFilter[]>>;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
}

const modelsFiltersOptions = ['All models', 'My models'];

export function ExploreModelList({
  allModels,
  filters,
  selected,
  error,
  loading,
  reachableFieldsets,
  handleSelect,
  setFilters,
  setShowFilters,
  refetch
}: ExploreModelListProps) {
  const { models } = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });

  const { user } = useAuth();
  const [hoveredField, setHoveredField] = useState<string>('');
  const [hoveredModel, setHoveredModel] = useState<string>('');
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState([]);
  const [modelsFilter, setModelsFilter] = useLocalStorage<string>(
    'exploreModelsFilter',
    modelsFiltersOptions[0]
  );
  const [labelsFilter, setLabelsFilter] = useLocalStorage<string[]>('exploreLabelsFilter', []);
  const [dropdownOpen, toggleDropdownOpen] = useToggle();
  const [labels, setLabels] = useState<LabelFragment[]>();

  useQuery(AllLabelsDocument, {
    variables: {
      entityType: 'model'
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const l = data?.allLabels ?? [];
      // Remove any filters that no longer exist from the filters
      setLabelsFilter(labelsFilter.filter(f => l?.map(l => l.id)?.includes(f)));
      setLabels(l);
    }
  });

  const dropdownLabel = useMemo(() => {
    if (labelsFilter?.length === 1) {
      return find(labels, { id: labelsFilter[0] })?.name ?? modelsFilter;
    }
    if (labelsFilter?.length > 1) {
      return `${labelsFilter.length} labels`;
    }
    return modelsFilter;
  }, [labelsFilter, labels, modelsFilter]);

  const filteredModelList = useMemo(() => {
    if (!allModels) return [];
    let items = allModels;
    if (modelsFilter === modelsFiltersOptions[1]) {
      items = items.filter(fs => fs.createdBy === user?.id);
    }
    if (labelsFilter?.length) {
      items = items.filter(fs => fs.labels.some(l => labelsFilter.includes(l.id)));
    }
    if (search) {
      const searchVal = search.toLocaleLowerCase().trim();
      items = items.filter(fs => {
        return (
          fs.name.toLocaleLowerCase().includes(searchVal) ||
          fs.realName.toLocaleLowerCase().includes(searchVal) ||
          fs.connection.name.toLocaleLowerCase().includes(searchVal) ||
          hasItems(fs.fields.filter(f => f.label.toLocaleLowerCase().includes(searchVal)))
        );
      });
    }
    return items;
  }, [allModels, search, labelsFilter, modelsFilter]);

  const handleExpand = (id: string) => {
    setExpanded(e => (e.includes(id) ? e.filter(i => i !== id) : [...e, id]));
  };

  useEffect(() => {
    if (!isEmpty(window.location.search) && !isEmpty(models)) {
      setExpanded(models as string[]);
    }
  }, [window.location.search]);

  return (
    <div className="h-full grid grid-rows-[3rem,auto,1fr]">
      {/* SEARCH */}
      <div className="w-full p-2">
        <SearchWithAction
          frontAction={
            <DropdownMenu onOpenChange={toggleDropdownOpen}>
              <DropdownMenuTrigger asChild>
                <button
                  className={cx(
                    'flex h-8 max-w-[6.625rem] items-center justify-between rounded-l-full border-r border-gray-300 p-1 focus:outline-none',
                    dropdownOpen
                      ? 'bg-gray-200'
                      : 'bg-gray-100 hover:bg-gray-200 active:bg-gray-200'
                  )}
                >
                  <p className="ml-2 max-w-[6rem] overflow-hidden whitespace-nowrap text-ellipsis text-xs font-medium">
                    {dropdownLabel}
                  </p>
                  <span className="ml-1 mr-0.5">
                    <Icon
                      name="SelectSingle"
                      className={cx(
                        'h-5 w-5 transform text-gray-500',
                        dropdownOpen && 'rotate-180'
                      )}
                    />
                  </span>
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="start">
                <DropdownMenuLabel>Models</DropdownMenuLabel>
                <DropdownMenuRadioGroup value={modelsFilter} onValueChange={setModelsFilter}>
                  {modelsFiltersOptions.map(opt => (
                    <DropdownMenuRadioItemCircle
                      key={opt}
                      value={opt}
                      onSelect={e => e.preventDefault()}
                      checked={modelsFilter === opt}
                    >
                      {opt}
                    </DropdownMenuRadioItemCircle>
                  ))}
                </DropdownMenuRadioGroup>
                {!!labels?.length && (
                  <>
                    <DropdownMenuSeparator />
                    <DropdownMenuLabel>Labels</DropdownMenuLabel>

                    {labels
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map(label => (
                        <DropdownMenuCheckboxItem
                          key={label.id}
                          checked={!!labelsFilter.find(l => l === label.id)}
                          onSelect={e => e.preventDefault()}
                          onCheckedChange={checked =>
                            setLabelsFilter(
                              checked
                                ? [...labelsFilter, label.id]
                                : labelsFilter.filter(l => l !== label.id)
                            )
                          }
                        >
                          {label.name}
                        </DropdownMenuCheckboxItem>
                      ))}
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          }
          debounce={true}
          onChange={setSearch}
          onReset={() => setSearch('')}
        />
      </div>
      <Divider />
      {/* LOADER */}
      {loading && (
        <div className="h-full flex justify-center items-center">
          <LoadingDots />
        </div>
      )}
      {/* ERROR */}
      {error && <div className="h-full flex justify-center items-center">{error.message}</div>}
      {/* MODEL LIST */}
      {!loading && !error && (
        <div className="w-full flex flex-col overflow-y-auto divide-y">
          {filteredModelList.map((model, modelIndex) => {
            const isExpanded = expanded.includes(model.id);
            const isDisabled =
              !isEmpty(reachableFieldsets) &&
              model.fields.every(field => !reachableFieldsets.find(f => f.id === field.id));
            return (
              <div key={modelIndex} className={clsx('w-full p-2', isDisabled && 'bg-gray-50')}>
                {/* MODEL */}
                <ModelListItem
                  model={model as ExploreFieldsetFragment}
                  setHoveredModel={setHoveredModel}
                  isDisabled={isDisabled}
                  isHovered={hoveredModel === model.id}
                  //isLoading={loadingModels.includes(model.id)}
                  refetchModels={refetch}
                />
                {/* MODEL FIELDS */}
                {/* Fields Expand button */}
                <Button
                  theme="ghost"
                  size="mini"
                  onClick={() => handleExpand(model.id)}
                  className={clsx(['ml-2', isDisabled && 'bg-transparent border-transparent'])}
                  disabled={isDisabled}
                >
                  {/* Caret Icon */}
                  <div
                    className={clsx(
                      'transform duration-150 ease-in-out',
                      !isExpanded && '-rotate-90'
                    )}
                  >
                    <Icon name="SelectSingle" size="md" className="p-0" />
                  </div>
                  {/* Fields counter */}
                  <div className="select-none text-xs font-medium uppercase tracking-widest">
                    {`${model.fields.length} Fields`}
                  </div>
                </Button>
                {/* Fields list */}
                {isExpanded && (
                  <div className="grid grid-flow-row gap-1 pt-1 w-full overflow-clip">
                    {model.fields.map(field => {
                      const isSelected = selected.includes(field.id);
                      const isDisabled =
                        !reachableFieldsets || !reachableFieldsets.length
                          ? false
                          : !reachableFieldsets.find(f => f.id === field.id);
                      return (
                        <div
                          key={field.id}
                          onClick={() => !isDisabled && handleSelect(field.id)}
                          className={clsx(
                            'w-full py-1.5 px-6 pr-1.5 rounded cursor-pointer',
                            isSelected
                              ? 'hover:bg-indigo-200 active:bg-indigo-100'
                              : 'hover:bg-indigo-50 active:bg-indigo-100',
                            isSelected && 'bg-indigo-100',
                            isDisabled && 'bg-gray-100 text-gray-400 cursor-not-allowed',
                            isDisabled && 'pointer-events-none'
                          )}
                          onMouseEnter={() => setHoveredField(field.id)}
                          onMouseLeave={() => setHoveredField('')}
                        >
                          <div className="grid grid-cols-[1fr,auto] w-full justify-between">
                            <Truncator content={field.label}>
                              <p className="text-gray-800 truncate">{field.label}</p>
                            </Truncator>
                            <div
                              className={clsx(
                                'transition-opacity',
                                field.id && hoveredField === field.id ? 'opacity-100' : 'opacity-0'
                              )}
                            >
                              <Button
                                className="max-h-5 shrink-0"
                                theme="primary"
                                size="mini"
                                onClick={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setFilters(f => {
                                    const newFilters = [...f];
                                    newFilters.push({
                                      fieldID: field.id,
                                      function: '',
                                      value: '',
                                      label: filters.length?.toString(),
                                      fieldType: 'Model'
                                    });
                                    return newFilters;
                                  });
                                  setShowFilters(true);
                                }}
                              >
                                Filter
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
