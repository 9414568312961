import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { JSONSchemaForm, Section, SideBySide } from '../../../components';
import {
  useApplyFieldsetUpdate,
  useApplyFieldsetUpdateLoading,
  useFieldsetState
} from '../../../hooks';
import { FieldsetFormValues, hasItems } from '../../../utils';
import { AdditionalConfig, FieldsTable, FieldsTableWrapper } from '../model-components';
import { SchemaForm } from '~/components/common/SchemaForm';

export function DefaultFieldsetConfig() {
  const fieldset = useFieldsetState();
  const { control, getValues } = useFormContext<FieldsetFormValues>();
  const { state: loading } = useApplyFieldsetUpdateLoading();
  const { applyUpdate } = useApplyFieldsetUpdate();

  const configuration = useWatch({ control, name: 'configuration' });

  const handleRefresh = React.useCallback(() => {
    applyUpdate({ ...getValues('configuration') }, { refresh: true });
  }, [applyUpdate, getValues]);

  const onChange = React.useCallback(
    (config: Record<string, unknown>) => {
      applyUpdate(config);
    },
    [applyUpdate]
  );

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          {fieldset.configurationForm && (
            <JSONSchemaForm
              formData={configuration || fieldset.configuration}
              schema={fieldset?.configurationForm?.jsonschema}
              uiSchema={{
                'ui:order': fieldset?.configurationForm?.uischema.order || undefined,
                'ui:submitButtonOptions': {
                  norender: true
                }
              }}
              onChange={applyUpdate}
            />
          )}
          {fieldset?.configurationSchema && !fieldset.configurationForm && (
            <div className="w-full max-w-xs animate-fadeIn space-y-4">
              <SchemaForm
                schema={fieldset.configurationSchema}
                values={configuration || fieldset.configuration || {}}
                onChange={onChange}
              />
            </div>
          )}
        </SideBySide>
        <FieldsTableWrapper
          hasFields={hasItems(fieldset?.fields)}
          loading={loading}
          refresh={handleRefresh}
          hasWriteinFields={fieldset?.properties.writeinFields}
        >
          <FieldsTable
            fields={fieldset?.fields}
            hasWriteinFields={fieldset?.properties.writeinFields}
          />
        </FieldsTableWrapper>
      </Section>
      <AdditionalConfig />
    </>
  );
}
