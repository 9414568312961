import { Cell, flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import { HTMLAttributes, ReactNode, useCallback } from 'react';

import { Truncator } from '~/components/truncator';

export type TableBodyColProps<TData, TValue> = {
  cell?: Cell<TData, TValue>;
  children?: ReactNode;
} & HTMLAttributes<HTMLTableCellElement>;

const TableBodyCol = <TData, TValue = unknown>({
  cell,
  children,
  className,
  ...rest
}: TableBodyColProps<TData, TValue>) => {
  const resizable = cell && cell.column.getCanResize();
  const width = useCallback(() => cell && cell.column.getSize(), [cell]);
  const baseClassName = clsx('py-2 px-4 overflow-clip', className, resizable && 'relative flex');

  return resizable ? (
    <td className={baseClassName} style={{ width: width() }} {...rest}>
      <Truncator content={cell?.getValue()}>
        <span className="truncate">
          {cell
            ? !cell.getIsPlaceholder() && flexRender(cell.column.columnDef.cell, cell.getContext())
            : children}
        </span>
      </Truncator>
    </td>
  ) : (
    <Truncator content={cell?.getValue()}>
      <td className={baseClassName} style={{ width: width() }} {...rest}>
        <span className="truncate">
          {cell
            ? !cell.getIsPlaceholder() && flexRender(cell.column.columnDef.cell, cell.getContext())
            : children}
        </span>
      </td>
    </Truncator>
  );
};

export default TableBodyCol;
