/* eslint-disable no-nested-ternary */
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import cx from 'clsx';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { Icon } from '~/components';
import { Button, LinkButton, Tooltip } from '~/components';
import {
  ExecutionsCondition,
  ExecutionStatus,
  Operation,
  StartSyncDocument,
  SyncDocument,
  SyncExecutionFragment,
  SyncExecutionsDocument,
  SyncExecutionsPageInfoDocument,
  SyncExecutionsQueryVariables,
  SyncFragment,
  SyncMode,
  SyncStatusDocument
} from '~/generated/graphql';
import { useBannerDispatch, useSyncState } from '~/hooks';
import {
  capsFirst,
  emptyCell,
  ExecutionRecordsDialog,
  ExecutionRecordType,
  getHistoryFilters,
  getLongLocalTime,
  hasItems,
  prepareRecordDisplay,
  recordDialogHeading,
  SYNC_FAILURE_ERROR_SUFFIX
} from '~/utils';
import { SyncExecutionRecordsPreview } from '../sync-execution-records-preview';
import { HistoryFilters } from './history-filters';
import { useSyncHistory } from './use-sync-history';
import { WebhookSyncRequests } from './webhook-sync-requests';
import { useConfiguration } from '~/hooks/configuration';
import { DataTableVirtual, type ColumnDef, type PaginatedQueryParams } from '~/components/v3';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';

const SyncHistory = () => {
  const { id } = useParams<{ id: string }>();
  const sync = useSyncState();

  const [retryExecutionId, setRetryExecutionId] = React.useState<string>();
  const [executionRecords, setExecutionRecords] = React.useState<ExecutionRecordsDialog>({
    show: false,
    executionId: '',
    type: null
  });
  const [webhookRequests, setWebhookRequests] = React.useState({
    show: false,
    execution: ''
  });
  const dispatchBanner = useBannerDispatch();
  const [getExecs, { data, fetchMore, loading }] = useLazyQuery(SyncExecutionsDocument, {
    notifyOnNetworkStatusChange: true,
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });

  const nodes = (data?.syncExecutions?.edges || []).map(({ node }) => node);
  const pageInfo = data?.syncExecutions?.pageInfo;

  const getHistory = React.useCallback(
    (variables: SyncExecutionsQueryVariables, paginate?: boolean) => {
      if (paginate) {
        void fetchMore({ variables });
      } else {
        void getExecs({ variables });
      }
    },
    [getExecs, fetchMore]
  );

  const {
    methods,
    filters,
    refreshHistory,
    clearFilters,
    getNewerHistory,
    hasNew,
    setHasNew,
    isSameFilters,
    where
  } = useSyncHistory({
    hasNodes: hasItems(nodes),
    pageInfo,
    getHistory
  });

  const [startSync, { loading: startSyncLoading }] = useMutation(StartSyncDocument, {
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } })
  });

  const syncFromCheckpoint = (executionId: string) => {
    startSync({
      variables: {
        id: sync?.id,
        opts: {
          checkpointExecution: executionId
        }
      }
    });
  };

  const isCheckpointable = sync?.fields?.some(
    field =>
      field?.model?.fieldset?.connection?.type?.operations?.includes(Operation.Checkpointable)
  );

  const getData = async ({ after }: PaginatedQueryParams) => {
    const { data } = after
      ? await fetchMore({ variables: { syncId: sync.id, where, after } })
      : await getExecs({ variables: { syncId: sync.id, where } });
    return data.syncExecutions;
  };

  React.useEffect(() => {
    if (!startSyncLoading) {
      setRetryExecutionId(undefined);
    }
  }, [startSyncLoading]);

  function SyncRecordCount(execution: SyncExecutionFragment) {
    function onClick() {
      setExecutionRecords({ show: true, executionId: execution.id, type: 'records' });
    }

    function onClickWebhook() {
      setWebhookRequests({ show: true, execution: execution.id });
    }

    if (execution.recordCount == null) {
      return emptyCell;
    }
    if (execution.status === ExecutionStatus.Running && execution.recordCount === 0) {
      return <span />;
    }

    if (sync?.targetConnection?.type.id !== 'webhook' && execution.records?.hasData) {
      return <LinkButton onClick={onClick}>{execution.recordCount.toLocaleString()}</LinkButton>;
    }
    if (sync?.targetConnection?.type.id === 'webhook' && execution.completedAt) {
      return (
        <LinkButton onClick={onClickWebhook}>{execution.recordCount.toLocaleString()}</LinkButton>
      );
    }
    return <p>{execution.recordCount.toLocaleString()}</p>;
  }

  function ExecutionErrorCount({ execution }: { execution: SyncExecutionFragment }) {
    function triggerSync() {
      setRetryExecutionId(execution.id);
      void startSync({
        variables: {
          id,
          opts: { retryExecution: execution.id }
        }
      });
    }

    const extraElements = execution.errors?.canRetry ? (
      <Button
        size="mini"
        iconEnd="RefreshSmall"
        onClick={triggerSync}
        loading={retryExecutionId === execution.id}
      >
        Retry
      </Button>
    ) : undefined;

    return (
      <RecordDisplayHelper
        setExecutionRecords={setExecutionRecords}
        execution={execution}
        type="errors"
        extraElements={extraElements}
      />
    );
  }

  const hasOperationCounts = !sync?.targetObject?.properties?.doesNotReportOperationCounts;

  const columns: ColumnDef<SyncExecutionFragment>[] = [
    {
      header: 'Sync time',
      accessorFn: row => row.startedAt,
      cell: ({ row }) => (
        <ExecutionStartTime
          id={row.original.id}
          syncId={sync.id}
          startedAt={row.original.startedAt || null}
          status={row.original.status}
        />
      ),
      size: 190
    },
    {
      header: 'Type',
      accessorFn: row => row.type,
      cell: ({ row }) => (row.original.resync ? 'Full Resync' : capsFirst(row.original.type)),
      size: 80
    },
    {
      header: 'Duration',
      accessorFn: row => row.formattedDuration,
      cell: ({ row }) => row.original.formattedDuration ?? emptyCell,
      size: 110
    },
    {
      header: 'Total Records',
      accessorFn: row => row.recordCount,
      cell: ({ row }) => <SyncRecordCount {...row.original} />,
      size: 150
    },
    {
      header: 'Inserted',
      accessorFn: row => row.insertCount,
      cell: ({ row }) => (
        <RecordDisplayHelper
          setExecutionRecords={setExecutionRecords}
          execution={row.original}
          type="inserts"
        />
      ),
      isVisible:
        ![SyncMode.Update, SyncMode.Replace, SyncMode.Append, SyncMode.Remove].includes(
          sync?.mode
        ) && hasOperationCounts,
      size: 80
    },
    {
      header: 'Updated',
      accessorFn: row => row.updateCount,
      cell: ({ row }) => (
        <RecordDisplayHelper
          setExecutionRecords={setExecutionRecords}
          execution={row.original}
          type="updates"
        />
      ),
      isVisible:
        ![SyncMode.Create, SyncMode.Replace, SyncMode.Append].includes(sync?.mode) &&
        hasOperationCounts,
      size: 80
    },
    {
      header: 'Deleted',
      accessorFn: row => row?.deleteCount,
      cell: ({ row }) => (
        <RecordDisplayHelper
          setExecutionRecords={setExecutionRecords}
          execution={row.original}
          type="deletes"
        />
      ),
      isVisible: sync?.mode === SyncMode.Remove && hasOperationCounts,
      size: 80
    },
    {
      header: 'Errors',
      accessorFn: row => row.errorCount,
      cell: ({ row }) => <ExecutionErrorCount execution={row.original} />,
      size: 80
    },
    {
      header: 'Warnings',
      accessorFn: row => row.warningCount,
      cell: ({ row }) => (
        <RecordDisplayHelper
          setExecutionRecords={setExecutionRecords}
          execution={row.original}
          type="warnings"
        />
      ),
      size: 80
    },
    {
      header: 'Status',
      accessorFn: row => row.status,
      cell: ({ row }) => (
        <ExecutionStatusDisplay
          execution={row.original}
          setExecutionRecords={setExecutionRecords}
        />
      ),
      size: 100
    }
  ];

  if (isCheckpointable) {
    columns.push({
      id: 'actions',
      header: null,
      cell: ({ row }) => (
        <>
          {row.original.checkpointable && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="invisible rounded p-[2px] hover:bg-gray-300 group-hover/row:visible">
                  <Icon name="DotsH" size="sm" />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" portal={false}>
                <DropdownMenuItem
                  onClick={() => syncFromCheckpoint(row.original.id)}
                  className="flex-col items-start"
                >
                  <p>Start sync from here</p>
                  <p className="text-gray-500">Begin from this history checkpoint</p>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </>
      ),
      size: 50
    });
  }

  return (
    <>
      <HistoryFilters
        historyFilters={getHistoryFilters(sync)}
        methods={methods}
        applyFiltersControl={
          <Button
            className="whitespace-nowrap"
            disabled={isSameFilters}
            loading={loading}
            onClick={refreshHistory}
          >
            Apply filters
          </Button>
        }
      />

      <div className="grid h-full w-full grid-cols-1 overflow-auto">
        <section className="max-h-full w-full space-y-4 overflow-auto p-6">
          {hasItems(nodes) ? (
            <>
              {hasNew && (
                <LinkButton className="animate-fadeIn text-sm" onClick={getNewerHistory}>
                  View newer results...
                </LinkButton>
              )}
              <DataTableVirtual<SyncExecutionFragment>
                columns={columns}
                data={nodes}
                getData={getData}
                filters={where}
                slots={{ tableCell: 'align-top' }}
              />
            </>
          ) : loading && (!filters || filters.length === 0) ? (
            <LoadingDots />
          ) : !filters || filters.length === 0 ? (
            <p className="text-sm text-gray-800">No results. Sync has not run yet.</p>
          ) : (
            <p className="text-sm text-gray-800">
              No results match the filters.{' '}
              <LinkButton onClick={clearFilters}>Clear filters</LinkButton> or adjust them.
            </p>
          )}
        </section>
      </div>

      {executionRecords.show && (
        <SyncExecutionRecordsPreview
          heading={recordDialogHeading(executionRecords.type)}
          executionId={executionRecords.executionId}
          recordType={executionRecords.type}
          targetConnectionTypeId={sync?.targetConnection?.type.id}
          targetConnectionName={sync?.targetConnection?.name}
          targetObjectName={sync?.targetObject?.name}
          handleDismiss={() => {
            setExecutionRecords({
              show: false,
              executionId: '',
              type: null
            });
          }}
        />
      )}

      {webhookRequests.show && (
        <WebhookSyncRequests
          referenceId={webhookRequests.execution}
          handleClose={() => {
            setWebhookRequests({ show: false, execution: '' });
          }}
        />
      )}

      {!loading && (
        <PollForPageInfo
          id={id}
          where={where}
          startCursor={pageInfo?.startCursor}
          setHasNew={setHasNew}
        />
      )}
      <CurrentExecutionSubscription id={id} />
    </>
  );
};

function ExecutionStartTime(props: {
  id: string;
  syncId: string;
  startedAt: string | null;
  status: ExecutionStatus;
}) {
  const { state: configuration } = useConfiguration();
  if (!props.startedAt) {
    return emptyCell;
  }
  const label = getLongLocalTime(props.startedAt);

  if (
    configuration.on_premises &&
    ![ExecutionStatus.Scheduled, ExecutionStatus.Queued, ExecutionStatus.Created].includes(
      props.status
    ) &&
    props.syncId &&
    props.id
  ) {
    return (
      <a
        className="link block whitespace-nowrap"
        href={`/api/executions/${props.syncId}/${props.id}/log.json`}
      >
        {label}
      </a>
    );
  }
  return <span className="whitespace-nowrap">{label}</span>;
}

function RecordDisplayHelper(props: {
  setExecutionRecords: React.Dispatch<React.SetStateAction<ExecutionRecordsDialog>>;
  execution: SyncExecutionFragment;
  type: ExecutionRecordType;
  extraElements?: JSX.Element;
}) {
  function onClick() {
    props.setExecutionRecords({ show: true, executionId: props.execution.id, type: props.type });
  }
  const { count, hasData } = prepareRecordDisplay(props.type, props.execution);
  if (count == null || count == 0) {
    return emptyCell;
  }

  const countStr = count.toLocaleString();
  if (count > 0 && hasData) {
    if (props.extraElements) {
      return (
        <div className="flex flex-col items-start justify-between space-y-2">
          <LinkButton onClick={onClick}>{countStr}</LinkButton>
          {props.extraElements}
        </div>
      );
    }
    return <LinkButton onClick={onClick}>{countStr}</LinkButton>;
  }
  return <p>{countStr}</p>;
}

function ExecutionStatusDisplay({
  execution,
  setExecutionRecords
}: {
  execution: SyncExecutionFragment;
  setExecutionRecords: React.Dispatch<React.SetStateAction<ExecutionRecordsDialog>>;
}) {
  function onClick() {
    setExecutionRecords({ show: true, executionId: execution.id, type: 'errors' });
  }
  switch (execution.status) {
    case ExecutionStatus.Created:
      return <p className="text-gray-800">Starting...</p>;
    case ExecutionStatus.Running:
      return <p className="text-gray-500">Running...</p>;
    case ExecutionStatus.Completed:
      if (execution.errorCount && execution.errorCount > 0) {
        return (
          <Tooltip placement="left" content="Completed with errors">
            <div
              className={cx(execution.errors?.hasData ? 'cursor-pointer' : 'cursor-default')}
              onClick={execution.errors?.hasData ? onClick : undefined}
            >
              <Icon name="WarningFilled" className="h-4 w-4 text-amber-500" />
            </div>
          </Tooltip>
        );
      }
      return <Icon name="CheckFilled" className="h-4 w-4 text-green-500" />;
    case ExecutionStatus.Failed:
      return (
        <Tooltip
          placement="left"
          interactive={true}
          content={handleExecutionFailedTooltip(execution)}
          className="max-w-3xl whitespace-pre-wrap break-words"
        >
          <div
            className={cx(execution.errors?.hasData ? 'cursor-pointer' : 'cursor-default')}
            onClick={execution.errors?.hasData ? onClick : undefined}
          >
            <Icon name="DangerFilled" className="h-4 w-4 text-red-500" />
          </div>
        </Tooltip>
      );
    default:
      return <p className="text-gray-800">{capsFirst(execution.status)}</p>;
  }
}

function handleExecutionFailedTooltip(execution: SyncExecutionFragment) {
  if (!execution.executionErrors || execution.executionErrors.length === 0) {
    return 'Sync failed';
  }

  return execution.executionErrors.map(e => e.error.replace(SYNC_FAILURE_ERROR_SUFFIX, ''));
}

function PollForPageInfo(props: {
  id: string;
  where: ExecutionsCondition;
  setHasNew: React.Dispatch<React.SetStateAction<boolean>>;
  startCursor?: string;
}) {
  const { startPolling, stopPolling } = useQuery(SyncExecutionsPageInfoDocument, {
    variables: {
      syncID: props.id,
      where: props.where,
      before: props.startCursor || undefined
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (!data || !data.syncExecutionsPageInfo) {
        return;
      }

      if (
        // if startCursor has a string, we probably have new nodes
        data.syncExecutionsPageInfo.startCursor !== '' &&
        // make sure the cursors don't match, otherwise
        // we'll end up refetching the "active" node again
        props.startCursor !== data.syncExecutionsPageInfo.startCursor
      ) {
        props.setHasNew(true);
        stopPolling();
      } else {
        props.setHasNew(false);
        startPolling(5000);
      }
    }
  });

  return null;
}

function CurrentExecutionSubscription(props: { id: string }) {
  useSubscription(SyncStatusDocument, {
    skip: !props.id,
    variables: { syncID: props.id },
    onSubscriptionData: ({ subscriptionData, client }) => {
      const syncStatus = subscriptionData.data?.syncStatus;
      if (
        !syncStatus ||
        !syncStatus.execution ||
        (syncStatus.execution.status !== ExecutionStatus.Canceled &&
          syncStatus.execution.status !== ExecutionStatus.Completed &&
          syncStatus.execution.status !== ExecutionStatus.Failed)
      ) {
        return;
      }
      client.cache.updateQuery(
        {
          query: SyncDocument,
          variables: { id: props.id }
        },
        (data: { sync: SyncFragment } | null) => {
          if (!data || !data.sync) {
            return;
          }
          return {
            sync: {
              ...data.sync,
              currentExecution: null,
              lastExecution: syncStatus.execution,
              nextExecutionTime: syncStatus.nextExecutionTime
            }
          };
        }
      );
    }
  });
  return null;
}

export default SyncHistory;
