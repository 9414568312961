import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';

import { EditPermission, InlineMessage, MySwitch } from '../../../components';
import {
  UpdateWarningNotificationsDocument,
  WarningNotificationsDocument
} from '../../../generated/graphql';
import { useBannerDispatch } from '../../../hooks';

const wrapperStyles = 'px-3 pt-3';

interface Props {
  id: string;
}

export function WarningNotificationsToggle({ id }: Props) {
  const dispatchBanner = useBannerDispatch();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [showInlineMessage, setShowInlineMessage] = React.useState(false);

  const { loading } = useQuery(WarningNotificationsDocument, {
    variables: { syncId: id },
    onCompleted: data => {
      if (!data || data.warningNotifications == null) {
        return;
      }
      setIsChecked(data.warningNotifications);
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } }),
    fetchPolicy: 'no-cache'
  });

  const [updatePartialFailureEmails] = useMutation(UpdateWarningNotificationsDocument, {
    onCompleted: data => {
      if (!data || data.warningNotifications == null) {
        return;
      }
      setShowInlineMessage(true);
      setIsChecked(data.warningNotifications);
    },
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
      setIsChecked(prev => !prev);
    }
  });

  const handleToggle = (checked: boolean) => {
    setIsChecked(checked);
    void updatePartialFailureEmails({
      variables: {
        syncId: id,
        state: checked
      }
    });
  };

  return (
    <div className="flex items-center space-x-2">
      <EditPermission>
        <MySwitch
          loading={loading}
          label="Notify on individual record warnings"
          labelPosition="right"
          checked={isChecked}
          onChange={handleToggle}
        />
      </EditPermission>
      <InlineMessage
        show={showInlineMessage}
        autoDismiss={() => setShowInlineMessage(false)}
        text="Saved"
      />
    </div>
  );
}
