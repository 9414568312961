import * as React from 'react';

import { createStateCtx } from '../factories';
import { FieldsetConfiguration } from '../generated/graphql';

export const { useCtx: useUpdatedConfig, Provider: UpdatedConfigProvider } =
  createStateCtx<FieldsetConfiguration | null>(null);

export function useModelQueryRef(key: string, defaultQuery: string | undefined | null) {
  const { state: updatedConfig, update: setUpdatedConfig } = useUpdatedConfig();
  const [ref, setRef] = React.useState<string>(defaultQuery || '');

  React.useEffect(() => {
    if (updatedConfig) {
      const newRef = updatedConfig[key] as string | undefined;
      if (newRef) {
        setRef(newRef);
      }
    }
    return () => {
      setUpdatedConfig(null);
    };
  }, [key, updatedConfig, setUpdatedConfig]);

  return ref;
}
