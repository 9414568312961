import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FrequencyOptions, Label, SchedulePicker } from '../../../components';
import { Frequency, Schedule } from '../../../generated/graphql';
import { BulkSyncForm, SyncConfigFormValues } from '../../../utils';
import { StageCard } from '../../syncs/sync-config';

export function StageBulkSchedule({
  step,
  setIsDirty
}: {
  step: number;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { control, setValue } = useFormContext<SyncConfigFormValues>();
  const { control: bulkSyncControl } = useFormContext<BulkSyncForm>();

  const source = useWatch({ control: bulkSyncControl, name: 'source' });
  const destination = useWatch({ control: bulkSyncControl, name: 'destination' });

  const schedule = useWatch({ control, name: 'schedule' });

  const handleScheduleChange = (schedule: Schedule) => {
    setValue('schedule', schedule);
    setIsDirty(true);
  };

  const options = [
    FrequencyOptions.manual,
    FrequencyOptions.continuous,
    FrequencyOptions.hourly,
    FrequencyOptions.daily,
    FrequencyOptions.weekly,
    FrequencyOptions.builder,
    FrequencyOptions.custom
  ];

  if (source?.properties?.supportsIncremental && destination?.properties?.supportsIncremental) {
    options.push(FrequencyOptions.multi);
  }

  return (
    <StageCard
      hasStickyHeader={schedule?.frequency !== Frequency.Manual}
      step={step}
      header="Set sync schedule"
      className="px-6"
    >
      <Label>Frequency</Label>
      <SchedulePicker
        options={options}
        schedule={schedule}
        onScheduleChange={handleScheduleChange}
      />
    </StageCard>
  );
}
