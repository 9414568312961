import { Icon } from '~/components';

const Dots = () => {
  const ROOT_SX = 'flex w-full items-center justify-center';
  const DOTS_SX = 'my-1.5 h-8 w-auto text-gray-400';
  return (
    <div className={ROOT_SX}>
      <Icon name="DotsLine" className={DOTS_SX} />
    </div>
  );
};

export default Dots;
