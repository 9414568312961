import { useQuery } from '@apollo/client';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '~/components';
import {
  ConnectionSelect,
  Label,
  LacksPermissionBanner,
  LinkButton,
  Permission,
  TableTopper,
  TableWrap
} from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import PageLayout from '~/components/v2/layout/PageLayout';
import EmptyPage from '~/components/v2/templates/EmptyPage';
import {
  Action,
  ConnectionsDocument,
  Operation,
  ResourceType,
  SqlRunnerResult
} from '~/generated/graphql';
import {
  AclProvider,
  dispatchExpandedSchemas,
  dispatchExpandedSearchedSchemas,
  resetAllExpandedSchemas,
  useBannerDispatch,
  useToggle
} from '~/hooks';
import {
  CreateModelLocalStorage,
  filterConnections,
  LocalConnection,
  PRIMARY_LINK_BTN_STYLES,
  routes,
  setTempLocal
} from '~/utils';
import { LastQuery } from '~/pages/query-runner/last-query';
import { QueryExportDialog } from '~/pages/query-runner/query-export-dialog';
import { QueryRunnerEditor } from '~/pages/query-runner/query-runner-editor';
import { RefreshRunnerSchema } from '~/pages/query-runner/refresh-runner-schema';
import { RunnerResultsTable } from '~/pages/query-runner/runner-results-table';
import { SchemaExplorer } from '~/pages/query-runner/schema-explorer';
import { useQueryPersistance } from '~/hooks/use-query-persistance';

export function QueryRunner() {
  const dispatchBanner = useBannerDispatch();
  const [queryLoading, setQueryLoading] = React.useState(false);
  const [refreshLoading, setRefreshLoading] = React.useState(false);
  const [showExportDialog, toggleExportDialog] = useToggle();

  const {
    connections,
    setConnections,
    connection,
    setConnection,
    lastQuery,
    setLastQuery,
    queryResult,
    setQueryResult
  } = useQueryPersistance();

  const { loading: connectionsLoading } = useQuery(ConnectionsDocument, {
    skip: connections.length > 0,
    onCompleted: data => {
      if (!data || !data.connections) {
        return;
      }
      const connections = filterConnections(data, Operation.SupportsSql, Action.Query);
      setConnections(connections);
      if (connections.length === 1 && !connections[0]?.disabled) {
        setConnection(connections[0]);
      }
    },
    onError: error => dispatchBanner({ type: 'show', payload: { message: error } })
  });

  function handleConnection(connection: LocalConnection) {
    setConnection(connection);
    setQueryResult(undefined);
    setLastQuery('');
    dispatchExpandedSearchedSchemas({ type: 'reset' });
    dispatchExpandedSchemas(connection.id, { type: 'reset' });
  }

  React.useEffect(() => {
    return () => {
      resetAllExpandedSchemas();
    };
  }, []);

  if (!connectionsLoading && connections.length === 0) {
    return (
      <EmptyPage message="Connect Polytomic to a database in order to use Query runner.">
        <Link className={PRIMARY_LINK_BTN_STYLES} to={routes.connectionsPicker}>
          Create a database connection
        </Link>
      </EmptyPage>
    );
  }

  return (
    <PageLayout
      sideNavInnerHeading="Query runner"
      sideNavInnerContent={
        connection?.id ? (
          <SchemaExplorer
            key={connection.id}
            connectionId={connection.id}
            refreshLoading={refreshLoading}
          />
        ) : (
          <div className="p-4">
            <Icon name="InfoFilled" className="mb-1 h-5 w-5 text-gray-400" />
            <p className="text-sm text-gray-500">
              Select a connection to write your query against.
            </p>
          </div>
        )
      }
      loading={connectionsLoading}
    >
      <div className="flex flex-col space-y-6 p-6 max-h-full">
        {connections.every(connection => connection.disabled) && (
          <LacksPermissionBanner message="You do not have query permissions." />
        )}
        <div>
          <Label>Connection</Label>
          <ConnectionSelect
            isDisabled={refreshLoading}
            autoFocus={!connection}
            isLoading={connectionsLoading}
            value={connection}
            options={connections}
            onChange={handleConnection}
            className="w-full max-w-xs"
          />
          {connection && (
            <RefreshRunnerSchema
              key={connection.id}
              connectionId={connection.id}
              refreshLoading={refreshLoading}
              setRefreshLoading={setRefreshLoading}
            />
          )}
        </div>
        {connection && (
          <AclProvider value={connection.acl}>
            <QueryRunnerEditor
              connectionId={connection.id}
              setQueryResult={setQueryResult}
              lastQuery={lastQuery}
              setLastQuery={setLastQuery}
              setQueryLoading={setQueryLoading}
            />
            {queryLoading ? (
              <div className="flex items-center justify-center pt-8">
                <LoadingDots />
              </div>
            ) : (
              queryResult && (
                <TableWrap className="flex-1 overflow-clip mt-6 animate-fadeIn flex flex-col ">
                  <LastQuery
                    lastQuery={lastQuery}
                    rightSlot={
                      connection.type.operations.includes(Operation.Fieldsetquery) && (
                        <Permission type={ResourceType.Model}>
                          <Link
                            to={routes.createModel}
                            onClick={() => {
                              setTempLocal<CreateModelLocalStorage>('createModel', {
                                connectionId: connection.id,
                                query: lastQuery
                              });
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link font-normal"
                          >
                            Create model
                          </Link>
                        </Permission>
                      )
                    }
                  />
                  <>
                    {(!!queryResult.rows.length || !!queryResult.count) && (
                      <TableTopper className="sticky top-0 flex h-12 min-w-full items-center justify-between space-x-1.5  whitespace-nowrap bg-white px-3.5">
                        <p>
                          <span className="mr-2">Results</span>
                          {!!queryResult.count ? (
                            <span className="font-normal text-gray-500">
                              Showing {queryResult.rows.length || 0} of{' '}
                              <strong>{queryResult.count}</strong> total
                            </span>
                          ) : (
                            <span className="font-normal text-gray-500">(50 row limit)</span>
                          )}
                        </p>
                        {!!queryResult.rows.length && (
                          <Permission type={Action.Export}>
                            <LinkButton onClick={toggleExportDialog} className="font-normal">
                              Export to CSV
                            </LinkButton>
                          </Permission>
                        )}
                        {showExportDialog && (
                          <QueryExportDialog
                            query={lastQuery}
                            connectionId={connection.id}
                            toggle={toggleExportDialog}
                          />
                        )}
                      </TableTopper>
                    )}
                    {!!queryResult.rows.length ? (
                      <div className="flex-1 overflow-clip">
                        <RunnerResultsTable data={queryResult} />
                      </div>
                    ) : (
                      <p className="flex animate-fadeIn items-center justify-center space-x-1.5 rounded-b bg-gray-50 py-8">
                        <Icon name="DangerFilled" className="h-5 w-5 text-gray-500" />
                        <span className="text-sm text-gray-600">No rows matched your query.</span>
                      </p>
                    )}
                  </>
                </TableWrap>
              )
            )}
          </AclProvider>
        )}
      </div>
    </PageLayout>
  );
}
export default QueryRunner;
