import { Reference, useMutation } from '@apollo/client';
import cx from 'clsx';
import * as React from 'react';

import { Button, MyInput } from '../../components';
import { CreateRoleDocument, UpdateRoleDocument } from '../../generated/graphql';
import { useBannerDispatch } from '../../hooks';
import { RoleWithUser } from '../../utils';
import { Dialog } from '~/components/v3';

const wrapperStyles = 'mb-3';

type Props =
  | {
      type: 'create';
      role?: never;
      toggleDialog: () => void;
      setToggle: (id: string) => void;
    }
  | {
      type: 'edit';
      role: RoleWithUser;
      toggleDialog: () => void;
      setToggle?: never;
    };

export function EditRoleDialog({ type, role, toggleDialog, setToggle }: Props) {
  const dispatchBanner = useBannerDispatch();
  const [roleName, setRoleName] = React.useState('');

  const [createRole, { loading: createLoading }] = useMutation(CreateRoleDocument, {
    onError: error => {
      dispatchBanner({
        type: 'show',
        payload: {
          message: error,
          wrapper: wrapperStyles
        }
      });
      toggleDialog();
    },
    update: (cache, { data }) => {
      if (!data || !data.createRole) {
        return;
      }
      cache.modify({
        fields: {
          allRoles: (existing: Reference[], { toReference }) => {
            return [...existing, toReference(data.createRole)];
          }
        }
      });
      setToggle?.(data.createRole.id);
      toggleDialog();
    }
  });

  const [updateRole, { loading: updateLoading }] = useMutation(UpdateRoleDocument, {
    onCompleted: () => {
      toggleDialog();
    },
    onError: error => {
      dispatchBanner({
        type: 'show',
        payload: {
          message: error,
          wrapper: wrapperStyles
        }
      });
      toggleDialog();
    }
  });

  const handleCreate = React.useCallback(() => {
    if (!roleName) {
      return;
    }
    dispatchBanner({ type: 'hide' });
    void createRole({ variables: { name: roleName } });
  }, [createRole, dispatchBanner, roleName]);

  const handleUpdate = React.useCallback(() => {
    if (!role?.id || !roleName) {
      return;
    }
    dispatchBanner({ type: 'hide' });
    void updateRole({
      variables: {
        update: {
          id: role.id,
          name: roleName
        }
      }
    });
  }, [dispatchBanner, role?.id, roleName, updateRole]);

  React.useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        type === 'create' ? handleCreate() : handleUpdate();
      }
    };
    document.addEventListener('keydown', callback, false);
    return () => {
      document.removeEventListener('keydown', callback, false);
    };
  }, [handleCreate, handleUpdate, type]);

  return (
    <Dialog
      show
      size="md"
      heading={cx(type === 'create' ? 'Create' : 'Rename', 'role')}
      actions={
        <>
          <Button onClick={toggleDialog}>Cancel</Button>
          <Button
            theme="primary"
            onClick={type === 'create' ? handleCreate : handleUpdate}
            disabled={createLoading || updateLoading}
          >
            {cx(type === 'create' ? 'Create' : 'Rename', 'role')}
          </Button>
        </>
      }
    >
      <div className="space-y-4">
        {type === 'edit' && (
          <div className="space-y-1">
            <p className="text-sm font-medium text-gray-800">Current name</p>
            <p className="text-sm text-gray-800">{role.name}</p>
          </div>
        )}
        <div className="max-w-xs">
          <MyInput
            label="New role name"
            name="new-role-name"
            placeholder="Enter name..."
            value={roleName}
            onChange={e => setRoleName(e.target.value)}
          />
        </div>
      </div>
    </Dialog>
  );
}
