import * as React from 'react';
import { useParams } from 'react-router-dom';

import { EditPermission, LacksPermissionBanner, SideBySide } from '../../../components';
import { AddSubscriber } from './add-subscriber';
import { AutoErrorRetryToggle } from './auto-error-retry-toggle';
import { PartialFailureToggle } from './partial-failure-toggle';
import { WarningNotificationsToggle } from './warning-notification-toggle';

const SyncNotifications = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <div className="animate-fadeIn">
      <EditPermission fallback={<LacksPermissionBanner wrapper="px-3 pt-3" />} />
      <div className="divide-y divide-gray-300 pb-32">
        <SideBySide hasSectionWrap heading="Error notifications" styles="space-y-4">
          <PartialFailureToggle id={id} />
          <WarningNotificationsToggle id={id} />
        </SideBySide>
        <SideBySide hasSectionWrap heading="Error retries" styles="space-y-4">
          <AutoErrorRetryToggle id={id} />
        </SideBySide>
        <AddSubscriber id={id} notificationEvent="sync.failed" />
      </div>
    </div>
  );
};

export default SyncNotifications;
