import PageLayout from '~/components/v2/layout/PageLayout';
import { Icon } from '~/components';

export type Route404Props = null;

const Route404 = () => {
  //SX
  const ROOT_SX = 'mx-auto p-8';
  const CONTAINER_SX = 'max-h-48 w-full max-w-lg space-y-2 px-8 pt-6 pb-7';
  const ICON_SX = 'h-8 w-8 text-orange-500';
  const TEXT_SX = 'text-sm font-medium uppercase text-gray-800';

  return (
    <PageLayout>
      <div className={ROOT_SX} />
      <div className={CONTAINER_SX}>
        <Icon name="DangerFilled" className={ICON_SX} />
        <p className={TEXT_SX}>ERROR 404 – Page not found</p>
      </div>
    </PageLayout>
  );
};

export default Route404;
