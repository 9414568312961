import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Section, SideBySide } from '../../../components';
import { HarmonicSource } from '../../../generated/graphql';
import {
  useApplyFieldsetUpdate,
  useApplyFieldsetUpdateLoading,
  useFieldsetState
} from '../../../hooks';
import { FieldsetFormValues, findName, getSchemaAsList, hasItems } from '../../../utils';
import { AdditionalConfig, EnumPicker, FieldsTable, FieldsTableWrapper } from '../model-components';

export function HarmonicFieldsetConfig() {
  const fieldset = useFieldsetState();
  const { control, getValues } = useFormContext<FieldsetFormValues>();
  const { state: loading } = useApplyFieldsetUpdateLoading();
  const { applyUpdate } = useApplyFieldsetUpdate();

  const source = useWatch({ control, name: 'configuration.source' });
  const streamID = useWatch({ control, name: 'configuration.streamID' });

  const list = React.useMemo(
    () => getSchemaAsList(fieldset?.configurationSchema, 'configuration'),
    [fieldset?.configurationSchema]
  );

  const handleSourceUpdate = React.useCallback(() => {
    applyUpdate(
      {
        source: getValues('configuration.source'),
        streamID: '',
        entity: ''
      },
      { refresh: true, resetFields: true }
    );
  }, [applyUpdate, getValues]);

  const handleStreamIdUpdate = React.useCallback(() => {
    applyUpdate(
      {
        source: getValues('configuration.source'),
        streamID: getValues('configuration.streamID'),
        // retain the entity, if any; the backend will validate/reset,
        // as needed.
        entity: getValues('configuration.entity')
      },
      { refresh: true, resetFields: true }
    );
  }, [applyUpdate, getValues]);

  const handleEntityUpdate = React.useCallback(() => {
    applyUpdate({ ...getValues('configuration') }, { refresh: true, resetFields: true });
  }, [applyUpdate, getValues]);

  const handleRefresh = React.useCallback(() => {
    applyUpdate({ ...getValues('configuration') }, { refresh: true });
  }, [applyUpdate, getValues]);

  return (
    <>
      <Section className="space-y-6">
        <SideBySide heading="Build model using">
          {fieldset?.configuration && fieldset.configurationSchema && (
            <div className="w-full max-w-xs animate-fadeIn space-y-3">
              <EnumPicker
                item={findName(list, 'configuration.source')}
                onChange={handleSourceUpdate}
              />
              {(source === HarmonicSource.SavedSearch ||
                source === HarmonicSource.SavedSearchPeople ||
                source === HarmonicSource.WatchlistCompanies ||
                source == HarmonicSource.SavedSearchGql ||
                source == HarmonicSource.SavedSearchPeopleGql ||
                source == HarmonicSource.WatchlistCompaniesGql ||
                source == HarmonicSource.WatchlistPeopleGql) && (
                <>
                  <EnumPicker
                    item={findName(list, 'configuration.streamID')}
                    onChange={handleStreamIdUpdate}
                  />
                  {streamID && (
                    <EnumPicker
                      item={findName(list, 'configuration.entity')}
                      onChange={handleEntityUpdate}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </SideBySide>
        <FieldsTableWrapper
          hasFields={hasItems(fieldset?.fields)}
          loading={loading}
          refresh={source ? handleRefresh : undefined}
          hasWriteinFields={fieldset?.properties.writeinFields}
        >
          <FieldsTable
            fields={fieldset?.fields}
            hasWriteinFields={fieldset?.properties.writeinFields}
          />
        </FieldsTableWrapper>
      </Section>
      <AdditionalConfig />
    </>
  );
}
