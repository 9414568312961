import { Icon } from '~/components';

interface Props {
  children?: React.ReactNode;
}

export function Layout(props: Props) {
  return (
    <>
      <header className="container max-w-xl mx-auto py-3">
        <a className="flex items-center gap-2" href="https://polytomic.com">
          <Icon name="PolytomicTriangle" className="h-12 w-12 text-neon" />
          <Icon name="PolytomicWordmark" className="h-6 w-[12.58125rem] text-gray-800" />
        </a>
      </header>
      <div className="h-[6px] bg-indigo-500"></div>
      <main
        className="
          container max-w-xl mx-auto pt-8 pb-4 space-y-6
          lg:border lg:border-gray-200 lg:rounded-sm lg:p-6 lg:mt-8
        "
      >
        {props.children}
      </main>
    </>
  );
}
