import cx from 'clsx';
import Select, { components, OptionProps } from 'react-select';

import {
  DropdownIndicator,
  isSingleValue,
  LocalConnection,
  Selectable,
  selectStyles
} from '../../utils';
import { Tooltip } from '../tooltip';
import { Icon } from '../Icon';
import { useState } from 'react';

function formatOptionLabel(option: Selectable & { isDisabled?: boolean; data: LocalConnection }) {
  return (
    <div className="flex items-start">
      <Icon
        className={cx('mr-1.5 h-5 w-5', option.isDisabled && 'opacity-60')}
        match={option.data.type.id}
      />
      <div
        className={cx('self-center text-sm', option.isDisabled ? 'text-gray-500' : 'text-gray-800')}
      >
        {option.data.name}
      </div>
    </div>
  );
}

function Option(props: OptionProps<Selectable & { data: LocalConnection }, false>) {
  if (props.isDisabled) {
    return (
      <Tooltip
        placement="right"
        offset={[0, 6]}
        content="You do not have permission to use this connection."
      >
        <span>
          <components.Option {...props} />
        </span>
      </Tooltip>
    );
  }
  return <components.Option {...props} />;
}

function makeConnectionOption(connection: LocalConnection) {
  // structure data to make it digestable for react-select
  return {
    label: connection.name,
    value: connection.id,
    isDisabled: connection.disabled,
    data: connection
  };
}

interface ConnectionSelectProps {
  options: LocalConnection[];
  isLoading?: boolean;
  isDisabled?: boolean;
  onChange: (data: LocalConnection) => void;
  value?: LocalConnection | null;
  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
}

export function ConnectionSelect(props: ConnectionSelectProps) {
  const options = props.options.filter(c => !!c).map(makeConnectionOption);
  const [value, setValue] = useState(options.find(opt => opt.data.id === props.value?.id));

  const handleChange = option => {
    if (option == null || option?.value === value?.value) {
      return;
    }
    if (isSingleValue(option)) {
      setValue(option);
      props.onChange(option.data);
    }
  };

  return (
    <Select<Selectable & { data: LocalConnection }>
      aria-label="Connection"
      filterOption={(option, rawInput) => {
        const input = rawInput.trim().toLowerCase();
        return option?.label?.toLowerCase().includes(input);
      }}
      placeholder={props.placeholder}
      autoFocus={props.autoFocus || false}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled || props.isLoading}
      value={value}
      options={options}
      components={{
        Option,
        DropdownIndicator,
        ClearIndicator: null,
        IndicatorSeparator: null
      }}
      onChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      styles={selectStyles}
      className={cx(props.className)}
    />
  );
}
