import { RadioGroup } from '@headlessui/react';
import cx from 'clsx';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Icon } from '~/components';
import { BulkSyncForm } from '../../../utils';
import { StageCard } from '../../syncs/sync-config';

interface Props {
  completeModeStage: () => void;
}

export function StageBulkMode(props: Props) {
  const { control } = useFormContext<BulkSyncForm>();
  const modes = useWatch({ control, name: 'destination.supportedModes' });

  return (
    <StageCard hasStickyHeader={false} step={2} header="Choose sync mode">
      <Controller
        control={control}
        name="mode"
        render={({ field }) => (
          <RadioGroup
            value={field.value}
            onChange={val => {
              field.onChange(val);
              props.completeModeStage();
            }}
          >
            <div className="space-y-4 px-6">
              {modes?.map(display => (
                <RadioGroup.Option
                  key={display.mode}
                  value={display.mode}
                  className="group focus:outline-none"
                >
                  {({ checked }) => (
                    <span className="flex items-start space-x-1.5 group-hover:cursor-pointer">
                      <Icon
                        name={checked ? 'ActiveCircle' : 'EmptyCircle'}
                        className={cx(
                          'h-4 w-4 rounded-full group-hover:cursor-pointer group-focus-visible:ring-2 group-focus-visible:ring-indigo-500 group-focus-visible:ring-offset-2',
                          checked ? 'text-green-500' : 'text-gray-500'
                        )}
                      />
                      <span className="-mt-px">
                        <RadioGroup.Label className="leading-none group-hover:cursor-pointer">
                          {display.label}
                        </RadioGroup.Label>
                        <RadioGroup.Description className="block text-sm text-gray-500">
                          {display.description}
                        </RadioGroup.Description>
                      </span>
                    </span>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        )}
      />
    </StageCard>
  );
}
