import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import { memo, ReactNode, useEffect, useMemo, useState } from 'react';

import { SanitizeIdentifierDocument } from '~/generated/graphql';
import { isIconName } from '~/utils';
import { Truncator } from '~/components/truncator';
import { COLOR } from '~/components/v2/configs/SX';
import Chip from '~/components/v2/display/Chip';
import { FIELD_MAPPING_TYPE } from '~/components/v2/experimental/StageMappings';
import { Icon } from '~/components';

export type FieldMappingButtonProps = {
  buttonRef?: React.LegacyRef<HTMLButtonElement> | null;
  onFocus?: () => void;
  fieldType: FIELD_MAPPING_TYPE;
  mappingIndex: number;
  connectionId?: string;
  selected?: boolean;
  error?: ReactNode;
  disabled?: boolean;
  placeholder?: ReactNode;
  onClick: (t: FIELD_MAPPING_TYPE, idx: number) => void;
  iconId?: string;
  label?: string | undefined | null;
  isNew?: boolean;
  sanitize?: boolean;
  fieldDetail?: ReactNode;
};

export const FieldMappingButton = ({
  buttonRef,
  fieldType,
  mappingIndex,
  connectionId,
  selected,
  error,
  disabled = false,
  placeholder,
  onClick,
  iconId,
  label,
  isNew = false,
  sanitize = false,
  fieldDetail
}: FieldMappingButtonProps) => {
  const HOVER_SX = `hover:cursor-pointer hover:bg-indigo-50 hover:border-indigo-50`;
  const ROOT_SX = `min-w-full w-full h-full flex content-start items-center p-1 overflow-clip`;
  const BUTTON_SX = useMemo(
    () =>
      clsx(
        'w-full rounded border py-1.25 px-2 flex overflow-clip',
        'outline-none focus:outline-none focus:ring-2 focus:ring-indigo-500',
        error && 'ring-1 ring-red-500',
        disabled && 'invisible',
        selected && 'bg-indigo-100 border-indigo-100',
        !disabled && !selected && label && 'border-gray-50 bg-gray-50',
        !disabled && !selected && !label && 'border-gray-50 bg-gray-50 text-gray-500',
        !disabled && !selected && HOVER_SX
      ),
    [HOVER_SX, disabled, error, label, selected]
  );
  const CONTENT_SX = clsx('flex h-full w-full space-x-2 items-center', disabled && 'invisible');

  return (
    <div className={ROOT_SX}>
      <div className="flex h-full w-full flex-col">
        <button
          ref={buttonRef}
          id="FieldMappingButton"
          className={BUTTON_SX}
          onClick={() => !disabled && onClick(fieldType, mappingIndex)}
        >
          <div className={CONTENT_SX}>
            <FieldMappingButtonIcon iconId={iconId} label={label} />
            <FieldMappingButtonLabel
              label={label}
              placeholder={placeholder}
              connectionId={connectionId}
              sanitize={sanitize}
            />

            <Chip color={COLOR.SUCCESS} enableHighlight children="New" hidden={!isNew} bold />
          </div>
        </button>
        {error && <p className="mt-1 text-xs font-medium text-red-500">{error}</p>}
        {fieldDetail}
      </div>
    </div>
  );
};

const FieldMappingButtonIcon = memo<Partial<FieldMappingButtonProps>>(({ iconId, label }) => {
  const getIcon = () => {
    if (isIconName(iconId)) {
      return <Icon match={iconId} className="h-4 w-4 text-gray-500" />;
    }
    return null;
  };

  if (!label) return null;

  return getIcon() || <Icon name="TypeString" className="h-4 w-4 text-gray-500" />;
});

const FieldMappingButtonLabel = memo<Partial<FieldMappingButtonProps>>(
  ({ label, placeholder, connectionId, sanitize, error }) => {
    const LABEL_SX = 'text-sm overflow-clip truncate';
    const [formattedLabel, setFormattedLabel] = useState<string | undefined | null>('');

    const [getSanitized, { loading, data }] = useLazyQuery(SanitizeIdentifierDocument);

    useEffect(() => {
      if (label) {
        if (connectionId && sanitize) {
          void getSanitized({ variables: { idString: label, connectionId } });
          data && setFormattedLabel(data.sanitizeIdentifier);
        } else {
          setFormattedLabel(label);
        }
      } else {
        setFormattedLabel(label);
      }
    }, [label, connectionId, sanitize, getSanitized, data]);

    return loading ? (
      <span className={LABEL_SX}>{'...'}</span>
    ) : (
      <Truncator content={label || placeholder}>
        <span className={LABEL_SX}>{formattedLabel || placeholder}</span>
      </Truncator>
    );
  }
);

export default FieldMappingButton;
