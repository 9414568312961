import clsx from 'clsx';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { FilterValueElements, LinkButton, MyCombobox, MyInput, ParamButton } from '~/components';
import { SIZE } from '~/components/v2/configs/SX';
import FieldMappingButton from '~/components/v2/experimental/FieldMappingButton';
import { FIELD_MAPPING_TYPE } from '~/components/v2/experimental/StageMappings';
import { Icon } from '~/components';
import { CompletionValue, ModelFieldFragment } from '~/generated/graphql';
import { getLetters, toSelectables } from '~/utils';

export type ExploreFilter = {
  function: string;
  value: string | string[] | CompletionValue;
  label: string;
  fieldID?: string;
  fieldType?: string;
};
interface ExploreFiltersProps {
  filters: ExploreFilter[];
  conditionLogic: string;
  reachableFields: ModelFieldFragment[];
  showFilters: boolean;
  letterIdx: number;
  showLogic: boolean;
  setShowLogic: Dispatch<SetStateAction<boolean>>;
  setLetterIdx: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<ExploreFilter[]>>;
  setConditionLogic: Dispatch<SetStateAction<string>>;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  setShowBottomNav: Dispatch<SetStateAction<boolean>>;
  setBottomNavConfig: Dispatch<SetStateAction<any>>;
}

export function ExploreFilters({
  filters,
  conditionLogic,
  reachableFields,
  showFilters,
  letterIdx,
  showLogic,
  setShowLogic,
  setLetterIdx,
  setFilters,
  setConditionLogic,
  setShowFilters,
  setShowBottomNav,
  setBottomNavConfig
}: ExploreFiltersProps) {
  const handleFilterFieldClick = (index: number) => {
    setShowBottomNav(true);
    setBottomNavConfig({ config: FIELD_MAPPING_TYPE.FILTER_TARGET, mappingIndex: index });
  };

  const handleAdd = useCallback(() => {
    setFilters(f => [...f, { label: showLogic ? getLetters(letterIdx) : '' }] as ExploreFilter[]);
    if (showLogic) {
      setConditionLogic(`${conditionLogic} AND ${getLetters(letterIdx)}`);
      setLetterIdx(prev => prev + 1);
    }
  }, [letterIdx, showLogic, conditionLogic]);

  const handleDelete = useCallback(
    (index: number) => {
      if (filters.length === 2) {
        setConditionLogic('');
        setFilters(filters => filters.map(f => ({ ...f, label: '' })));
        setLetterIdx(0);
        setShowLogic(false);
      }
      setFilters(filters => filters.filter((f, idx) => index !== idx));
    },
    [filters, showLogic, conditionLogic]
  );

  const toggleLogic = () => {
    if (!showLogic) {
      setFilters(filters.map((f, i) => ({ ...f, label: getLetters(i) })));
      setConditionLogic(filters.map((f, i) => getLetters(i)).join(' AND '));
      setLetterIdx(filters.length);
      setShowLogic(true);
    } else {
      setFilters(filters.map(f => ({ ...f, label: '' })));
      setConditionLogic('');
      setLetterIdx(0);
      setShowLogic(false);
    }
  };
  return (
    <>
      {!filters.length && filters.length === 0 && (
        <div className="w-full p-2 items-center content-center border-b border-gray-300 ">
          {/* FILTERS */}
          <div className="flex flex-row space-x-2 items-center content-center ">
            <div className="uppercase">Filter</div>
            <ParamButton
              action="add"
              onClick={() => {
                setFilters([{ function: '', value: '', label: filters.length?.toString() }]);
                setShowFilters(true);
              }}
            />
          </div>
        </div>
      )}
      {filters?.length > 0 && (
        <div className="w-full p-2 pb-1 transition-all duration-150 border-b border-gray-300">
          {/* Filters expand button */}
          <div className="flex flex-row space-x-2">
            <button
              disabled={!filters.length}
              onClick={() => setShowFilters(f => !f)}
              className={clsx(
                'flex flex-row items-center rounded text-gray-500 p-1 focus:outline-none',
                'hover:bg-gray-100 active:bg-gray-200 hover:text-gray-800'
              )}
            >
              {/* Caret Icon */}
              <div
                className={clsx('transform duration-150 ease-in-out', !showFilters && '-rotate-90')}
              >
                <Icon name="SelectSingle" size="sm" />
              </div>
              {/* Fields counter */}
              <div className="text-xs font-medium uppercase tracking-wider pl-2">
                {filters.length > 0 ? `${filters.length} Filters` : 'No filters applied'}
              </div>
            </button>
            <div>
              {showFilters && filters?.length > 1 && (
                <LinkButton className="animate-fadeIn text-right text-xs" onClick={toggleLogic}>
                  {showLogic ? 'Remove' : 'Add'} filter logic
                </LinkButton>
              )}
            </div>
          </div>
          {/* Filters list */}
          {showFilters && (
            <div className="flex flex-col space-y-1 max-w-[40rem]">
              {showLogic && (
                <div className="flex items-center bg-gray-200 rounded py-0.5">
                  <div className="flex h-full items-center space-x-1 pl-2.5 pr-1.5">
                    <span className="thead-text text-gray-500">Logic</span>
                  </div>

                  <MyInput
                    value={conditionLogic}
                    onChange={e => setConditionLogic(e.target.value)}
                    className="rounded-l-none font-mono"
                  />
                </div>
              )}
              {filters.map((filter, idx) => {
                const field = reachableFields?.find(f => f.id === filter.fieldID);
                return (
                  <div
                    key={idx}
                    className={clsx(
                      'w-full flex items-center space-x-2',
                      showLogic && ' bg-gray-200 rounded px-2 py-0.5'
                    )}
                  >
                    {showLogic && (
                      <p className="min-w-10 px-2 min-h-full flex rounded-l self-stretch items-start justify-center pt-2.5  font-semibold uppercase text-gray-800">
                        {filter?.label || ''}
                      </p>
                    )}
                    <div className="flex-1">
                      <FieldMappingButton
                        fieldType={FIELD_MAPPING_TYPE.FILTER_TARGET}
                        mappingIndex={idx}
                        iconId={field?.fieldset?.connection?.type?.id}
                        label={field?.label}
                        onClick={() => handleFilterFieldClick(idx)}
                      />
                    </div>
                    <div className="flex-1">
                      <MyCombobox
                        variant="flat"
                        options={
                          field?.filterFunctions ? toSelectables(field?.filterFunctions) : []
                        }
                        value={
                          field?.filterFunctions
                            ? toSelectables(field?.filterFunctions).find(
                                f => f.id === filter.function
                              )
                            : null
                        }
                        onChange={value =>
                          setFilters(f => {
                            const newFilters = [...f];
                            newFilters[idx].function = value.id;
                            return newFilters;
                          })
                        }
                      />
                    </div>
                    <div className="flex-1">
                      <FilterValueElements
                        obj={{
                          label: filter.label,
                          field: field,
                          value: filter.value,
                          //@ts-expect-error its okay
                          function: filter.function
                        }}
                        handleValue={value =>
                          setFilters(f => {
                            const newFilters = [...f];
                            newFilters[idx].value = value;
                            return newFilters;
                          })
                        }
                        index={idx}
                        variant="flat"
                      />
                    </div>
                    <ParamButton action="delete" onClick={() => handleDelete(idx)} />
                    <ParamButton action="add" onClick={handleAdd} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
}
