import clsx from 'clsx';
import Divider from './Divider';
import { cn } from '~/lib/utils';

// Todo - add comments
export type CardProps = {
  className?: string;
  header?: React.ReactNode;
  headerTitle?: React.ReactNode;
  headerSubtitle?: React.ReactNode;
  headerActions?: React.ReactNode;
  headerDivider?: boolean;
  footerTitle?: React.ReactNode;
  footerActions?: React.ReactNode;
  noContentPadding?: boolean;
  children?: React.ReactNode;
  contentClasses?: string;
};

// Todo - add comments
const Card = ({
  className,
  header,
  headerActions,
  headerTitle,
  headerSubtitle,
  headerDivider = false,
  footerTitle,
  footerActions,
  noContentPadding = false,
  children,
  contentClasses
}: CardProps) => {
  const CARD_SX =
    'flex flex-col bg-white shadow shadow-card border rounded-md border-gray-300 w-full';
  const CONTENT_SX = clsx('flex-1 flex flex-col', !noContentPadding && 'p-4 py-2', contentClasses);
  const HEADER_SX = 'flex w-full bg-gray-50 justify-between items-center px-4 py-3';
  const HEADER_TITLE_SX = 'text-sm text-gray-800';
  const HEADER_SUBTITLE_SX = 'text-xs font-light text-gray-600';
  const HEADER_ACTIONS_SX = 'flex gap-2';
  const FOOTER_SX = 'flex w-full justify-between items-center p-4';
  const FOOTER_TITLE_SX = 'text-sm font-light text-gray-600';
  const FOOTER_ACTIONS_SX = 'flex gap-2';

  return (
    <div className={cn(CARD_SX, className)}>
      <div className={HEADER_SX}>
        {header || (
          <>
            <div>
              <p className={HEADER_TITLE_SX}>{headerTitle}</p>
              <p className={HEADER_SUBTITLE_SX}>{headerSubtitle}</p>
            </div>
            <div className={HEADER_ACTIONS_SX}>{headerActions}</div>
          </>
        )}
      </div>
      {headerDivider && <Divider />}
      <div className={CONTENT_SX}>{children}</div>
      {(!!footerTitle || !!footerActions) && (
        <div className={FOOTER_SX}>
          <p className={FOOTER_TITLE_SX}>{footerTitle}</p>
          <div className={FOOTER_ACTIONS_SX}>{footerActions}</div>
        </div>
      )}
    </div>
  );
};

export default Card;
