import { getValueAsFormattedString } from '../utils';
import { Truncator } from './truncator';

export function TableTruncatedCell({ value }: { value: string }) {
  const val = getValueAsFormattedString(value);
  return (
    <Truncator
      offset={[0, 4]}
      className="w-max max-w-lg"
      placement="auto"
      content={
        val ? (
          <pre className="overflow-hidden text-xs font-normal leading-4">{val}</pre>
        ) : (
          <span className="break-words text-sm leading-5">{String(value)}</span>
        )
      }
    >
      <p className="hide-native-tooltip w-full max-w-[15rem] cursor-default truncate py-1.5 text-sm text-gray-800">
        {val}
      </p>
    </Truncator>
  );
}
