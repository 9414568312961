import clsx from 'clsx';

import { COLOR, SIZE, TextColor, TextSize } from '../configs/SX';

export type InputLabelProps = {
  // String
  inputId?: string;
  // Boolean
  enablePointer?: boolean;
  // Slots
  children?: React.ReactNode;
  // SX
  color?: keyof typeof TextColor;
  size?: keyof typeof TextSize;
};

const InputLabel = ({
  // String
  inputId = undefined,
  // Boolean
  enablePointer = true,
  // Slots
  children = null,
  // SX
  color = COLOR.DEFAULT,
  size = SIZE.MEDIUM
}: InputLabelProps) => {
  // SX
  const ROOT_SX = clsx(
    'leading-tight',
    TextColor[color],
    TextSize[size],
    enablePointer && 'cursor-pointer'
  );

  return (
    <label className={ROOT_SX} htmlFor={inputId}>
      {children}
    </label>
  );
};

export default InputLabel;
