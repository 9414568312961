import cx from 'clsx';
import { Link } from 'react-router-dom';

import { Icon } from '~/components';

interface Props {
  pathname: string;
  label: string;
  isEditing: boolean;
}

export function Field({ pathname, label, isEditing }: Props) {
  return (
    <li>
      <Link
        to={{ pathname }}
        className={cx(
          'flex w-full items-center justify-between rounded py-1.5 pl-6 pr-4 text-sm text-gray-800 hover:bg-indigo-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-400',
          isEditing && 'bg-indigo-100'
        )}
      >
        {label}
        {isEditing ? <Icon name="FastForward" className="h-5 w-5 text-indigo-500" /> : null}
      </Link>
    </li>
  );
}
