import cx from 'clsx';
import * as React from 'react';
import { Controller, useFieldArray, UseFormReturn, useWatch } from 'react-hook-form';

import { Icon } from '~/components';
import { Button, MyCombobox, ParamButton } from '../../../components';
import { NumericComparator } from '../../../generated/graphql';
import { useToggle } from '../../../hooks';
import {
  EMPTY_FILTER,
  hasItems,
  objToSelectables,
  plural,
  Selectable,
  SyncHistoryFilter
} from '../../../utils';
import { HistoryFilterComparators } from './history-filter-comparators';
import { HistoryFilterParams } from './history-filter-params';

interface Props {
  methods: UseFormReturn<{ filters: SyncHistoryFilter[] }>;
  applyFiltersControl: React.ReactNode;
  historyFilters: Record<string, string>;
  historyFilterParams?: Record<string, string[]>;
}

export function HistoryFilters({ historyFilters, historyFilterParams, ...props }: Props) {
  const [showFilters, toggleFilters] = useToggle(true);
  const columns = React.useMemo(() => objToSelectables(historyFilters, true), [historyFilters]);
  const [filteredColumns, setFilteredColumns] = React.useState<Selectable[]>(
    objToSelectables(historyFilters, true)
  );

  const { control, setValue, reset } = props.methods;

  const filters = useWatch({ control, name: 'filters' });

  const { fields, append, remove } = useFieldArray({
    control: props.methods.control,
    name: 'filters',
    shouldUnregister: true
  });

  React.useEffect(() => {
    if (hasItems(filters)) {
      const newColumns = columns.filter(
        ({ value }) => !filters.find(filter => filter.column === value)
      );
      setFilteredColumns(newColumns);
    }
  }, [filters, columns]);

  return (
    <div
      className={cx(
        'sticky top-0 left-0 z-40 border-b border-gray-300 bg-gray-100 px-6 pt-3',
        showFilters ? 'pb-5' : 'pb-2.25'
      )}
    >
      {fields.length === 0 ? (
        <div className="-mb-2.25 flex items-center space-x-2">
          <label className="thead-text mt-0.5">FILTER</label>
          <ParamButton
            action="add"
            className="focus-visible:ring-offset-gray-100"
            onClick={() => append(EMPTY_FILTER)}
          />
        </div>
      ) : (
        <div className="space-y-2">
          <Button theme="ghost" size="mini" onClick={toggleFilters}>
            <Icon
              name="Disclosure"
              className={cx('-ml-1 mr-1 h-5 w-5', showFilters && 'rotate-90 transform')}
            />
            <span className="thead-text mt-0.5 text-gray-800">
              {fields.length} {plural('filter', fields.length > 1)}
            </span>
          </Button>

          <div
            className={cx(
              showFilters
                ? 'grid max-w-2xl grid-cols-[repeat(2,10rem),14rem,repeat(2,1.25rem)] items-center gap-2'
                : 'hidden'
            )}
          >
            {fields.map((filter, index) => {
              return (
                <React.Fragment key={filter.id}>
                  <div className="static col-start-1 col-end-2">
                    <Controller
                      name={`filters.${index}.column`}
                      control={props.methods.control}
                      defaultValue={filter.column}
                      render={({ field }) => {
                        return (
                          <MyCombobox
                            autoFocus={!field.value}
                            placeholder="Table columns..."
                            value={
                              field.value
                                ? ({
                                    label: historyFilters[field.value] || field.value,
                                    value: field.value
                                  } as Selectable)
                                : null
                            }
                            options={filteredColumns}
                            onChange={option => {
                              field.onChange(option?.value);
                              setValue(`filters.${index}`, {
                                column: option?.value,
                                comparator:
                                  option && ['time', 'trigger', 'status'].includes(option.value)
                                    ? NumericComparator.Eq
                                    : undefined,
                                param: ''
                              });
                            }}
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="static col-start-2 col-end-3">
                    <HistoryFilterComparators
                      filter={filter}
                      index={index}
                      methods={props.methods}
                    />
                  </div>
                  <div className="static col-start-3 col-end-4">
                    <HistoryFilterParams
                      filter={filter}
                      index={index}
                      methods={props.methods}
                      params={historyFilterParams}
                    />
                  </div>
                  <ParamButton
                    className="col-start-4 col-end-5 mt-0.5 focus-visible:ring-offset-gray-100"
                    action="delete"
                    onClick={() => {
                      remove(index);
                      if (fields.length === 1) {
                        reset({ filters: [] });
                      }
                    }}
                  />
                  <ParamButton
                    className={cx(
                      index === filters?.length - 1 && index <= columns.length - 2
                        ? 'visible'
                        : 'invisible',
                      'col-start-5 col-end-6 mt-0.5 focus-visible:ring-offset-gray-100'
                    )}
                    action="add"
                    onClick={() => append(EMPTY_FILTER)}
                  />
                </React.Fragment>
              );
            })}
            <div className="col-span-full block">{props.applyFiltersControl}</div>
          </div>
        </div>
      )}
    </div>
  );
}
