import { BulkSyncIndexFragment, SyncListItemFragment } from '../generated/graphql';
import { SyncGroup, SyncGroupObject } from './custom-types.util';

export function groupSyncsByConnection(syncs: SyncListItemFragment[]): SyncGroup[] {
  const syncsByConnectionId = sortSyncsList(syncs).reduce(
    (syncGroupObject: SyncGroupObject, sync: SyncListItemFragment) => {
      const { targetConnection } = sync;

      if (!targetConnection) {
        return syncGroupObject;
      }
      const {
        id,
        name,
        type: { id: logo }
      } = targetConnection;

      if (!(id in syncGroupObject)) {
        syncGroupObject[id] = { id, name, logo, syncs: [] };
      }

      syncGroupObject[id].syncs.push(sync);

      return syncGroupObject;
    },
    {}
  );

  return Object.values(syncsByConnectionId);
}

export function sortSyncsList(syncs?: SyncListItemFragment[]) {
  if (!syncs || syncs.length === 0) {
    return [];
  }
  return [...syncs].sort((sync1, sync2) => {
    if (sync1.targetConnection && sync2.targetConnection) {
      // order by targetConnection name alphabetically
      if (
        sync1.targetConnection.name.toLocaleLowerCase() >
        sync2.targetConnection.name.toLocaleLowerCase()
      ) {
        return 1;
      }
      if (
        sync1.targetConnection.name.toLocaleLowerCase() <
        sync2.targetConnection.name.toLocaleLowerCase()
      ) {
        return -1;
      }
    }
    if (sync1.targetObject && sync2.targetObject) {
      // order by targetObject name alphabetically
      if (
        sync1.targetObject.name.toLocaleLowerCase() > sync2.targetObject.name.toLocaleLowerCase()
      ) {
        return 1;
      }
      if (
        sync1.targetObject.name.toLocaleLowerCase() < sync2.targetObject.name.toLocaleLowerCase()
      ) {
        return -1;
      }
    }
    // order by sync name alphabetically
    if (sync1.name.toLocaleLowerCase() > sync2.name.toLocaleLowerCase()) {
      return 1;
    }
    if (sync1.name.toLocaleLowerCase() < sync2.name.toLocaleLowerCase()) {
      return -1;
    }
    // order by sync id alphabetically
    if (sync1.id.toLocaleLowerCase() > sync2.id.toLocaleLowerCase()) {
      return 1;
    }
    if (sync1.id.toLocaleLowerCase() < sync2.id.toLocaleLowerCase()) {
      return -1;
    }
    return 0;
  });
}
export function groupBulkSyncsByConnection(
  bulkSyncs: BulkSyncIndexFragment[]
): SyncGroup<BulkSyncIndexFragment>[] {
  const syncsByConnectionId = sortBulkSyncsList(bulkSyncs).reduce(
    (syncGroupObject: SyncGroupObject<BulkSyncIndexFragment>, sync: BulkSyncIndexFragment) => {
      const connection = sync?.destination?.connection;

      if (!connection) {
        return syncGroupObject;
      }
      const {
        id,
        name,
        type: { id: logo }
      } = connection;

      if (!(id in syncGroupObject)) {
        syncGroupObject[id] = { id, name, logo, syncs: [] };
      }

      syncGroupObject[id].syncs.push(sync);

      return syncGroupObject;
    },
    {}
  );

  return Object.values(syncsByConnectionId);
}

export function sortBulkSyncsList(bulkSyncs?: BulkSyncIndexFragment[]) {
  if (!bulkSyncs || bulkSyncs.length === 0) {
    return [];
  }
  return [...bulkSyncs].sort((sync1, sync2) => {
    if (sync1.destination?.connection && sync2.destination?.connection) {
      // order by destination.connection.name alphabetically
      if (
        sync1.destination?.connection.name.toLocaleLowerCase() >
        sync2.destination?.connection.name.toLocaleLowerCase()
      ) {
        return 1;
      }
      if (
        sync1.destination?.connection.name.toLocaleLowerCase() <
        sync2.destination?.connection.name.toLocaleLowerCase()
      ) {
        return -1;
      }
      // order by destination name alphabetically
      if (
        sync1.destination?.connection.name.toLocaleLowerCase() >
        sync2.destination?.connection.name.toLocaleLowerCase()
      ) {
        return 1;
      }
      if (
        sync1.destination.connection.name.toLocaleLowerCase() <
        sync2.destination.connection.name.toLocaleLowerCase()
      ) {
        return -1;
      }
    }
    // order by sync name alphabetically
    if (sync1.name.toLocaleLowerCase() > sync2.name.toLocaleLowerCase()) {
      return 1;
    }
    if (sync1.name.toLocaleLowerCase() < sync2.name.toLocaleLowerCase()) {
      return -1;
    }
    // order by sync id alphabetically
    if (sync1.id.toLocaleLowerCase() > sync2.id.toLocaleLowerCase()) {
      return 1;
    }
    if (sync1.id.toLocaleLowerCase() < sync2.id.toLocaleLowerCase()) {
      return -1;
    }
    return 0;
  });
}
