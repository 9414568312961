import cx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { Icon } from '~/components';
import { FormDefinition, SupportedMode } from '~/generated/graphql';
import { Button, Checkbox, JSONSchemaForm } from '../../../components';
import { useToggle } from '../../../hooks';

export interface Props {
  targetMode: SupportedMode;
  syncAllRecords: boolean;
  onChangeSyncAll: (bool) => void;
  form?: FormDefinition;
}

export function AdvancedSettings(props: Props) {
  if (!props.form && !props.targetMode?.supportsIncremental) {
    return null;
  }
  const { setValue, watch } = useFormContext();

  return (
    <AdvancedSettingsToggle>
      <div className="mt-3 animate-fadeIn px-6">
        <section className="grid w-full max-w-xl gap-3">
          {props.form && (
            <JSONSchemaForm
              schema={props.form.jsonschema}
              formData={watch('targetObjectConfiguration')}
              uiSchema={{
                'ui:order': props.form?.uischema.order || undefined,
                'ui:submitButtonOptions': {
                  norender: true
                },
                bulkBatchSize: { 'ui:classNames': 'max-w-xs' },
                batchSize: { 'ui:classNames': 'max-w-xs' },
                concurrencyMode: { 'ui:classNames': 'max-w-xs' },
                metadata: {
                  'ui:classNames':
                    'grid grid-cols-[repeat(2,1fr),repeat(2,1.25rem)] items-center gap-x-2 gap-y-3'
                },
                sortBatchesBy: { 'ui:classNames': 'max-w-xs' }
              }}
              onChange={value => {
                setValue('targetObjectConfiguration', value);
              }}
            />
          )}
          {props.targetMode?.supportsIncremental && (
            <div className="flex items-center">
              <Checkbox
                label="Always do a full sync"
                checked={props.syncAllRecords}
                onChange={e => props.onChangeSyncAll(e.target.checked)}
              />
            </div>
          )}
        </section>
      </div>
    </AdvancedSettingsToggle>
  );
}

function AdvancedSettingsToggle(props: { children: React.ReactNode }) {
  const [show, toggle] = useToggle();
  return (
    <div className={cx('rounded-b-lg bg-gray-50 pt-3', show ? 'pb-6' : 'pb-3')}>
      <Button theme="ghost" className="ml-2" onClick={toggle}>
        <Icon
          name="Disclosure"
          className={cx(
            '-ml-1 mr-0.5 h-5 w-5 text-gray-500 group-hover:text-gray-800',
            show && 'rotate-90 transform'
          )}
        />
        Advanced settings
      </Button>
      {show && props.children}
    </div>
  );
}
