import { useQuery } from '@apollo/client';
import * as React from 'react';
import { Icon } from '~/components';
import * as Sentry from '@sentry/react';

import { Button, JsonViewer, Label, MyCombobox, TableWrap } from '~/components';
import ErrorText from '~/components/v2/feedback/ErrorText';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { ColumnDef, DataTable, Dialog } from '~/components/v3';
import { SyncExecutionDetailDocument } from '~/generated/graphql';
import {
  ExecutionRecordType,
  formatPreviewRecord,
  getEnumKeyByValue,
  hasItems,
  isSyncExecutionErrors,
  isSyncRecordMessage,
  isSyncRecordMessages,
  isSyncRecords,
  objToSelectables,
  PreviewFormat,
  previewRecordsToRows,
  SyncRecordPreview,
  SyncRecordPreviewData
} from '~/utils';
import { ExecutionWarningTypes } from '~/pages/syncs/execution-warning-types';

interface Props {
  heading: string;
  recordType: ExecutionRecordType | null;
  executionId: string;
  targetConnectionTypeId?: string;
  targetConnectionName?: string;
  targetObjectName?: string;
  handleDismiss: () => void;
  hidePreviewFormats?: boolean;
}

enum DownloadStatus {
  None = 'none',
  Downloading = 'downloading',
  Success = 'success',
  Error = 'error'
}

export function SyncExecutionRecordsPreview({ recordType, ...props }: Props) {
  const [previewFormat, setPreviewFormat] = React.useState<PreviewFormat>(PreviewFormat.Table);
  const { data, loading, error } = useQuery(SyncExecutionDetailDocument, {
    errorPolicy: 'all',
    variables: {
      executionID: props.executionId,
      includeErrors: recordType === 'errors',
      includeWarnings: recordType === 'warnings',
      includeRecords: recordType === 'records',
      includeInserts: recordType === 'inserts',
      includeUpdates: recordType === 'updates',
      includeDeletes: recordType === 'deletes'
    }
  });

  const [downloadStatus, setDownloadStatus] = React.useState<DownloadStatus>(DownloadStatus.None);

  const closeRef = React.useRef<HTMLButtonElement>(null);

  const warningTypes = data?.syncExecutionStatus?.warningTypes || [];

  let recordData: SyncRecordPreviewData | null = null;
  if (data) {
    if (data.syncExecutionStatus?.records) {
      recordData = data.syncExecutionStatus.records;
    } else if (data.syncExecutionStatus?.inserts) {
      recordData = data.syncExecutionStatus.inserts;
    } else if (data.syncExecutionStatus?.updates) {
      recordData = data.syncExecutionStatus.updates;
    } else if (data.syncExecutionStatus?.warnings) {
      recordData = data.syncExecutionStatus.warnings;
    } else if (data.syncExecutionStatus?.deletes) {
      recordData = data.syncExecutionStatus.deletes;
    } else if (data.syncExecutionStatus?.errors) {
      recordData = data.syncExecutionStatus.errors;
    }
    if (data.syncExecutionStatus?.executionErrors && !data.syncExecutionStatus?.errors?.hasData) {
      recordData = { errors: data.syncExecutionStatus?.executionErrors, download: null };
    }
  }

  const [records, availableResults, idField] = React.useMemo(() => {
    let availableResults = false;
    let records: SyncRecordPreview[] = [];
    let idField: string | null = null;
    if (!loading && recordData) {
      if (isSyncRecords(recordData)) {
        records = recordData.records;
        if (recordData.targetIDProperty) {
          idField = recordData.targetIDProperty;
        }
      } else if (isSyncRecordMessages(recordData)) {
        records = recordData.recordMessages;
      } else if (isSyncExecutionErrors(recordData)) {
        records = recordData.errors;
      }
      availableResults = hasItems(records);
    }
    return [records, availableResults, idField];
  }, [recordData, loading]);

  const previewTableColumns: ColumnDef<Partial<SyncRecordPreview>>[] = React.useMemo(() => {
    if (records.length === 0) {
      return [];
    }
    const idealRecord =
      records.find(rec => isSyncRecordMessage(rec) && rec.identityField) || records[0];
    return Object.keys(formatPreviewRecord(idealRecord, idField, recordType)).map(field => {
      return {
        header: field,
        accessorFn: data => data[field],
        cell: ({ cell }) => cell?.getValue() || null,
        width: 200
      };
    });
  }, [records, idField, recordType]);

  const handleDownload = async () => {
    if (recordData && recordData.download) {
      try {
        setDownloadStatus(DownloadStatus.Downloading);
        const res = await fetch(recordData.download);
        if (res.status !== 200) {
          throw new Error(`Failed to download sync data - unexpected status code ${res.status}`);
        } else {
          setDownloadStatus(DownloadStatus.Success);
        }
      } catch (e) {
        setDownloadStatus(DownloadStatus.Error);
        Sentry.captureException(e, {
          extra: {
            downloadUrl: recordData?.download || 'no url',
            executionId: props?.executionId || 'unknown'
          }
        });
      }
    }
  };

  return (
    <Dialog
      initialFocusRef={closeRef}
      show={true}
      onDismiss={props.handleDismiss}
      heading={props.heading}
      headingLogo={
        props.targetConnectionTypeId &&
        props.targetConnectionName &&
        props.targetObjectName && (
          <div className="flex items-center space-x-1.5">
            <Icon match={props.targetConnectionTypeId} size="lg" />
            <p className="text-sm text-gray-800">{`${props.targetConnectionName} ${props.targetObjectName}`}</p>
          </div>
        )
      }
      size="xl"
      actions={
        <>
          {recordData &&
            recordData.download &&
            (recordType === 'warnings' ? (
              <a
                className="group inline-flex h-8 items-center rounded border border-gray-400 bg-gray-50 px-3 text-sm font-medium text-gray-700 shadow-button hover:border-gray-500 hover:bg-gray-100 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-1 focus-visible:ring-indigo-500 active:border-gray-600 active:bg-gray-200"
                target="_blank"
                rel="noopener noreferrer"
                href={recordData.download}
              >
                Download full warning list
              </a>
            ) : (
              <div className="flex flex-row items-center space-x-2">
                {downloadStatus === DownloadStatus.Success && (
                  <div className="flex flex-row space-x-2">
                    <Icon match="check" size="sm" variant="success" />
                    <p className="text-sm text-green-500">
                      You will receive an email when results are ready
                    </p>
                  </div>
                )}
                {downloadStatus === DownloadStatus.Error && (
                  <div className="flex flex-row space-x-2">
                    <Icon name="DangerFilled" className="h-4 w-4 text-red-500" />
                    <p className="text-sm text-red-500">Unable to complete download</p>
                  </div>
                )}
                <Button
                  theme="default"
                  disabled={downloadStatus !== DownloadStatus.None}
                  onClick={handleDownload}
                  loading={downloadStatus === DownloadStatus.Downloading}
                >
                  Download full {recordType === 'errors' ? 'error' : 'record'} list
                </Button>
              </div>
            ))}
          <Button ref={closeRef} theme="primary" onClick={props.handleDismiss}>
            Close
          </Button>
        </>
      }
    >
      {hasItems(warningTypes) && <ExecutionWarningTypes warningTypes={warningTypes} />}
      {!props.hidePreviewFormats && (
        <>
          <Label>View as</Label>
          <MyCombobox
            className="w-40"
            value={{
              label: previewFormat,
              value: getEnumKeyByValue(PreviewFormat, previewFormat)
            }}
            options={objToSelectables(PreviewFormat, true)}
            onChange={option => {
              if (!option) {
                return;
              }
              setPreviewFormat(option?.label as PreviewFormat);
            }}
          />
        </>
      )}
      <div className="mt-4 max-h-[20rem] rounded">
        {loading ? (
          <div className="flex h-80 items-center justify-center">
            <LoadingDots />
          </div>
        ) : (
          <>
            {error && <ErrorText>{error.message}</ErrorText>}
            {recordData && records.length === 0 && (
              <p className="space-x-1 text-sm text-gray-600">No valid records found.</p>
            )}
          </>
        )}
        {availableResults &&
          recordData &&
          (previewFormat === PreviewFormat.Json ? (
            <TableWrap className="max-h-[20rem] animate-fadeIn overflow-scroll py-1">
              <ol>
                {records.map((record, index) => (
                  <li key={index} className="text-sm leading-3">
                    <JsonViewer data={formatPreviewRecord(record, idField, recordType)} />
                  </li>
                ))}
              </ol>
            </TableWrap>
          ) : (
            <DataTable
              data={previewRecordsToRows(records, idField, recordType)}
              columns={previewTableColumns}
              slots={{ wrapper: 'max-h-[20rem]', table: 'table-auto' }}
              disableVirtualization={true}
            />
          ))}
      </div>
    </Dialog>
  );
}
