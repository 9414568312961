import { useFormContext } from 'react-hook-form';
import { FormBuilder, JSONSchemaForm, SideBySide } from '../../../components';
import { CompletionValue, ConnectionTypeFragment, Operation } from '../../../generated/graphql';
import ConnectionEnrichmentOption from '../connection-components/ConnectionEnrichmentOption';
import { ConnectionFormValues } from '../connection-config';

export type DefaultConnectionConfigProps = {
  connectionType: ConnectionTypeFragment;
  features?: string[];
  promiseOptions: (field: string, query?: string) => Promise<CompletionValue[]>;
};

export function DefaultConnectionConfig({
  features,
  connectionType,
  promiseOptions
}: DefaultConnectionConfigProps) {
  const { watch, setValue } = useFormContext();

  return (
    <>
      <SideBySide
        hasSectionWrap
        heading="Options"
        maxWidth={['csv'].includes(connectionType.id) ? undefined : 'max-w-xs'}
        styles="space-y-3"
      >
        {connectionType.configurationForm ? (
          <JSONSchemaForm
            schema={connectionType.configurationForm.jsonschema}
            formData={watch('configuration') || connectionType.initialConfiguration}
            uiSchema={{
              'ui:order': connectionType.configurationForm.uischema.order || undefined,
              'ui:submitButtonOptions': {
                norender: true
              }
            }}
            onChange={value => {
              setValue('configuration', value, { shouldDirty: true });
            }}
          />
        ) : (
          <FormBuilder<ConnectionFormValues>
            features={features}
            schema={connectionType.configurationSchema}
            prefix="configuration"
            promiseOptions={promiseOptions}
            styleOverrides={handleStyleOverrides(connectionType.id)}
          />
        )}
        {connectionType.operations.includes(Operation.EnrichableBackend) && (
          <ConnectionEnrichmentOption />
        )}
      </SideBySide>
    </>
  );
}

function handleStyleOverrides(connectionTypeId: string): Record<string, string> | undefined {
  switch (connectionTypeId) {
    case 'csv':
      return {
        'configuration.headers': 'grid grid-cols-[8rem,1fr,repeat(2,1.25rem)] items-center gap-2',
        'configuration.parameters':
          'grid grid-cols-[8rem,1fr,repeat(2,1.25rem)] items-center gap-2',
        'configuration.body': 'hidden'
      };
    default:
      return;
  }
}
