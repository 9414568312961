import cx from 'clsx';
import { JSONSchema4 } from 'json-schema';
import * as React from 'react';
import { FieldValues, useFormContext, useWatch } from 'react-hook-form';

import { Action } from '../../generated/graphql';
import { useLacksPermission } from '../../hooks';
import { hasError, isRequiredMsg } from '../../utils';
import { InlineDescription } from './inline-description';
import { InlineFormError } from './inline-form-error';
import { Label } from './label';

// TODO: make custom elements keyboard/screen-reader accessible

interface Props {
  item: JSONSchema4;
  className?: string;
}

export function FormFileUpload<TFormValues extends FieldValues>({ item, className }: Props) {
  const lacksPermission = useLacksPermission(Action.Edit);
  const { control, formState, register, watch } = useFormContext<TFormValues>();
  const { errors } = formState;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const value = useWatch({ control, name: item.name });

  const isMaskedValue = typeof value === 'string' ? value.includes('*') : false;

  const dependsOn = item.dependsOn ? watch(item.dependsOn) : null;
  const required = isMaskedValue
    ? false
    : (item.required || !!dependsOn) && isRequiredMsg(item.title);

  const field = register(item.name, { required, shouldUnregister: true });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const fileName = value?.[0]?.name as string | undefined;

  const hasErrors = hasError(errors, item.name as string | undefined);

  return (
    <div className={className}>
      <Label htmlFor={item.name as string} className="block">
        <div className="font-medium text-gray-800">{item.title}</div>
        <input
          hidden
          type="file"
          ref={field.ref}
          id={field.name as string}
          name={field.name as string}
          onChange={e => {
            if (e.target.validity.valid && e.target.files?.[0]) {
              void field.onChange(e);
            }
          }}
          className="focus:outline-none"
          disabled={lacksPermission}
          // onBlur={field.onBlur}
        />
        <div className={cx(!lacksPermission && 'cursor-pointer', 'mt-1 flex items-center')}>
          <div
            className={cx(
              fileName ? 'text-gray-800' : 'text-gray-400',
              lacksPermission
                ? 'border-gray-300 bg-white'
                : hasErrors
                ? 'border-red-500 bg-red-50 ring-1 ring-red-500'
                : 'border-gray-400 bg-white shadow-input',
              'w-full truncate rounded-l border-t border-l border-b py-1.5 pl-3 pr-0 text-sm'
            )}
          >
            {fileName ?? value ? 'Replace saved file...' : 'Choose file...'}
          </div>
          <div
            className={cx(
              lacksPermission
                ? 'border-gray-300 bg-gray-200 text-gray-400'
                : hasErrors
                ? 'border-red-500 ring-1 ring-red-500 hover:border-red-700 hover:ring-red-700'
                : 'border-gray-400 bg-gray-50 text-gray-700 hover:border-gray-500 hover:bg-gray-100',
              'rounded-r border py-1.5 px-3 text-sm font-medium'
            )}
          >
            Browse...
          </div>
        </div>
      </Label>
      <InlineFormError errors={errors} name={item.name as string} />
      <InlineDescription description={item.description} />
    </div>
  );
}
