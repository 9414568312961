import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { Icon } from '~/components';
import { DisabledSelect, EditPermission, Label, MyCombobox } from '../../../components';
import { SelectOption } from '~/components/v2/inputs/Select';
import { GetEnrichersDocument } from '../../../generated/graphql';
import { ConnectionFormValues } from '../connection-config';

export type ConnectionEnrichmentOptionProps = null;

const ConnectionEnrichmentOption = () => {
  const { data } = useQuery(GetEnrichersDocument);
  const { setValue, watch } = useFormContext<ConnectionFormValues>();
  const enrichmentOption = watch('configuration.enricher_connection_id');
  const enrichmentOptions: SelectOption[] = [
    { label: 'None', value: 'none' },
    ...(data?.getEnrichers?.map(v => ({
      id: v.id,
      value: v.id,
      label: v.name,
      iconId: v.type.id
    })) || [])
  ];
  const selectedEnrichmentOption = enrichmentOptions.find(o => o.value === enrichmentOption);
  const handleEnrichmentOptionChange = (option: SelectOption | null) => {
    const value = option?.value === 'none' ? null : option?.value;
    setValue('configuration.enricher_connection_id', value, {
      shouldDirty: true,
      shouldValidate: true
    });
  };

  const formatOptionLabel = (option: SelectOption & { isDisabled?: boolean }) => {
    return (
      <div className="flex items-start">
        {option.iconId && (
          <Icon
            match={option.iconId}
            className={clsx('mr-1.5 h-5 w-5', option.isDisabled && 'opacity-60')}
          />
        )}
        <div
          className={clsx(
            'self-center text-sm',
            option.isDisabled ? 'text-gray-500' : 'text-gray-800'
          )}
        >
          {option.label}
        </div>
      </div>
    );
  };

  return (
    <>
      <Label>Enrich emails with</Label>
      <EditPermission
        fallback={
          <DisabledSelect valueLabel={selectedEnrichmentOption?.label} className="max-w-xs" />
        }
      >
        <MyCombobox<SelectOption>
          defaultValue={enrichmentOptions[0]}
          value={selectedEnrichmentOption}
          options={enrichmentOptions}
          onChange={handleEnrichmentOptionChange}
          formatOptionLabel={formatOptionLabel}
        />
      </EditPermission>
    </>
  );
};

export default ConnectionEnrichmentOption;
