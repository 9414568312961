import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  FormBuilder,
  MyCombobox,
  MyInput,
  SideBySide
} from '../../../components';
import { CompletionValue, ConnectionTypeFragment } from '../../../generated/graphql';
import { getSchemaNormalized, isRequiredMsg, Selectable } from '../../../utils';
import { ConnectionFormValues, ConnectionWithoutType } from '../connection-config';
import { OauthButton } from '../connection-components';
import { omit } from 'lodash';

const SFDC_AUTH_METHODS = {
  OAUTH: { label: 'OAuth', value: 'browser' },
  CLIENTCREDS: { label: 'Client credentials', value: 'clientcredentials' }
};

interface Props {
  connection?: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
  features?: string[];
  oauthLoading: boolean;
  saved: boolean;
  promiseOptions: (field: string, query?: string) => Promise<CompletionValue[]>;
  onSave: (force: boolean) => (data: ConnectionFormValues) => Promise<void>;
  setConnectionType: React.Dispatch<React.SetStateAction<ConnectionTypeFragment>>;
}

export function SalesforceConnectionConfig(props: Props) {
  const {
    connection,
    connectionType,
    features,
    oauthLoading,
    saved,
    promiseOptions,
    onSave,
    setConnectionType
  } = props;

  const { setValue, register, getValues, formState } = useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  const [authMethod, setAuthMethod] = React.useState<Selectable>(() => {
    const auth = getValues('configuration.connect_mode');
    return auth === 'clientcredentials' ? SFDC_AUTH_METHODS.CLIENTCREDS : SFDC_AUTH_METHODS.OAUTH;
  });

  const fields = getSchemaNormalized(connectionType.configurationSchema);

  const handleAuthMethodChange = (option: Selectable | null) => {
    if (option) {
      setValue('configuration.connect_mode', option.value, {
        shouldValidate: true,
        shouldDirty: true
      });
      setAuthMethod(option);
      setConnectionType({ ...connectionType, useOAuth: option.value === 'browser' });
    }
  };

  return (
    <>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Domain">
        <EditPermission>
          <MyInput
            className="max-w-xs"
            {...register('configuration.domain', {
              required: isRequiredMsg(fields?.['domain']?.title)
            })}
            description={fields?.['domain']?.description}
            errors={errors}
          />
        </EditPermission>
      </SideBySide>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Authentication method">
        <EditPermission
          fallback={<DisabledSelect valueLabel={authMethod?.label} className="max-w-xs" />}
        >
          <MyCombobox
            className="max-w-xs"
            value={authMethod}
            options={Object.values(SFDC_AUTH_METHODS)}
            onChange={handleAuthMethodChange}
          />
        </EditPermission>
      </SideBySide>
      {authMethod && (
        <SideBySide hasSectionWrap styles="space-y-3" heading="Options">
          {authMethod?.value === SFDC_AUTH_METHODS.OAUTH.value && (
            <div className="max-w-xs">
              <OauthButton
                isConnected={saved || !!connection?.saved}
                oauthLoading={oauthLoading}
                connectionTypeName={connectionType.name}
                onSave={onSave}
              />
            </div>
          )}
          {authMethod?.value === SFDC_AUTH_METHODS.CLIENTCREDS.value && (
            <>
              <EditPermission>
                <MyInput
                  {...register('configuration.client_id', {
                    required: isRequiredMsg(fields?.['client_id']?.title)
                  })}
                  label={fields?.['client_id']?.title}
                  description={fields?.['client_id']?.description}
                  errors={errors}
                />
              </EditPermission>
              <EditPermission>
                <MyInput
                  {...register('configuration.client_secret', {
                    required: isRequiredMsg(fields?.['client_secret']?.title)
                  })}
                  label={fields?.['client_secret']?.title}
                  description={fields?.['client_secret']?.description}
                  errors={errors}
                />
              </EditPermission>
            </>
          )}
          {connection?.saved && (
            <FormBuilder<ConnectionFormValues>
              features={features}
              schema={omit(props.connectionType.configurationSchema, [
                // Omit fields that are collected manually above
                'definitions.ConnectionConf.properties.connect_mode',
                'definitions.ConnectionConf.properties.domain',
                'definitions.ConnectionConf.properties.client_id',
                'definitions.ConnectionConf.properties.client_secret'
              ])}
              prefix="configuration"
              promiseOptions={promiseOptions}
            />
          )}
        </SideBySide>
      )}
    </>
  );
}
