import { useQuery } from '@apollo/client';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { Button, Search } from '~/components';
import TooltipIcon from '~/components/tooltip-icon';
import { SIZE } from '~/components/v2/configs/SX';
import { Icon } from '~/components';
import { Sheet, SheetContent } from '~/components/v3/Sheet';
import {
  BulkExecutionStatus,
  BulkSyncExecutionDocument,
  BulkSyncPeekFragment,
  BulkSyncSchemaExecution,
  SchemaExecutionStatus
} from '~/generated/graphql';
import { useBannerDispatch, useLocalStorageState } from '~/hooks';
import { useConfiguration } from '~/hooks/configuration';
import { emptyCell, getLongLocalTime } from '~/utils';
import {
  getDurationInMilliseconds,
  getFormattedDuration,
  getSyncStatusOrder
} from '~/utils/bulk-sync-history.util';
import { HistoryItem } from '../bulk-sync-history';
import { ExecutionStatusDisplay } from './ExecutionStatusDisplay';
import { DataTable } from '~/components/v3';

const getColumns = (schemaLabel: string): ColumnDef<BulkSyncSchemaExecution>[] => [
  {
    id: 'schema',
    accessorKey: 'schema',
    header: () => schemaLabel,
    accessorFn: row => row.schema.name,
    cell: ({ row }) =>
      row.original.slowMode ? (
        <div className="flex items-center gap-1">
          <span>{row.original.schema.name}</span>
          <TooltipIcon
            message={
              row.original.status === SchemaExecutionStatus.Completed
                ? 'Object synced non-incrementally'
                : 'Object will be synced non-incrementally'
            }
            icon={<Icon className="align-top" name="Turtle" size="sm" />}
          />
        </div>
      ) : (
        <span>{row.original.schema.name}</span>
      ),
    size: 300,
    sortDescFirst: false,
    enableGlobalFilter: true
  },
  {
    accessorKey: 'duration',
    accessorFn: row => getDurationInMilliseconds(row.startedAt, row.completedAt),
    header: 'Duration',
    cell: ({ row }) =>
      getFormattedDuration(row.original.startedAt, row.original.completedAt) || emptyCell,
    size: 100,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'recordCount',
    accessorFn: row => row.recordCount,
    header: 'Total Records',
    cell: ({ row }) => row.original?.recordCount?.toLocaleString() || emptyCell,
    size: 125,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'errorCount',
    accessorFn: row => row.errorCount,
    header: 'Errored',
    cell: ({ row }) => row.original?.errorCount?.toLocaleString() || emptyCell,
    size: 125,
    sortDescFirst: false,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'status',
    accessorFn: row => getSyncStatusOrder(row.status),
    header: 'Status',
    cell: ({ row }) =>
      (
        <ExecutionStatusDisplay message={row.original.statusMessage} status={row.original.status} />
      ) || emptyCell,
    size: 100,
    sortDescFirst: false,
    enableGlobalFilter: false
  }
];

interface ExecutionDetailProps {
  row: HistoryItem;
  sync: BulkSyncPeekFragment;
  sheetRef?: React.RefObject<HTMLDivElement>;
  onClose: () => void;
}

export function ExecutionDetailDrawer({ row, sync, sheetRef, onClose }: ExecutionDetailProps) {
  const { state: configuration } = useConfiguration();

  const dispatchBanner = useBannerDispatch();

  const { data, error, loading, refetch } = useQuery(BulkSyncExecutionDocument, {
    skip: !row,
    notifyOnNetworkStatusChange: true,
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    variables: {
      syncId: row?.syncId,
      executionId: row?.id
    }
  });

  useEffect(() => {
    row && refetch();
  }, [row, refetch]);

  const [search, setSearch] = useState<string>('');
  const [sorting, setSorting] = useLocalStorageState<SortingState>(
    [{ id: 'schema', desc: false }],
    'bulkSyncExecutionDetailSort'
  );

  const schemaLabel = sync?.source?.schemaLabel?.singular ?? 'object';
  const columns = useMemo(() => getColumns(schemaLabel), [schemaLabel]);

  const shouldShowDowloadLogButton = useMemo(() => {
    return (
      row &&
      configuration.on_premises &&
      configuration.execution_logs_v2 &&
      ![BulkExecutionStatus.Created, BulkExecutionStatus.Scheduled].includes(row?.status)
    );
  }, [configuration, row]);

  const downloadSyncLog = () => {
    if (row?.syncId && row?.id) {
      window.open(`/api/executions/${row?.syncId}/${row?.id}/log.json`);
    }
  };

  return (
    <Sheet open={!!row} modal={false}>
      <SheetContent
        header={
          <>
            <div className="flex flex-1 flex-wrap items-center">
              <h2 className="mr-2 text-base font-medium">Sync details</h2>
              <p>{getLongLocalTime(row?.startedAt || row?.createdAt)}</p>
            </div>
            <div className="flex flex-1 justify-center">
              <Search
                className="font-normal"
                wrapperStyles="h-8 w-80"
                placeholder="Search objects..."
                defaultValue={search}
                onChange={v => setSearch(v ?? '')}
                onReset={() => setSearch('')}
                disabled={!!error}
              />
            </div>
            <div className="flex flex-1 items-center justify-end space-x-2">
              {shouldShowDowloadLogButton && (
                <Button onClick={downloadSyncLog}>Download sync log</Button>
              )}
              <Button theme="primary" onClick={onClose}>
                Close
              </Button>
            </div>
          </>
        }
        onEscapeKeyDown={onClose}
        storageKey="bulkSyncHistorySheet"
        ref={sheetRef}
      >
        <DataTable
          data={data?.bulkSync?.execution?.schemas as BulkSyncSchemaExecution[]}
          columns={columns}
          loading={loading}
          // Filtering
          globalFilter={search}
          onGlobalFilterChange={setSearch}
          //Sorting
          sorting={sorting}
          onSortingChange={setSorting}
        />
      </SheetContent>
    </Sheet>
  );
}
