import { ApolloError } from '@apollo/client';
import cx from 'clsx';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Icon } from '~/components';
import {
  Button,
  JsonViewer,
  Label,
  LinkButton,
  MyCombobox,
  MyInput,
  TableWrap
} from '~/components';
import { COLOR } from '~/components/v2/configs/SX';
import Chip from '~/components/v2/display/Chip';
import ErrorText from '~/components/v2/feedback/ErrorText';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { ColumnDef, DataTable, Dialog } from '~/components/v3';
import { SyncFragment, SyncPreviewFragment, SyncRecord } from '~/generated/graphql';
import { useToggle } from '~/hooks';
import {
  capsFirst,
  formatPreviewRecord,
  getEnumKeyByValue,
  hasItems,
  objToSelectables,
  PreviewFormat,
  previewRecordsToRows,
  SyncRecordPreview
} from '~/utils';

interface FormValues {
  identity: string;
}

interface Props {
  heading: string;
  sync: SyncFragment;
  data?: SyncPreviewFragment;
  show: boolean;
  hidePreviewFormats?: boolean;
  handleClose: () => void;
  error?: ApolloError;
  loading?: boolean;
  getPreviewData?: (identity?: string) => void;
}

export function SyncConfigPreview(props: Props) {
  const [previewFormat, setPreviewFormat] = React.useState<PreviewFormat>(PreviewFormat.Table);
  const [showIdentityFilter, toggleIdentityFilter] = useToggle();

  const closeRef = React.useRef<HTMLButtonElement>(null);

  const { handleSubmit, register, reset, watch } = useForm<FormValues>({
    defaultValues: { identity: '' }
  });

  const identityValue = watch('identity');

  const identityValueRef = React.useRef<string>('');

  const isWebhook = props.sync.targetConnection?.type.id === 'webhook';

  let previewTableColumns: ColumnDef<Partial<SyncRecordPreview>>[] = [];
  let idField: string | null = null;

  let availableResults = false;
  let records: SyncRecord[] = [];

  if (props.data) {
    records = props.data.records;
    availableResults = !props.loading && hasItems(records);
    if (props.data.targetIDProperty) {
      idField = props.data.targetIDProperty;
    }
  }

  if (hasItems(records)) {
    previewTableColumns = Object.keys(formatPreviewRecord(records[0], idField)).map(field => {
      return {
        header: field,
        accessorKey: field,
        cell: ({ cell }) => cell?.getValue() || null,
        size: 200
      };
    });
  }

  function handleDimiss() {
    reset();
    toggleIdentityFilter();
    props.handleClose();
  }

  function clearAndRefetch() {
    if (props.getPreviewData) {
      props.getPreviewData();
    }
    reset();
    identityValueRef.current = '';
  }

  function handleIdentityFilter() {
    if (identityValueRef.current && showIdentityFilter) {
      clearAndRefetch();
    } else if (identityValue) {
      reset();
    }
    toggleIdentityFilter();
  }

  function updatePreviewData({ identity }: FormValues) {
    if (!props.getPreviewData) {
      return;
    }
    props.getPreviewData(identity);
    identityValueRef.current = identity;
  }

  return (
    <Dialog
      initialFocusRef={closeRef}
      show={props.show}
      onDismiss={handleDimiss}
      heading={props.heading}
      headingLogo={
        props.sync.targetConnection &&
        props.sync.targetObject && (
          <div className="flex items-center space-x-1.5">
            <Icon match={props.sync.targetConnection.type.id} className="h-6 w-6" />
            <p className="text-sm text-gray-800">{`${props.sync.targetConnection.name} ${props.sync.targetObject.name}`}</p>
          </div>
        )
      }
      size="xl"
      actions={
        <Button ref={closeRef} theme="primary" onClick={props.handleClose}>
          Close
        </Button>
      }
    >
      {!isWebhook && (
        <>
          <div className="relative">
            {!props.hidePreviewFormats && (
              <>
                <Label>View as</Label>
                <MyCombobox
                  className="w-40"
                  value={{
                    label: previewFormat,
                    value: getEnumKeyByValue(PreviewFormat, previewFormat)
                  }}
                  options={objToSelectables(PreviewFormat, true)}
                  onChange={option => {
                    if (!option) {
                      return;
                    }
                    setPreviewFormat(option?.label as PreviewFormat);
                  }}
                />
              </>
            )}

            {props.sync.identity?.model && (
              <LinkButton
                position="absolute"
                className="top-6 right-0 text-right text-xs"
                onClick={handleIdentityFilter}
              >
                {showIdentityFilter ? 'Remove' : 'Show'} {props.sync.identity.model.label} filter
              </LinkButton>
            )}
          </div>
          {props.sync.identity?.model && showIdentityFilter && (
            <form className="flex items-end space-x-2" onSubmit={handleSubmit(updatePreviewData)}>
              <div className="relative w-80">
                <MyInput
                  label={
                    <label className="label mt-4 flex items-center space-x-1">
                      <span>Value for</span>

                      <Icon
                        match={props.sync.identity.model.fieldset.connection.type.id}
                        className="h-5 w-5"
                      />

                      <span>{props.sync.identity.model.label}</span>

                      <span className="font-normal text-gray-500">(optional)</span>
                    </label>
                  }
                  {...register('identity')}
                  placeholder="Enter value..."
                  className="pr-6"
                />
                {identityValue && (
                  <div className="absolute bottom-0 right-0">
                    <button
                      type="reset"
                      className="group h-8 w-6 focus:outline-none"
                      onClick={() => {
                        reset();
                      }}
                    >
                      <Icon
                        name="CloseXSmall"
                        className="m-0 h-5 w-5 text-gray-500 group-hover:text-gray-800"
                      />
                    </button>
                  </div>
                )}
              </div>
              <Button
                onClick={handleSubmit(updatePreviewData)}
                className="whitespace-nowrap"
                loading={props.loading}
              >
                Update sample
              </Button>
            </form>
          )}
        </>
      )}

      <div className={cx('max-h-[20rem] rounded', !isWebhook && 'mt-4')}>
        {props.loading ? (
          !showIdentityFilter && (
            <div className="flex h-80 items-center justify-center">
              <LoadingDots />
            </div>
          )
        ) : (
          <>
            {props.error && <ErrorText>{capsFirst(props.error.message)}</ErrorText>}
            {props.data && records.length === 0 && (
              <>
                <p className="space-x-1 text-sm text-gray-600">
                  No valid records found
                  {identityValueRef.current && props.sync.identity?.model && showIdentityFilter && (
                    <>
                      <span> using</span>
                      <Chip color={COLOR.DEFAULT}>{identityValueRef.current}</Chip>
                    </>
                  )}
                  .
                </p>
                {identityValueRef.current && props.sync.identity?.model && showIdentityFilter && (
                  <p className="mt-2 space-x-1 text-sm text-gray-600">
                    <LinkButton onClick={clearAndRefetch}>Clear the value</LinkButton>
                    <span>or try a different one.</span>
                  </p>
                )}
              </>
            )}
          </>
        )}
        {availableResults &&
          props.data &&
          (isWebhook || previewFormat === PreviewFormat.Json ? (
            <TableWrap className="max-h-[20rem] animate-fadeIn overflow-scroll py-1">
              <ol>
                {records.map((record, index) => (
                  <li key={index} className="text-sm leading-3">
                    <JsonViewer data={formatPreviewRecord(record, idField)} />
                  </li>
                ))}
              </ol>
            </TableWrap>
          ) : (
            <DataTable
              data={previewRecordsToRows(records, idField)}
              columns={previewTableColumns}
              slots={{ wrapper: 'max-h-[20rem]', table: 'table-auto' }}
              disableVirtualization={true}
            />
          ))}
      </div>
    </Dialog>
  );
}
