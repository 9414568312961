import { InUse } from '../../../components';
import { ConnectionTypeFragment, ConnectionUsedBy } from '../../../generated/graphql';
import { isBulkSync, isFieldset, isSync } from '../../../utils';
import { ConnectionWithoutType } from '../connection-config';

interface Props {
  connection: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
  error: string;
  usedBy: ConnectionUsedBy[];
}

export function ConnectionInUse(props: Props) {
  const usedFieldsets = props.usedBy.filter(isFieldset);
  const usedSyncs = props.usedBy.filter(isSync);
  const usedBulkSyncs = props.usedBy.filter(isBulkSync);

  return (
    <div className="animate-fadeIn">
      <h3 className="mb-5 text-sm font-medium">
        Unable to delete connection:
        <span className="ml-1 font-normal lowercase text-red-500">{props.error}</span>
      </h3>
      <InUse
        type="model"
        name={props.connection.name}
        logo={props.connectionType.id}
        usedBy={usedFieldsets}
      />
      <InUse
        type="sync"
        name={props.connection.name}
        logo={props.connectionType.id}
        usedBy={usedSyncs}
      />
      <InUse
        type="bulk sync"
        name={props.connection.name}
        logo={props.connectionType.id}
        usedBy={usedBulkSyncs}
      />
    </div>
  );
}
