import * as React from 'react';

import { Button, EditPermission, SideBySide, TableTopper, TableWrap } from '../../../components';
import LoadingDots from '../../../components/v2/feedback/LoadingDots';
import { AddField } from '.';

export interface FieldsTableWrapperProps {
  children: React.ReactNode;
  hasFields: boolean;
  loading: boolean;
  refresh?: () => void;
  hasWriteinFields?: boolean;
}

export const FieldsTableWrapper = React.memo<FieldsTableWrapperProps>(props => {
  if (props.loading && !props.hasFields) {
    return (
      <SideBySide maxWidth="max-w-xs">
        <div className="flex h-16 items-center justify-center">
          <LoadingDots />
        </div>
      </SideBySide>
    );
  }
  if (!props.hasFields) {
    return null;
  }

  return (
    <div className="min-w-[calc(100%+1.5rem)] animate-fadeIn pr-6">
      <TableWrap className="min-w-full">
        <TableTopper className="h-16 space-x-4 bg-white px-4">
          <span>Model fields</span>
          {props.refresh && (
            <EditPermission>
              <Button onClick={props.refresh} loading={props.loading} iconEnd="Refresh">
                Refresh
              </Button>
            </EditPermission>
          )}
          {props.hasWriteinFields && <AddField />}
        </TableTopper>
        {props.children}
      </TableWrap>
    </div>
  );
});
