import { ApolloProvider } from '@apollo/client';
import SentryFullStory from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { client } from './apollo/';
import { App } from './app';
import { AuthProvider, EditorProvider } from './hooks';
import { ConfigurationProvider } from './hooks/configuration';

import '../config/tailwind.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new SentryFullStory('polytomic')],
    environment: import.meta.env.VITE_MODE,
    // We recommend adjusting this value in production,
    // or using tracesSampler for finer control
    tracesSampleRate: 1.0
  });
}

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={client}>
            <AuthProvider>
              <ConfigurationProvider>
                {/* TODO - Remove Editor Provider - Replace with Jotai or react.state via props */}
                <EditorProvider>
                  <App />
                </EditorProvider>
              </ConfigurationProvider>
            </AuthProvider>
          </ApolloProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
