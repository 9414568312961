import { createStateCtx } from '../factories';

export const {
  useCtx: useApplyFieldsetUpdateLoading,
  Provider: ApplyFieldsetUpdateLoadingProvider
} = createStateCtx<boolean>(false);
export const { useCtx: useSaveFieldsetLoading, Provider: SaveFieldsetLoadingProvider } =
  createStateCtx<boolean>(false);
export const { useCtx: usePreventSave, Provider: PreventSaveProvider } =
  createStateCtx<boolean>(false);
