import { atom, useAtom } from 'jotai';
import React, { ReactNode, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export type TopNavStateAtomType = {
  topNavHeading?: ReactNode | undefined;
  topNavActions?: ReactNode;
};
export const TopNavStateAtom = atom<TopNavStateAtomType>({
  topNavHeading: null,
  topNavActions: null
});

const TopNav = React.memo(() => {
  // state
  const [topNavState, setTopNavState] = useAtom(TopNavStateAtom);
  const location = useLocation();
  // SX
  const ROOT_SX =
    'sticky top-0 flex max-h-[4rem] min-h-[4rem] w-full flex-row items-center justify-between bg-indigo-600 px-6 py-4';
  const HEADING_SX = 'pr-3 text-xl text-white';
  const ACTIONS_SX = 'flex flex-row space-x-3';
  // effects
  useLayoutEffect(() => {
    setTopNavState(s => ({ ...s, topNavHeading: null, topNavActions: undefined }));
  }, [location.pathname, setTopNavState]);

  return (
    <div className={ROOT_SX}>
      <h2 className={HEADING_SX}>{topNavState.topNavHeading}</h2>
      <span className={ACTIONS_SX}>{topNavState.topNavActions}</span>
    </div>
  );
});

export default TopNav;
