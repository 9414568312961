import TooltipIcon from '~/components/tooltip-icon';
import { Icon } from '~/components';
import { IBulkSchema, SchemaOrFieldKey } from './BulkNamespaceUtil';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import { useMemo, useState } from 'react';
import { Search, Tooltip } from '~/components';
import { cn } from '~/lib/utils';
import { Row } from '@tanstack/react-table';

interface SchemaRowMenuProps {
  row: Row<IBulkSchema>;
  supportsPartitionKeys: boolean;
  supportsTrackingFields: boolean;
  setNamespaceValue: (path: string, key: SchemaOrFieldKey, value: string) => void;
}

export function SchemaRowMenu({
  row,
  setNamespaceValue,
  supportsPartitionKeys,
  supportsTrackingFields
}: SchemaRowMenuProps) {
  const schema = row.original as IBulkSchema;

  const [trackingFieldSearch, setTrackingFieldSearch] = useState<string>('');
  const [partitionKeySearch, setPartitionKeySearch] = useState<string>('');

  const trackingFieldOptions = useMemo(
    () =>
      schema.fields
        .filter(field => field.trackable)
        .filter(field => field.name?.toLowerCase().includes(trackingFieldSearch?.toLowerCase())),
    [schema.fields, trackingFieldSearch]
  );

  const partitionKeyOptions = useMemo(
    () =>
      schema.fields
        .filter(field => field.partitionable)
        .filter(field => field.name?.toLowerCase().includes(partitionKeySearch?.toLowerCase())),
    [schema.fields, partitionKeySearch]
  );

  const showPartitionKeys = supportsPartitionKeys && !!partitionKeyOptions?.length;
  const showTrackingFields = supportsTrackingFields && !!trackingFieldOptions?.length;

  return (
    <div className={cn('flex flex-row space-x-2 items-start justify-end text-gray-500')}>
      {!!schema.partitionKey && (
        <TooltipIcon
          message={`Partition key: ${schema.partitionKey}`}
          icon={<Icon name="PartitionKey" size="sm" className="align-top" />}
        />
      )}
      {(showPartitionKeys || showTrackingFields) && (
        <DropdownMenu>
          <DropdownMenuTrigger onClick={e => e.stopPropagation()}>
            <div className="invisible group-hover/row:visible hover:bg-gray-300 p-[2px] rounded">
              <Icon name="DotsH" size="sm" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" portal={false} onClick={e => e.stopPropagation()}>
            <DropdownMenuSub onOpenChange={() => setPartitionKeySearch('')}>
              <Tooltip disabled={showPartitionKeys} content="No partition keys available">
                <DropdownMenuSubTrigger disabled={!showPartitionKeys}>
                  Partition key
                </DropdownMenuSubTrigger>
              </Tooltip>
              <DropdownMenuPortal>
                <DropdownMenuSubContent onClick={e => e.stopPropagation()}>
                  <DropdownMenuLabel>Partition key</DropdownMenuLabel>
                  <div className="px-2 mb-2">
                    <Search
                      className="font-normal"
                      wrapperStyles="h-8 w-80"
                      placeholder="Search..."
                      defaultValue={partitionKeySearch}
                      onChange={setPartitionKeySearch}
                      onReset={() => setPartitionKeySearch('')}
                    />
                  </div>
                  <DropdownMenuRadioGroup
                    value={schema.partitionKey}
                    onValueChange={v => setNamespaceValue(row.original.path, 'partitionKey', v)}
                  >
                    <DropdownMenuRadioItem value="" checked={!schema.partitionKey}>
                      None
                    </DropdownMenuRadioItem>
                    {partitionKeyOptions.map(field => (
                      <DropdownMenuRadioItem
                        key={field.id}
                        value={field.id}
                        checked={schema.partitionKey === field.id}
                      >
                        {field.name}
                      </DropdownMenuRadioItem>
                    ))}
                  </DropdownMenuRadioGroup>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
            <DropdownMenuSub onOpenChange={() => setTrackingFieldSearch('')}>
              <Tooltip disabled={showTrackingFields} content="No tracking fields available">
                <DropdownMenuSubTrigger disabled={!showTrackingFields}>
                  Tracking field
                </DropdownMenuSubTrigger>
              </Tooltip>
              <DropdownMenuPortal>
                <DropdownMenuSubContent onClick={e => e.stopPropagation()}>
                  <DropdownMenuLabel>Tracking field</DropdownMenuLabel>
                  <div className="px-2 mb-2">
                    <Search
                      className="font-normal"
                      wrapperStyles="h-8 w-80"
                      placeholder="Search..."
                      defaultValue={trackingFieldSearch}
                      onChange={setTrackingFieldSearch}
                      onReset={() => setTrackingFieldSearch('')}
                    />
                  </div>
                  <DropdownMenuRadioGroup
                    value={schema.trackingField}
                    onValueChange={v => setNamespaceValue(row.original.path, 'trackingField', v)}
                  >
                    <DropdownMenuRadioItem value="" checked={!schema.trackingField}>
                      None
                    </DropdownMenuRadioItem>
                    {trackingFieldOptions.map(field => (
                      <DropdownMenuRadioItem
                        key={field.id}
                        value={field.id}
                        checked={schema.trackingField === field.id}
                      >
                        {field.name}
                      </DropdownMenuRadioItem>
                    ))}
                  </DropdownMenuRadioGroup>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
}
