import { Button } from './Button';
import { Footer } from './Footer';

export interface ConfirmingProps {
  connectionType: { name: string };
  organizationName?: string;
  onCancel(): unknown;
  onContinue(): unknown;
}

export function Confirming(props: ConfirmingProps) {
  return (
    <>
      <p className="text-gray-800 text-xl">
        {props.organizationName} uses Polytomic to securely connect to {props.connectionType.name}
      </p>

      <div className="flex gap-2">
        <svg className="w-5 h-5 text-gray-400">
          <path
            d="M9.661 2.23701C9.75629 2.15797 9.8762 2.11472 10 2.11472C10.1238 2.11472 10.2437 2.15797 10.339 2.23701C12.3366 3.89867 14.8215 4.86376 17.417 4.98601C17.534 4.98961 17.6459 5.03411 17.7335 5.11177C17.821 5.18942 17.8785 5.29532 17.896 5.41101C17.965 5.93101 18 6.46101 18 7.00101C18 12.163 14.74 16.564 10.166 18.257C10.0589 18.2965 9.94114 18.2965 9.834 18.257C5.26 16.564 2 12.163 2 7.00001C2 6.46201 2.035 5.93101 2.104 5.41101C2.12153 5.29515 2.17919 5.18913 2.26692 5.11145C2.35465 5.03378 2.46687 4.98938 2.584 4.98601C5.17927 4.86326 7.66379 3.89882 9.661 2.23701ZM13.857 8.19101C13.974 8.03002 14.0222 7.82917 13.991 7.63263C13.9599 7.43609 13.852 7.25997 13.691 7.14301C13.53 7.02605 13.3292 6.97783 13.1326 7.00896C12.9361 7.04009 12.76 7.14802 12.643 7.30901L9.16 12.099L7.28 10.219C7.21078 10.1474 7.128 10.0903 7.03647 10.0511C6.94495 10.0118 6.84653 9.99115 6.74694 9.99033C6.64736 9.98951 6.54861 10.0085 6.45646 10.0463C6.3643 10.084 6.28059 10.1398 6.2102 10.2102C6.13982 10.2807 6.08417 10.3644 6.0465 10.4566C6.00883 10.5488 5.9899 10.6476 5.99081 10.7472C5.99173 10.8468 6.01246 10.9452 6.05181 11.0366C6.09116 11.1281 6.14834 11.2109 6.22 11.28L8.72 13.78C8.79663 13.8567 8.88896 13.9158 8.99065 13.9534C9.09233 13.9909 9.20094 14.006 9.30901 13.9975C9.41708 13.9891 9.52203 13.9573 9.61663 13.9044C9.71123 13.8515 9.79324 13.7787 9.857 13.691L13.857 8.19101Z"
            clipRule="evenodd"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
        <p className="text-sm text-gray-600">Your data is encrypted.</p>
      </div>

      <Footer label="Next step: set up the connection">
        <Button variant="link" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button onClick={props.onContinue}>Continue</Button>
      </Footer>
    </>
  );
}
