import { LinkButton } from '../../components';
import { useBannerDispatch, useFetchSchemaCache, useRefreshableSchemas } from '../../hooks';

interface Props {
  connectionId: string;
  refreshLoading: boolean;
  setRefreshLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function RefreshRunnerSchema({ connectionId, refreshLoading, setRefreshLoading }: Props) {
  const dispatchBanner = useBannerDispatch();
  const { getSchema } = useFetchSchemaCache(connectionId);

  const calls = useRefreshableSchemas(connectionId);

  async function refresh() {
    dispatchBanner({ type: 'hide' });
    setRefreshLoading(true);
    try {
      await getSchema({ variables: { connectionId, refresh: true } });
      for await (const str of calls) {
        const call = str.split('^');
        // it's a database call
        if (call.length === 1) {
          await getSchema({ variables: { connectionId, database: call[0] } });
          continue;
        }
        // it's a table call
        const [database, table] = call;
        await getSchema({ variables: { connectionId, database, table } });
      }
    } catch (error) {
      dispatchBanner({ type: 'show', payload: { message: JSON.stringify(error) } });
    } finally {
      setRefreshLoading(false);
    }
  }

  return (
    <LinkButton className="mt-1.5 text-xs" onClick={refresh} disabled={refreshLoading}>
      Refresh schema
    </LinkButton>
  );
}
