import _ from 'lodash';
import { MutableRefObject, useMemo, useState } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { ModelFieldFragment } from '~/generated/graphql';
import {
  getFilterLabel,
  hasItems,
  hasMissingSource,
  isModelField,
  isModelMapping,
  LocalConnection,
  SyncConfigFormValues,
  toSelectables
} from '~/utils';
import { FieldDetail } from '~/components/field-detail';
import {
  FilterValueElements,
  MyCombobox,
  MyInput,
  ParamButton
} from '~/components/form-components';
import { LinkableIcon } from '~/components/linkable-icon';
import PageLayout from '~/components/v2/layout/PageLayout';
import FieldMappingBottomNav from '~/components/v2/experimental/FieldMappingBottomNav';
import FieldMappingButton from '~/components/v2/experimental/FieldMappingButton';
import { FIELD_MAPPING_TYPE } from '~/components/v2/experimental/StageMappings';

export type StageMappingsOverridesProps = {
  showFieldDetails?: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  showBottomNav: boolean;
  setShowBottomNav: React.Dispatch<React.SetStateAction<boolean>>;
  bottomNavConfig: { config: FIELD_MAPPING_TYPE; mappingIndex: number };
  setBottomNavConfig: React.Dispatch<
    React.SetStateAction<{ config: FIELD_MAPPING_TYPE; mappingIndex: number }>
  >;
  buttonRefs?: MutableRefObject<Record<string | number, HTMLButtonElement | null>>;
  // F(x)
  focusOnMappingButton?: (id?: string) => void;
};

const StageMappingsOverrides = ({
  showFieldDetails,
  setIsDirty,
  showBottomNav,
  setShowBottomNav,
  bottomNavConfig,
  setBottomNavConfig,
  buttonRefs,
  focusOnMappingButton
}: StageMappingsOverridesProps) => {
  // hooks
  const { register, control, getValues } = useFormContext<SyncConfigFormValues>();
  const targetConnection = getValues('targetConnection');
  const overrides = useWatch({ control, name: 'overrides' });
  const mappings = useWatch({ control, name: 'mappings' });
  const { fields, append, remove, replace } = useFieldArray({ control, name: 'overrides' });
  // state
  const [autoSelectedModelId, setAutoSelectedModelId] = useState<string>('');
  const [selectedFieldIds, setSelectedFieldIds] = useState<string[]>([]);
  // memo
  const overrideOptions = useMemo(() => {
    if (!mappings) return [];
    const opts = mappings.reduce((acc: ModelFieldFragment[], mapping) => {
      if (isModelMapping(mapping) && mapping.model && hasItems(mapping.model?.filterFunctions)) {
        acc.push(mapping.model);
      }
      return acc;
    }, []);
    return _.uniqBy(opts, 'id');
  }, [mappings]);
  // vars
  const hideOverrides =
    (!overrides || overrides.length === 0) && (!mappings || !mappings.some(isModelMapping));
  // f(x)
  const handleAdd = () => {
    setIsDirty(true);
    append({
      field: null,
      function: null,
      value: null,
      overrideValue: ''
    });
    focusOnMappingButton?.(`${bottomNavConfig.mappingIndex + 1}${FIELD_MAPPING_TYPE.OVERRIDE}`);
  };
  const handleDelete = (index: number) => {
    setIsDirty(true);
    remove(index);
  };

  const currentFieldId = useMemo(() => {
    if (fields[bottomNavConfig.mappingIndex]) {
      return fields[bottomNavConfig.mappingIndex]?.field?.id || undefined;
    } else {
      return undefined;
    }
  }, [fields, bottomNavConfig.mappingIndex]);

  return hideOverrides ? null : (
    <>
      {bottomNavConfig.config === FIELD_MAPPING_TYPE.OVERRIDE && (
        <PageLayout
          bottomNavShow={showBottomNav}
          bottomNavContent={
            bottomNavConfig.config === FIELD_MAPPING_TYPE.OVERRIDE && (
              <FieldMappingBottomNav
                autoSelectedModelId={autoSelectedModelId}
                setAutoSelectedModelId={setAutoSelectedModelId}
                mappingIndex={bottomNavConfig.mappingIndex}
                targetConnection={targetConnection as LocalConnection}
                setShowBottomNav={setShowBottomNav}
                fieldType={FIELD_MAPPING_TYPE.OVERRIDE}
                title={'Select field...'}
                fields={overrideOptions}
                selectedFieldIds={selectedFieldIds}
                setSelectedFieldIds={setSelectedFieldIds}
                disabledFieldIds={[]}
                multiSelect={false}
                currentFieldId={currentFieldId}
                handleAddFields={(
                  fieldType: FIELD_MAPPING_TYPE,
                  mappingIndex: number,
                  fieldIds: string[]
                ) => {
                  const fieldMappingAtIndex = fields[mappingIndex];
                  replace([
                    ...fields.slice(0, mappingIndex),
                    ...[
                      {
                        ...fieldMappingAtIndex,
                        field: overrideOptions.find(f => f.id === fieldIds[0]),
                        function: null,
                        value: null
                      }
                    ],
                    // Fields after the selected index
                    ...fields.slice(mappingIndex + 1, fields.length)
                  ]);
                  setIsDirty(true);
                  focusOnMappingButton?.();
                }}
                linkButton={undefined}
              />
            )
          }
        />
      )}
      <div className="col-span-full mt-6 border-t border-gray-300 px-6 pt-6">
        <div className="mb-2 flex items-center space-x-1">
          <label className="block text-sm font-semibold">Overrides</label>
          <LinkableIcon href="https://docs.polytomic.com/docs/mapping-overrides" />
        </div>
        {hasItems(fields) ? (
          <section className="space-y-3">
            {fields.map(({ id }, index) => {
              const override = overrides && overrides.length >= index ? overrides[index] : null;
              const isInvalid =
                override && isModelField(override.field) && hasMissingSource(override, mappings);
              return (
                <div
                  key={id}
                  className="grid w-full grid-cols-[3fr,2fr,3fr,repeat(2,1.25rem)] items-center gap-x-2 space-y-1"
                >
                  <label className="thead-text col-span-full -mb-0.75 block text-gray-500">
                    If
                  </label>
                  <div className="col-start-1 col-end-2">
                    <FieldMappingButton
                      buttonRef={el => {
                        if (buttonRefs) {
                          buttonRefs.current[`${index}${FIELD_MAPPING_TYPE.OVERRIDE}`] = el;
                        }
                      }}
                      fieldType={FIELD_MAPPING_TYPE.OVERRIDE}
                      mappingIndex={index}
                      placeholder={'Model field...'}
                      disabled={false}
                      sanitize={false}
                      iconId={
                        (fields[index].field as ModelFieldFragment)?.fieldset?.connection?.type?.id
                      }
                      label={(fields[index].field as ModelFieldFragment)?.label}
                      selected={
                        bottomNavConfig.mappingIndex === index &&
                        bottomNavConfig.config === FIELD_MAPPING_TYPE.OVERRIDE &&
                        showBottomNav
                      }
                      onClick={() => {
                        setBottomNavConfig({
                          config: FIELD_MAPPING_TYPE.OVERRIDE,
                          mappingIndex: index
                        });
                        setAutoSelectedModelId('');
                        setShowBottomNav(true);
                      }}
                    />
                  </div>
                  <MyCombobox
                    variant="flat"
                    className="col-start-2 col-end-3"
                    options={override?.field ? toSelectables(override.field.filterFunctions) : []}
                    // @ts-expect-error this value is a lie but it's our lie
                    value={
                      fields[index].function
                        ? {
                            label:
                              getFilterLabel(fields[index].function, override?.field) ||
                              fields[index].function,
                            value: fields[index].function.toString()
                          }
                        : null
                    }
                    placeholder="Compare..."
                    onChange={option => {
                      setIsDirty(true);
                      replace([
                        ...fields.slice(0, index),
                        ...[
                          {
                            ...fields[index],
                            function: option?.value
                          }
                        ],
                        // Fields after the selected index
                        ...fields.slice(index + 1, fields.length)
                      ]);
                    }}
                    isDisabled={!override?.field}
                  />
                  <div className="col-start-3 col-end-4">
                    <FilterValueElements
                      variant="flat"
                      index={index}
                      obj={fields[index]}
                      handleValue={value => {
                        setIsDirty(true);
                        replace([
                          ...fields.slice(0, index),
                          ...[
                            {
                              ...fields[index],
                              value: value
                            }
                          ],
                          // Fields after the selected index
                          ...fields.slice(index + 1, fields.length)
                        ]);
                      }}
                    />
                  </div>
                  {isInvalid && (
                    <p className="col-start-1 col-end-2 mt-0.5 text-xs font-medium text-red-500">
                      Invalid override: model field removed
                    </p>
                  )}
                  {showFieldDetails && override?.field && (
                    <FieldDetail
                      className="col-start-1 col-end-2"
                      defaultStyles="self-stretch p-2 pl-2.25"
                      logoId={override.field.fieldset.connection.type.id}
                      modelName={override.field.fieldset.name}
                      modelId={override.field.fieldset.id}
                      columnName={override.field.sourceName}
                      type={override.field.type}
                    />
                  )}

                  <label className="col-start-1 col-end-4 -mt-px mb-0.75 block text-xs text-gray-500">
                    replace with
                  </label>
                  <MyInput
                    variant="flat"
                    className="col-start-1 col-end-4"
                    {...register(`overrides.${index}.overrideValue`)}
                    placeholder="Override value..."
                    disabled={!override?.field || !override.function}
                  />
                  <ParamButton
                    className="col-start-4 col-end-5 row-start-2 row-end-3 self-center"
                    action="delete"
                    onClick={() => handleDelete(index)}
                  />
                  {overrides &&
                    index === overrides.length - 1 &&
                    hasItems(overrideOptions) &&
                    !!overrides[overrides.length - 1].field && (
                      <ParamButton
                        className="col-start-5 col-end-6 row-start-2 row-end-3 self-center"
                        action="add"
                        onClick={handleAdd}
                      />
                    )}
                </div>
              );
            })}
          </section>
        ) : (
          <ParamButton action="add" onClick={handleAdd} />
        )}
      </div>
    </>
  );
};

export default StageMappingsOverrides;
