import { Controller, useFormContext } from 'react-hook-form';
import Select, { createFilter } from 'react-select';

import { useMountEffect } from '../../../hooks';
import {
  DropdownIndicator,
  formatOptionLabel,
  isSingleValue,
  Selectable,
  selectStyles
} from '../../../utils';
import { EditPermission } from '../../edit-permission';
import { DisabledSelect } from '../../form-components';
import { SchemaFormElementProps } from './elements';

export type EnumElement = string | Selectable;
export interface CompletedElementProps {
  options: EnumElement[];
  isObject?: boolean;
}

export const EnumSelect = (props: SchemaFormElementProps & CompletedElementProps) => {
  const { control, setValue } = useFormContext();
  const options: Selectable[] = props.options.map(option => {
    if (typeof option === 'object') {
      return {
        label: option.label || option.value,
        value: option.value
      };
    }
    return { label: option, value: option };
  });

  useMountEffect(() => {
    if (props.defaultValue || props.field.defaultValue) {
      setValue(props.name, props.defaultValue || props.field.defaultValue);
    }
  });

  return (
    <Controller
      control={control}
      name={props.name}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      defaultValue={props.defaultValue || props.field.defaultValue}
      render={({ field }) => {
        return (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          <EditPermission
            fallback={
              <DisabledSelect
                valueLabel={
                  options.filter(option => {
                    return option.value === field.value;
                  })?.[0]?.label
                }
              />
            }
          >
            <Select<Selectable>
              filterOption={createFilter({ ignoreAccents: false })}
              className={props.className}
              components={{
                DropdownIndicator,
                ClearIndicator: null,
                IndicatorSeparator: null
              }}
              value={options.filter(option => {
                return option.value === (props.isObject ? field.value?.value : field.value);
              })}
              options={options}
              styles={selectStyles}
              formatOptionLabel={formatOptionLabel}
              onChange={selected => {
                (props.dependencies || []).forEach((dep: string) => {
                  setValue(dep, null);
                });
                if (!selected) {
                  return;
                }
                if (isSingleValue(selected)) {
                  setValue(props.name, props.isObject ? selected : selected.value);
                }
                props.onChange();
                return selected;
              }}
            />
          </EditPermission>
        );
      }}
      rules={{
        required: props.required && `${props.field.title || ''} is required`,
        validate: (value: { value: boolean }): boolean | string => {
          if (value.value !== null || value.value !== undefined) {
            return true;
          }
          return `Value is invalid: ${JSON.stringify(value.value, null, 2)}`;
        }
      }}
    />
  );
};
