import { useQuery } from '@apollo/client';
import { EditorView } from 'codemirror';
import * as React from 'react';

import { Icon } from '~/components';
import { Button, Tooltip } from '../../components';
import { HistoryQueryFragment, QueryHistoryDocument } from '../../generated/graphql';
import { getQueryHistoryTime, handleReplaceQuery, hasItems } from '../../utils';
import { useToggle } from '~/hooks';
import { useHotkeys } from 'react-hotkeys-hook';
import { Sheet, SheetContent } from '~/components/v3/Sheet';
import { ListBox, ListBoxItem } from '~/components/v3/Listbox';
import { cn } from '~/lib/utils';
import { useRef } from 'react';
import { ShowMore } from '~/components/v3/ShowMore';

interface QueryHistoryProps {
  connectionId: string;
  refetch: string;
  view: EditorView | null;
}

export function QueryHistory({ connectionId, refetch, view }: QueryHistoryProps) {
  const [open, toggleOpen] = useToggle(false);

  const listboxRef = useRef<HTMLDivElement>();

  const [history, setHistory] = React.useState<HistoryQueryFragment[]>([]);
  const { refetch: refetchHistory } = useQuery(QueryHistoryDocument, {
    fetchPolicy: 'no-cache',
    variables: { connectionId },
    onCompleted: data => {
      if (!data || !data.queryHistory) {
        return;
      }
      setHistory(data.queryHistory as HistoryQueryFragment[]);
    }
  });

  React.useEffect(() => {
    void refetchHistory({ connectionId });
  }, [connectionId, refetch, refetchHistory]);

  const handleSelection = (id: string) => {
    const item = history.find(item => item.lastExecutedAt === id);
    if (item) {
      handleReplaceQuery(view, item.query);
      toggleOpen();
    }
  };

  useHotkeys(['1', '2', '3', '4', '5', '6', '7', '8', '9'], ({ key }) => {
    if (open) {
      handleSelection(history[parseInt(key) - 1].lastExecutedAt);
    }
  });

  if (!hasItems(history)) {
    return null;
  }

  return (
    <>
      <Button tabIndex={-1} onClick={toggleOpen} theme="ghost">
        <Tooltip offset={[0, 12]} placement="top" content="Query history">
          <Icon name="Clock" className="h-5 w-5 focus:outline-none" />
        </Tooltip>
      </Button>
      <Sheet open={open} onOpenChange={toggleOpen}>
        <SheetContent
          initialFocusRef={listboxRef}
          storageKey="queryHistorySheet"
          header={
            <>
              <h2 className={'text-base font-medium'}>Query history</h2>
              <Button theme="primary" onClick={toggleOpen}>
                Close
              </Button>
            </>
          }
        >
          <ListBox
            className="flex flex-col gap-2"
            selectionMode="single"
            onSelection={handleSelection}
            ref={listboxRef}
            aria-label="Query history"
          >
            {history.map((item, idx) => {
              const truncated = item.query
                .split(/\r?\n/)
                .filter((_v, i) => i < 4)
                .join('\n');
              return (
                <ListBoxItem
                  key={item.lastExecutedAt}
                  id={item.lastExecutedAt}
                  className="flex cursor-pointer space-x-2 p-2 bg-white border hover:bg-indigo-50 focus:bg-indigo-50"
                  aria-label={`Query ${idx + 1}`}
                >
                  {({ isFocused, isHovered }) => (
                    <>
                      <div
                        className={cn([
                          'h-5 w-5 rounded bg-indigo-100 pt-0.5 mt-0.5 text-center text-xs font-normal text-indigo-600',
                          (isFocused || isHovered) && 'bg-indigo-200 text-indigo-700'
                        ])}
                      >
                        {idx + 1}
                      </div>
                      <div className="w-full">
                        <p className="whitespace-nowrap pt-0.5 font-normal">
                          {getQueryHistoryTime(item.lastExecutedAt)}
                        </p>
                        <div className="pt-0.5 font-mono font-normal text-gray-500 whitespace-pre">
                          <ShowMore full={item.query} summary={truncated} />
                        </div>
                      </div>
                    </>
                  )}
                </ListBoxItem>
              );
            })}
          </ListBox>
        </SheetContent>
      </Sheet>
    </>
  );
}
