import { useQuery } from '@apollo/client';
import * as React from 'react';

import { ModelDocument } from '../generated/graphql';
import {
  addFieldsetToFields,
  getFieldsetIds,
  GetReachableFields,
  getReachableList
} from '../utils';

export function useReachableFields(): GetReachableFields {
  const { data } = useQuery(ModelDocument);
  const cache = React.useMemo(() => {
    if (!data?.model) {
      return { empty: [] };
    }
    return getReachableList(data.model.fieldsets.map(addFieldsetToFields));
  }, [data?.model]);

  const result = React.useCallback(
    (fieldsetId?: string) => {
      if (!fieldsetId) {
        return {
          reachableFields: [],
          reachableFieldsetIds: []
        };
      }
      const reachableFields = cache[fieldsetId] || [];
      return {
        reachableFields,
        reachableFieldsetIds: getFieldsetIds(reachableFields)
      };
    },
    [cache]
  );
  return result;
}
