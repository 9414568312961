import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { generatePath, Redirect, Route, Switch, useParams } from 'react-router-dom';

import { EditPermission } from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import EmptyPage from '~/components/v2/templates/EmptyPage';
import Route404 from '~/components/v2/templates/Route404';
import { Action, BulkSyncPeekDocument } from '~/generated/graphql';
import { AclProvider } from '~/hooks';
import { routes } from '~/utils';
import { BulkSyncProvider } from '~/pages/bulk-syncs/bulk-sync-components';
import BulkSyncConfig from '~/pages/bulk-syncs/bulk-sync-config';
import { BulkSyncHistory } from '~/pages/bulk-syncs/bulk-sync-history';
import BulkSyncNotifications from '~/pages/bulk-syncs/bulk-sync-notifications';
import BulkSyncStatus from '~/pages/bulk-syncs/bulk-sync-status';

const BulkSync = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery(BulkSyncPeekDocument, {
    skip: !id,
    variables: { id }
  });
  const bulkSync = data?.bulkSync;

  if (!bulkSync || !id) {
    return <EmptyPage loading={loading} error={!!error} message="No such bulk sync." />;
  }

  const navConf = [
    {
      label: 'Status and information',
      to: generatePath(routes.bulkSyncStatus, { id }),
      path: routes.bulkSyncStatus,
      slug: 'status'
    },
    {
      label: 'Configuration',
      to: generatePath(routes.bulkSyncConfig, { id }),
      path: routes.bulkSyncConfig,
      slug: 'config',
      permission: Action.Edit,
      permissionMessage: 'You do not have permission to edit this bulk sync.'
    },
    {
      label: 'History',
      to: generatePath(routes.bulkSyncHistory, { id }),
      path: routes.bulkSyncHistory,
      slug: 'history'
    },
    {
      label: 'Error handling',
      to: generatePath(routes.bulkSyncErrorHandling, { id }),
      path: routes.bulkSyncErrorHandling,
      slug: 'error_handling'
    }
  ];

  return (
    <BulkSyncProvider bulkSync={bulkSync}>
      <AclProvider value={bulkSync.acl}>
        <Helmet
          title={bulkSync?.name ? `${bulkSync.name + ' | Polytomic'}` : 'Bulk Syncs | Polytomic'}
        />
        <PageLayout topNavHeading={bulkSync.name} pageNavConf={navConf}>
          <Switch>
            <Route exact={true} path={routes.bulkSyncStatus}>
              <BulkSyncStatus key={`${id}-1`} />
            </Route>
            <Route exact={true} path={routes.bulkSyncConfig}>
              <EditPermission
                fallback={<Redirect to={generatePath(routes.bulkSyncStatus, { id })} />}
              >
                <BulkSyncConfig key={`${id}-2`} />
              </EditPermission>
            </Route>
            <Route exact={true} path={routes.bulkSyncHistory}>
              <BulkSyncHistory key={`${id}-3`} bulkSync={bulkSync} />
            </Route>
            <Route exact={true} path={routes.bulkSyncErrorHandling}>
              <BulkSyncNotifications key={`${id}-4`} />
            </Route>
            <Redirect exact={true} from={routes.bulkSync} to={routes.bulkSyncStatus} />
            <Route path="*">
              <Route404 />
            </Route>
          </Switch>
        </PageLayout>
      </AclProvider>
    </BulkSyncProvider>
  );
};

export default BulkSync;
