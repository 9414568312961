import { RowSelectionState } from '@tanstack/react-table';
import { find, take } from 'lodash';
import { BulkNamespaceOptionFragment, BulkSelectedNamespace, FieldType } from '~/generated/graphql';
import { sortByNameAndId } from '~/utils';

export enum EBulkEntityType {
  NAMESPACE = 'NAMESPACE',
  SCHEMA = 'SCHEMA',
  FIELD = 'FIELD'
}

export type IBulkEntityState = {
  path: string;
  bulkEntityType: EBulkEntityType;
};

export type SchemaOrFieldKey = keyof IBulkSchema | keyof IBulkField;

export type IBulkField = {
  id: string;
  name: string;
  type: FieldType;
  obfuscate: boolean;
  trackable?: boolean;
  isCalculated?: boolean;
  slowMode?: boolean;
  slowReason?: string;
  partitionable?: boolean;
} & IBulkEntityState;

export type IBulkSchema = {
  id: string;
  name: string;
  partitionKey: string;
  trackingField: string;
  slowMode?: boolean;
  fields: IBulkField[];
} & IBulkEntityState;

export type IBulkNamespace = {
  id: string;
  name: string;
  schemas: IBulkSchema[];
} & IBulkEntityState;

const DELIMETER = '~.~';

export const isNamespaceRoot = (namespaces: IBulkNamespace[] | BulkSelectedNamespace[]) =>
  namespaces.length === 1 && namespaces[0].id === null;

// Merge the local namespace state with available options and selected state from the server
export function getNamespaceState(
  options: BulkNamespaceOptionFragment[],
  selections: BulkSelectedNamespace[],
  local?: IBulkNamespace[]
): IBulkNamespace[] {
  return (
    (options ?? [])
      .map(namespace => {
        const localNamespace = local?.find(n => n.id === namespace.id);
        const serverNamespace = selections?.find(n => n.id == namespace.id);
        return {
          id: namespace.id,
          name: namespace.name,
          ...namespace,
          bulkEntityType: EBulkEntityType.NAMESPACE,
          path: getPathFromEntities(namespace),
          schemas: namespace.schemas
            ?.map(schema => {
              const localSchema = localNamespace?.schemas.find(s => s.id === schema.id);
              const serverSchema = serverNamespace?.schemas.find(s => s.id === schema.id);
              const selectedSchema = localSchema ?? serverSchema;
              return {
                ...schema,
                path: getPathFromEntities(namespace, schema),
                partitionKey: selectedSchema?.partitionKey,
                trackingField: selectedSchema?.trackingField,
                bulkEntityType: EBulkEntityType.SCHEMA,
                fields: schema.fields
                  ?.map(field => {
                    const f =
                      localSchema?.fields.find(f => f.id === field.id) ??
                      serverSchema?.fields.find(f => f.id === field.id);
                    return {
                      ...field,
                      path: getPathFromEntities(namespace, schema, field),
                      obfuscate: !!f?.obfuscate,
                      bulkEntityType: EBulkEntityType.FIELD
                    };
                  })
                  .sort((a, b) => a.name.localeCompare(b.name))
              };
            })
            .sort(sortByNameAndId)
        };
      })
      // filter out any namespaces without id, unless it's the root
      .filter(namespace => (options.length === 1 && !options[0].id ? true : !!namespace.id))
      .sort(sortByNameAndId)
  );
}

// Get the namespace state filtered by selected and formatted for update
export function getNamespaceUpdateFromState(
  namespaces: IBulkNamespace[] | BulkSelectedNamespace[],
  selection?: RowSelectionState
) {
  return namespaces
    .map(namespace => ({
      id: namespace.id,
      schemas: namespace.schemas
        .map(schema => ({
          id: schema.id,
          partitionKey: schema.partitionKey,
          trackingField: schema.trackingField,
          fields: schema.fields
            .map(field => ({
              id: field.id,
              obfuscate: field.obfuscate
            }))
            .filter(field => !selection || selection[getPathFromEntities(namespace, schema, field)])
            .sort((a, b) => a.id.localeCompare(b.id))
        }))
        .filter(schema => schema.fields.length)
        .sort(sortByNameAndId)
    }))
    .filter(namespace => namespace.schemas.length)
    .sort(sortByNameAndId) as BulkSelectedNamespace[];
}

// These paths are used for selection and expansion state
type IdObj = { id?: string };
export function getPathFromEntities(namespace: IdObj, schema?: IdObj, field?: IdObj) {
  return [namespace?.id ?? '', schema?.id, field?.id].filter(i => i !== undefined).join(DELIMETER);
}

// Extract the entities from a path by reference
export function getEntitiesFromPath(namespaces: IBulkNamespace[], path: string) {
  const ids = path.split(DELIMETER);
  const namespace = ids[0] === '' ? namespaces[0] : find(namespaces, { path: ids[0] });
  const schema = find(namespace?.schemas, { path: take(ids, 2).join(DELIMETER) });
  const field = find(schema?.fields, { path: take(ids, 3).join(DELIMETER) });

  return { namespace, schema, field };
}

// Populate the initial row selection state using paths generated from nested entities
export function namespacesToSelection(namespaces: BulkSelectedNamespace[]): RowSelectionState {
  const map = {};
  namespaces?.forEach(namespace =>
    namespace.schemas.forEach(schema =>
      schema.fields.forEach(field => {
        map[getPathFromEntities(namespace, schema, field)] = true;
      })
    )
  );
  return map;
}

// Get the row selection state for new fields from the server
export function getServerSelection(
  server: BulkSelectedNamespace[],
  newServerFields: string[]
): RowSelectionState {
  const map = {};
  server?.forEach(namespace =>
    namespace.schemas.forEach(schema =>
      schema.fields.forEach(field => {
        const path = getPathFromEntities(namespace, schema, field);
        if (newServerFields?.includes(path)) {
          map[path] = true;
        }
      })
    )
  );
  return map;
}

// Check updated schema against local state for newly added fields,
// to later be checked against the server selection status
export function getNewFields(local: IBulkNamespace[], options: BulkNamespaceOptionFragment[]) {
  const paths = [];
  options?.forEach(namespace =>
    namespace.schemas.forEach(schema =>
      schema.fields.forEach(field => {
        const path = getPathFromEntities(namespace, schema, field);
        if (!getEntitiesFromPath(local, path)?.field) {
          paths.push(path);
        }
      })
    )
  );
  return paths;
}
