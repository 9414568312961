import cx from 'clsx';
import * as React from 'react';

interface NumTabProps extends React.ComponentPropsWithoutRef<'div'> {
  step: number;
  hasErrors?: boolean;
}

function NumberTab(props: NumTabProps) {
  return (
    <aside
      className={cx(
        'absolute -top-px left-[-45px] grid h-[3.0625rem] w-11 place-items-center rounded-l-full border-r pl-1.5 text-base font-medium',
        props.hasErrors
          ? 'border-red-300 bg-red-200 text-red-600'
          : 'border-blue-300 bg-blue-200 text-blue-600'
      )}
    >
      {props.step}
    </aside>
  );
}

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  header: React.ReactNode;
  footer?: React.ReactNode;
  step: number;
  hasErrors?: boolean;
  hasStickyHeader?: boolean;
}

export function StageCard({ hasStickyHeader = false, ...props }: Props) {
  const [isSticky, setIsSticky] = React.useState(false);
  const stickyRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!hasStickyHeader || !stickyRef.current) {
      return;
    }
    // we need to grab the ref here or the cleanup function
    // will not work as the ref could have changed
    const cachedRef = stickyRef.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        // css on the sticky element is set to top: -1px
        setIsSticky(e.intersectionRatio < 1);
      },
      {
        threshold: [1]
      }
    );
    observer.observe(cachedRef);
    return () => observer.unobserve(cachedRef);
  }, [hasStickyHeader]);

  return (
    <div className="relative mx-auto max-w-4xl animate-fadeIn px-11">
      <div className="relative rounded-r-lg rounded-bl-lg border border-gray-300 bg-white shadow-stage">
        <div>
          <div
            ref={stickyRef}
            className={cx(
              'min-h-6 grid w-full place-items-center rounded-tr-lg bg-gray-50 py-3 text-base font-medium text-gray-800 will-change-transform',
              hasStickyHeader && 'sticky -top-px transition-shadow duration-200',
              isSticky && 'z-50 shadow-[0_2px_4px_0_rgba(0,0,0,0.1)]'
            )}
          >
            <>
              <NumberTab hasErrors={props.hasErrors} step={props.step} />
              {props.header}
            </>
          </div>
          <div className={cx('pt-6', props.className)}>{props.children}</div>
        </div>
        {props.footer || <div className="pb-6" />}
      </div>
    </div>
  );
}
