import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Icon } from '~/components';
import { Dialog } from '~/components/v3';

import {
  AccessControlInForm,
  AccessControlWrap,
  Button,
  EditPermission,
  LacksPermissionBanner,
  PromptUnsaved,
  SideBySide
} from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import {
  ClientFieldsetFragment,
  ConfirmationRequestFragment,
  DeleteFieldsetDocument,
  EditableFieldsetDocument,
  ModelDocument,
  ResourceType,
  SuggestedNameDocument
} from '~/generated/graphql';
import {
  AclProvider,
  ApplyFieldsetUpdateLoadingProvider,
  FieldsetProvider,
  PreventSaveProvider,
  SaveFieldsetLoadingProvider,
  UpdatedConfigProvider,
  useApplyFieldsetUpdateLoading,
  useBannerDispatch,
  usePreventSave,
  useSaveFieldsetLoading
} from '~/hooks';
import {
  FieldsetFormValues,
  fieldsetReducer,
  getDateOnly,
  hasItems,
  NO_EDIT_PERMISSION,
  routes,
  updateFieldsetForm,
  isConfirmationRequest
} from '~/utils';
import { FieldsetConnection, FieldsetName } from '~/pages/models/model-components';
import FieldsetConfigActions from '~/pages/models/model-components/fieldset-config-actions';
import FieldsetConfigSwitcher from './model-components/fieldset-config-switcher';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { FieldsetLabels } from './model-components/fieldset-labels';
import ActivityLog from '~/components/v2/experimental/activitylog/ActivityLog';
import clsx from 'clsx';
import { omit } from 'lodash';

const wrapperStyles = 'px-3 pt-3';

function Config() {
  const { fieldsetId } = useParams<{ fieldsetId: string }>();
  const history = useHistory();
  const dispatchBanner = useBannerDispatch();
  const isAdding = useRouteMatch({ path: routes.createModel, exact: true });
  const [fieldset, dispatch] = React.useReducer(fieldsetReducer, undefined);
  const [serverErrors, setServerErrors] = React.useState(false);
  const [enableNameSuggestion, setEnableNameSuggestion] = React.useState(true);

  const { state: saveLoading } = useSaveFieldsetLoading();
  const { state: preventSave, update: setPreventSave } = usePreventSave();
  const { state: updateLoading, update: setUpdateLoading } = useApplyFieldsetUpdateLoading();

  const methods = useForm<FieldsetFormValues>();
  const { reset, handleSubmit, getValues, formState, setValue } = methods;
  const { isDirty, dirtyFields } = formState;

  const [confirmationRequest, setConfirmationRequest] =
    React.useState<ConfirmationRequestFragment | null>(null);

  const {
    error,
    called,
    loading: getFieldsetLoading
  } = useQuery(EditableFieldsetDocument, {
    skip: !fieldsetId,
    variables: { id: fieldsetId },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: data => {
      if (!data || !data.fieldset) {
        return;
      }
      dispatch({ type: 'replace', fieldset: data.fieldset });
      updateFieldsetForm(reset, getValues, data.fieldset);
    }
  });

  React.useEffect(() => {
    if (!serverErrors && error && called) {
      if (
        error.graphQLErrors[0]?.message.includes('invalid UUID') ||
        error.graphQLErrors[0]?.message.includes('not found')
      ) {
        history.push(routes.err404);
        return;
      }
      setServerErrors(true);
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
    } else if (serverErrors && !error) {
      setServerErrors(false);
    }
  }, [error, called, serverErrors, history, dispatchBanner]);

  const [deleteFieldset, { loading: deleteLoading }] = useMutation(DeleteFieldsetDocument, {
    fetchPolicy: 'no-cache',
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
    },
    update: (cache, { data }) => {
      if (!data || !data.deleteFieldset) {
        return;
      }

      if (isConfirmationRequest(data.deleteFieldset)) {
        setConfirmationRequest(data.deleteFieldset);
        return;
      }
      // This is to guard against the response sending down unpublished fields.
      // We don't want those in the Model. Ideally, this wouldn't be needed.
      const fieldsets = data.deleteFieldset.fieldsets.reduce(
        (acc: ClientFieldsetFragment[], fs) => {
          acc.push({ ...fs, fields: fs.fields.filter(f => f.published) });
          return acc;
        },
        []
      );
      cache.writeQuery({
        query: ModelDocument,
        data: { model: { fieldsets, __typename: 'Model' } }
      });
      // force syncs to refetch
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'syncs'
      });
      reset();
      history.push(routes.models);
    }
  });

  React.useEffect(() => {
    return () => {
      // if this component unmounts, clear these atoms
      setUpdateLoading(false);
      setPreventSave(false);
    };
  }, [setPreventSave, setUpdateLoading]);

  function handleDelete(confirmation?: string) {
    if (!fieldset) {
      return;
    }
    void deleteFieldset({ variables: { id: fieldset.id, confirmation } });
  }

  const [getSuggestedName] = useLazyQuery(SuggestedNameDocument, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data) {
        const newName = data?.suggestModelName === '' ? 'Unnamed model' : data?.suggestModelName;
        setValue('realName', newName);
      }
    }
  });

  /**
   * If the user is adding a model, and the name suggestion is enabled, we'll
   * try to suggest a name for the model based on the fields they've added.
   *
   * This will happen every time the fieldsetConfiguration changes.
   *
   * If the user has already entered a name, we won't suggest a name.
   * If the user has changed the suggested name, we won't suggest a name.
   *
   */
  React.useEffect(() => {
    if (isAdding && enableNameSuggestion && !updateLoading && !getFieldsetLoading && !saveLoading) {
      if (fieldset?.id && fieldset?.configurationSchema) {
        getSuggestedName({
          variables: {
            modelId: fieldset.id,
            config: omit(fieldset.configuration, 'sampleResponse')
          }
        });
      }
    }
  }, [fieldset, updateLoading, enableNameSuggestion]);

  return (
    <AclProvider value={fieldset?.acl}>
      <Helmet title={`${isAdding ? 'Add model' : fieldset?.name || 'Models'} | Polytomic`} />
      <PageLayout
        loading={getFieldsetLoading}
        topNavHeading={fieldsetId ? 'Edit model' : 'Add model'}
        topNavActions={
          <FieldsetConfigActions
            deleteLoading={deleteLoading}
            fieldset={fieldset}
            handleSubmit={handleSubmit}
            reset={reset}
            preventSave={preventSave}
          />
        }
      >
        <EditPermission
          fallback={<LacksPermissionBanner message={NO_EDIT_PERMISSION} wrapper={wrapperStyles} />}
        />
        <div className="divide-y divide-gray-300 pb-48">
          <FormProvider {...methods}>
            <FieldsetConnection fieldset={fieldset} dispatch={dispatch} />
            <div className="divide-y divide-gray-300">
              {fieldset && (
                <FieldsetProvider fieldset={fieldset} dispatch={dispatch}>
                  <FieldsetName
                    disabled={updateLoading || saveLoading || deleteLoading}
                    onInputCapture={() => setEnableNameSuggestion(false)}
                  />
                  <UpdatedConfigProvider>
                    <FieldsetConfigSwitcher
                      key={fieldset.connection.id}
                      adding={!!isAdding}
                      type={fieldset.fieldsetType}
                      connectionId={fieldset.connection.type.id}
                    />
                  </UpdatedConfigProvider>
                </FieldsetProvider>
              )}
            </div>
            {hasItems(fieldset?.fields) && (
              <>
                <FieldsetLabels disabled={updateLoading || saveLoading || deleteLoading} />
                <AccessControlWrap>
                  <AccessControlInForm resourceType={ResourceType.Model} />
                </AccessControlWrap>
              </>
            )}
          </FormProvider>
          {fieldsetId && (
            <SideBySide
              hasSectionWrap
              styles="flex items-center space-x-2 w-full"
              heading="Last modified by"
            >
              <ActivityLog
                objectId={fieldsetId}
                fieldset={fieldset}
                title={
                  <span className="text-sm text-gray-800">
                    {!fieldset?.updatedBy
                      ? null
                      : clsx(
                          fieldset?.updatedBy?.name,
                          fieldset?.updatedBy?.email && `<${fieldset?.updatedBy?.email}>`,
                          fieldset?.updatedAt && `on ${getDateOnly(fieldset?.updatedAt) || ''}`
                        )}
                  </span>
                }
              />
            </SideBySide>
          )}
          {fieldsetId && (
            <>
              <SideBySide hasSectionWrap heading="Delete Model" styles="pt-[3px]">
                <p className="mb-2 mt-1 max-w-sm text-sm text-gray-800">
                  Deleting this model will result in the failure of any existing syncs that use this
                  model.
                </p>
                <div className="flex items-center space-x-2">
                  <Button
                    theme="danger"
                    iconEnd="Trashcan"
                    onClick={() => handleDelete()}
                    disabled={deleteLoading}
                  >
                    Delete Model
                  </Button>
                  {deleteLoading && <LoadingDots />}
                </div>
              </SideBySide>
            </>
          )}
        </div>
        <Dialog
          show={!!confirmationRequest}
          heading="Delete model?"
          onDismiss={() => setConfirmationRequest(null)}
          actions={
            <>
              <Button onClick={() => setConfirmationRequest(null)}>Cancel</Button>
              <Button
                theme="danger"
                loading={deleteLoading}
                onClick={() => handleDelete(String(confirmationRequest?.confirm))}
              >
                Delete Model
              </Button>
            </>
          }
        >
          <p className="mb-4 text-sm font-medium text-gray-800">{fieldset?.name}</p>
          <p className="mb-4 flex items-center text-sm font-medium text-gray-800">
            <Icon match={fieldset?.connection.type.id} className="mr-2 h-5 w-auto" />
            {fieldset?.connection.name}
          </p>
          <p className="text-sm text-gray-600 [max-width:65ch]">{confirmationRequest?.message}</p>
        </Dialog>
        <PromptUnsaved when={isDirty && hasItems(Object.keys(dirtyFields))} />
      </PageLayout>
    </AclProvider>
  );
}

export function FieldsetConfig() {
  return (
    <SaveFieldsetLoadingProvider>
      <PreventSaveProvider>
        <ApplyFieldsetUpdateLoadingProvider>
          <Config />
        </ApplyFieldsetUpdateLoadingProvider>
      </PreventSaveProvider>
    </SaveFieldsetLoadingProvider>
  );
}
