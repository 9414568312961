import { useLayoutEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { routes } from '../../utils';

export const SSRedirectLink = () => {
  const params = useParams<{ id: string; org_id?: string }>();
  const history = useHistory();
  useLayoutEffect(() => {
    const load = () => {
      if (params.org_id) {
        window.location.href = `/org/${params.org_id}/redirect/${params.id}`;
      } else {
        window.location.href = `/redirect/${params.id}`;
      }
    };
    void load();
  }, [params, history]);
  return <Redirect to={routes.models} />;
};

export default SSRedirectLink;
