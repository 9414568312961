import { ColumnDef, ExpandedState, RowSelectionState } from '@tanstack/react-table';
import { keyBy, mapValues } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Button, Search } from '~/components';
import { DataTable } from '~/components/v3';
import { Sheet, SheetContent } from '~/components/v3/Sheet';
import { sortByNameAndId } from '~/utils';

interface BulkSyncRow {
  id?: string;
  name?: string;
  schemas?: BulkSyncRow[];
}

// Some schema names are "<schema>.<table>", when we want to display "<table>"
const getSchemaName = (isNamespaceRoot: boolean, depth: number, name: string) => {
  // If there's a single namespace, or multiple namespaces and we're at the root level
  if (isNamespaceRoot || depth === 0) {
    return name;
  }
  // Otherwise the name needs to have the schema name before "." removed
  return name.split('.')?.[depth] ?? name;
};

const getColumns = (namespaceLabel: string, isNamespaceRoot: boolean): ColumnDef<BulkSyncRow>[] => [
  {
    id: 'schema',
    accessorKey: 'schema',
    header: () => <span>{namespaceLabel}</span>,
    accessorFn: row => row.id,
    cell: ({ row }) =>
      getSchemaName(isNamespaceRoot, row.depth, row.original.name ?? row.original.id),
    sortDescFirst: false,
    enableGlobalFilter: true
  }
];
interface BulkSyncSchemaPickerDrawerProps {
  namespaces: BulkSyncRow[];
  selected: string[];
  schemaLabel: { plural: string; singular: string };
  namespaceLabel: string;
  open: boolean;
  onDismiss: (selected?: string[]) => void;
}

export function BulkSyncSchemaPickerDrawer({
  namespaces = [],
  selected,
  schemaLabel,
  namespaceLabel,
  open,
  onDismiss
}: BulkSyncSchemaPickerDrawerProps) {
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const isNamespaceRoot = namespaces?.length === 1 && !namespaces[0].id;
  const columns = getColumns(namespaceLabel, isNamespaceRoot);

  // If namespace is an array of length one, only render children, otherwise render namespaces
  const data = useMemo(
    () =>
      isNamespaceRoot
        ? [...(namespaces[0].schemas ?? [])].sort(sortByNameAndId)
        : namespaces
            .filter(({ id }) => !!id)
            .map(namespace => ({
              ...namespace,
              schemas: [...(namespace?.schemas ?? [])].sort(sortByNameAndId)
            }))
            .sort(sortByNameAndId),
    [namespaces, isNamespaceRoot]
  );

  // SelectedRows is an array of the selected schema keys, filtering out namespaces
  const selectedRows = useMemo(
    () =>
      Object.keys(rowSelection).filter(key =>
        isNamespaceRoot ? true : key.split('.')?.length >= 2
      ),
    [namespaces, rowSelection, isNamespaceRoot]
  );

  const initialSelection = useMemo(
    () =>
      mapValues(
        keyBy(selected, key => key),
        () => true
      ),
    [selected]
  );

  useEffect(() => {
    if (open) {
      setRowSelection(initialSelection);
    } else {
      setExpanded({});
    }
  }, [open]);

  const handleCancel = () => {
    setRowSelection(initialSelection);
    onDismiss();
  };

  return (
    <Sheet open={open} onOpenChange={open => !open && handleCancel()}>
      <SheetContent
        storageKey="bulkSyncPickerDrawer"
        header={
          <>
            <div className="flex flex-1 flex-wrap items-center">
              <h2 className="text-base font-medium">Select {schemaLabel.plural} to resync</h2>
            </div>
            <div className="flex flex-1 justify-center">
              <Search
                className="font-normal"
                wrapperStyles="h-8 w-80"
                placeholder={`Search ${schemaLabel.plural}...`}
                defaultValue={search}
                onChange={v => setSearch(v ?? '')}
                onReset={() => setSearch('')}
              />
            </div>
            <div className="flex flex-1 items-center justify-end space-x-2">
              <Button onClick={() => handleCancel()}>Cancel</Button>
              <Button
                theme="primary"
                onClick={() => onDismiss(selectedRows)}
                disabled={!selectedRows.length}
              >
                {`Select ${selectedRows.length ?? 0} ${
                  selectedRows.length === 1 ? schemaLabel.singular : schemaLabel.plural
                }`}
              </Button>
            </div>
          </>
        }
      >
        <DataTable
          data={data}
          columns={columns}
          // Expansion
          expanded={expanded}
          onExpandedChange={setExpanded}
          getRowId={row => row.id}
          getSubRows={row => row.schemas}
          // Filtering
          globalFilter={search}
          onGlobalFilterChange={setSearch}
          // Selection
          rowSelection={rowSelection}
          onRowSelectionChange={setRowSelection}
        />
      </SheetContent>
    </Sheet>
  );
}
