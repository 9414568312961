import { HTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ReloadNotice } from '~/components/reload-notice';

import BottomNav from '~/components/v2/layout/BottomNav';
import PageContent from '~/components/v2/layout/PageContent';
import PageNav from '~/components/v2/layout/PageNav';
import SideNavInner from '~/components/v2/layout/SideNavInner';
import SideNavOuter from '~/components/v2/layout/SideNavOuter';
import TopNav from '~/components/v2/layout/TopNav';

export type WrapperProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const Wrapper = ({ children }: WrapperProps) => {
  const location = useLocation();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [location.pathname, ref]);
  return (
    <div className="flex flex-col h-[100vh] overflow-clip">
      <div className="sticky">
        <ReloadNotice />
      </div>
      <div className="flex flex-grow-1 overflow-clip">
        <SideNavOuter />
        <SideNavInner />
        <div className="flex h-full w-full flex-col overflow-clip">
          <main className="grid h-full w-full grid-cols-1 grid-rows-[4rem_calc(100vh-4rem)] overflow-clip z-10">
            <TopNav />
            <div className="overflow-x-none flex h-full w-full flex-row overflow-clip">
              <PageNav />
              <PageContent>
                <section className="flex h-full w-full flex-col overflow-auto" ref={ref}>
                  {children}
                </section>
              </PageContent>
            </div>
          </main>
          <BottomNav />
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
