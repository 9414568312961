import { Controller, UseFormReturn, useWatch } from 'react-hook-form';

import { MyCombobox } from '../../../components';
import { NumericComparator } from '../../../generated/graphql';
import {
  HistoryColumnTypes,
  objToSelectables,
  Selectable,
  SyncHistoryFilter
} from '../../../utils';

const timeComparators: Record<string, string> = {
  eq: 'in the last',
  between: 'is between'
};

const triggerComparators: Record<string, string> = {
  eq: 'is'
};

const schemaComparators: Record<string, string> = {
  eq: 'is',
  neq: 'is not'
};

const statusComparators: Record<string, string> = {
  eq: 'is'
};

const countComparators: Record<string, string> = {
  neq: 'is not zero',
  gt: 'is greater than',
  lt: 'is less than'
};

function getComparators(column?: HistoryColumnTypes) {
  if (!column) {
    return [];
  }
  let comparators;
  switch (column) {
    case 'time':
      comparators = timeComparators;
      break;
    case 'trigger':
      comparators = triggerComparators;
      break;
    case 'schema':
      comparators = schemaComparators;
      break;
    case 'status':
      comparators = statusComparators;
      break;
    case 'recordCount':
    case 'insertCount':
    case 'errorCount':
    case 'updateCount':
    case 'warningCount':
      comparators = countComparators;
      break;
    default:
      return [];
  }
  return objToSelectables(comparators, true);
}

function getComparatorLabel(comparator?: NumericComparator, column?: HistoryColumnTypes) {
  const defaultText = 'Compare...';
  if (!comparator || !column) {
    return defaultText;
  }
  switch (column) {
    case 'time':
      return timeComparators[comparator] || defaultText;
    case 'trigger':
      return triggerComparators[comparator] || defaultText;
    case 'schema':
      return schemaComparators[comparator] || defaultText;
    case 'status':
      return statusComparators[comparator] || defaultText;
    case 'recordCount':
    case 'insertCount':
    case 'errorCount':
    case 'updateCount':
    case 'warningCount':
      return countComparators[comparator] || defaultText;
    default:
      return defaultText;
  }
}

interface Props {
  methods: UseFormReturn<{ filters: SyncHistoryFilter[] }>;
  index: number;
  filter: SyncHistoryFilter;
}

export function HistoryFilterComparators(props: Props) {
  const column = useWatch({
    control: props.methods.control,
    name: `filters.${props.index}.column`
  });

  const comparators = getComparators(column);

  const getDefaultValue = () => {
    if (props.filter.comparator) {
      return props.filter.comparator;
    }
    if (column && ['time', 'trigger', 'status'].includes(column)) {
      return NumericComparator.Eq;
    }
    return;
  };

  return (
    <Controller
      name={`filters.${props.index}.comparator`}
      control={props.methods.control}
      defaultValue={getDefaultValue()}
      render={({ field }) => {
        return (
          <MyCombobox
            isDisabled={!column}
            placeholder="Compare..."
            value={
              field.value == null || getComparatorLabel(field.value, column) === 'Compare...'
                ? null
                : ({
                    label: getComparatorLabel(field.value, column) || field.value,
                    value: field.value
                  } as Selectable)
            }
            options={comparators}
            onChange={option => {
              field.onChange(option);

              props.methods.setValue(`filters.${props.index}`, {
                column,
                comparator: option?.value as NumericComparator,
                param:
                  column &&
                  [
                    'recordCount',
                    'updateCount',
                    'errorCount',
                    'insertCount',
                    'warningCount'
                  ].includes(column) &&
                  option?.value === 'neq'
                    ? '0'
                    : ''
              });
            }}
          />
        );
      }}
    />
  );
}
