import TooltipIcon from '~/components/tooltip-icon';
import { Icon } from '~/components';
import { BulkExecutionStatus, Maybe, SchemaExecutionStatus } from '~/generated/graphql';

export function ExecutionStatusDisplay({
  status,
  message
}: {
  status: BulkExecutionStatus | SchemaExecutionStatus;
  message?: Maybe<string | string[]>;
}) {
  switch (status) {
    case (BulkExecutionStatus.Created, SchemaExecutionStatus.Created):
      return <p className="text-gray-800">Starting...</p>;
    case BulkExecutionStatus.Running:
    case BulkExecutionStatus.Processing:
    case SchemaExecutionStatus.Running:
      return <p className="text-gray-500">Running...</p>;
    case BulkExecutionStatus.Exporting:
    case SchemaExecutionStatus.Exporting:
      return <p className="text-gray-500">Exporting...</p>;
    case BulkExecutionStatus.Completed:
    case SchemaExecutionStatus.Completed:
      return <Icon name="CheckFilled" className="h-4 w-4 text-green-500" />;
    case BulkExecutionStatus.Errors:
      return (
        <p className="flex w-max items-center space-x-1.5 text-sm text-gray-800">
          <Icon name="WarningFilled" className="h-4 w-4 text-amber-500" />
        </p>
      );
    case (BulkExecutionStatus.Failed, SchemaExecutionStatus.Failed):
      return (
        <TooltipIcon
          placement="left"
          interactive={true}
          icon={<Icon name="DangerFilled" className="h-4 w-4 text-red-500" />}
          message={message || 'Bulk sync failed'}
          className="max-w-3xl break-words whitespace-pre-wrap"
        />
      );
    default:
      return <p className="capitalize text-gray-800">{status}</p>;
  }
}
