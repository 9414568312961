import * as React from 'react';

import { Button, MyInput } from '~/components';
import { Dialog } from '~/components/v3';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { useSanitizeIdText } from '~/hooks';
import { capsFirst } from '~/utils';

interface Props {
  type: 'override' | 'create';
  show: boolean;
  handleSave: (value: string) => void;
  dismiss: () => void;
  input: string;
  connectionName: string;
  targetObjectName: string;
  objectType: string;
  connectionId?: string;
}

export const StringInputDialog = React.memo<Props>(
  ({ show, handleSave, dismiss, connectionId, input, ...props }) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const prevTextRef = React.useRef<string>('');

    const { sanitizeIdText, sanitizeIdTextLoading } = useSanitizeIdText();

    // Todo -> Add comments
    const save = React.useCallback(() => {
      if (inputRef.current && inputRef.current.value) {
        handleSave(inputRef.current.value);
        if (inputRef?.current?.value) {
          inputRef.current.value = '';
        }
        dismiss();
      }
    }, [dismiss, handleSave]);

    // Todo -> Add comments
    const handleSanitize = React.useCallback(
      async (str: string | undefined) => {
        if (!connectionId || !str || str === prevTextRef.current) {
          return;
        }
        const safeName = await sanitizeIdText(connectionId, str);
        if (!safeName) {
          return;
        }
        prevTextRef.current = safeName;
        if (inputRef.current) {
          inputRef.current.value = safeName;
        }
      },
      [connectionId, sanitizeIdText]
    );

    // Todo -> Add comments
    React.useEffect(() => {
      void handleSanitize(input);
    }, [handleSanitize, input]);

    // Todo -> Add comments
    const handleKeyDown = async (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
        await handleSanitize(inputRef.current?.value);
        save();
      }
    };

    // Todo -> Add comments
    const handleSaveOnClick = async (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      await handleSanitize(inputRef.current?.value);
      save();
    };

    // Todo -> Add comments
    const handleCancelOnClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dismiss();
    };

    return (
      <Dialog
        show={show}
        initialFocusRef={inputRef}
        size="sm"
        heading={props.type === 'override' ? 'Text value' : `New ${props.objectType}`}
        onDismiss={dismiss}
        actions={
          <>
            <Button onClick={handleCancelOnClick}>Cancel</Button>
            <Button theme="primary" disabled={sanitizeIdTextLoading} onClick={handleSaveOnClick}>
              Save
            </Button>
          </>
        }
      >
        <div className="relative" onKeyDown={handleKeyDown}>
          <MyInput
            ref={inputRef}
            name="string-input"
            defaultValue={input}
            label={props.type === 'override' ? 'Enter text' : `Enter ${props.objectType} name`}
            placeholder={props.type === 'override' ? 'Type value...' : 'Type name...'}
            description={
              props.type === 'override' ? (
                <span className="flex pt-4">
                  <span>
                    Text will be written to the {props.connectionName} {props.targetObjectName}{' '}
                    {props.objectType}.{' '}
                    <a
                      href="https://docs.polytomic.com/docs/custom-text"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      Learn more ↗
                    </a>
                  </span>
                </span>
              ) : (
                <span className="flex items-start space-x-1 pt-4">
                  {capsFirst(String(props.objectType))} will be created in {props.connectionName}{' '}
                  after saving your sync configuration.
                </span>
              )
            }
            onBlur={e => handleSanitize(e.target.value)}
            readOnly={sanitizeIdTextLoading}
          />
          {sanitizeIdTextLoading && (
            <div className="absolute top-9 right-2 flex items-center">
              <LoadingDots />
            </div>
          )}
        </div>
      </Dialog>
    );
  }
);

if (import.meta.env.MODE === 'development') {
  StringInputDialog.displayName = 'StringInputDialog';
}
