import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { CustomSchedule, Schedule, WeeklySchedule } from '../generated/graphql';
import { DAYS } from './constants.util';
import { Selectable } from './custom-types.util';
import cronstrue from 'cronstrue';
import { isValidCron } from 'cron-validator';
import { times } from 'lodash';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

export function daySelectable(day?: string | null): Selectable | null {
  const dayInt = day && parseInt(day);
  if (dayInt === 0 || dayInt) {
    return DAYS[dayInt];
  }
  return null;
}

export function minuteHourSelectable(integer?: string | null): Selectable | null {
  if (integer != null) {
    const value = integer.padStart(2, '0');
    return { value, label: value };
  }
  return null;
}

export function minuteHourSelectables(integers: string[]): Selectable[] {
  return integers.map(minuteHourSelectable);
}

// export const shortTz = dayjs().format("z")
// export const tzOffset = dayjs().utcOffset() / 60

export function convertHour({
  hour,
  offset,
  to
}: {
  hour: string | null | undefined;
  offset: number;
  to: 'utc' | 'local';
}): number | null {
  if (!hour) {
    return null;
  }
  const val = Number.parseInt(hour);
  if (offset === 0) {
    return val;
  }
  let newHour = to === 'utc' ? val - offset : val + offset;
  if (newHour < 0) {
    newHour += 24;
  } else if (newHour > 23) {
    newHour -= 24;
  }
  return newHour;
}

export function convertDayOfWeek({
  schedule,
  offset,
  to
}: {
  schedule: WeeklySchedule | null | undefined;
  offset: number;
  to: 'utc' | 'local';
}) {
  if (!schedule || !schedule.dayOfWeek || !schedule.hour) {
    return { hour: null, dayOfWeek: null };
  }
  let hour = Number.parseInt(schedule.hour);
  let day = Number.parseInt(schedule.dayOfWeek);
  if (offset === 0) {
    return {
      hour,
      dayOfWeek: day
    };
  }

  hour = to === 'utc' ? hour - offset : hour + offset;
  if (hour < 0) {
    hour += 24;
    day -= 1;
  } else if (hour > 23) {
    hour -= 24;
    day += 1;
  }
  if (day < 0) {
    day += 7;
  } else if (day > 6) {
    day -= 7;
  }

  return { hour: hour, dayOfWeek: day };
}

export function handleScheduleUpdate<Type extends { __typename?: string }>(
  value: Type | null | undefined
) {
  if (!value) {
    return;
  }
  const schedule = { ...value };
  // if (isDailySchedule(schedule)) {
  //     const hour = convertHour({
  //         value: schedule.hour,
  //         offset: tzOffset,
  //         to: "utc"
  //     })
  //     schedule.hour = hour ? hour.toString().padStart(2, "0") : ""
  // }
  // if (isWeeklySchedule(schedule)) {
  //     const {hour, dayOfWeek} = convertDayOfWeek({
  //         schedule,
  //         offset: tzOffset,
  //         to: "utc"
  //     })
  //     schedule.hour = hour ? hour.toString().padStart(2, "0") : ""
  //     schedule.dayOfWeek = dayOfWeek.toString()
  // }
  const { __typename, ...cleaned } = schedule;
  return cleaned;
}

export function scheduleToCron(schedule: Schedule) {
  if (!schedule) {
    return;
  }
  const { minute, hour, dayOfMonth, month, dayOfWeek } = schedule as CustomSchedule;
  return `${minute ?? '*'} ${hour ?? '*'} ${dayOfMonth ?? '*'} ${month ?? '*'} ${dayOfWeek ?? '*'}`;
}

export const TIME_FORMAT_24 = true;

export function isValidCronString(cron: string) {
  return isValidCron(cron);
}

export function friendlyCronDescription(cron: string) {
  try {
    if (!isValidCronString(cron)) {
      throw new Error('Invalid cron string');
    }
    return cronstrue
      .toString(cron, {
        verbose: true,
        use24HourTimeFormat: TIME_FORMAT_24,
        tzOffset: 0
      })
      .replace(/\b\d{2}:\d{2}\b/g, match => `${match} UTC`);
  } catch {
    return undefined;
  }
}

export function defaultCron(schedule?: Schedule) {
  if (schedule?.frequency === 'builder' || schedule?.frequency === 'custom') {
    return scheduleToCron(schedule);
  }
  return '* * * * *';
}

export function isValidCronPart(value: string, index: number) {
  return isValidCronString(
    [...times(index, () => '*'), value, ...times(4 - index, () => '*')].join(' ')
  );
}
