import { ApolloError, useQuery } from '@apollo/client';
import cx from 'clsx';
import * as React from 'react';
import FocusLock from 'react-focus-lock';
import { generatePath, useHistory } from 'react-router-dom';
import { Icon } from '~/components';

import { Button, Lister, Search } from '~/components';
import { Dialog } from '~/components/v3';
import { COLOR } from '~/components/v2/configs/SX';
import Chip from '~/components/v2/display/Chip';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { ConnectionTypeFragment, ConnectionTypesDocument, Operation } from '~/generated/graphql';
import { useIntersection } from '~/hooks';
import { getIndexOfId, hasItems, routes } from '~/utils';

interface Props {
  onDismiss: () => void;
  onRequestIntegration: () => void;
}

export function AddConnection({ onDismiss, onRequestIntegration }: Props) {
  const [filtered, setFiltered] = React.useState<ConnectionTypeFragment[]>([]);
  const [selection, setSelection] = React.useState<ConnectionTypeFragment | null>(null);
  const [serverErrors, setServerErrors] = React.useState<ApolloError>();

  const { data, loading } = useQuery(ConnectionTypesDocument, {
    onCompleted: data => {
      if (!data || !data.connectionTypes) {
        return;
      }
      setFiltered(data.connectionTypes);
      setSelection(data.connectionTypes[0]);
    },
    onError: error => setServerErrors(error)
  });

  const connections = React.useMemo(() => data?.connectionTypes || [], [data]);

  const history = useHistory();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [blockPointer, setBlockPointer] = React.useState(false);
  const [isListMove, setIsListMove] = React.useState(false);

  const handleSearch = React.useCallback(
    (value: string | undefined) => {
      if (!value || value === '') {
        setFiltered(connections);
        return;
      }
      const val = value.replace(/[\s-]*/g, '').toLowerCase();
      const filtered = connections.filter(item => {
        const name = item.name.replace(/\s/g, '').toLowerCase();
        return name.includes(val);
      });
      setFiltered(filtered);
      if (filtered.length === 0) {
        setSelection(null);
        return;
      }
      if (!filtered.some(item => item.id === selection?.id)) {
        setSelection(filtered[0]);
      }
    },
    [connections, selection]
  );

  const handleResetSearch = React.useCallback(() => setFiltered(connections), [connections]);

  const goToConfig = React.useCallback(() => {
    if (!selection || !selection.enabled) {
      return;
    }
    history.push(generatePath(routes.addConnection, { connectionTypeId: selection.id }));
    onDismiss();
  }, [history, onDismiss, selection]);

  const guards = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, action: () => void) => {
      e.preventDefault();
      if (filtered.length === 0) {
        return;
      }
      action();
    },
    [filtered.length]
  );

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      setBlockPointer(true);
      switch (e.key) {
        case 'ArrowDown':
          guards(e, () => {
            setSelection(prev => {
              if (!prev) {
                return filtered[0];
              }
              const curIndex = getIndexOfId(prev.id, filtered);
              const idx = Math.min(curIndex + 1, filtered.length - 1);
              return filtered[idx];
            });
            setIsListMove(true);
          });
          return;
        case 'ArrowUp':
          guards(e, () => {
            setSelection(prev => {
              if (!prev) {
                return filtered[0];
              }
              const curIndex = getIndexOfId(prev.id, filtered);
              const idx = Math.max(curIndex - 1, 0);
              return filtered[idx];
            });
            setIsListMove(true);
          });
          return;
        case 'Enter':
          guards(e, () => {
            goToConfig();
          });
          return;
        default:
          return;
      }
    },
    [filtered, goToConfig, guards]
  );

  const handleIntegrationRequest = () => {
    onDismiss();
    onRequestIntegration();
  };

  React.useEffect(() => {
    function handler() {
      setIsListMove(false);
      setBlockPointer(false);
    }
    document.addEventListener('pointermove', handler);
    return () => {
      document.removeEventListener('pointermove', handler);
    };
  }, []);

  return (
    <Dialog
      show={true}
      initialFocusRef={inputRef}
      onDismiss={onDismiss}
      size="md"
      heading="Select connection type"
      headingLogo={
        <div className="absolute right-6">
          <Button theme="ghost" size="icon" onClick={onDismiss}>
            <Icon name="CloseX" className="h-5 w-5" />
          </Button>
        </div>
      }
      slots={{ body: 'p-0' }}
      actions={
        hasItems(filtered) ? (
          <p>
            {`Don't see the integration you need?`}
            <button className="ml-2 text-blue-500" onClick={handleIntegrationRequest}>
              Make a request
            </button>
          </p>
        ) : null
      }
    >
      {!!serverErrors && (
        <div className="flex max-w-xl items-start space-x-2 break-words px-5 pt-3 text-sm font-medium text-amber-600">
          <Icon name="WarningFilled" className="h-5 w-5 text-amber-500" />
          <Lister items={serverErrors} />
        </div>
      )}
      {loading ? (
        <div className="flex h-[29.75rem] items-center justify-center">
          <LoadingDots />
        </div>
      ) : (
        <>
          <div className="sticky top-0 z-10 w-full bg-white px-5 pt-3">
            <FocusLock>
              <Search
                debounce={true}
                placeholder="Search connections..."
                onChange={handleSearch}
                onReset={handleResetSearch}
                onKeyDown={handleKeyDown}
              />
            </FocusLock>
          </div>
          <div className="h-[27rem] space-y-1 overflow-scroll py-1.5">
            {hasItems(filtered)
              ? filtered.map(item => (
                  <Wrap
                    key={item.id}
                    scrollIntoView={isListMove && item.id === selection?.id}
                    className={cx(
                      'mx-6 flex items-center justify-between rounded py-2.5 px-3',
                      item.enabled ? 'cursor-pointer' : 'bg-gray-100 opacity-50',
                      item.id === selection?.id &&
                        (item.enabled ? 'bg-indigo-50' : 'ring-2 ring-gray-300')
                    )}
                    onClick={goToConfig}
                    onPointerOver={() => {
                      if (blockPointer) {
                        return;
                      }
                      setSelection(item);
                    }}
                  >
                    <span className="flex items-center space-x-2">
                      <Icon match={item.id} className="h-8 w-8" />
                      <p className="text-sm text-gray-800">{item.name}</p>
                    </span>
                    {hasItems(item.operations) && (
                      <span className="space-x-2">
                        {item.operations.includes(Operation.Target) && (
                          <Chip color={COLOR.PRIMARY} bold>
                            Destination
                          </Chip>
                        )}
                        {(item.operations.includes(Operation.Fieldsetquery) ||
                          item.operations.includes(Operation.BulkSyncSource)) && (
                          <Chip color={COLOR.PRIMARY} bold>
                            Source
                          </Chip>
                        )}
                      </span>
                    )}
                  </Wrap>
                ))
              : !serverErrors && (
                  <div className="m-6 text-center text-sm text-gray-500 space-y-2">
                    <p>No integrations match your search</p>
                    <button className="text-blue-500" onClick={handleIntegrationRequest}>
                      Request an integration
                    </button>
                  </div>
                )}
          </div>
        </>
      )}
    </Dialog>
  );
}

interface WrapProps extends React.ComponentProps<'div'> {
  scrollIntoView: boolean;
}

function Wrap({ scrollIntoView, ...rest }: WrapProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const entry = useIntersection(ref, {});

  React.useLayoutEffect(() => {
    if (scrollIntoView && !entry?.isIntersecting) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [entry?.isIntersecting, scrollIntoView]);

  return <div ref={ref} {...rest} />;
}
