import { flexRender, Header } from '@tanstack/react-table';
import clsx from 'clsx';
import { HTMLAttributes, useCallback } from 'react';

import TableColResizer from '~/components/v2/table/TableColResizer';

export type TableHeaderColProps<TData, TValue> = {
  header: Header<TData, TValue>;
} & HTMLAttributes<HTMLTableCellElement>;

const TableHeaderCol = <TData, TValue>({ header, ...rest }: TableHeaderColProps<TData, TValue>) => {
  const resizable = header.column.getCanResize();
  const width = useCallback(() => header.getSize(), [header]);

  const baseClassName = clsx(
    'flex thead-label w-full items-center',
    resizable && 'relative border-r border-gray-300'
  );

  return (
    <th
      scope="col"
      colSpan={header.colSpan}
      className={baseClassName}
      style={{ width: width() }}
      {...rest}
    >
      <span className="truncate">
        {!header?.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}
      </span>
      {header.column.getCanResize() && <TableColResizer header={header} />}
    </th>
  );
};

export default TableHeaderCol;
