import { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';
import { cn, getSlots, type VariantProps } from '~/lib/utils';
import { CheckSmallIcon, CloseIcon, ExclamationIcon, InfoIcon, WarningIcon } from './Icons';

const alertVariants = tv({
  slots: {
    container: 'p-4 rounded shadow-lg border justify-start items-start gap-1 flex text-white',
    icon: 'w-5 h-5',
    text: 'text-white text-sm'
  },
  variants: {
    variant: {
      danger: {
        container: 'bg-red-500 border-red-600',
        icon: 'text-red-200'
      },
      info: {
        container: 'bg-sky-500 border-sky-600',
        icon: 'text-blue-200  '
      },
      success: {
        container: 'bg-emerald-500 border-emerald-600',
        icon: 'text-emerald-200 '
      },
      warning: {
        container: 'bg-orange-500 border-orange-600 ',
        icon: 'text-orange-200'
      }
    }
  }
});

export interface AlertProps extends VariantProps<typeof alertVariants> {
  children?: React.ReactNode;
  className?: string;
  isCloseable?: boolean;
  timeout?: number;
}

const VariantIcon = {
  danger: ExclamationIcon,
  info: InfoIcon,
  success: CheckSmallIcon,
  warning: WarningIcon
};

export function Alert({ children, timeout, isCloseable, ...props }: AlertProps) {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    timeout && setTimeout(() => setHidden(true), timeout);
  }, [timeout]);

  const { container, icon, text } = getSlots(alertVariants, props);
  const Icon = VariantIcon[props.variant];
  return (
    <div className={cn(container, hidden && 'hidden')}>
      <Icon className={icon} />
      <p className={text}>{children}</p>
      <div className="flex-1" />
      {isCloseable && (
        <button className="cursor-pointer" onClick={() => setHidden(true)}>
          <CloseIcon className={cn(icon, 'w-4 h-4')} />
        </button>
      )}
    </div>
  );
}
