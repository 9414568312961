import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormElements, Label, MyCombobox } from '~/components';
import { getSchemaAsList } from '~/utils';
import { GoogleSheetsPicker } from './GoogleSheetPicker';
import { OAuthMessage } from './OAuthMessage';
import { FormBuilderProps } from './types';

const Options = {
  browser: { label: 'OAuth', value: 'browser', fields: ['user_email'] },
  jwt: {
    label: 'Service Account',
    value: 'jwt',
    fields: ['user_email', 'service_account']
  }
};

const DefaultOption = Options['browser'];

const AuthMethodKey = 'connect_mode';

export function GoogleSheets(props: FormBuilderProps) {
  const { control, setValue, register, reset, formState } = useFormContext();

  const auth = useWatch({ control, name: AuthMethodKey });
  const email = useWatch({ control, name: 'user_email' });
  const [isReconnecting, setIsReconnecting] = useState(false);
  const selected = Options[auth] || DefaultOption;
  const items = getSchemaAsList(props.schema).filter(item =>
    selected.fields.includes(item.name as string)
  );

  const handleSelect = (selected: { label: string; value: string; fields: string[] }) => {
    if (email) {
      // if a user is associated then the connection has been previously saved and we need force
      // a reset when switching the auth to make sure older data isn't stored on the backend
      reset({
        __reconnect: selected.value === 'browser',
        [AuthMethodKey]: selected.value,
        user_email: email,
        spreadsheet_id: { value: '' }
      });
      setIsReconnecting(true);
    } else {
      //
      setValue(AuthMethodKey, selected.value);
    }
  };

  return (
    <>
      {/* Include default values for spreadsheet_id value */}
      <input {...register('spreadsheet_id.value')} type="hidden" defaultValue="" />

      <div>
        <Label>Authentication method</Label>
        <MyCombobox options={Object.values(Options)} value={selected} onChange={handleSelect} />
      </div>
      {selected.value === 'browser' && <OAuthMessage {...props} />}
      {items.map(item => (
        <FormElements key={item.name as string} item={item} />
      ))}
      {!isReconnecting && <GoogleSheetsPicker {...props} />}
    </>
  );
}
