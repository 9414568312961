import { tv, type VariantProps } from 'tailwind-variants';
import { cn } from '~/lib/utils';
import { ComponentPropsWithRef } from 'react';

const buttonVariants = tv({
  base: `text-sm`,
  variants: {
    variant: {
      default: `
        flex py-[6px] px-3 gap-2 bg-gray-50 border-gray-400 rounded-sm shadow font-medium text-gray-800
        transition-shadow
        active:shadow-sm
        disabled:disabled:text-gray-400 disabled:bg-gray-200 disabled:border-gray-200 disabled:shadow-none
      `,
      link: `text-gray-500 transition-colors active:text-gray-400 disabled:text-gray-400`
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});

export interface ButtonProps
  extends ComponentPropsWithRef<'button'>,
    VariantProps<typeof buttonVariants> {}

export function Button({ className, variant, ...props }: ButtonProps) {
  return <button className={cn(buttonVariants({ variant, className }))} {...props} />;
}
