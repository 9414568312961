import { MyPopover, Permission, PopoverAction, PopoverButtonMinimal } from '../../components';
import { Action, PermissionTagFragment } from '../../generated/graphql';
import { useToggle } from '../../hooks';
import { RoleOption } from '../../utils';
import DeleteTagDialog from './delete-tag-dialog';
import { EditTagDialog } from './edit-tag-dialog';
import CloneTagDialog from './clone-tag-dialog';

export function TagActions({ tag, roles }: { tag: PermissionTagFragment; roles: RoleOption[] }) {
  const [showPopover, togglePopover] = useToggle();
  const [showEditDialog, toggleEditDialog] = useToggle();
  const [showCloneDialog, toggleCloneDialog] = useToggle();
  const [showDeleteDialog, toggleDeleteDialog] = useToggle();

  const toggleEdit = () => {
    togglePopover();
    toggleEditDialog();
  };

  const toggleClone = () => {
    togglePopover();
    toggleCloneDialog();
  };

  const toggleDelete = () => {
    togglePopover();
    toggleDeleteDialog();
  };

  return (
    <>
      <MyPopover
        visible={showPopover}
        onClickOutside={togglePopover}
        placement="bottom-end"
        offset={[0, 6]}
        className="z-10 w-40"
        content={
          <div className="py-1">
            <Permission type={Action.Edit}>
              <PopoverAction onClick={toggleEdit}>Edit</PopoverAction>
            </Permission>
            <Permission type={Action.Edit}>
              <PopoverAction onClick={toggleClone}>Clone</PopoverAction>
            </Permission>
            {!tag.system && (
              <Permission type={Action.Delete}>
                <PopoverAction onClick={toggleDelete}>Delete</PopoverAction>
              </Permission>
            )}
          </div>
        }
      >
        <PopoverButtonMinimal isShowingPopover={showPopover} onClick={togglePopover} />
      </MyPopover>
      {showEditDialog && (
        <EditTagDialog roles={roles} type="edit" tag={tag} toggleDialog={toggleEditDialog} />
      )}
      {showCloneDialog && <CloneTagDialog tag={tag} toggleDialog={toggleCloneDialog} />}
      {showDeleteDialog && <DeleteTagDialog tag={tag} toggleDialog={toggleDeleteDialog} />}
    </>
  );
}
