import { v4 as uuid } from 'uuid';

import { ExecutionRecordType } from './custom-types.util';
import { isSyncExecutionError, isSyncRecordMessage } from './predicates.util';
import { SyncRecordPreview } from './union-types.util';

export function formatPreviewRecord(
  record: SyncRecordPreview,
  idField: string | null,
  recordType?: ExecutionRecordType | null
) {
  if (isSyncRecordMessage(record)) {
    const messageKey =
      recordType === 'errors' ? 'error' : recordType === 'warnings' ? 'warning' : 'message';
    if (!record.identityField && !record.identityValue) {
      return {
        [messageKey]: record.message
      };
    }
    if (!record.identityField) {
      return {
        id: record.identityValue as string,
        [messageKey]: record.message
      };
    }
    return {
      [record.identityField]: record.identityValue as unknown,
      [messageKey]: record.message
    };
  }
  if (isSyncExecutionError(record)) {
    return { error: record.error };
  }

  const data = { ...record.data };
  if ('' in data) {
    const value = data[''];
    data['-'] = value;
    delete data[''];
  }
  // We always want "id" to be the first field in preview objects
  if (idField != null && idField in data) {
    const { [idField]: id, ...rest } = data;
    return { [idField]: id, ...rest };
  }
  return data;
}

export function previewRecordsToRows(
  records: SyncRecordPreview[],
  idField: string | null,
  recordType?: ExecutionRecordType | null
) {
  return records.map(record => {
    if (isSyncRecordMessage(record)) {
      const messageKey =
        recordType === 'errors' ? 'error' : recordType === 'warnings' ? 'warning' : 'message';

      if (!record.identityField && !record.identityValue) {
        return {
          [messageKey]: record.message
        };
      }
      if (!record.identityField) {
        return {
          id: record.identityValue as string,
          [messageKey]: record.message
        };
      }
      return {
        id: record.identityValue as string,
        [record.identityField]: record.identityValue as unknown,
        [messageKey]: record.message
      };
    }
    if (isSyncExecutionError(record)) {
      return {
        id: '',
        error: record.error
      };
    }
    let id = '';
    const data = { ...record.data };
    if ('' in data) {
      const value = data[''];
      data['-'] = value;
      delete data[''];
    }
    if (record.link && idField != null) {
      id = data[idField] as string;
      data[idField] = (
        <a href={record.link} target="_blank" rel="noopener noreferrer" className="link">
          {data[idField] as React.ReactNode}
        </a>
      );
    }
    return { id, ...data };
  });
}

export const emptyCell = <span className="pointer-events-none text-gray-400">–</span>;
