import * as React from 'react';

import { SyncFragment } from '../generated/graphql';

export const SyncContext = React.createContext<SyncFragment | undefined>(undefined);

export function SyncProvider({
  sync,
  children
}: {
  sync: SyncFragment;
  children: React.ReactNode;
}) {
  const value = React.useMemo(() => sync, [sync]);
  return <SyncContext.Provider value={value}>{children}</SyncContext.Provider>;
}

export function useSyncState() {
  const contextIsDefined = React.useContext(SyncContext);
  if (contextIsDefined == null) {
    throw new Error('useSync must be used within a SyncProvider');
  }
  return contextIsDefined;
}
