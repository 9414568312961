import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

import { TooltipMessaging } from '../utils';
import { Icon } from '~/components';
import { Tooltip, TooltipProps } from './tooltip';

export type TooltipIconProps = {
  message: TooltipMessaging | ReactNode;
  icon?: ReactElement;
} & Partial<TooltipProps>;

const TooltipIcon = ({
  message,
  icon = <Icon name="InfoFilled" className="h-4 w-4 text-blue-500 hover:text-blue-400" />,
  className,
  ...rest
}: TooltipIconProps) => {
  // Note: Should consider moving 'max-w-xs' to the base Tooltip component
  return (
    <Tooltip {...rest} content={message} children={icon} className={clsx('max-w-xs', className)} />
  );
};

export default TooltipIcon;
