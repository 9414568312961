import { JSONSchema4 } from 'json-schema';
import * as React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { CompletionValue } from '../../../generated/graphql';
import { SchemaFormElement } from './elements';

export const resolveRef = (root: JSONSchema4, ref: string): JSONSchema4 => {
  let resolved;
  const path = ref.split('/');
  for (const element of path) {
    if (element === '#') {
      resolved = root;
    } else {
      if (resolved === undefined) {
        throw new Error('invalid reference');
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      resolved = resolved[element];
    }
  }
  // @ts-ignore
  return resolved; // eslint-disable-line @typescript-eslint/no-unsafe-return
};

const deRef = (root: JSONSchema4, current?: JSONSchema4): JSONSchema4 => {
  if (!current) {
    current = root;
  }

  if (current.$ref) {
    // replace with reference
    current = deRef(root, {
      ...current,
      ...resolveRef(root, current.$ref),
      $ref: undefined
    });
  }

  // no? OK, then deRef any properties we have
  if (current.properties) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
    current.properties = Object.fromEntries(
      Object.entries(current.properties).map<[string, JSONSchema4]>(([key, schema]) => [
        key,
        deRef(root, schema)
      ])
    );
  }
  let items = current.items;
  if (current.items) {
    if (Array.isArray(current.items)) {
      items = current.items.map(item => deRef(root, item));
    } else {
      items = deRef(root, current.items);
    }
  }
  return {
    ...current,
    items
  };
};

export type RenderFunc =
  | ((data: {
      field: JSONSchema4;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (...event: any[]) => void;
      setValue: (updated: unknown) => void;
      value: unknown;
    }) => React.ReactElement<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any,
      | string
      | ((
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          props: any
        ) => React.ReactElement<
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          string | any | (new (props: any) => React.Component<any, any, any>)
        > | null)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | (new (props: any) => React.Component<any, any, any>)
    >)
  | undefined;
export type ValueType = unknown;

interface SchemaFormProps<T extends { [k: string]: ValueType }> {
  schema: JSONSchema4;
  values: T;
  errors?: Partial<Record<keyof T, ValueType>>;
  getOptions?: (field: string, query?: string) => Promise<CompletionValue[]>;
  showFieldTitle?: boolean;
  onChange?: (values: Record<string, unknown>) => void;
  classNames?: Partial<Record<keyof T, string>>;
  renderFields?: Partial<Record<keyof T, RenderFunc>>;
}

export function SchemaForm<T extends { [k: string]: ValueType }>(props: SchemaFormProps<T>) {
  const formMethods = useForm<SchemaFormProps<T>>({
    mode: 'onBlur',
    defaultValues: props.values
  });

  return (
    <FormProvider {...formMethods}>
      <NestedSchemaForm<T> {...props} />
    </FormProvider>
  );
}

type watchType = ReturnType<ReturnType<typeof useForm>['watch']>;

export function NestedSchemaForm<T extends { [k: string]: ValueType }>({
  showFieldTitle = true,
  ...props
}: SchemaFormProps<T> & { prefix?: string; index?: number }) {
  const { getValues, watch } = useFormContext();
  const schema = deRef(props.schema);
  const index = props.index !== undefined ? `[${props.index}]` : '';
  const namePrefix = props.prefix ? `${props.prefix}${index}.` : '';

  const watchedBy: { [key: string]: watchType } = {};

  Object.keys((schema.dependentRequired as Record<string, unknown>) || {}).forEach(f => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const w = watch(f, props.values[f]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    schema.dependentRequired[f].forEach((d: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
      watchedBy[d] = w;
    });
  });

  if (!schema.properties) {
    return null;
  }
  return (
    <>
      {Object.keys(schema.properties)
        .map((key: string) => ({
          key,
          field: schema.properties![key]
        }))
        .filter(({ field }: { field: JSONSchema4 }) => !!field)
        .map(({ key, field }: { key: string; field: JSONSchema4 }) => {
          let conditional: boolean | watchType = true;
          if (Object.keys(watchedBy).includes(key)) {
            conditional = watchedBy[key];
          }

          const render =
            props.renderFields &&
            (props.renderFields[key] || props.renderFields[`${props.prefix || ''}.${key}`]);

          return (
            field.title &&
            !field.hidden &&
            (!field.readonly || props.values[key]) &&
            conditional && (
              <div key={key}>
                {showFieldTitle && field.type !== 'boolean' && !field.hideTitle && (
                  <label
                    htmlFor={key}
                    className="mb-1 block text-left text-sm font-medium text-gray-800"
                  >
                    {field.title}
                  </label>
                )}
                <SchemaFormElement
                  id={key}
                  getOptions={props.getOptions}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                  dependencies={(schema.dependentRequired || {})[key]}
                  name={`${namePrefix}${key}`}
                  field={field}
                  type={field.type}
                  defaultValue={props.values[key] || field.default}
                  readOnly={!!field.readonly}
                  required={
                    schema.required
                      ? Array.isArray(schema.required)
                        ? schema.required.includes(key)
                        : true
                      : false
                  }
                  errors={props.errors}
                  renderFields={render}
                  className={props.classNames && props.classNames[key]}
                  onChange={() => {
                    if (props.onChange) {
                      props.onChange(getValues());
                    }
                  }}
                />
                {field.description ? (
                  <p className="mt-1 text-left text-xs text-gray-500">{field.description}</p>
                ) : null}
              </div>
            )
          );
        })}
    </>
  );
}

// SchemaFormTest provides a Hook Form container which is useful when writing tests
export function SchemaFormTest<T extends { [k: string]: ValueType }>(
  props: React.PropsWithChildren<{ values: Record<string, ValueType> }>
) {
  const formMethods = useForm<SchemaFormProps<T>>({
    mode: 'onBlur',
    defaultValues: props.values
  });

  return <FormProvider {...formMethods}>{props.children && props.children}</FormProvider>;
}
