import cx from 'clsx';
import { JSONSchema4 } from 'json-schema';
import * as React from 'react';
import { FieldValues, PathValue, useFormContext } from 'react-hook-form';

import { CompletionValue } from '../../generated/graphql';
import { isRequiredMsg } from '../../utils';
import { EditPermission } from '../edit-permission';
import { Checkbox } from './checkbox';
import { FormArray } from './form-array';
import { FormCompletionsSelect } from './form-completions-select';
import { FormEnumSelect } from './form-enum';
import { FormFileUpload } from './form-file-upload';
import FormHierarchyCompletion from './FormHierarchyCompletion';
import { InputProps, MyInput, Textarea } from './input';

interface Props {
  item: JSONSchema4;
  promiseOptions?: (field: string, query?: string) => Promise<CompletionValue[]>;
  styleOverrides?: Record<string, string>;
}

const SetOnceInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [isReadOnly] = React.useState(Boolean(props.value));
  return <MyInput ref={ref} {...props} readOnly={isReadOnly} />;
});

export function FormElements<TFormValues extends FieldValues>({
  item,
  styleOverrides,
  promiseOptions
}: Props) {
  const [inputType, setInputType] = React.useState<'password' | 'text'>('password');

  const { formState, register, watch } = useFormContext<TFormValues>();
  const { errors } = formState;

  const watchedDependsOn = item.dependsOn ? watch(item.dependsOn) : null;
  const required = (item.required || !!watchedDependsOn) && isRequiredMsg(item.title);

  const sensitiveInput = item.sensitive
    ? {
        type: inputType,
        onMouseEnter: () => setInputType('text'),
        onMouseLeave: () => setInputType('password')
      }
    : {};

  const customStyles = styleOverrides ? styleOverrides[item.name as string] : undefined;

  if (item.hidden || (item.dependsOn && !watchedDependsOn)) {
    return null;
  }

  if (item.widget === 'textarea') {
    return (
      <div className={customStyles}>
        <EditPermission>
          <Textarea
            {...register(item.name, { required })}
            label={item.title}
            errors={errors}
            description={item.description}
          />
        </EditPermission>
      </div>
    );
  }

  if (item.widget === 'file') {
    return <FormFileUpload item={item} className={customStyles} />;
  }

  if (item.password) {
    return (
      <div className={customStyles}>
        <EditPermission>
          <MyInput
            {...register(item.name, { required })}
            label={item.title}
            type="password"
            errors={errors}
            description={item.description}
          />
        </EditPermission>
      </div>
    );
  }

  if (item.readonly) {
    const value = watch(item.name);
    return (
      <div className={cx(!value && 'hidden', customStyles)}>
        <MyInput
          {...register(item.name)}
          {...sensitiveInput}
          label={item.title}
          readOnly
          errors={errors}
          description={item.description}
        />
      </div>
    );
  } else if (item.setonce) {
    const value = watch(item.name);
    return (
      <div className={customStyles}>
        <SetOnceInput
          {...register(item.name)}
          {...sensitiveInput}
          label={item.title}
          errors={errors}
          description={item.description}
          value={value}
        />
      </div>
    );
  }

  if (item.enum) {
    return <FormEnumSelect className={customStyles} item={item} />;
  }

  if (item.completions) {
    return (
      <FormCompletionsSelect className={customStyles} item={item} promiseOptions={promiseOptions} />
    );
  }

  if (item.hierarchyCompletion) {
    return (
      <FormHierarchyCompletion
        className={customStyles}
        item={item}
        promiseOptions={promiseOptions}
      />
    );
  }

  switch (item.type) {
    case 'boolean':
      return (
        <div className={cx('flex items-center', customStyles)}>
          <EditPermission>
            <Checkbox
              {...register(item.name, {
                required: item.required as boolean | undefined,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                value: item.default as PathValue<TFormValues, any> | undefined
              })}
              // defaultChecked={item.default as boolean | undefined}
              label={item.title}
            />
          </EditPermission>
        </div>
      );
    case 'array':
      return <FormArray item={item} className={customStyles} />;
    default:
      // fallback to string
      return (
        <div className={customStyles}>
          <EditPermission>
            <MyInput
              {...register(item.name, { required })}
              {...sensitiveInput}
              label={item.title}
              defaultValue={item.default as string}
              errors={errors}
              description={item.description}
            />
          </EditPermission>
        </div>
      );
  }
}
