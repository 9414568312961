import cx from 'clsx';
import * as React from 'react';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import { Icon } from '~/components';
import { Tooltip, Truncator } from '~/components';
import { SqlRunnerSchemaV2MetaFragment, TableFragment } from '~/generated/graphql';
import {
  dispatchExpandedSchemas,
  dispatchExpandedSearchedSchemas,
  useFetchSchemaCache,
  useEditorViewState
} from '~/hooks';
import {
  fieldTypeIconName,
  handleInsertQuery,
  isRunnerCatalog,
  isRunnerColumn,
  isRunnerSchema,
  isRunnerTable,
  isStr,
  SchemaFlatTypes
} from '~/utils';
import { set } from 'lodash';

const plusCircleStyles =
  'mr-1 hidden h-5 w-5 cursor-pointer text-gray-500 hover:text-indigo-500 group-hover:block';

const hoverBgStyles = 'hover:bg-indigo-50 active:bg-indigo-50';

interface WrapProps {
  children: React.ReactNode;
}

function SpacingWrap({ children }: WrapProps) {
  return <div className="px-2">{children}</div>;
}

function BorderWrap({ children }: WrapProps) {
  return (
    <div className="pt-2">
      <div className="border-t border-gray-300 px-2 pt-2">{children}</div>
    </div>
  );
}

interface TableRowProps {
  extraPadding?: boolean;
  isExpanded?: boolean;
  isLoading?: boolean;
  item: TableFragment;

  onAppend: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, name: string) => unknown;
  onToggle: () => unknown;
}

function TableRow(props: TableRowProps) {
  const [isShiftDown, setIsShiftDown] = React.useState(false);

  const isShiftDownAllowed = props.item.columns && props.item.columns.length > 0;

  return (
    <SpacingWrap>
      <button
        className={cx(
          props.extraPadding ? 'pl-6' : 'pl-4',
          'group relative inline-flex w-full items-center justify-between rounded bg-transparent py-px pr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-400',
          hoverBgStyles
        )}
        onClick={props.onToggle}
      >
        <Truncator offset={[0, 64]} placement="right" content={props.item.label || props.item.name}>
          <span className="hide-native-tooltip truncate py-2 text-left text-sm font-semibold leading-none tracking-wide text-indigo-500">
            {props.item.label || props.item.name}
          </span>
        </Truncator>
        <span className="flex items-center">
          <span
            onClick={e => {
              if (isShiftDown && isShiftDownAllowed) {
                const names = props.item.columns.map(c => c.name).join(', ');
                props.onAppend(e, names);
              } else {
                props.onAppend(e, props.item.name);
              }
            }}
            onMouseEnter={e => setIsShiftDown(e.shiftKey)}
            onMouseMove={e => setIsShiftDown(e.shiftKey)}
            onMouseLeave={() => setIsShiftDown(false)}
          >
            {isShiftDown && isShiftDownAllowed ? (
              <Tooltip content={`Paste ${props.item.columns.length} columns`} showOnCreate>
                <Icon
                  className={cx(
                    plusCircleStyles,
                    `rounded-full hover:bg-indigo-500 hover:text-white`
                  )}
                  name="PlusSmall"
                />
              </Tooltip>
            ) : (
              <Icon className={plusCircleStyles} name="PlusCircle" />
            )}
          </span>
          {props.isLoading ? (
            <span className="h-5 w-5">
              <LoadingDots dense />
            </span>
          ) : (
            <Icon
              name="Disclosure"
              className={cx(
                'pointer-events-none h-5 w-5 transform text-gray-500',
                props.isExpanded && 'rotate-90'
              )}
            />
          )}
        </span>
      </button>
    </SpacingWrap>
  );
}

interface Props {
  item: SchemaFlatTypes;
  index: number;
  connectionId: string;
  infoMeta: SqlRunnerSchemaV2MetaFragment | undefined;
  expanded: Record<string, boolean>;
  expandedSearched: Record<string, boolean>;
  search: string | undefined;
}

export const RunnerRow = ({
  item,
  index,
  connectionId,
  infoMeta,
  expanded,
  expandedSearched,
  search
}: Props) => {
  const { getSchema, loading } = useFetchSchemaCache(connectionId);

  const view = useEditorViewState();
  const isExpanded = isStr(item.name)
    ? search
      ? expandedSearched[item.name]
      : expanded[item.name]
    : null;

  const handleQueryAppend = (e: React.MouseEvent, name: string) => {
    handleInsertQuery(view, name);
    e.preventDefault();
    e.stopPropagation();
  };

  function expandSearchItem() {
    if (!item?.name) {
      return;
    }
    dispatchExpandedSearchedSchemas({
      type: 'toggle',
      id: item.name
    });
  }

  function expandItem() {
    if (!item?.name) {
      return;
    }
    if (isExpanded == null) {
      if (isRunnerSchema(item)) {
        void getSchema({ variables: { connectionId, database: item.name } });
      } else if (isRunnerTable(item)) {
        void getSchema({
          variables: {
            connectionId,
            database: infoMeta?.supportsDatabaseSelection
              ? item.name.split('.').slice(0, -1).join('.')
              : item.name.split('.')[0],
            table: item.label
          }
        });
      }
    } else {
      dispatchExpandedSchemas(connectionId, {
        type: 'toggle',
        id: item.name
      });
    }
  }

  if (isRunnerCatalog(item)) {
    if (index === 0) {
      return (
        <SpacingWrap>
          <h3 className="pt-1.5 pl-2 text-base font-semibold leading-5 text-gray-500">
            {item.name}
          </h3>
        </SpacingWrap>
      );
    }
    return (
      <BorderWrap>
        <h3 className="pt-1.5 pl-2 text-base font-semibold leading-5 text-gray-500">{item.name}</h3>
      </BorderWrap>
    );
  }
  if (isRunnerSchema(item)) {
    if (infoMeta?.supportsDatabaseSelection) {
      return (
        <SpacingWrap>
          <button
            className={cx(
              'flex h-[52px] w-full justify-between rounded bg-transparent pt-1.5 pl-4 pr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-400',
              hoverBgStyles
            )}
            onClick={search ? expandSearchItem : expandItem}
          >
            <div className="text-left">
              <h3 className="pb-0.5 text-base font-semibold leading-5 text-indigo-500">
                {item.label || item.name}
              </h3>
              <h4 className="text-sm leading-5 text-gray-500">
                {infoMeta?.schemaLabel || 'Schema'}
              </h4>
            </div>
            <div className="pt-0.5">
              {loading ? (
                <div className="h-5 w-5">
                  <LoadingDots dense />
                </div>
              ) : (
                <Icon
                  name="Disclosure"
                  className={cx(
                    'pointer-events-none h-5 w-5 transform text-gray-500',
                    isExpanded && 'rotate-90'
                  )}
                />
              )}
            </div>
          </button>
        </SpacingWrap>
      );
    }
    if (index === 0) {
      return (
        <SpacingWrap>
          <div className="pl-2 pt-1.5">
            <h3 className="pb-0.5 text-base font-semibold leading-5 text-indigo-500">
              {item.label || item.name}
            </h3>
            <h4 className="text-sm leading-5 text-gray-500">{infoMeta?.schemaLabel || 'Schema'}</h4>
          </div>
        </SpacingWrap>
      );
    }
    return (
      <BorderWrap>
        <div className="pl-2 pt-1.5">
          <h3 className="pb-0.5 text-base font-semibold leading-5 text-indigo-500">
            {item.label || item.name}
          </h3>
          <h4 className="text-sm leading-5 text-gray-500">{infoMeta?.schemaLabel || 'Schema'}</h4>
        </div>
      </BorderWrap>
    );
  }
  if (isRunnerTable(item)) {
    return (
      <TableRow
        extraPadding={infoMeta?.supportsDatabaseSelection}
        isExpanded={isExpanded}
        isLoading={loading}
        item={item}
        onAppend={handleQueryAppend}
        onToggle={search ? expandSearchItem : expandItem}
      />
    );
  }
  if (isRunnerColumn(item)) {
    return (
      <SpacingWrap>
        <div
          className={cx(
            infoMeta?.supportsDatabaseSelection ? 'pl-8' : 'pl-6',
            'group flex items-start justify-between rounded bg-transparent py-1.5 pr-2',
            hoverBgStyles
          )}
        >
          {/* <p className="pt-1 text-sm leading-none text-gray-800">
                        {addLineBreaks(item.name)}
                    </p> */}
          <Truncator offset={[0, 64]} placement="right" content={item.name}>
            <p className="truncate text-sm text-gray-800">{item.name}</p>
          </Truncator>
          <span className="flex items-center">
            <span onClick={e => handleQueryAppend(e, `${item.name},`)}>
              <Icon name="PlusCircle" className={plusCircleStyles} />
            </span>
            <Tooltip
              offset={[0, 20]}
              placement="right"
              disabled={!item.sourceType}
              content={item.sourceType}
            >
              <Icon name={fieldTypeIconName(item.type)} className="h-5 w-5 text-gray-500" />
            </Tooltip>
          </span>
        </div>
      </SpacingWrap>
    );
  }
  return null;
};
