import { gql, useMutation } from '@apollo/client';
import * as React from 'react';
import { Icon } from '~/components';

import { Button, Checkbox, Permission, TableWrap } from '../../components';
import { Dialog } from '~/components/v3';
import {
  Action,
  PermissionRoleFragment,
  SetRolesDocument,
  UserFragment
} from '../../generated/graphql';
import { AclProvider, dispatchResetStore, useBannerDispatch } from '../../hooks';
import { ADMIN_ROLE_ID } from '../../utils';

const wrapperStyles = 'mb-3';

interface Props {
  isCurrentUser: boolean;
  roles: PermissionRoleFragment[];
  user: UserFragment;
  toggleDialog: () => void;
}

export function EditUserDialog({ isCurrentUser, user, roles, toggleDialog }: Props) {
  const dispatchBanner = useBannerDispatch();
  const [selectedRoles, setSelectedRoles] = React.useState<PermissionRoleFragment[]>(user.roles);

  const [setRoles, { loading: setRolesLoading }] = useMutation(SetRolesDocument, {
    fetchPolicy: 'no-cache',
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
      toggleDialog();
    },
    update: (cache, { data }) => {
      if (!data || !data.setRoles) {
        return;
      }
      cache.writeFragment({
        id: `User:${user.id}`,
        fragment: gql`
          fragment RolesUpdate on User {
            roles {
              id
              name
              system
            }
          }
        `,
        data: {
          roles: selectedRoles
        }
      });
      if (isCurrentUser) {
        dispatchResetStore();
      }
      toggleDialog();
    }
  });

  const toggleRoleId = (e: React.FormEvent<HTMLInputElement>) => {
    const roleId = e.currentTarget.name;
    const role = roles.find(role => role.id === roleId);
    if (!role) {
      return;
    }
    setSelectedRoles(prev => {
      if (prev.find(item => item.id === roleId)) {
        return prev.filter(item => item.id !== roleId);
      }
      return prev.concat(role);
    });
  };

  const handleSetRoles = () => {
    dispatchBanner({ type: 'hide' });
    void setRoles({ variables: { userId: user.id, roleIds: selectedRoles.map(role => role.id) } });
  };

  return (
    <Dialog
      show
      size="lg"
      heading="Change user roles"
      actions={
        <>
          <Button onClick={toggleDialog}>Cancel</Button>
          <Button theme="primary" onClick={handleSetRoles} disabled={setRolesLoading}>
            Change roles
          </Button>
        </>
      }
    >
      <div className="mb-4 flex items-start space-x-2">
        <Icon name="UserCircle" className="h-5 w-5 text-gray-500" />
        <p className="text-sm">{user.email}</p>
      </div>
      <TableWrap className="max-h-52 overflow-auto">
        <table className="min-w-full">
          <tbody>
            {roles.map(role => (
              <AclProvider key={role.id} value={role.acl}>
                <tr className="align-top even:bg-gray-50">
                  <td className="p-2">
                    <Permission type={Action.ApplyPolicy}>
                      <Checkbox
                        disabled={
                          (isCurrentUser &&
                            role.id === ADMIN_ROLE_ID &&
                            !!selectedRoles.find(item => item.id === role.id)) ||
                          undefined
                        }
                        name={role.id}
                        label={role.name}
                        checked={!!selectedRoles.find(item => item.id === role.id)}
                        onChange={toggleRoleId}
                      />
                    </Permission>
                  </td>
                </tr>
              </AclProvider>
            ))}
          </tbody>
        </table>
      </TableWrap>
    </Dialog>
  );
}
