import { generatePath, Link } from 'react-router-dom';
import { BulkSyncFragment, FieldsetFragment, SyncFragment } from '../generated/graphql';
import { isFieldset, isSync, plural, routes } from '../utils';
import { Icon } from '~/components';

interface Props {
  name: string;
  logo?: string;
  type: string;
  usedBy: Array<FieldsetFragment | SyncFragment | BulkSyncFragment>;
}

export function InUse(props: Props) {
  if (props.usedBy.length === 0) {
    return null;
  }
  return (
    <>
      <p className="flex items-center text-sm text-gray-500">
        <Icon match={props.logo} className="h-5 w-5" />
        <span className="mr-1 ml-2 max-w-xs truncate font-medium text-gray-800">{props.name}</span>
        <span>
          is used by
          {props.usedBy.length > 1 ? ' these ' : ' this '}
          {plural(props.type, props.usedBy.length > 1)}:
        </span>
      </p>
      <ul className="my-1.5 list-inside list-disc space-y-1 pl-2">
        {props.usedBy.map(item => {
          return (
            <li key={item.id}>
              <Link
                to={() => handleLink(item)}
                rel="noopener noreferrer"
                target="_blank"
                className="link"
              >
                {item.name}
                {isFieldset(item) && ' model'}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

function handleLink(item: BulkSyncFragment | FieldsetFragment | SyncFragment) {
  if (isFieldset(item)) {
    return generatePath(routes.editModel, { fieldsetId: item.id });
  }
  if (isSync(item)) {
    return generatePath(routes.syncStatus, { id: item.id });
  }
  return generatePath(routes.bulkSyncStatus, { id: item.id });
}
