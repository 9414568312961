import { useQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { Icon } from '~/components';

import { Button, TableWrap } from '~/components';
import PageLayout from '~/components/v2/layout/PageLayout';
import EmptyPage from '~/components/v2/templates/EmptyPage';
import {
  BulkSyncsDocument,
  BulkSyncsUpdatesDocument,
  ConnectionsDocument,
  Operation
} from '~/generated/graphql';
import { useBannerDispatch } from '~/hooks';
import {
  filterConnections,
  groupBulkSyncsByConnection,
  hasItems,
  PRIMARY_LINK_BTN_STYLES,
  routes
} from '~/utils';
import { BulkSyncRow } from '~/pages/bulk-syncs/bulk-sync-components/bulk-sync-row';

const BulkSyncs = () => {
  // hooks
  const history = useHistory();
  const dispatchBanner = useBannerDispatch();
  // queries
  const { data: connectionsData, loading: connectionsLoading } = useQuery(ConnectionsDocument);
  const { data, loading } = useQuery(BulkSyncsDocument, {
    errorPolicy: 'all',
    onError: error =>
      dispatchBanner({
        type: 'show',
        payload: {
          message: error,
          disableDismiss: true,
          wrapper: 'px-3 pt-3 max-w-3xl mx-auto'
        }
      })
  });
  // Vars
  const hasConnection =
    hasItems(filterConnections(connectionsData, Operation.BulkSyncSource)) &&
    hasItems(filterConnections(connectionsData, Operation.BulkSyncDestination));
  const bulkSyncs =
    data && hasItems(data.bulkSyncs) ? groupBulkSyncsByConnection(data.bulkSyncs) : [];
  const isLoading = connectionsLoading || loading;

  // queries
  useQuery(BulkSyncsUpdatesDocument, {
    skip: bulkSyncs.length === 0,
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    onCompleted: () => dispatchBanner({ type: 'hide' })
  });

  if (!isLoading && !hasItems(bulkSyncs) && !hasConnection) {
    return (
      <EmptyPage
        topNavHeading="Bulk syncs"
        message="To create a bulk sync, you must have at least one bulk connection defined."
      >
        <div className="flex flex-col items-start space-y-3">
          <Link className={PRIMARY_LINK_BTN_STYLES} to={routes.connectionsPicker}>
            Add connections
          </Link>
        </div>
      </EmptyPage>
    );
  }

  if (!isLoading && !hasItems(bulkSyncs)) {
    return (
      <EmptyPage topNavHeading="Bulk syncs" message="To create a bulk sync, start here.">
        <div className="flex flex-col items-start space-y-3">
          <Link className={PRIMARY_LINK_BTN_STYLES} to={routes.createBulkSync}>
            Create bulk sync
          </Link>
        </div>
      </EmptyPage>
    );
  }

  return (
    <PageLayout
      topNavHeading="Bulk syncs"
      topNavActions={
        <Button theme="outline" onClick={() => history.push(routes.createBulkSync)}>
          Create bulk sync
        </Button>
      }
      loading={isLoading}
    >
      <div className="space-y-8 p-6">
        {bulkSyncs.map(group => (
          <div key={group.id}>
            <div className="mb-4 ml-4 inline-flex w-full items-center">
              <Icon match={group.logo} className="mr-2.5 h-7 w-auto" />
              <h3 className="text-lg text-gray-800">{group.name}</h3>
            </div>
            <TableWrap className="grid max-w-[84rem] grid-cols-[repeat(2,minmax(7rem,35rem)),repeat(2,7rem)] overflow-x-auto">
              {['Name', 'Definition', 'Schedule', 'Status'].map(heading => (
                <span key={heading} className="thead-label inline-flex items-center bg-gray-50">
                  {heading}
                </span>
              ))}
              {group.syncs.map(bulkSync => (
                <BulkSyncRow key={bulkSync.id} bulkSync={bulkSync} />
              ))}
            </TableWrap>
          </div>
        ))}
      </div>
    </PageLayout>
  );
};

export default BulkSyncs;
