import { useSubscription } from '@apollo/client';
import cx from 'clsx';

import {
  ExecutionStatus,
  SyncDocument,
  SyncFragment,
  SyncStatusDocument,
  SyncStatusFragment
} from '../../../generated/graphql';
import { useSyncState } from '../../../hooks';

export function StatusSub(props: {
  syncStatus?: SyncStatusFragment;
  setSyncStatus: React.Dispatch<React.SetStateAction<SyncStatusFragment>>;
}) {
  const sync = useSyncState();
  useSubscription(SyncStatusDocument, {
    skip: !sync.id,
    variables: { syncID: sync.id },
    onSubscriptionData: ({ subscriptionData, client }) => {
      const syncStatus = subscriptionData.data?.syncStatus;
      const compareOld = cx(
        props.syncStatus?.execution?.status,
        props.syncStatus?.nextExecutionTime,
        props.syncStatus?.execution?.startedAt,
        props.syncStatus?.execution?.completedAt,
        props.syncStatus?.execution?.recordCount
      );
      const compareNew = cx(
        syncStatus?.execution?.status,
        syncStatus?.nextExecutionTime,
        syncStatus?.execution?.startedAt,
        syncStatus?.execution?.completedAt,
        syncStatus?.execution?.recordCount
      );
      if (!syncStatus || !syncStatus.execution || compareOld === compareNew) {
        return;
      }
      props.setSyncStatus(syncStatus);
      // early return if sync has NOT reached a terminal state
      if (
        syncStatus.execution.status !== ExecutionStatus.Canceled &&
        syncStatus.execution.status !== ExecutionStatus.Completed &&
        syncStatus.execution.status !== ExecutionStatus.Failed
      ) {
        return;
      }
      client.cache.updateQuery(
        {
          query: SyncDocument,
          variables: { id: sync.id }
        },
        (data: { sync: SyncFragment } | null) => {
          if (!data || !data.sync) {
            return;
          }
          return {
            sync: {
              ...data.sync,
              currentExecution: null,
              lastExecution: syncStatus.execution,
              nextExecutionTime: syncStatus.nextExecutionTime
            }
          };
        }
      );
    }
  });

  return null;
}
