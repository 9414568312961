import * as React from 'react';
import { Action, ResourceType } from '../generated/graphql';
import { useToggle } from '../hooks';
import { Button, Permission, SideBySide } from '.';
import { Dialog } from './v3';
import LoadingDots from './v2/feedback/LoadingDots';
import { IconName } from '~/assets';

type SharedProps = {
  // Socket
  children: React.ReactNode;
  cta: string;
  description: string;
  dismissText?: string;
  // F(x)
  handleTrigger?: () => void;
  handleDismiss?: () => void;
  handleAction?: () => void;
  // Arrays
  preventToggle?: Array<'dismiss' | 'action'>;
  // Booleans
  hideAction?: boolean;
  loading?: boolean;
  // Enums
  createPermission?: ResourceType;
};

type UnionProps =
  | {
      isDelete: true;
      triggerIcon?: never;
    }
  | {
      isDelete?: never;
      triggerIcon: IconName;
    };

type ConfigTriggerDialogProps = SharedProps & UnionProps & { contentPadding?: boolean };

const ConfigTriggerDialog = ({
  // Socket
  children,
  cta,
  description,
  dismissText,
  // F(x)
  handleAction,
  handleDismiss,
  handleTrigger,
  // Arrays
  preventToggle,
  triggerIcon,
  // Booleans
  isDelete,
  loading,
  hideAction,
  // Enums
  createPermission,
  // Dialog Props
  contentPadding
}: ConfigTriggerDialogProps) => {
  // State
  const [showDialog, toggleDialog] = useToggle();

  // F(x)
  const handleDialogTrigger = () => {
    handleTrigger?.();
    toggleDialog();
  };
  const handleDialogDismiss = () => {
    handleDismiss?.();
    if (!preventToggle || preventToggle.length === 0 || !preventToggle.includes('dismiss')) {
      toggleDialog();
    }
  };
  const handleDialogAction = () => {
    handleAction?.();
    if (!preventToggle || preventToggle.length === 0 || !preventToggle.includes('action')) {
      toggleDialog();
    }
  };

  return (
    <>
      <SideBySide hasSectionWrap heading={cta}>
        <p className="mb-2 mt-1 max-w-sm text-sm text-gray-800">{description}</p>
        <div className="flex items-center space-x-2">
          <Permission
            type={createPermission ? createPermission : isDelete ? Action.Delete : undefined}
          >
            <Button
              theme={isDelete ? 'danger' : 'default'}
              iconEnd={isDelete ? 'Trashcan' : triggerIcon}
              onClick={handleDialogTrigger}
              disabled={loading}
            >
              {cta}
            </Button>
          </Permission>
          {loading && <LoadingDots />}
        </div>
      </SideBySide>
      <Dialog
        show={showDialog}
        onDismiss={handleDialogDismiss}
        heading={`${cta}${isDelete ? '?' : ''}`}
        slots={{ body: contentPadding !== false ? 'p-6' : 'p-0' }}
        actions={
          <>
            <Button onClick={handleDialogDismiss}>{dismissText || 'Cancel'}</Button>
            {!hideAction && (
              <Button
                theme={isDelete ? 'danger' : 'primary'}
                onClick={handleDialogAction}
                disabled={loading}
              >
                {cta}
              </Button>
            )}
          </>
        }
      >
        {children}
      </Dialog>
    </>
  );
};
export default ConfigTriggerDialog;
