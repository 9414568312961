import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormElements, Label, MyCombobox } from '~/components';
import { getSchemaAsList } from '~/utils';
import type { FormBuilderProps } from './types';
import { OAuthMessage } from './OAuthMessage';

const Options = {
  oauth: {
    label: 'OAuth',
    value: 'oauth'
  },
  token: {
    label: 'Token',
    value: 'token'
  }
};
const DefaultOption = Options['oauth'];

const AuthMethodKey = 'auth_method';

export function FBAudience(props: FormBuilderProps) {
  const { control, register, setValue } = useFormContext();
  const auth = useWatch({ control, name: AuthMethodKey });
  const selected = Options[auth] || DefaultOption;

  const handleSelect = (selected: { label: string; value: string }) => {
    setValue(AuthMethodKey, selected.value);
  };

  const items = getSchemaAsList(props.schema).filter(item =>
    ['byo_app_token', 'graph_api_version', 'account_id'].includes(item.name as string)
  );

  return (
    <>
      <div>
        <Label>Authentication method</Label>
        <MyCombobox options={Object.values(Options)} value={selected} onChange={handleSelect} />
        <input {...register(AuthMethodKey)} type="hidden" value={selected.value} />
      </div>
      {selected.value === 'oauth' && <OAuthMessage {...props} />}
      {selected.value === 'token' &&
        items.map(item => <FormElements key={item.name as string} item={item} />)}
    </>
  );
}
