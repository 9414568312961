import cx from 'clsx';
import { useAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import { Icon } from '~/components';
import { Button, Tooltip } from '../../components';
import { FieldsetListItemFragment } from '../../generated/graphql';
import { addLineBreaks, plural, routes } from '../../utils';
import { Field } from './field';
import { ModelsStateAtom } from './models';
import scrollIntoView from 'scroll-into-view-if-needed';

interface FieldsetProps {
  fieldset: FieldsetListItemFragment;
}

export function FieldsetRow({ fieldset }: FieldsetProps) {
  const scrollRef = useRef<HTMLDivElement>();
  // state
  const [modelsState, setModelsState] = useAtom(ModelsStateAtom);
  // hooks
  const editingField = useRouteMatch<{ fieldId: string }>({ path: routes.editField, exact: true });
  const editingModel = useRouteMatch<{ fieldsetId: string }>({
    path: routes.editModel,
    exact: true
  });
  // vars
  const isEditingFieldset = editingModel && fieldset.id === editingModel?.params?.fieldsetId;
  const isEditingField =
    editingField && fieldset.fields.some(field => field.id === editingField?.params?.fieldId);
  const isExpanded = !!modelsState.expandedFieldsetIds.includes(fieldset.id);

  // f(x)
  const toggleFieldset = () => {
    setModelsState(s => ({
      ...s,
      expandedFieldsetIds: s.expandedFieldsetIds.includes(fieldset.id)
        ? s.expandedFieldsetIds.filter(id => id !== fieldset.id)
        : [...s.expandedFieldsetIds, fieldset.id]
    }));
  };
  // effects
  useEffect(() => {
    if (isEditingField) {
      setModelsState(s => ({
        ...s,
        expandedFieldsetIds: [...s.expandedFieldsetIds, fieldset.id]
      }));
    }
  }, [fieldset.id, isEditingField, setModelsState]);

  useEffect(() => {
    if (scrollRef.current && fieldset.id === editingModel?.params?.fieldsetId) {
      scrollIntoView(scrollRef.current, {
        scrollMode: 'if-needed',
        block: 'start'
      });
    }
  }, []);

  return (
    <section ref={scrollRef} className="border-b border-gray-300 p-2">
      <Link
        to={generatePath(routes.editModel, { fieldsetId: fieldset.id })}
        className={cx(
          'grid cursor-pointer grid-cols-[1fr,auto] gap-x-1 rounded px-4 py-1.5 hover:bg-indigo-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-400',
          isEditingFieldset ? 'items-center bg-indigo-100' : 'items-start'
        )}
      >
        <div>
          <h3 className="break-words text-base font-medium">{addLineBreaks(fieldset.name)}</h3>
          <div className="relative flex items-center">
            <h4 className="text-sm leading-5 text-gray-500">{fieldset.connection.name}</h4>
            {fieldset.relatedTo[0] && (
              <Tooltip
                placement="right"
                className="max-w-2xl"
                content={`${fieldset.relatedTo[0].from.fieldset.name}.${fieldset.relatedTo[0].from.label} → ${fieldset.relatedTo[0].to.fieldset.name}.${fieldset.relatedTo[0].to.label}`}
              >
                <span>
                  <Icon name="Link" className="ml-1.5 h-4 w-4 text-gray-400 hover:text-gray-800" />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="self-start p-2">
          {isEditingFieldset ? (
            <Icon name="FastForward" className="h-5 w-5 text-indigo-500" />
          ) : (
            <Icon match={fieldset.connection.type.id} size="lg" />
          )}
        </div>
      </Link>
      <Button className="ml-2" theme="ghost" size="mini" onClick={toggleFieldset}>
        <div className={cx('transform duration-150 ease-in-out', !isExpanded && '-rotate-90')}>
          <Icon name="SelectSingle" size="md" className="p-0" />
        </div>
        <span className="select-none text-xs font-medium uppercase tracking-widest">
          {fieldset.fields.length} {plural('field', fieldset.fields.length > 1)}
        </span>
      </Button>
      {isExpanded && (
        <ul className="animate-fadeIn">
          {fieldset.fields.map(field => {
            return (
              <Field
                key={field.id}
                label={field.label}
                isEditing={editingField?.params?.fieldId === field.id}
                pathname={generatePath(routes.editField, {
                  fieldsetId: fieldset.id,
                  fieldId: field.id
                })}
              />
            );
          })}
        </ul>
      )}
    </section>
  );
}
