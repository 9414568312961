import {
  ExpandedState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingState,
  Table as TanstackTable,
  useReactTable
} from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './Table';
import { cn } from '~/lib/utils';
import { Icon } from '../Icon';
import LoadingDots from '../v2/feedback/LoadingDots';
import Checkbox from '../v2/inputs/Checkbox';
import { ReactNode, useRef } from 'react';
import { isFunction } from 'lodash';

import { tv } from 'tailwind-variants';
import { getSlots, type VariantProps } from '~/lib/utils';
import { useVirtual, VirtualItem } from 'react-virtual';
import { ColumnDef } from './DataTableVirtual';

const tableVariants = tv({
  slots: {
    wrapper: 'max-h-full overflow-auto rounded-md border border-gray-300',
    table: 'w-full'
  }
});

export interface DataTableProps<T> extends VariantProps<typeof tableVariants> {
  columns: ColumnDef<T>[];
  data: T[];
  loading?: boolean;

  // Filtering
  globalFilter?: string | FilterFn<T>;
  onGlobalFilterChange?: OnChangeFn<string>;

  // Sorting
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  enableSorting?: boolean;

  // Selection
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;

  // Expansion
  expanded?: ExpandedState;
  onExpandedChange?: OnChangeFn<ExpandedState>;
  getSubRows?: (originalRow: T) => T[];
  getRowId?: (originalRow: T) => string;

  // Options
  emptyMessage?: string;
  maxLeafRowFilterDepth?: number;
  disableVirtualization?: boolean;
  showLoadingWhenRowsExist?: boolean;
}

export function DataTable<T>({
  columns = [],
  data = [],
  loading = false,
  globalFilter = '',
  onGlobalFilterChange,
  sorting = [],
  onSortingChange,
  enableSorting = true,
  rowSelection = {},
  onRowSelectionChange,
  expanded = {},
  onExpandedChange,
  getSubRows,
  getRowId,
  emptyMessage = 'No results.',
  maxLeafRowFilterDepth = 1,
  disableVirtualization = false,
  showLoadingWhenRowsExist = false,
  ...rest
}: DataTableProps<T>) {
  const slots = getSlots(tableVariants, rest);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const enableRowSelection = isFunction(onRowSelectionChange);
  const enableGlobalFilter = isFunction(onGlobalFilterChange);

  const table = useReactTable({
    data,
    columns: columns.filter(column => column.isVisible !== false),
    state: {
      sorting,
      globalFilter,
      rowSelection,
      expanded
    },
    // Filtering
    onGlobalFilterChange,
    enableGlobalFilter,
    globalFilterFn: isFunction(globalFilter) ? globalFilter : 'includesString',

    // Sorting
    enableSorting,
    onSortingChange,

    // Selection
    onRowSelectionChange,

    // Expansion
    onExpandedChange,
    getSubRows,
    getRowId,

    // Models
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),

    //Default config
    enableRowSelection: row => !(row.subRows && row.subRows.length > 0),
    filterFromLeafRows: true,
    maxLeafRowFilterDepth
  });

  const { rows: rawRows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rawRows.length,
    overscan: 10
  });

  let rows: Row<T>[] | VirtualItem[], paddingTop: number, paddingBottom: number;
  if (disableVirtualization) {
    rows = rawRows;
    paddingTop = 0;
    paddingBottom = 0;
  } else {
    rows = rowVirtualizer.virtualItems;
    paddingTop = rows.length > 0 ? rows?.[0]?.start || 0 : 0;
    paddingBottom =
      rows.length > 0 ? rowVirtualizer.totalSize - (rows?.[rows.length - 1]?.end || 0) : 0;
  }

  return (
    <div className={slots.wrapper} ref={tableContainerRef}>
      <Table
        className={cn(
          !rows?.length || (loading && showLoadingWhenRowsExist) ? 'inline-table' : 'table-fixed',
          slots.table
        )}
      >
        <TableHeader className="sticky top-0">
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => {
                return (
                  <TableHead
                    key={header.id}
                    className={cn(header.column.getCanSort() && 'cursor-pointer select-none')}
                    style={{ width: header.column.getSize() }}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div
                      className={cn(
                        ((enableRowSelection && i === 0) || header.column.getCanSort()) &&
                          'flex items-center space-x-2'
                      )}
                    >
                      {enableRowSelection && i === 0 && (
                        <HeaderCheckbox
                          table={table}
                          data={data}
                          getRowId={getRowId}
                          getSubRows={getSubRows}
                          rowSelection={rowSelection}
                        />
                      )}

                      {flexRender(header.column.columnDef.header, header.getContext())}

                      {!!header.column.getIsSorted() && (
                        <Icon
                          name="ArrowNarrowRight"
                          className={cn([
                            'h-3.5 w-3.5 transition-all',
                            header.column.getIsSorted() === 'asc' && 'rotate-90',
                            header.column.getIsSorted() === 'desc' && '-rotate-90'
                          ])}
                        />
                      )}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="overflow-auto">
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {(!rows?.length || (loading && showLoadingWhenRowsExist)) && (
            <TableRow className="h-24">
              <TableCell colSpan={columns.length} className="h-full bg-white text-center">
                {loading ? <LoadingDots /> : <span>{emptyMessage}</span>}
              </TableCell>
            </TableRow>
          )}
          {!(loading && showLoadingWhenRowsExist) &&
            rows
              .map<Row<T>>(row =>
                disableVirtualization ? row : rawRows[(row as VirtualItem).index]
              )
              .map(row => {
                return (
                  <TableRow
                    key={row.id}
                    className={cn(
                      'group/row',
                      row.getCanExpand() || !!(row.index % 2) ? 'bg-gray-50' : 'bg-white',
                      row.getCanExpand() ? 'cursor-pointer' : 'cursor-default',
                      row.getCanExpand() && row.depth == 0
                        ? 'border-t border-b-0 first:border-t-0'
                        : 'border-none',
                      'hover:bg-indigo-50',
                      'snap-start'
                    )}
                    onClick={() => !!row.subRows?.length && row.toggleExpanded()}
                  >
                    {row.getVisibleCells().map((cell, i) => (
                      <TableCell key={cell.id} style={{ width: cell.column.getSize() }}>
                        {i === 0 ? (
                          <div className="flex items-center space-x-2">
                            {enableRowSelection && (
                              <RowCheckbox
                                row={row}
                                getSubRows={getSubRows}
                                getRowId={getRowId}
                                rowSelection={rowSelection}
                              />
                            )}
                            <RowExpander row={row}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </RowExpander>
                          </div>
                        ) : (
                          flexRender(cell.column.columnDef.cell, cell.getContext())
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

function getAllDescendentRows<T>(rows: T[], getSubRows: (originalRow: T) => T[]): T[] {
  return rows.reduce((acc, row) => {
    const subRows = getSubRows?.(row) ?? [];
    if (!subRows?.length) {
      return acc.concat(row);
    }
    return acc.concat(getAllDescendentRows(subRows, getSubRows));
  }, []);
}
interface HeaderCheckboxProps<T> {
  table: TanstackTable<T>;
  data: T[];
  getSubRows: (originalRow: T) => T[];
  getRowId: (originalRow: T) => string;
  rowSelection: RowSelectionState;
}

function HeaderCheckbox<T>({
  table,
  data,
  getSubRows,
  getRowId,
  rowSelection
}: HeaderCheckboxProps<T>) {
  const subRows = getAllDescendentRows(data, getSubRows);

  const everyRowSelected = subRows.length && subRows.every(row => rowSelection[getRowId?.(row)]);
  const someRowsSelected = !everyRowSelected && subRows.some(row => rowSelection[getRowId?.(row)]);

  return (
    <Checkbox
      checked={everyRowSelected}
      onChange={() => table.toggleAllRowsSelected()}
      onClick={e => e.stopPropagation()}
      indeterminate={someRowsSelected}
      variant="indeterminate"
    />
  );
}

interface RowCheckboxProps<T> {
  row: Row<T>;
  getSubRows: (originalRow: T) => T[];
  getRowId: (originalRow: T) => string;
  rowSelection: RowSelectionState;
}

function RowCheckbox<T>({ row, getRowId, getSubRows, rowSelection }: RowCheckboxProps<T>) {
  const subRows = getAllDescendentRows([row.original], getSubRows);

  const everyRowSelected = subRows?.length && subRows?.every(row => rowSelection[getRowId?.(row)]);
  const someRowsSelected = !everyRowSelected && subRows?.some(row => rowSelection[getRowId?.(row)]);

  return (
    <Checkbox
      checked={row.getIsSelected() || everyRowSelected}
      onChange={() =>
        row.subRows?.length > 0 ? row.toggleSelected(!everyRowSelected) : row.toggleSelected()
      }
      onClick={e => {
        e.stopPropagation();
      }}
      indeterminate={someRowsSelected}
      variant={!!subRows?.length ? 'indeterminate' : 'determinate'}
    />
  );
}

interface RowExpanderProps<T> {
  row: Row<T>;
  children: ReactNode;
}

function RowExpander<T>({ row, children }: RowExpanderProps<T>) {
  return (
    <div
      className="flex items-center overflow-hidden"
      style={{ paddingLeft: `${row.depth * 24}px` }}
    >
      {!!row.subRows?.length && (
        <Icon name={row.getIsExpanded() ? 'SelectSingle' : 'Disclosure'} className="h-5 w-5" />
      )}
      {children}
    </div>
  );
}
