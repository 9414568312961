import { LazyQueryExecFunction, useLazyQuery, useQuery } from '@apollo/client';
import { SetStateAction } from 'jotai';
import * as React from 'react';
import { Dispatch, useMemo, useState } from 'react';
import { FormProvider, useController, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button, JSONSchemaForm, Label, ModelFieldSelect, SideBySide } from '~/components';
import { Dialog } from '~/components/v3';
import { useBannerDispatch } from '~/hooks';
import { ConnectionSelect, MyInput } from '../../components';
import {
  Action,
  ConnectionsDocument,
  Exact,
  Maybe,
  ModelFieldFragment,
  Operation,
  TargetObjectDocument,
  TargetObjectFragment,
  TargetObjectQuery
} from '../../generated/graphql';
import {
  CREATE_TARGET_SCHEMA,
  CREATE_TARGET_TABLE,
  LocalConnection,
  Selectable,
  SyncConfigFormValues,
  filterConnections,
  getDataArchitecturePath,
  getSchemaHierarchy,
  getSchemaNormalized,
  routes
} from '../../utils';
import { DestDataArchitectureSelect, PARTIAL_SYNC_STORAGE_KEY } from '../syncs/sync-config';
import { ExploreFilter } from './ExploreFilters';

interface Props {
  getTargetObject: LazyQueryExecFunction<
    TargetObjectQuery,
    Exact<{
      connectionId: string;
      targetObject: string;
      refresh?: Maybe<boolean> | undefined;
    }>
  >;
}

// Borrowed from stage-target of sync-config
const TargetConfigurationForm = React.memo<Props>(({ getTargetObject }) => {
  const { id } = useParams<{ id: string }>();
  const { control, setValue, getValues, register, formState, watch } =
    useFormContext<SyncConfigFormValues>();
  const { errors } = formState;

  const { field } = useController({ control, name: 'targetConnection' });

  const targetConnection = useWatch({ control, name: 'targetConnection' });
  const targetObject = useWatch({ control, name: 'targetObject' });
  const searchValues = useWatch({ control, name: 'targetSearchValues' });

  const { data: connectionsData, loading } = useQuery(ConnectionsDocument);

  const hasInlineConfig = !!targetConnection?.type.operations.includes(
    Operation.DestinationRequireConfiguration
  );

  const { parent, child } = React.useMemo(
    () => getSchemaHierarchy(targetConnection?.type.destinationDataArchitecture),
    [targetConnection?.type.destinationDataArchitecture]
  );
  const path = React.useMemo(
    () => getDataArchitecturePath(targetConnection?.type.destinationDataArchitecture),
    [targetConnection?.type.destinationDataArchitecture]
  );
  const schema = React.useMemo(
    () => getSchemaNormalized(targetConnection?.type.destinationDataArchitecture),
    [targetConnection?.type.destinationDataArchitecture]
  );

  const parentValue = React.useMemo(() => searchValues?.[parent], [parent, searchValues]);
  const parentField = React.useMemo(
    () => (parent ? { ...schema?.[parent], name: parent } : null),
    [parent, schema]
  );

  const childField = React.useMemo(
    () =>
      child
        ? {
            ...schema?.[child],
            name: child,
            default: getValues(`targetSearchValues.${child}`) || null
          }
        : null,
    [child, getValues, schema]
  );

  const singletonField = React.useMemo(
    () => (!parent && child ? { ...schema?.[child], name: child } : null),
    [child, parent, schema]
  );

  const handleConnection = (connection: LocalConnection) => {
    field.onChange(connection);
    if (targetObject) {
      setValue('targetObject', null);
      setValue('targetSearchValues', null);
    }
    // this is for blob storage destinations
    if (connection.type.operations.includes(Operation.DestinationRequireConfiguration)) {
      void getTargetObject({
        variables: {
          connectionId: connection.id,
          targetObject: 'file',
          refresh: false
        }
      });
    }
  };

  const handleTargetObjectEffects = React.useCallback(
    (object: TargetObjectFragment) => {
      if (!targetConnection) {
        return;
      }

      void getTargetObject({
        variables: {
          connectionId: targetConnection.id,
          targetObject: object.id,
          refresh: false
        }
      });
      const name = getValues('name');
      if (name || !object.name) {
        return;
      }

      const objectName = object.name || '';
      const generatedName =
        object.id === CREATE_TARGET_TABLE ||
        object.properties?.targetCreator ||
        parentValue === CREATE_TARGET_SCHEMA
          ? ''
          : // Generate sync name that user can modify later
            `${targetConnection?.name || ''} ${objectName.replaceAll('...', '')} sync`;
      setValue('name', generatedName);
    },
    [getValues, getTargetObject, parentValue, setValue, targetConnection]
  );

  const handleParentOnChange = React.useCallback(() => {
    if (getValues(`targetSearchValues.${child}`)) {
      setValue(`targetSearchValues.${child}`, null);
    }
  }, [child, getValues, setValue]);

  const handleChildOnChange = React.useCallback(
    (option: Selectable) => {
      handleTargetObjectEffects({
        id: option.value,
        name: option.label
      } as TargetObjectFragment);
    },
    [handleTargetObjectEffects]
  );

  return (
    <div className="z-[99] space-y-2">
      <ConnectionSelect
        isLoading={loading}
        autoFocus={!id}
        options={filterConnections(connectionsData, Operation.Target, Action.SyncTo)}
        placeholder="Choose destination..."
        value={field.value}
        onChange={handleConnection}
      />
      {hasInlineConfig ? (
        <section className="grid w-full max-w-xl animate-fadeIn gap-3">
          <div>
            <MyInput
              label="Filename"
              {...register('targetObjectIdDraft', {
                required: 'Filename is required',
                value: targetObject?.id || ''
              })}
              errors={errors}
            />
          </div>
          {targetObject && (
            <JSONSchemaForm
              formData={watch('targetObjectConfiguration')}
              schema={targetObject?.advancedConfiguration.jsonschema}
              uiSchema={{
                'ui:order': targetObject?.advancedConfiguration?.uischema.order || undefined,
                'ui:submitButtonOptions': {
                  norender: true
                }
              }}
              onChange={value => {
                setValue('targetObjectConfiguration', value);
              }}
            />
          )}
        </section>
      ) : (
        targetConnection &&
        schema && (
          <div className="col-start-2 space-y-2">
            <React.Fragment key={targetConnection.id}>
              {
                // loop over path, showing a select for each level
                path.map(
                  (field, index) =>
                    (index === 0 || getValues(`targetSearchValues.${path[index - 1]}`)) && (
                      <DestDataArchitectureSelect
                        key={field}
                        connectionId={targetConnection.id}
                        field={schema[field]}
                        name={field}
                        path={path
                          .slice(0, index)
                          .map(field => getValues(`targetSearchValues.${field}`))
                          .join('.')}
                        callback={o => {
                          // if this is the final element, fetch the target object
                          if (index === path.length - 1) {
                            handleChildOnChange(o);
                          }
                        }}
                      />
                    )
                )
              }
            </React.Fragment>
          </div>
        )
      )}
    </div>
  );
});

interface ExportToSyncDialogProps {
  filters: ExploreFilter[];
  conditionLogic: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  selectedFields: ModelFieldFragment[];
}

const wrapperStyles = 'px-3 pt-3 max-w-5xl mx-auto';

export function ExportToSyncDialog({
  filters,
  conditionLogic,
  selectedFields,
  show,
  setShow
}: ExportToSyncDialogProps) {
  const methods = useForm<SyncConfigFormValues>({});
  const { setValue, reset } = methods;

  const targetConnection = methods.watch('targetConnection');
  const targetObject = methods.watch('targetObject');
  const searchValues = methods.watch('targetSearchValues');

  const dispatchBanner = useBannerDispatch();

  const [identity, setIdentity] = useState<ModelFieldFragment>();

  const [getTargetObject] = useLazyQuery(TargetObjectDocument, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (!data || !data.targetObject) {
        return;
      }
      setValue('targetObject', data.targetObject);
    },
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
    }
  });

  const isValidTarget = useMemo(
    () => targetConnection?.id && targetObject?.id,
    [targetConnection, targetObject]
  );

  const isIdentityRequired = useMemo(() => targetObject?.modes[0].requiresIdentity, [targetObject]);

  const isValid = useMemo(
    () => isValidTarget && (identity || !isIdentityRequired),
    [targetConnection, targetObject, identity]
  );

  const configureSync = async () => {
    if (!isValid) {
      return;
    }

    localStorage.setItem(
      PARTIAL_SYNC_STORAGE_KEY,
      JSON.stringify({
        targetObject: targetObject,
        targetConnection: targetConnection,
        searchValues: searchValues,
        identity,
        fields: selectedFields.filter(f => f.id !== identity?.id),
        filters,
        conditionLogic
      })
    );

    window.open(routes.createSync, '_blank');
  };

  const handleDismiss = () => {
    reset({
      targetConnection: null,
      targetObject: null,
      targetSearchValues: null
    });
    setIdentity(null);
    setShow(false);
  };

  return (
    <Dialog
      heading="Export"
      show={show}
      slots={{ content: 'overflow-visible' }}
      onDismiss={handleDismiss}
      actions={
        <>
          <Button onClick={handleDismiss}>Cancel</Button>
          <Button theme="primary" disabled={!isValid} onClick={configureSync}>
            Next: configure sync
          </Button>
        </>
      }
    >
      <SideBySide heading="Destination">
        <FormProvider {...methods}>
          <TargetConfigurationForm getTargetObject={getTargetObject}></TargetConfigurationForm>
          {!!isValidTarget && isIdentityRequired && (
            <div className="mt-2">
              <Label>Identity field</Label>
              <ModelFieldSelect
                options={selectedFields}
                value={identity}
                onChange={field => setIdentity(field)}
                hideGroups={true}
              />
            </div>
          )}
        </FormProvider>
      </SideBySide>
    </Dialog>
  );
}
