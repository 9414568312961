import * as React from 'react';

import { CustomProviderProps } from '../utils/custom-types.util';
import { createUseCtx } from './context-factories';

export function createStateCtx<StateType>(defaultState: StateType): {
  useCtx: () => {
    state: StateType;
    update: React.Dispatch<React.SetStateAction<StateType>>;
  };
  Provider: ({ children, initialState }: CustomProviderProps<StateType>) => JSX.Element;
};
export function createStateCtx<StateType>(): {
  useCtx: () => {
    state: StateType | undefined;
    update: React.Dispatch<React.SetStateAction<StateType | undefined>>;
  };
  Provider: ({ children, initialState }: CustomProviderProps<StateType>) => JSX.Element;
};
export function createStateCtx<StateType>(defaultState?: StateType) {
  const defaultUpdate: React.Dispatch<React.SetStateAction<StateType | undefined>> = () =>
    defaultState;
  const Ctx = React.createContext({ state: defaultState, update: defaultUpdate });

  function Provider({ children, initialState = defaultState }: CustomProviderProps<StateType>) {
    const [state, update] = React.useState(initialState);
    return <Ctx.Provider value={{ state, update }}>{children}</Ctx.Provider>;
  }

  const useCtx = createUseCtx(Ctx);

  return { Ctx, useCtx, Provider };
}

export function createSplitStateCtx<StateType>(defaultState: StateType): {
  StateCtx: React.Context<StateType>;
  UpdateCtx: React.Context<React.Dispatch<React.SetStateAction<StateType>>>;
  Provider: ({ children, initialState }: CustomProviderProps<StateType>) => JSX.Element;
};
export function createSplitStateCtx<StateType>(): {
  StateCtx: React.Context<StateType | undefined>;
  UpdateCtx: React.Context<React.Dispatch<React.SetStateAction<StateType | undefined>>>;
  Provider: ({ children, initialState }: CustomProviderProps<StateType>) => JSX.Element;
};
export function createSplitStateCtx<StateType>(defaultState?: StateType) {
  const StateCtx = React.createContext<StateType | undefined>(defaultState);
  const UpdateCtx = React.createContext<
    React.Dispatch<React.SetStateAction<StateType | undefined>>
  >(() => defaultState);

  function Provider({ children, initialState = defaultState }: CustomProviderProps<StateType>) {
    const [state, update] = React.useState(initialState);

    const memoState = React.useMemo(() => state, [state]);
    const memoUpdate = React.useCallback(update, [update]);

    return (
      <StateCtx.Provider value={memoState}>
        <UpdateCtx.Provider value={memoUpdate}>{children}</UpdateCtx.Provider>
      </StateCtx.Provider>
    );
  }

  const useCtxState = createUseCtx(StateCtx);
  const useCtxUpdate = createUseCtx(UpdateCtx);

  return { StateCtx, UpdateCtx, useCtxState, useCtxUpdate, Provider };
}
