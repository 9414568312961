import { Button, Permission } from '../../components';
import { ResourceType } from '../../generated/graphql';
import { useToggle } from '../../hooks';
import { EditRoleDialog } from './edit-role-dialog';

interface Props {
  setToggle: (id: string) => void;
}

export function AddRole({ setToggle }: Props) {
  const [showDialog, toggle] = useToggle();

  return (
    <>
      <Permission type={ResourceType.Role}>
        <Button theme="outline" onClick={toggle}>
          Create role
        </Button>
      </Permission>
      {showDialog && <EditRoleDialog type="create" setToggle={setToggle} toggleDialog={toggle} />}
    </>
  );
}
