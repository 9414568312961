import clsx from 'clsx';
import { atom, useAtom } from 'jotai';
import { Resizable } from 're-resizable';
import { LegacyRef, ReactNode, useEffect, useState } from 'react';
import { Icon } from '../../Icon';
import { SIZE } from '../configs/SX';

export type BottomNavStateAtomType = {
  bottomNavRef?: LegacyRef<HTMLDivElement>;
  bottomNavContent?: ReactNode;
  bottomNavShow?: boolean;
};
export const BottomNavStateAtom = atom<BottomNavStateAtomType>({
  bottomNavRef: undefined,
  bottomNavContent: null,
  bottomNavShow: false
});

export type BottomNavProps = null;
const MIN_HEIGHT = 224;
const DEFAULT_HEIGHT = 500;

const BottomNav = () => {
  const [bottomNavState] = useAtom(BottomNavStateAtom);
  const [height, setHeight] = useState(0);
  const [isResizing, setIsResizing] = useState(false);
  const [closed, setClosed] = useState(false);

  /**
   * If bottomNavShow is false, set closed to true after 200ms
   * This allows the transition to finish before the bottomNav is hidden
   * If bottomNavShow is true, set closed to false
   */
  useEffect(() => {
    if (!bottomNavState.bottomNavShow) {
      setTimeout(() => {
        setClosed(true);
      }, 200);
    } else {
      setClosed(false);
    }
  }, [bottomNavState.bottomNavShow, setClosed]);

  useEffect(() => {
    if (!bottomNavState.bottomNavShow) {
      setHeight(0);
    } else {
      const height = localStorage.getItem('bottomNavHeight');
      if (height) {
        const parsedHeight = parseInt(height);
        if (parsedHeight >= MIN_HEIGHT) {
          setHeight(parsedHeight);
        } else {
          setHeight(MIN_HEIGHT);
        }
      } else {
        setHeight(DEFAULT_HEIGHT);
      }
    }
  }, [bottomNavState.bottomNavShow, setHeight]);

  useEffect(() => {
    if (height > 100) {
      const bottomNavHeight = localStorage.getItem('bottomNavHeight');
      if (bottomNavHeight) {
        const parsedHeight = parseInt(bottomNavHeight);
        if (parsedHeight !== height) {
          localStorage.setItem('bottomNavHeight', height.toString());
        }
      } else {
        localStorage.setItem('bottomNavHeight', height.toString());
      }
    }
  }, [height]);

  const DRAWER_SX = clsx(!isResizing && 'transition-all duration-200 ease-in-out');

  return !bottomNavState.bottomNavShow && closed ? null : (
    <span
      id="BottomNav"
      className="sticky bottom-0 z-90 box-border flex rounded-t-lg border border-gray-300 bg-gray-100 shadow-bottomNav"
    >
      <Resizable
        className={DRAWER_SX}
        handleClasses={{
          right: 'pointer-events-none',
          bottom: 'pointer-events-none',
          left: 'pointer-events-none',
          topRight: 'pointer-events-none',
          bottomRight: 'pointer-events-none',
          bottomLeft: 'pointer-events-none',
          topLeft: 'pointer-events-none',
          top: `
            !w-[initial] flex flex-row items-center justify-center
            bg-transparent hover:bg-gray-200 active:bg-gray-300 transition
            absolute !top-[0.3rem] !right-2 !left-2 text-gray-400 hover:text-gray-500 active:text-gray-600
            rounded !h-[5px]
          `
        }}
        handleComponent={{ top: <Icon name="ResizeHandleH" size="lg" /> }}
        maxHeight={'75vh'}
        minWidth="100%"
        size={{ height: height, width: '100%' }}
        onResizeStart={() => {
          setIsResizing(true);
        }}
        onResizeStop={(e, direction, ref, d) => {
          setIsResizing(false);
          setHeight(h => h + d.height);
        }}
      >
        {closed ? null : (
          <div
            className="flex flex-col h-full w-full overflow-clip"
            ref={bottomNavState.bottomNavRef}
          >
            {!bottomNavState.bottomNavContent ? null : bottomNavState.bottomNavContent}
          </div>
        )}
      </Resizable>
    </span>
  );
};
export default BottomNav;
