import * as React from 'react';

import { EditableFieldsetFragment } from '../generated/graphql';
import { FieldsetReducerActions } from '../utils';

type FieldsetContextType = EditableFieldsetFragment | undefined;
type FieldsetDispatchContextType = React.Dispatch<FieldsetReducerActions>;

interface FieldsetProviderProps {
  children: React.ReactNode;
  fieldset: FieldsetContextType;
  dispatch: FieldsetDispatchContextType;
}

export const FieldsetContext = React.createContext<FieldsetContextType>(undefined);
export const FieldsetDispatchContext = React.createContext<FieldsetDispatchContextType | undefined>(
  undefined
);

export function FieldsetProvider({ fieldset, dispatch, children }: FieldsetProviderProps) {
  return (
    <FieldsetContext.Provider value={fieldset}>
      <FieldsetDispatchContext.Provider value={dispatch}>
        {children}
      </FieldsetDispatchContext.Provider>
    </FieldsetContext.Provider>
  );
}

export function useFieldsetState() {
  const contextIsDefined = React.useContext(FieldsetContext);
  if (contextIsDefined == null) {
    throw new Error('useFieldset must be used within a FieldsetProvider');
  }
  return contextIsDefined;
}

export function useFieldsetDispatch() {
  const contextIsDefined = React.useContext(FieldsetDispatchContext);
  if (contextIsDefined == null) {
    throw new Error('useFieldsetDispatch must be used within a FieldsetDispatchProvider');
  }
  return contextIsDefined;
}
