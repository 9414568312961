import { FieldDetail, FieldPill } from '../../../components';
import { ConnectionFragment, SyncMode } from '../../../generated/graphql';
import { isModelMapping, isOverrideMapping, LocalIdentityMapping, Mapping } from '../../../utils';

import { Icon } from '~/components';
interface Props {
  syncMode: SyncMode | null;
  hasOptionalTargetMappings?: boolean;
  targetConnection?: ConnectionFragment | null;
  mappings: Array<Mapping | LocalIdentityMapping>;
}

export function FieldMappingsSummary(props: Props) {
  return (
    <>
      {props.mappings.map((mapping, index) => {
        if (!(isModelMapping(mapping) || isOverrideMapping(mapping))) {
          return null;
        }
        return (
          <div key={index} className="flex items-center">
            {isModelMapping(mapping) ? (
              <FieldPill
                type="logo"
                img={mapping.model?.fieldset.connection.type.id}
                text={mapping.model?.label}
                disabled={!mapping.model}
                tooltipContent={
                  <FieldDetail
                    theme="dark"
                    logoId={mapping.model?.fieldset.connection?.type.id}
                    modelName={mapping.model?.fieldset.name}
                    columnName={mapping.model?.sourceName}
                    type={mapping.model?.type}
                  />
                }
              />
            ) : (
              <FieldPill text={mapping.overrideValue} img="TypeString" />
            )}

            {!props.hasOptionalTargetMappings && (
              <>
                <Icon name="ArrowNarrowRight" className="mx-1 h-5 w-5 text-gray-500" />
                {mapping.target && (
                  <FieldPill
                    type="logo"
                    img={props.targetConnection?.type.id}
                    text={mapping.target.name}
                    tooltipContent={
                      <FieldDetail
                        theme="dark"
                        logoId={props.targetConnection?.type.id}
                        columnName={mapping?.target.id}
                        type={mapping.target.type}
                        options={
                          props.syncMode === SyncMode.UpdateOrCreate && (
                            <>
                              <Icon name="RefreshSmall" className="h-4 w-4" />
                              <p className="text-xs">
                                {mapping.syncMode === SyncMode.Create
                                  ? 'Create only'
                                  : 'Update or create'}
                              </p>
                            </>
                          )
                        }
                      />
                    }
                  />
                )}
              </>
            )}
          </div>
        );
      })}
    </>
  );
}
