import { useQuery } from '@apollo/client';
import cx from 'clsx';
import * as React from 'react';
import { v4 as uuid } from 'uuid';

import { Button, JsonViewer, Truncator } from '../../../components';
import { Dialog } from '~/components/v3';
import ErrorText from '../../../components/v2/feedback/ErrorText';
import LoadingDots from '../../../components/v2/feedback/LoadingDots';
import { RequestFragment, WebhookRequestsDocument } from '../../../generated/graphql';
import { useToggle } from '../../../hooks';
import { hasItems } from '../../../utils';

const rowStyles = 'border-t border-gray-300 truncate';
const headingStyles = 'text-sm text-gray-800 font-semibold';

function Row({ request }: { request: RequestFragment }) {
  const [showPanel, togglePanel] = useToggle();

  const rowTextStyles = cx(
    'py-2.5 px-3 text-sm text-gray-800 truncate group-hover:bg-indigo-50',
    showPanel && 'bg-indigo-100'
  );

  return (
    <>
      <div className="group contents cursor-pointer" onClick={togglePanel}>
        <div className={rowStyles}>
          <Truncator content={request.id}>
            <p className={rowTextStyles}>{request.id}</p>
          </Truncator>
        </div>

        <div className={rowStyles}>
          <p className={rowTextStyles}>{request.method}</p>
        </div>

        <div className={rowStyles}>
          <Truncator content={request.url}>
            <p className={rowTextStyles}>{request.url}</p>
          </Truncator>
        </div>
      </div>
      <div
        className={cx(
          'animate-fadeIn truncate',
          showPanel ? 'col-span-full my-2 block space-y-2 px-3 pb-1.5' : 'hidden'
        )}
      >
        <h3 className={headingStyles}>Request Headers</h3>
        <div className="space-y-1.5 truncate rounded border border-gray-300 bg-gray-50 py-2 px-2.5 font-mono text-xs">
          {request.headers.map(header => (
            <p key={header.name} className="truncate">
              <span className="font-semibold text-gray-700">{header.name}</span>:{' '}
              <span className="text-gray-800">{header.values}</span>
            </p>
          ))}
        </div>
        <h3 className={headingStyles}>Request Payload</h3>
        <div className="rounded border border-gray-300 bg-gray-50 py-1">
          <JsonViewer data={request.body} className="text-xs" />
        </div>
        {request.response && (
          <>
            <h3 className={headingStyles}>Response status</h3>
            <p className="space-y-1.5 rounded border border-gray-300 bg-gray-50 py-2 px-2.5 font-mono text-xs text-gray-800">
              {request.response.statusCode}
            </p>
            <h3 className={headingStyles}>Response Headers</h3>
            <div className="space-y-1.5 rounded border border-gray-300 bg-gray-50 py-2 px-2.5 font-mono text-xs">
              {request.response?.headers.map(header => (
                <p key={header.name} className="truncate">
                  <span className="font-semibold text-gray-700">{header.name}</span>:{' '}
                  <span className="text-gray-800">{header.values}</span>
                </p>
              ))}
            </div>
            <h3 className={headingStyles}>Response Body</h3>
            {request.response?.error && <ErrorText>{request.response.error}</ErrorText>}
            <pre className="rounded border border-gray-300 bg-gray-50 py-2 px-2.5 font-mono text-xs text-gray-800">
              {request.response?.body !== '' ? (
                request.response?.body
              ) : (
                <span className="text-gray-500">(Body is empty)</span>
              )}
            </pre>
          </>
        )}
      </div>
    </>
  );
}

interface WebhookRequestProps {
  referenceId: string;
  handleClose: () => void;
}

export const WebhookSyncRequests = (props: WebhookRequestProps) => {
  const [requests, setRequests] = React.useState<RequestFragment[]>([]);
  const { error, loading } = useQuery(WebhookRequestsDocument, {
    variables: { refID: props.referenceId },
    onCompleted: data => {
      if (!data || !data.webhookRequests) {
        return;
      }
      setRequests(data.webhookRequests);
    }
  });

  return (
    <Dialog
      show={true}
      heading="Webhook deliveries"
      size="xl"
      onDismiss={props.handleClose}
      actions={
        <Button theme="primary" onClick={props.handleClose}>
          Close
        </Button>
      }
    >
      {error && (
        <span className="mb-4">
          <ErrorText>{error.message}</ErrorText>{' '}
        </span>
      )}
      {loading ? (
        <div className="flex h-80 items-center justify-center">
          <LoadingDots />
        </div>
      ) : hasItems(requests) ? (
        <section className="grid grid-cols-[2fr,5rem,3fr]">
          {['ID', 'Method', 'URL'].map(label => (
            <label
              key={uuid()}
              className="mb-2 px-3 text-xs font-semibold uppercase tracking-widest text-gray-600"
            >
              {label}
            </label>
          ))}
          {requests.map(request => (
            <Row key={uuid()} request={request} />
          ))}
        </section>
      ) : (
        <h2>No requests recorded.</h2>
      )}
    </Dialog>
  );
};
