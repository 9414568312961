import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { EditableFieldsetFragment, FieldsetConfiguration } from '../generated/graphql';
import {
  FieldsetFormValues,
  ModeConfiguration,
  ModeRefs,
  Selectable,
  updateFieldsetForm
} from '../utils';
import { useFieldsetDispatch, useFieldsetState } from '.';

export function useModeSwitcher<ConfType extends FieldsetConfiguration>(
  conf: ModeConfiguration<ConfType>,
  modeCallbacks?: Record<string, () => void>
) {
  const fieldset = useFieldsetState();
  const dispatch = useFieldsetDispatch();
  const { getValues, reset } = useFormContext<FieldsetFormValues>();

  const [modes] = React.useState(() =>
    conf.modes.reduce((modes: Record<string, string>, { name, label }) => {
      modes[name] = label;
      return modes;
    }, {})
  );
  const [mode, setMode] = React.useState<keyof typeof modes>(
    () =>
      conf.modes.reduce((mode, { name }) => {
        if (name !== mode && (fieldset?.configuration as ConfType)?.[name as keyof ConfType]) {
          return name;
        }
        return mode;
      }, conf.modes[0].name) // first item in conf arr is default
  );
  const refs = React.useRef<ModeRefs>(
    conf.modes.reduce((refs: ModeRefs, cv) => {
      refs[cv.name] = null;
      return refs;
    }, {})
  );

  const handleMode = React.useCallback(
    (opt: Selectable | null) => {
      const config = getValues('configuration') as ConfType;
      if (!fieldset || !config || !opt || opt.value === mode) {
        return;
      }

      if (config[mode as keyof ConfType] || mode === 'fields') {
        refs.current[mode] = {
          ...fieldset,
          ...getValues(),
          ...conf.saveObj(mode, config)
        };
      }

      setMode(opt.value);
      const realName = getValues('realName');
      const confReset = typeof conf.reset === 'function' ? conf.reset(config) : conf.reset;
      // Not entirely sure why, but this reset is needed, possibly to flush hook-form?
      // State gets out of sync and causes TypeError/app crashes when switching modes
      reset(
        {
          realName,
          fields: [],
          relatedTo: [],
          primaryKey: null,
          ...confReset
        },
        { keepDirty: true }
      );

      let newFieldset: EditableFieldsetFragment = {
        ...fieldset,
        fields: [],
        trackableFields: [],
        relatedTo: [],
        primaryKey: null
      };
      const temp = refs.current[opt.value];
      if (temp) {
        if (fieldset.connection.id !== temp?.connection.id) {
          refs.current[opt.value] = null;
          return;
        }
        newFieldset = temp;
      } else {
        if (modeCallbacks?.[opt.value] != null) {
          modeCallbacks[opt.value]();
          return;
        }
      }
      // pretty sure this is a guard because the
      // name was getting messed up during switches
      newFieldset.realName = realName;
      dispatch({ type: 'replace', fieldset: newFieldset });
      updateFieldsetForm(reset, getValues, newFieldset);
    },
    [conf, fieldset, getValues, mode, modeCallbacks, reset, dispatch]
  );

  return { modes, mode, handleMode };
}
