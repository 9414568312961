import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  label?: ReactNode;
}

export function Footer({ children, label }: Props) {
  return (
    <footer
      className="
      bg-gray-100 rounded p-2 flex flex-row w-full items-center justify-between
      "
    >
      <p className="text-gray-800 text-sm">{label}</p>
      <div className="flex gap-4 items-center justify-end">{children}</div>
    </footer>
  );
}
