import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import PageLayout from '../../components/v2/layout/PageLayout';
import Route404 from '../../components/v2/templates/Route404';
import { routes } from '../../utils';
import TeamRoles from './team-roles';
import TeamTag from './team-tag';
import TeamUsers from './team-users';

const Team = () => {
  useLocation();
  const navConf = [
    {
      label: 'Users',
      to: routes.teamUsers,
      path: routes.teamUsers,
      slug: 'users'
    },
    {
      label: 'Roles',
      to: routes.teamRoles,
      path: routes.teamRoles,
      slug: 'roles'
    },
    {
      label: 'Access control',
      to: routes.teamAccessControl,
      path: routes.teamAccessControl,
      slug: 'access-control'
    }
  ];
  return (
    <PageLayout pageNavConf={navConf}>
      <div className="space-y-4 p-6">
        <Switch>
          <Route exact path={routes.teamUsers}>
            <Helmet title="Users | Polytomic" />
            <TeamUsers />
          </Route>
          <Route exact path={routes.teamRoles}>
            <Helmet title="Roles | Polytomic" />
            <TeamRoles />
          </Route>
          <Route exact path={routes.teamAccessControl}>
            <Helmet title="Access control | Polytomic" />
            <TeamTag />
          </Route>
          <Redirect exact from={routes.team} to={routes.teamUsers} />
          <Route path="*">
            <Route404 />
          </Route>
        </Switch>
      </div>
    </PageLayout>
  );
};

export default Team;
