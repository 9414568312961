import cx from 'clsx';
import { ReactNode } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Icon } from '~/components';
import { Button, Checkbox, FormBuilder, MyInput } from '../../../components';
import { useAuth, useMountEffect, useToggle } from '../../../hooks';
import { BulkSyncForm } from '../../../utils';
import { StageCard } from '../../syncs/sync-config';
import { BulkSyncSummary } from './bulk-sync-summary';
import { NestedSchemaForm, resolveRef } from '~/components/common/SchemaForm';
import { Discover } from '~/generated/graphql';
import Select from '~/components/v2/inputs/Select';

function AdvancedSettingsToggle(props: { children: ReactNode }) {
  const [show, toggle] = useToggle();
  return (
    <div className={cx('rounded-b-lg bg-gray-50 pt-3', show ? 'pb-6' : 'pb-3')}>
      <Button theme="ghost" className="ml-2" onClick={toggle}>
        <Icon
          name="Disclosure"
          className={cx(
            '-ml-1 mr-0.5 h-5 w-5 text-gray-500 group-hover:text-gray-800',
            show && 'rotate-90 transform'
          )}
        />
        Advanced settings
      </Button>
      {show && props.children}
    </div>
  );
}

interface DiscoveryFieldProps {
  name: 'tableDiscovery' | 'fieldDiscovery';
  label: string;
}

function DiscoveryField({ name, label }: DiscoveryFieldProps) {
  const { control, setValue } = useFormContext<BulkSyncForm>();
  const value = useWatch({ control, name });
  const isChecked = value && !(value === Discover.None);

  const options = [
    {
      label: 'All fields',
      value: Discover.All
    },
    {
      label: 'Only incremental fields',
      value: Discover.OnlyIncremental
    },
    {
      label: 'Only non-incremental fields',
      value: Discover.OnlyNonIncremental
    }
  ];

  return (
    <div>
      <Checkbox
        checked={isChecked}
        label={label}
        onChange={e => {
          if (e.target.checked) {
            setValue(name, Discover.All);
          } else {
            setValue(name, Discover.None);
          }
        }}
      />
      {isChecked && (
        <div className="mt-1 mb-2 ml-5">
          <Select
            variant="filled"
            options={options}
            onChange={v => setValue(name, v.value as Discover)}
            value={options.find(o => o.value === value) || options[0]}
            inputWidth="w-fit"
            optionsWidth=""
          />
        </div>
      )}
    </div>
  );
}

export function AdvancedSettings() {
  const { user } = useAuth();
  const { control, register, watch } = useFormContext<BulkSyncForm>();
  const destination = useWatch({ control, name: 'destination' });
  const advancedSettings = useWatch({ control, name: 'destinationConfiguration' });
  const advanced = destination
    ? resolveRef(destination.configurationSchema, '#/definitions/WarehouseAdvancedConfiguration') ||
      resolveRef(destination.configurationSchema, '#/definitions/AdvancedConfiguration')
    : undefined;
  const bulkSyncConfigForm = useWatch({ control });
  const schemaLabel = watch('source.schemaLabel') || { singular: 'object', plural: 'objects' };

  return (
    <AdvancedSettingsToggle>
      <div className="pt-6 animate-fadeIn px-6 space-y-4 flex flex-col">
        <section className="grid w-full max-w-xl gap-4">
          <DiscoveryField
            name="fieldDiscovery"
            label={`Automatically add new fields on selected ${schemaLabel.plural}`}
          />
          <DiscoveryField
            name="tableDiscovery"
            label={`Automatically add new ${schemaLabel.plural}`}
          />
        </section>
        <section className="grid w-full max-w-xl">
          {bulkSyncConfigForm.source?.configurationSchema && (
            <FormBuilder<BulkSyncForm>
              features={user?.organization.features}
              schema={bulkSyncConfigForm.source?.configurationSchema}
              prefix="sourceConfiguration"
            />
          )}
        </section>
        <section className="grid w-full max-w-xl">
          {advanced && (
            <NestedSchemaForm
              prefix="destinationConfiguration.advanced"
              schema={advanced}
              values={advancedSettings || {}}
            />
          )}
        </section>
        <Checkbox
          {...register('disableRecordTimestamps')}
          label="Exclude Polytomic timestamp columns"
        />
      </div>
    </AdvancedSettingsToggle>
  );
}

export type StageBulkSummaryProps = {
  step: number;
  totalSchemaCount?: number;
  totalSelectedSchemaCount?: number;
};

export const StageBulkSummary = ({ step, totalSelectedSchemaCount }: StageBulkSummaryProps) => {
  const { register, control, getValues, setValue } = useFormContext<BulkSyncForm>();

  const bulkSync = useWatch({ control }) as BulkSyncForm;

  useMountEffect(() => {
    if (!getValues('name')) {
      setValue(
        'name',
        cx(
          getValues('source.connection.name'),
          'to',
          getValues('destination.connection.name'),
          'sync'
        )
      );
    }
  });

  return (
    <StageCard
      hasStickyHeader={true}
      step={step}
      header="Sync summary"
      className="px-6 pb-6"
      footer={<AdvancedSettings />}
    >
      <BulkSyncSummary
        bulkSync={bulkSync}
        totalSelectedSchemaCount={totalSelectedSchemaCount}
        nameControls={
          <>
            <p className="self-center text-sm font-semibold text-gray-500">Name</p>
            <MyInput {...register('name')} />
          </>
        }
      />
    </StageCard>
  );
};
